import moment from "moment";
import { generateRandomColor } from "../../helpers/Strategy";
import {
  LOGOUT,
  RESET_DEFAULT,
  RESET_FACTOR_TESTING_REPORT,
} from "../actions/Type";
import * as FactorTestingTypes from "../types/FactorTesting";
import { CREATE_BACKTEST_SUCCESS } from "../types/Stock";

const initialState = {
  activeTab: 0,
  filterList: [],
  rebalanceList: [],
  currencyList: [],
  savedReports: [],
  loadedReport: null,
  loadedCsvReport: {
    byId: {},
  },
  filters: {
    country_list: [],
    filter_col: [],
    position_col: [],
    size_col: [],
  },
  selectedFilters: {
    country: "all",
    filter: "all",
    position: "all",
    size: "all",
  },
  reportStrategies: [],
  loading: false,
};

function saveReport(
  state,
  best_worst_periods,
  corellation_matrix,
  returns_distribution,
  rolling_corr_chart,
  rolling_returns_volatility_sharp,
  underwater_chart,
  win_rate_table,
  strats
) {
  return {
    byId: {
      ...Object.assign(
        {},
        ...Object.keys(strats).map((key) => {
          return {
            [key]: {
              ...state.loadedCsvReport.byId[key],
              id: key,
              name: key,
              color: generateRandomColor(),
              stats: {
                periodRets: strats[key]["performance_summary"],
                rollingRets: rolling_returns_volatility_sharp[key]
                  ? rolling_returns_volatility_sharp[key]
                  : null,
                cumulitiveRets: strats[key]["cumulative_returns"],
                yearly: strats[key]["yearly_returns"],
                monthly: saveMonthlyReturn(strats[key]["returns"].strategy),
                strategy_returns: strats[key]["returns"],
                best_worst_periods: best_worst_periods[key],
                returns_distribution:
                  returns_distribution.strategy_name === key
                    ? returns_distribution
                    : null,
                underwater:
                  underwater_chart.strategy_name === key
                    ? underwater_chart
                    : null,
                worst_drawdowns: strats[key]["worst_drawdowns"],
                DD_main_table: strats[key]["DD_main_table"],
                beta: strats[key]["beta"],
                alpha: strats[key]["alpha"],
                winsorized_statistics: strats[key]["winsorized_statistics"],
                correlation_to_style: strats[key]["correlation_to_style"],
                alpha_correlation: strats[key]["alpha_correlation"],
                dynamic_alpha_table: strats[key]["dynamic_alpha_table"],
                sample_comparison: strats[key]["sample_comparison"],
              },
            },
          };
        })
      ),
    },
    winRate: win_rate_table != null ? win_rate_table : {},
    correllation_matrix:
      corellation_matrix != null && Object.keys(corellation_matrix).length > 0
        ? corellation_matrix
        : {},
    rollingCorellation: rolling_corr_chart,
  };
}

function saveMonthlyReturn(data) {
  const res = {};

  Object.keys(data).forEach((item) => {
    const [year, unformatedMonth] = item.split("-");

    const month = moment(unformatedMonth).format("M");

    res[month] = {};

    Object.keys(data).forEach((el) => {
      const [year2] = el.split("-");
      res[month] = {
        ...res[month],
        [year2]: data[`${year2}-${unformatedMonth}`],
      };
    });
  });

  return res;
}

export default function factorTesting(state = initialState, action) {
  switch (action.type) {
    case FactorTestingTypes.GET_FACTOR_TESTING_FILTER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_FILTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        filterList: action.payload.filter_list,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_FILTER_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case FactorTestingTypes.GET_FACTOR_TESTING_REBALANCE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_REBALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        rebalanceList: action.payload.rebalance_list,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_REBALANCE_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case FactorTestingTypes.GET_FACTOR_TESTING_CURRENCY_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_CURRENCY_SUCCESS: {
      return {
        ...state,
        loading: false,
        currencyList: action.payload.currency_list,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_CURRENCY_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case FactorTestingTypes.GET_FACTOR_TESTING_SAVED_REPORTS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_SAVED_REPORTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        savedReports: action.payload.saved_reports_list,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_SAVED_REPORTS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case FactorTestingTypes.SET_FACTOR_TESTING_SAVED_REPORT: {
      return {
        ...state,
        loading: false,
        loadedReport: action.payload,
      };
    }

    case FactorTestingTypes.LOAD_FACTOR_TESTING_SAVED_REPORT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.LOAD_FACTOR_TESTING_SAVED_REPORT_SUCCESS: {
      const {
        best_worst_periods,
        corellation_matrix,
        returns_distribution,
        rolling_corr_chart,
        rolling_returns_volatility_sharp,
        underwater_chart,
        win_rate_table,
        filters,
        ...strats
      } = action.payload.data[0];

      return {
        ...state,
        loading: false,
        loadedCsvReport: saveReport(
          state,
          best_worst_periods,
          corellation_matrix,
          returns_distribution,
          rolling_corr_chart,
          rolling_returns_volatility_sharp,
          underwater_chart,
          win_rate_table,
          strats
        ),
        filters,
        reportStrategies: Object.keys(strats),
        selectedFilters: initialState.selectedFilters,
      };
    }
    case FactorTestingTypes.LOAD_FACTOR_TESTING_SAVED_REPORT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case CREATE_BACKTEST_SUCCESS: {
      const {
        best_worst_periods,
        corellation_matrix,
        returns_distribution,
        rolling_corr_chart,
        rolling_returns_volatility_sharp,
        underwater_chart,
        win_rate_table,
        filters,
        ...strats
      } = action.payload;

      return {
        ...state,
        loading: false,
        loadedCsvReport: saveReport(
          state,
          best_worst_periods,
          corellation_matrix,
          returns_distribution,
          rolling_corr_chart,
          rolling_returns_volatility_sharp,
          underwater_chart,
          win_rate_table,
          strats
        ),
        filters,
        reportStrategies: Object.keys(strats),
        selectedFilters: initialState.selectedFilters,
      };
    }

    case RESET_FACTOR_TESTING_REPORT: {
      return {
        ...state,
        loadedCsvReport: initialState.loadedCsvReport,
        filters: initialState.filters,
        selectedFilters: initialState.selectedFilters,
        reportStrategies: initialState.reportStrategies,
      };
    }

    case FactorTestingTypes.CALCULATE_FACTOR_TESTING_CSV_REPORT_START: {
      return {
        ...state,
        loading: true,
        loadedCsvReport: {
          byId: {},
        },
      };
    }
    case FactorTestingTypes.CALCULATE_FACTOR_TESTING_CSV_REPORT_SUCCESS: {
      const {
        best_worst_periods,
        corellation_matrix,
        returns_distribution,
        rolling_corr_chart,
        rolling_returns_volatility_sharp,
        underwater_chart,
        win_rate_table,
        filters,
        ...strats
      } = action.payload;

      return {
        ...state,
        loading: false,
        loadedReport: null,
        loadedCsvReport: saveReport(
          state,
          best_worst_periods,
          corellation_matrix,
          returns_distribution,
          rolling_corr_chart,
          rolling_returns_volatility_sharp,
          underwater_chart,
          win_rate_table,
          strats
        ),
        filters,
        reportStrategies: Object.keys(strats),
        selectedFilters: initialState.selectedFilters,
      };
    }
    case FactorTestingTypes.CALCULATE_FACTOR_TESTING_CSV_REPORT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case FactorTestingTypes.SAVE_FACTOR_TESTING_REPORT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.SAVE_FACTOR_TESTING_REPORT_SUCCESS: {
      const { status, ...data } = action.payload;

      return {
        ...state,
        loading: false,
        savedReports: [...state.savedReports, { ...data }],
        loadedReport: { ...data },
      };
    }
    case FactorTestingTypes.SAVE_FACTOR_TESTING_REPORT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.UPDATE_FACTOR_TESTING_REPORT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.UPDATE_FACTOR_TESTING_REPORT_SUCCESS: {
      const { status, ...data } = action.payload;

      return {
        ...state,
        loading: false,
        savedReports: state.savedReports.map((item) =>
          item.report_id === data.report_id ? { ...item, ...data } : item
        ),
        loadedReport: { ...data },
      };
    }
    case FactorTestingTypes.UPDATE_FACTOR_TESTING_REPORT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case FactorTestingTypes.GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadedCsvReport: {
          ...state.loadedCsvReport,
          winRate: action.payload,
        },
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadedCsvReport: {
          ...state.loadedCsvReport,
          byId: {
            ...state.loadedCsvReport.byId,

            [action.payload.strategy_name]: {
              ...state.loadedCsvReport.byId[action.payload.strategy_name],
              stats: {
                ...state.loadedCsvReport.byId[action.payload.strategy_name]
                  .stats,
                returns_distribution: action.payload,
              },
            },
          },
        },
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadedCsvReport: {
          ...state.loadedCsvReport,
          byId: {
            ...state.loadedCsvReport.byId,
            ...Object.assign(
              {},
              ...Object.keys(action.payload).map((key) => ({
                [key]: {
                  ...state.loadedCsvReport.byId[key],
                  stats: {
                    ...state.loadedCsvReport.byId[key].stats,
                    rollingRets: action.payload[key],
                  },
                },
              }))
            ),
          },
        },
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadedCsvReport: {
          ...state.loadedCsvReport,
          byId: {
            ...state.loadedCsvReport.byId,
            [action.payload.strategy_name]: {
              ...state.loadedCsvReport.byId[action.payload.strategy_name],
              stats: {
                ...state.loadedCsvReport.byId[action.payload.strategy_name]
                  .stats,
                underwater: action.payload,
              },
            },
          },
        },
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadedCsvReport: {
          ...state.loadedCsvReport,
          rollingCorellation: action.payload,
        },
      };
    }
    case FactorTestingTypes.GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case FactorTestingTypes.DELETE_FACTOR_TESTING_REPORT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FactorTestingTypes.DELETE_FACTOR_TESTING_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        savedReports: state.savedReports.filter(
          (report) => report.report_id !== action.payload.report_id
        ),
      };
    }
    case FactorTestingTypes.DELETE_FACTOR_TESTING_REPORT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case FactorTestingTypes.SET_FACTOR_TESTING_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }

    case FactorTestingTypes.SET_FACTOR_TESTING_FILTER: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case RESET_DEFAULT: {
      return {
        ...state,
        activeTab: 0,
        loadedReport: null,
        loadedCsvReport: {
          byId: {},
        },
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
