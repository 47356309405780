const PieChartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="512" viewBox="0 0 32 32" width="512">
    <g data-name="11">
      <path d="M16 2A14 14 0 0 0 2 16a1 1 0 0 0 1 1h12v12a1 1 0 0 0 1 1 14 14 0 0 0 0-28zm1 26V16a1 1 0 0 0-1-1H4a12 12 0 1 1 13 13z" />
      <path d="M12 19H3.63a1 1 0 0 0-1 1.31 14 14 0 0 0 9 9 1 1 0 0 0 1.31-1V20a1 1 0 0 0-.94-1zm-1 7.9A12 12 0 0 1 5.1 21H11z" />
    </g>
  </svg>
);

export default PieChartIcon;
