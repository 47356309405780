const dashboardRoutes = {
  dashboard: "/",
  portfolio: "/portfolio",
  factors: "/factors",
  xRay: "/x-ray",
  factorTesting: "/factor-testing",
  stockScreener: "/stock-screener",
  signup: "/signup",
  login: "/login",
  userGuide: "/user-guide",
  privacy: "/privacy",
  resetPassword: "/reset-password",
  admin: "/admin",
  modelStrategies: "/model-strategies"
};

export { dashboardRoutes };
