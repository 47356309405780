import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import BasicButton from "../../../components/Atoms/BasicButton";
import LoadingCircular from "../../../components/Atoms/LoadingCircular/LoadingCircular";
import { generateErrorMessage } from "../../../helpers/Error";
import SnackBarUtils from "../../../helpers/SnackBarUtils";
import SupportApi from "../../../services/Support";
import * as Yup from "yup";

const FeedbackSchema = Yup.object().shape({
  subject: Yup.string().required("Required"),
  body: Yup.string().required("Required"),
});

function FeedbackForm() {
  const user = JSON.parse(localStorage.getItem("user"));

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      subject: "",
      body: "",
    },
    validationSchema: FeedbackSchema,
    onSubmit: async (values) => {
      try {
        SupportApi.sendFeedback(values, user.access_token).then(
          ({ status }) => {
            SnackBarUtils.success(status);
          }
        );
      } catch (error) {
        generateErrorMessage(error);
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap={2}>
        <FormControl sx={{ width: "100%" }}>
          <OutlinedInput
            value={values.subject}
            name="subject"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Subject"
            size="small"
            disabled={isSubmitting}
            error={touched.subject && Boolean(errors.subject)}
          />
          {touched.subject && Boolean(errors.subject) && (
            <FormHelperText error>{errors.subject}</FormHelperText>
          )}
        </FormControl>

        <FormControl sx={{ width: "100%" }}>
          <TextField
            multiline
            rows={4}
            maxRows={6}
            value={values.body}
            name="body"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Text"
            size="small"
            disabled={isSubmitting}
            type="textarea"
            error={touched.body && Boolean(errors.body)}
          />
          {touched.body && Boolean(errors.body) && (
            <FormHelperText error>{errors.body}</FormHelperText>
          )}
        </FormControl>
        {isSubmitting ? (
          <LoadingCircular />
        ) : (
          <BasicButton title={"Send"} variant="contained" type="submit" />
        )}
      </Box>
    </form>
  );
}

export default FeedbackForm;
