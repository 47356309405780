import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
} from "../actions/Type";
import { IS_ADMIN_SUCCESS } from "../types/Admin";
import { UPDATE_DEFAULT_DASHBOARD_PRESET } from "../types/Dashboard";
import * as UserTypes from "../types/User";

export const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  isLoggedIn: user ? true : false,
  user: user?.name ? user.name : null,
  userData:
    {
      ...user,
      strategies_col: [],
      default_strategy: false,
    } || null,
  factorsGroups: [],
  factorsGroupsUpdateLoader: false,
  factorsGroupsLoader: false,
  initLoader: false,
  isAdmin: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_SUCCESS: {
      return {
        ...state,
        user: action.payload.name,
        isLoggedIn: true,
      };
    }

    case REGISTER_FAIL: {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload.name,
        userData: action.payload,
        isLoggedIn: true,
      };
    }

    case LOGIN_FAIL: {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userData: null,
      };
    }

    case LOGOUT: {
      return {
        isLoggedIn: false,
        user: null,
        userData: null,
        factorsGroups: [],
        factorsGroupsUpdateLoader: false,
        factorsGroupsLoader: false,
        initLoader: false,
        isAdmin: false,
      };
    }

    case UserTypes.UPDATE_USER_FACTORS_GROUPING_START: {
      return {
        ...state,
        factorsGroupsUpdateLoader: true,
      };
    }
    case UserTypes.UPDATE_USER_FACTORS_GROUPING_SUCCESS: {
      return {
        ...state,
        factorsGroupsUpdateLoader: false,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      };
    }
    case UserTypes.UPDATE_USER_FACTORS_GROUPING_FAILED: {
      return {
        ...state,
        factorsGroupsUpdateLoader: false,
      };
    }

    case UserTypes.GET_FACTORS_GROUPING_START: {
      return {
        ...state,
        factorsGroupsLoader: true,
      };
    }
    case UserTypes.GET_FACTORS_GROUPING_SUCCESS: {
      return {
        ...state,
        factorsGroupsLoader: false,
        factorsGroups: action.payload.source_factors,
      };
    }
    case UserTypes.GET_FACTORS_GROUPING_FAILED: {
      return {
        ...state,
        factorsGroupsLoader: false,
      };
    }

    case UPDATE_DEFAULT_DASHBOARD_PRESET: {
      return {
        ...state,
        userData: {
          ...state.userData,
          preset: action.payload,
        },
      };
    }

    case UserTypes.INIT_APP_START: {
      return {
        ...state,
        initLoader: true,
      };
    }
    case UserTypes.INIT_APP_SUCCESS: {
      return {
        ...state,
        initLoader: false,
        userData: {
          ...state.userData,
          strategies_col: action.payload.strategies.strategies_col,
          default_strategy: action.payload.strategies.default_strategy,
        },
      };
    }
    case UserTypes.INIT_APP_FAILED: {
      return {
        ...state,
        initLoader: false,
      };
    }
    case IS_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isAdmin: action.payload.admin,
      };
    }

    case UserTypes.UPDATE_CURRENT_USER_NAME: {
      return {
        ...state,
        user: action.payload,
        userData: { ...state.userData, name: action.payload },
      };
    }

    default:
      return state;
  }
}
