export const CREATE_USER_START = "ADMIN.CREATE_USER_START";
export const CREATE_USER_SUCCESS = "ADMIN.CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "ADMIN.CREATE_USER_FAILED";

export const CHANGE_USER_PASSWORD_START = "ADMIN.CHANGE_USER_PASSWORD_START";
export const CHANGE_USER_PASSWORD_SUCCESS =
  "ADMIN.CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAILED = "ADMIN.CHANGE_USER_PASSWORD_FAILED";

export const GET_WORKING_GROUPS_START = "ADMIN.GET_WORKING_GROUPS_START";
export const GET_WORKING_GROUPS_SUCCESS = "ADMIN.GET_WORKING_GROUPS_SUCCESS";
export const GET_WORKING_GROUPS_FAILED = "ADMIN.GET_WORKING_GROUPS_FAILED";

export const GET_COMPANIES_START = "ADMIN.GET_COMPANIES_START";
export const GET_COMPANIES_SUCCESS = "ADMIN.GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILED = "ADMIN.GET_COMPANIES_FAILED";

export const GET_ROLES_START = "ADMIN.GET_ROLES_START";
export const GET_ROLES_SUCCESS = "ADMIN.GET_ROLES_SUCCESS";
export const GET_ROLES_FAILED = "ADMIN.GET_ROLES_FAILED";

export const GET_USERS_START = "ADMIN.GET_USERS_START";
export const GET_USERS_SUCCESS = "ADMIN.GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "ADMIN.GET_USERS_FAILED";

export const IS_ADMIN_START = "ADMIN.IS_ADMIN_START";
export const IS_ADMIN_SUCCESS = "ADMIN.IS_ADMIN_SUCCESS";
export const IS_ADMIN_FAILED = "ADMIN.IS_ADMIN_FAILED";

export const ADD_WORKING_GROUP_START = "ADMIN.ADD_WORKING_GROUP_START";
export const ADD_WORKING_GROUP_SUCCESS = "ADMIN.ADD_WORKING_GROUP_SUCCESS";
export const ADD_WORKING_GROUP_FAILED = "ADMIN.ADD_WORKING_GROUP_FAILED";

export const UPDATE_COMPANY_START = "ADMIN.UPDATE_COMPANY_START";
export const UPDATE_COMPANY_SUCCESS = "ADMIN.UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILED = "ADMIN.UPDATE_COMPANY_FAILED";

export const UPDATA_COMPANY_DEFAULT_STRATEGIES_START =
  "ADMIN.UPDATA_COMPANY_DEFAULT_STRATEGIES_START";
export const UPDATA_COMPANY_DEFAULT_STRATEGIES_SUCCESS =
  "ADMIN.UPDATA_COMPANY_DEFAULT_STRATEGIES_SUCCESS";
export const UPDATA_COMPANY_DEFAULT_STRATEGIES_FAILED =
  "ADMIN.UPDATA_COMPANY_DEFAULT_STRATEGIES_FAILED";

export const GET_LIST_STRATEGIES_START = "ADMIN.GET_LIST_STRATEGIES_START";
export const GET_LIST_STRATEGIES_SUCCESS = "ADMIN.GET_LIST_STRATEGIES_SUCCESS";
export const GET_LIST_STRATEGIES_FAILED = "ADMIN.GET_LIST_STRATEGIES_FAILED";

export const UPDATE_USER_DATA_START = "ADMIN.UPDATE_USER_DATA_START";
export const UPDATE_USER_DATA_SUCCESS = "ADMIN.UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAILED = "ADMIN.UPDATE_USER_DATA_FAILED";

export const UPDATE_USER_STATUS_START = "ADMIN.UPDATE_USER_STATUS_START";
export const UPDATE_USER_STATUS_SUCCESS = "ADMIN.UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILED = "ADMIN.UPDATE_USER_STATUS_FAILED";

export const UPDATE_WORKING_GROUP_START = "ADMIN.UPDATE_WORKING_GROUP_START";
export const UPDATE_WORKING_GROUP_SUCCESS =
  "ADMIN.UPDATE_WORKING_GROUP_SUCCESS";
export const UPDATE_WORKING_GROUP_FAILED = "ADMIN.UPDATE_WORKING_GROUP_FAILED";

export const DELETE_WORKING_GROUP_START = "ADMIN.DELETE_WORKING_GROUP_START";
export const DELETE_WORKING_GROUP_SUCCESS =
  "ADMIN.DELETE_WORKING_GROUP_SUCCESS";
export const DELETE_WORKING_GROUP_FAILED = "ADMIN.DELETE_WORKING_GROUP_FAILED";
