import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  list: {
    "&.MuiList-root": {
      overflow: "hidden",
      backgroundColor: theme.palette.primary.light,
      zIndex: 1,
    },
  },
  listItem: {
    zIndex: 10,
    height: "60px",
    "&.MuiListItem-root": {
      cursor: "pointer",
      padding: "0 30px",
      justifyContent: "center",
      "&.active": {
        boxShadow: "0px 0px 16px 12px rgba(30, 68, 73, 0.8)",
      },
      "&:hover": {
        backgroundColor: theme.palette.grey["900"],
        zIndex: 1,
      },
    },
  },
  listItemText: {
    color: "#fff",
    "& .MuiTypography-root": {
      fontFamily: theme.typography.fontFamily,
    },
  },
  listItemIcon: {
    "&.MuiListItemIcon-root": {
      maxWidth: "16px",
      minWidth: "inherit",
      marginRight: "12px",
      "& svg": {
        fill: "#fff",
        height: "auto",
      },
    },
  },
  [theme.breakpoints.down("xl")]: {
    listItemText: {
      display: "none",
    },
    listItemIcon: {
      "&.MuiListItemIcon-root": {
        marginRight: "unset",
      },
    },
    listItem: {
      width: "70px",
      "&.MuiListItem-root": {
        padding: "unset",
      },
    },
  },
}));

export default useStyles;
