import XLSX from "xlsx-js-style";
import {
  CUSTOM_MULTIFACTOR,
  CUSTOM_RATIO,
  DEFAULT_KEYS_INDUSTRY_COMPARISON,
} from "../features/StockScreener/consts";
import { monthDesc } from "./Constants";
import { getRoundedValueOrNaIfFalsy } from "./Utils";
import { checkColor } from "./XRay";

export class EditedStockFactor {
  constructor(
    id,
    name,
    type,
    normalize = false,
    value = 0,
    percentile = 0.5,
    screenBy = "",
    ratioOperator = null,
    childs = {},
    inUse = []
  ) {
    this.id = id;
    this.name = name;
    this.variable = type;
    this.normalize = normalize;
    this.value = value;
    this.percentile = percentile;
    this.screenBy = screenBy;
    this.ratioOperator = ratioOperator;
    this.childs = childs;
    this.inUse = inUse;
  }

  changeValueByKey(key, newValue) {
    this[key] = newValue;
    return this;
  }

  convertStockFactorToRequest() {
    const stock = {
      item_type: this.variable,
      name: this.name,
      value: this.value.toString(),
      percentile: this.percentile.toString(),
      normalize: this.normalize,
      screen_by: this.screenBy ? this.screenBy : null,
      ratio_operator: this.ratioOperator,
      childs: Object.values(this.childs).length
        ? Object.values(this.childs).map((child) =>
            child.convertStockFactorToRequest()
          )
        : [],
    };

    return stock;
  }

  removeInUseName(keyName) {
    this.inUse = this.inUse.filter((item) => item !== keyName);
    this.childs = Object.values(this.childs).length
      ? Object.values(this.childs).map((child) =>
          child.removeInUseName(keyName)
        )
      : [];

    return this;
  }
}

export const convertResponseChildsDataToStockFactorChilds = (data) => {
  if (!data.length) {
    return {};
  }

  return data.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.item_id]: new EditedStockFactor(
        cur.item_id,
        cur.name,
        cur.item_type,
        cur.normalize,
        cur.value,
        cur.percentile,
        cur.screen_by ? cur.screen_by : "",
        cur.ratio_operator,
        cur.childs.length
          ? convertResponseChildsDataToStockFactorChilds(cur.childs)
          : {}
      ),
    }),
    {}
  );
};

export const compareArrays = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const formatUniverse = (str) =>
  str ? str.replaceAll("_", " ").capitalize() : "";

export const defaultFormatOption = (str) =>
  str
    ? str
        .replaceAll("_", " ")
        .split(" ")
        .map((el) => el.capitalize())
        .join(" ")
    : "";

export function calcValueToPersentile(arr, value) {
  let right = 0;
  let left = 0;

  for (let number of arr) {
    if (number < 0) {
      break;
    }

    if (value >= number) {
      right++;
    }
    if (value > number) {
      left++;
    }
  }

  const addValue = right > left ? 1 : 0;

  const res = ((right + left + addValue) * (50 / arr.length)) / 100;
  return res.toFixed(4);
}

export function calcPersentileToValue(arr, value) {
  const index = (arr.length - 1) * value;
  return arr[parseInt(index)];
}

export function createChildsForNewStock(factors) {
  return Object.values(factors).reduce(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {}
  );
}

export function stockNameValidate(name, factors, variableNames) {
  const selectedFactorsNames = Object.values(factors).map(
    (factor) => factor.name
  );

  const vars = Object.keys(variableNames).reduce((acc, cur) => {
    return [...acc, ...variableNames[cur].map((item) => item.name)];
  }, []);

  const arrOfNames = new Set([...selectedFactorsNames, ...vars]);

  return Boolean([...arrOfNames].find((factorName) => factorName === name));
}

export function mapStockLocalParams(data, addDefaultColumns = true) {
  const local_params = Object.values(data).map((factor) => factor.name);
  return addDefaultColumns
    ? ["ticker", "country", ...local_params]
    : local_params;
}

function getWsInfoOrValuation(data, title) {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      {
        v: "",
      },
      {
        v: title,
        s: { font: { bold: true }, alignment: { horizontal: "left" } },
      },
    ],
    ...Object.entries(data).map(([key, value]) => [
      {
        v: defaultFormatOption(key),
        s: { font: { bold: true }, alignment: { horizontal: "right" } },
      },
      {
        v: value,
        s: { alignment: { horizontal: "left" } },
      },
    ]),
  ]);

  let maxLength = [];

  Object.entries(data).map((arr) => {
    Object.keys(arr).map((key) => {
      const value = arr[key] === null ? "" : arr[key].toString();

      maxLength[key] =
        maxLength[key] >= value.length ? maxLength[key] : value.length;
    });
  });

  const worksheetCols = maxLength.map((width) => {
    return {
      width: width + 5,
    };
  });

  ws["!cols"] = worksheetCols;

  return ws;
}

function getWsIndustryComparison(data) {
  const tickerKey = Object.keys(data).filter(
    (key) => !Object.keys(DEFAULT_KEYS_INDUSTRY_COMPARISON).includes(key)
  );

  const indexes = Object.keys(data.factor);

  const keys = Object.keys(data);

  const economiesGroups = Object.entries(data.economy).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [value]: {
          name: value,
          count: acc[value]?.count ? acc[value].count + 1 : 1,
          firstIndex: !acc[value]?.firstIndex ? key : acc[value].firstIndex,
        },
      };
    },
    {}
  );

  const ws = XLSX.utils.aoa_to_sheet([
    [
      {
        v: "",
      },
      {
        v: "",
      },
      {
        v: tickerKey,
        s: { font: { bold: true } },
      },
      {
        v: DEFAULT_KEYS_INDUSTRY_COMPARISON["upper quartile"],
        s: { font: { bold: true } },
      },
      {
        v: "Median",
        s: { font: { bold: true } },
      },
      {
        v: "Lower Quartile",
        s: { font: { bold: true } },
      },
    ],
    ...indexes.map((index) => {
      const group = data.economy[index];

      const { firstIndex } = economiesGroups[group];

      return [
        {
          v: index === firstIndex ? defaultFormatOption(group) : "",
          s: {
            font: { bold: true },
            alignment: { vertical: "center" },
          },
        },
        {
          v: defaultFormatOption(data.factor[index]),
          s: { font: { bold: true } },
        },
        {
          v: data[tickerKey][index],
        },
        {
          v: data["upper quartile"][index],
        },
        {
          v: data.median[index],
        },
        {
          v: data["lower quartile"][index],
        },
      ];
    }),
  ]);

  let maxLength = {};

  indexes.forEach((index) => {
    keys.forEach((key) => {
      maxLength[key] =
        maxLength[key] >= data[key][index].toString().length
          ? maxLength[key]
          : data[key][index].toString().length;

      if (maxLength[key] < key.length) {
        maxLength[key] = key.length;
      }
    });
  });

  ws["!cols"] = [
    "economy",
    "factor",
    `${tickerKey}`,
    "upper quartile",
    "median",
    "lower quartile",
  ].map((name) => ({
    width: maxLength[name] + 5,
  }));

  const groups = new Set(Object.values(data.economy));

  ws["!merges"] = [...groups].map((group) => {
    const { count, firstIndex, name } = economiesGroups[group];
    return {
      name,
      s: { r: +firstIndex + 1, c: 0 },
      e: {
        r: +firstIndex + count,
        c: 0,
      },
    };
  });

  return ws;
}

function getWsMovingAveragesOrTechnicalIndicators(data) {
  const keys = Object.keys(data);

  const itemKeys = Object.keys(data[keys[0]]);

  let maxLength = {};

  const ws = XLSX.utils.aoa_to_sheet([
    keys.map((key) => ({
      v: defaultFormatOption(key),
      s: { font: { bold: true } },
    })),
    ...itemKeys.map((itemKeyOrIndex) => [
      ...keys.map((key) => {
        const value = data[key][itemKeyOrIndex] || "-";

        maxLength[key] =
          maxLength[key] >= value.toString().length
            ? maxLength[key]
            : value.toString().length;

        if (maxLength[key] < key.length) {
          maxLength[key] = key.length;
        }

        return {
          v: value,
        };
      }),
    ]),
  ]);

  ws["!cols"] = Object.values(maxLength).map((item) => ({
    width: item + 5,
  }));

  return ws;
}

function getWsVisuals(data) {
  const keys = Object.keys(data.monthly_returns);

  const columns = new Set();

  const items = keys.reduce((acc, key) => {
    const arr = Object.entries(data.monthly_returns[key]);

    const values = arr.reduce((accItem, [itemKey, itemValue]) => {
      const year = itemKey.split("-")[0];
      const month = parseInt(itemKey.split("-")[1]);

      columns.add(year);

      return {
        ...accItem,
        [month]: accItem
          ? [month]
            ? { ...accItem[month], [year]: itemValue }
            : [year]
          : itemValue,
      };
    }, {});

    return { ...acc, [key]: values };
  }, {});

  const orderedColumns = [...columns].sort().reverse();

  const groupedData = Object.values(items)[0];

  const ws = XLSX.utils.aoa_to_sheet([
    [
      {
        v: "Month",
        s: { font: { bold: true }, alignment: { horizontal: "center" } },
      },
      ...orderedColumns.map((item) => ({
        v: item,
        s: { font: { bold: true }, alignment: { horizontal: "center" } },
      })),
    ],

    ...Object.keys(groupedData).map((value) => [
      { v: monthDesc[value], s: { alignment: { horizontal: "center" } } },
      ...orderedColumns.map((col) => ({
        v: getRoundedValueOrNaIfFalsy(groupedData[value][col], 1),
        s: {
          alignment: { horizontal: "center" },
          fill: { fgColor: { rgb: checkColor(groupedData[value][col]) } },
        },
      })),
    ]),
  ]);

  return ws;
}

export function excelExportTickerModal(data) {
  const wb = XLSX.utils.book_new();

  // ===================

  const wsInfo = getWsInfoOrValuation(data["Info"], "Stock info");

  XLSX.utils.book_append_sheet(wb, wsInfo, "Info");

  // ===================

  const wsValuation = getWsInfoOrValuation(
    data["Valuation"],
    "Valuation Analysis"
  );

  XLSX.utils.book_append_sheet(wb, wsValuation, "Valuation");

  // ===================

  const wsIndustryComparison = getWsIndustryComparison(
    data["Industry Comparison"]
  );

  XLSX.utils.book_append_sheet(wb, wsIndustryComparison, "Industry Comparison");

  // ===================

  const wsMovingAverages = getWsMovingAveragesOrTechnicalIndicators(
    data["Moving Averages"]
  );

  XLSX.utils.book_append_sheet(wb, wsMovingAverages, "Moving Averages");

  // ===================

  const wsTechnicalIndicators = getWsMovingAveragesOrTechnicalIndicators(
    data["Technical Indicators"]
  );

  XLSX.utils.book_append_sheet(
    wb,
    wsTechnicalIndicators,
    "Technical Indicators"
  );

  // ===================

  const wsVisuals = getWsVisuals(data["Visuals"]);

  XLSX.utils.book_append_sheet(wb, wsVisuals, "Visuals");

  // ===================

  const fileName = `${data["Visuals"].returns_distribution.strategy_name}.xlsx`;

  XLSX.writeFile(wb, fileName);
}

export function excelExportStockScreenerBreakdown(
  data,
  stocks,
  factors,
  order
) {
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.aoa_to_sheet([
    [
      {
        v: "",
      },
      {
        v: "Ticker",
        s: {
          font: {
            bold: true,
          },
        },
      },
      {
        v: "Country",
        s: {
          font: {
            bold: true,
          },
        },
      },
      ...order.map((id) => {
        const factor = factors[id];
        return {
          v: factor.name.capitalize().replace(/_/g, " "),
          s: {
            font: {
              bold: true,
              color:
                factor.variable === CUSTOM_RATIO ||
                factor.variable === CUSTOM_MULTIFACTOR
                  ? {
                      rgb:
                        factor.variable === CUSTOM_MULTIFACTOR
                          ? "BD8C1D"
                          : "008000",
                    }
                  : {},
            },
          },
        };
      }),
    ],
    ...data.map((item, index) => {
      return [
        { v: index },
        { v: item.ticker },
        { v: defaultFormatOption(item.country) },
        ...order.map((id) => {
          const factor = factors[id];

          const stockRow = factor?.normalize
            ? item.normalize[factor.name]
            : item.data[factor.name];

          const stockRowData =
            typeof stockRow === "number" ? stockRow.toFixed(2) : stockRow;

          return { v: stockRowData };
        }),
      ];
      //   return [
      //   {
      //     v: defaultFormatOption(key),
      //     s: { font: { bold: true }, alignment: { horizontal: "right" } },
      //   },
      //   {
      //     v: value,
      //     s: { alignment: { horizontal: "left" } },
      //   },
      // ]
    }),
  ]);

  // let maxLength = [];

  // Object.entries(data).map((arr) => {
  //   Object.keys(arr).map((key) => {
  //     const value = arr[key] === null ? "" : arr[key].toString();

  //     maxLength[key] =
  //       maxLength[key] >= value.length ? maxLength[key] : value.length;
  //   });
  // });

  // const worksheetCols = maxLength.map((width) => {
  //   return {
  //     width: width + 5,
  //   };
  // });

  // ws["!cols"] = worksheetCols;

  XLSX.utils.book_append_sheet(wb, ws, "Breakdown");

  XLSX.writeFile(wb, "stock_screener_breakdown.xlsx");
}
