import * as React from "react";
import LoadingIcon from "../../../assets/Icons/loading";
import styles from './LoadingCircular.module.scss';

export default function LoadingCircular() {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loader}>
          <LoadingIcon />
        </div>
      </div>
    );
}
