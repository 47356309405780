const CorrelationMatrixColorsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="287">
      <defs>
          <linearGradient id="a" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#184C51"/>
              <stop offset="50%" stopColor="#F3F6F7"/>
              <stop offset="100%" stopColor="#B61A1A"/>
          </linearGradient>
      </defs>
      <path fillRule="evenodd" fill="#B61A1A" d="M0 .3h15.999v286.696H0V.3z"/>
      <path fill="url(#a)" d="M0 .3h15.999v286.696H0V.3z"/>
  </svg>
)

export default CorrelationMatrixColorsIcon
