import jwt from "jwt-decode";
import axios from "axios";
import { mainUrl as url } from "../config";
import { parseJwt } from "../helpers/Utils";
import AdminApi from "./Admin";

const register = (username, last_name, email, password) =>
  fetch(`${url.API_URL}/api/registration`, {
    body: `{"name" : "${username}","last_name": "${last_name}","email":"${email}","password": "${password}"}`,
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  })
    .then((response) => response.json())
    .then((json) => {
      const tmp = jwt(json.access_token);
      const token = JSON.parse(JSON.stringify(json));
      localStorage.setItem("user", JSON.stringify({ ...tmp, ...token }));
      return json;
    })
    .catch((exception) => {
      throw exception;
    });

const logout = () => {
  localStorage.removeItem("user");
};

const guestLogin = async() => {
  return axios
    .post(`${url.API_URL}/default_token`)
    .then((json) => {
      const { access_token, ...rest } = json.data;
      const tokenData = jwt(access_token);
      const token = JSON.parse(JSON.stringify(json.data));
      const { strategies, ...additionalData } = rest;
      const data = {
        access_token: token.access_token,
        ...additionalData,
        ...strategies,
        ...tokenData,
      };

      delete token.strategies;

      const lsData = data;

      delete lsData.default_strategy;
      delete lsData.strategies_col;

      localStorage.setItem("user", JSON.stringify(lsData));
      return data;
    })
    .catch((exception) => {
      throw exception;
    });
}

const login = async (email, pwd) => {
  const bodyFormData = new FormData();
  bodyFormData.append("username", email);
  bodyFormData.append("password", pwd);
  return axios
    .post(`${url.API_URL}/token`, bodyFormData)
    .then((json) => {
      const { access_token, ...rest } = json.data;
      const tokenData = jwt(access_token);
      const token = JSON.parse(JSON.stringify(json.data));
      const { strategies, ...additionalData } = rest;
      const data = {
        access_token: token.access_token,
        ...additionalData,
        ...strategies,
        ...tokenData,
      };

      delete token.strategies;

      const lsData = data;

      delete lsData.default_strategy;
      delete lsData.strategies_col;

      localStorage.setItem("user", JSON.stringify(lsData));
      return data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const authVerify = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    const decodedJwt = parseJwt(user.access_token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const adminVerify = () =>
  new Promise(async (resolve) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const { admin } = await AdminApi.isAdmin(user.access_token);
      if (admin) {
        resolve(true);
        return true;
      } else {
        resolve(false);
        return false;
      }
    } else {
      resolve(false);
      return false;
    }
  });

const loginsrvs = {
  register,
  logout,
  login,
  authVerify,
  adminVerify,
  guestLogin
};

export default loginsrvs;
