import * as ModelsTypes from "../types/Models";
import { requestStatuses } from "../../helpers/Constants";
import { generateRandomColor } from "../../helpers/Strategy";

import {
  RESET_DEFAULT,
} from "../actions/Type";

const defaultPositionValue = "Top-Index";

const initialState = {
    benchmarksStatistic: {},
    strategiesStatistics: {},
    bookmarkedStrategies: {},
    position: defaultPositionValue,
    newStatistics: {},
    strategies: {
      byId: {},
    },
    loaders: {
      isBenchmarksStatisticLoading: false,
      isStrategiesStatisticLoading: false,
      isStrategiesLoading: false
    },
    activeTab: 0,
  };

function deleteItemFromObject(object, key) {
  delete object[key];
  return object;
}

const { idle } = requestStatuses;

function saveStatistics(state, action) {
  const { payload } = action;
  const {
    start_date,
    end_date,
    win_rate_table,
    beta_table,
    correlation_matrix,
    rolling_returns,
    rolling_correlation,
    alpha_table,
    ...strats
  } = payload;

  return {
    byId: {
      ...state.strategies.byId,
      ...Object.assign(
        {},
        ...Object.keys(strats).map((key) => {
          return {
            [key]: {
              ...state.strategies.byId[key],
              id: key,
              name: `${state.newStatistics[key].name_to_show}`,
              strategyCreator: "-",
              color: generateRandomColor(),
              longPos: 0,
              shortPos: 0,
              exposure: 0,
              past: [],
              present: {
                benchmarks: { byId: [], byAstId: new Set() },
                portfolios: { byId: [], byAstId: new Set() },
              },
              future: [],
              nestedStrategies: [],
              date: new Date().toISOString().slice(0, 10),
              initializationDates: [],
              stocks: {
                stocks: [],
                status: idle,
                error: null,
              },
              stats: {
                periodRets: payload[key]["performance_summary"],
                rollingRets: rolling_returns[key],
                cumulitiveRets: payload[key]["cumulative_returns"],
                yearly: payload[key]["year_returns"],
                monthly: payload[key]["month_returns"],
                weekly: payload[key]["week_returns"],
                strategy_returns: payload[key]["strategy_returns"],
                best_worst_periods: payload[key]["best_worst_periods"],
                returns_distribution: payload[key]["returns_distribution"],
                underwater: payload[key]["underwater"],
                worst_drawdowns: payload[key]["worst_drawdowns"],
              },
            },
          };
        })
      ),
    },
    winRate: win_rate_table != null ? win_rate_table : {},
    beta: beta_table != null ? beta_table : {},
    correllation_matrix:
      correlation_matrix != null && Object.keys(correlation_matrix).length > 0
        ? correlation_matrix
        : {},
    rollingCorellation: rolling_correlation,
    startDate: start_date,
    endDate: end_date,
  };
}

export default function model(state = initialState, action) {
    switch (action.type) {
        case ModelsTypes.GET_BENCHMARKS_STATISTIC_START:
            return {
                ...state,
                loaders: {
                ...state.loaders,
                isBenchmarksStatisticLoading: true,
                },
            };
        
        case ModelsTypes.GET_BENCHMARKS_STATISTIC_SUCCESS:
            return {
                ...state,
                benchmarksStatistic: action.payload,
                loaders: {
                ...state.loaders,
                isBenchmarksStatisticLoading: false,
                },
            };

        case ModelsTypes.GET_BENCHMARKS_STATISTIC_FAILED:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    isBenchmarksStatisticLoading: false,
                  },
            };

            case ModelsTypes.GET_STRATEGIES_STATISTIC_START:
                return {
                  ...state,
                  loaders: {
                    ...state.loaders,
                    isStrategiesStatisticLoading: true,
                  },
                };

            case ModelsTypes.GET_STRATEGIES_STATISTIC_SUCCESS:          
                return {
                  ...state,
                  strategiesStatistics: action.payload,
                //   newStatistics: res,
                  loaders: {
                    ...state.loaders,
                    isStrategiesStatisticLoading: false,
                  },
                };
                
              case ModelsTypes.GET_STRATEGIES_STATISTIC_FAILED:
                return {
                  ...state,
                  loaders: {
                    ...state.loaders,
                    isStrategiesStatisticLoading: false,
                  },
                };
          
              case ModelsTypes.CLEAR_STRATEGIES_STATISTIC:
                return {
                  ...state,
                  strategiesStatistics: {},
                };

              case ModelsTypes.ADD_BOOKMARKED_STRATEGY:
                return {
                  ...state,
                  bookmarkedStrategies: {
                    ...state.bookmarkedStrategies,
                    [action.payload.asset_id]: action.payload,
                  },
                };

              case ModelsTypes.REMOVE_BOOKMARKED_STRATEGY:
                return {
                  ...state,
                  bookmarkedStrategies: deleteItemFromObject(
                    state.bookmarkedStrategies,
                    action.payload
                  ),
                };

              case ModelsTypes.CLEAR_NEW_STATISTIC: {
                return {
                  ...state,
                  newStatistics: {},
                };
              }
            
              case ModelsTypes.ADD_STRATEGY_TO_STATISTIC:
                return {
                  ...state,
                  newStatistics: {
                    ...state.newStatistics,
                    [action.payload.asset_id]: action.payload,
                  },
                };
            
              case ModelsTypes.REMOVE_ALL_STRATEGY_FROM_STATISTIC:
                return {
                  ...state,
                  newStatistics: {},
                };
            
              case ModelsTypes.REMOVE_STRATEGY_FROM_STATISTIC:
                return {
                  ...state,
                  newStatistics: deleteItemFromObject(
                    state.newStatistics,
                    action.payload
                  ),
                };
              
              case ModelsTypes.SET_ALL_STRATEGIES_TO_STATISTIC: {
                const res = {};
            
                Object.values(state.strategiesStatistics).forEach((element) => {
                  res[element.asset_id] = element;
                });
            
                return {
                  ...state,
                  newStatistics: res,
                };
              }

              case ModelsTypes.GET_STRATEGIES_START: {
                return {
                  ...state,
                  strategies:
                    action.payload === false
                      ? {
                          byId: {},
                        }
                      : { ...state.strategies },
                  loaders: {
                    ...state.loaders,
                    isStrategiesLoading: true,
                  },
                };
              }
          
              case ModelsTypes.GET_STRATEGIES_SUCCESS: {
                const { payload } = action;
                const {
                  start_date,
                  end_date,
                  win_rate_table,
                  beta_table,
                  correlation_matrix,
                  rolling_returns,
                  rolling_correlation,
                  alpha_table,
                  ...strats
                } = payload;
          
                return {
                  ...state,
                  strategies: saveStatistics(state, action),
                  orderOfStrategies: Object.keys(strats),
                  selectedStatisticsStrategies: Object.keys(strats).filter(
                    (strategy) =>
                      Object.keys(strats[strategy].cumulative_returns).length > 0
                  ),
                  loaders: {
                    ...state.loaders,
                    isStrategiesLoading: false,
                  },
                };
              }
          
              case ModelsTypes.GET_STRATEGIES_FAILED: {
                return {
                  ...state,
                  loaders: {
                    ...state.loaders,
                    isStrategiesLoading: false,
                  },
                };
              }
              
              case RESET_DEFAULT: {
                return {
                  ...state,
                  activeTab: 0,
                  newStatistics: {},
                  strategies: {
                    byId: {},
                  },
                };
              }
        
        default:
            return state;
    }
}
