import { combineReducers } from "redux";
import user from "./User.js";
import Message from "./Message";
import portfolio from "./Portfolio";
import strategiesReducer from "./StrategiesReducer";
import monthlyReturnReducer from "./MonthlyReturnReducer";
import editMode from "./EditMode";
import rollingReturns from "./RollingReturns";
import factor from "./Factor";
import xray from "./XRay";
import factorTesting from "./FactorTesting";
import dashboard from "./Dashboard";
import admin from "./Admin";
import catalog from "./Catalog";
import calculator from "./Calculator";
import stock from "./Stock";
import model from "./Models"

export default combineReducers({
  user,
  message: Message,
  portfolio,
  strategiesReducer,
  monthlyReturnReducer,
  catalog,
  editMode,
  rollingReturns,
  factor,
  xray,
  factorTesting,
  dashboard,
  admin,
  stock,
  calculator,
  model
});
