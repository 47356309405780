import { mainUrl } from "../config";
import axios from "axios";

const createUser = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/create_user`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const changeUserPassword = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/change_user_pwd`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const updateUserData = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/update_user_first_last_name`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const getWorkingGroups = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/working_groups`, {
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getCompanies = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/companies`, {
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getRoles = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/roles`, {
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getExistingUsers = async (token, controller) =>
  axios
    .get(
      `${mainUrl.API_URL}/api/get_existing_users`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const isAdmin = async (token) =>
  axios
    .get(
      `${mainUrl.API_URL}/api/is_user_admin`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {}
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const insertWorkingGroup = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/insert_working_group`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const updateWorkingGroup = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/edit_working_group`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const deleteWorkingGroup = async (id, token, controller) => {
  const data = {
    id,
  };
  return axios
    .delete(`${mainUrl.API_URL}/api/delete_working_group`, {
      headers: { Authorization: `Bearer ${token}` },
      signal: controller?.signal,
      data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const updateCompany = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/edit_company`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const updateCompanyDefaultStrategies = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/change_company_default_strategies`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const getListStrategies = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/list_strategies`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const updateUserStatus = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/change_user_status`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => response.data)
    .catch((exception) => {
      throw exception;
    });

const AdminApi = {
  createUser,
  changeUserPassword,
  getWorkingGroups,
  getCompanies,
  isAdmin,
  getRoles,
  getExistingUsers,
  insertWorkingGroup,
  updateCompany,
  updateCompanyDefaultStrategies,
  getListStrategies,
  updateUserData,
  updateUserStatus,
  updateWorkingGroup,
  deleteWorkingGroup,
};

export default AdminApi;
