import { LOGOUT, STORE_MONTHLY_RETURNS } from "../actions/Type";

const initialState = {};

export default function monthlyReturnReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_MONTHLY_RETURNS:
      return { ...state, monthlyReturns: action.payload };

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
