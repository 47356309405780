import * as CatalogTypes from "../types/Catalog";
import { requestStatuses } from "../../helpers/Constants";
import { LOGOUT } from "../actions/Type";
import { ASSET_TYPES } from "../../helpers/Assets";

const { idle } = requestStatuses;

const initialState = {
  securityCatalog: {
    security: [],
    status: idle,
    error: null,
  },
  methodologiesCatalog: {
    methodologies: [],
    status: idle,
    error: null,
  },
  benchmarksCatalog: {
    benchmarks: [],
    status: idle,
    error: null,
  },
};

export default function catalog(state = initialState, action) {
  switch (action.type) {
    case CatalogTypes.GET_SECURITY_START:
      return {
        ...state,
        securityCatalog: {
          ...state.securityCatalog,
          status: action.payload,
        },
      };

    case CatalogTypes.GET_SECURITY_SUCCESS:
      return {
        ...state,
        securityCatalog: {
          ...state.securityCatalog,
          security: action.payload.response,
          status: action.payload.status,
        },
      };

    case CatalogTypes.GET_SECURITY_FAILED:
      return {
        ...state,
        securityCatalog: {
          ...state.securityCatalog,
          status: action.payload.status,
          error: action.payload.error,
        },
      };

    case CatalogTypes.GET_METHODOLOGIES_START:
      return {
        ...state,
        methodologiesCatalog: {
          ...state.methodologiesCatalog,
          status: action.payload,
        },
      };

    case CatalogTypes.GET_METHODOLOGIES_SUCCESS:
      return {
        ...state,
        methodologiesCatalog: {
          ...state.methodologiesCatalog,
          methodologies: action.payload.response,
          status: action.payload.status,
        },
      };

    case CatalogTypes.GET_METHODOLOGIES_FAILED:
      return {
        ...state,
        methodologiesCatalog: {
          ...state.methodologiesCatalog,
          status: action.payload.status,
          error: action.payload.error,
        },
      };

    case CatalogTypes.GET_BENCHMARKS_START:
      return {
        ...state,
        benchmarksCatalog: {
          ...state.benchmarksCatalog,
          status: action.payload,
        },
      };

    case CatalogTypes.GET_BENCHMARKS_SUCCESS:
      return {
        ...state,
        benchmarksCatalog: {
          ...state.benchmarksCatalog,
          benchmarks: action.payload.response,
          status: action.payload.status,
        },
      };

    case CatalogTypes.GET_BENCHMARKS_FAILED:
      return {
        ...state,
        benchmarksCatalog: {
          ...state.benchmarksCatalog,
          status: action.payload.status,
          error: action.payload.error,
        },
      };

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
