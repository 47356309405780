import * as React from "react";
import {
  DashboardIcon,
  GraphIcon,
  NetworkIcon,
  ReportIcon,
  PieChartIcon
} from "../../../assets/Icons";
import { dashboardRoutes } from "../../../configs/routes";
import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InventoryIcon from "@mui/icons-material/Inventory";

let config = {
  navigationList: [
    {
      path: dashboardRoutes.dashboard,
      icon: <DashboardIcon />,
      title: "Dashboard",
      name: "dashboard",
    },
    {
      path: dashboardRoutes.portfolio,
      icon: <GraphIcon />,
      title: "Strategy Constructor",
      name: "portfolio",
    },
    {
      path: dashboardRoutes.factors,
      icon: <NetworkIcon />,
      title: "Factor Screening",
      name: "factors",
    },
    {
      path: dashboardRoutes.xRay,
      icon: <ReportIcon />,
      title: "Strategy X-Ray",
      name: "xRay",
    },
    {
      path: dashboardRoutes.factorTesting,
      icon: <BiotechOutlinedIcon />,
      title: "Factor Testing",
      name: "factorTesting",
    },
    {
      path: dashboardRoutes.stockScreener,
      icon: <InventoryIcon />,
      title: "Stock Screener",
      name: "stockScreener",
    },
    {
      path: dashboardRoutes.modelStrategies,
      icon: <PieChartIcon />,
      title: "Model Strategies",
      name: "Model Strategies",
    },
  ],

  guestNavigation: [
  {
      path: dashboardRoutes.portfolio,
      icon: <GraphIcon />,
      title: "Strategy Constructor",
      name: "portfolio",
    },    
    {
      path: dashboardRoutes.modelStrategies,
      icon: <PieChartIcon />,
      title: "Model Strategies",
      name: "Model Strategies",
    }
  ],

  adminNavigation: [
    {
      path: dashboardRoutes.admin,
      icon: <AdminPanelSettingsIcon />,
      title: "Admin-panel",
      name: "admin",
    },
  ],
};

if (process.env.REACT_APP_FACTOR_TESTING_SHOW != "true") {
  console.log(process.env.REACT_APP_FACTOR_TESTING_SHOW);
  config = {
    ...config,
    navigationList: config.navigationList.filter(
      (x) => x.name != "factorTesting"
    ),
  };
}

export default config;
