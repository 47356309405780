import AdminApi from "../../services/Admin";
import * as AdminTypes from "../types/Admin";
import { generateErrorMessage } from "../../helpers/Error";
import SnackBarUtils from "../../helpers/SnackBarUtils";
import { UPDATE_CURRENT_USER_NAME } from "../types/User";
import { batch } from "react-redux";

export const createUser =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.CREATE_USER_START });
    const { user } = getState();
    return AdminApi.createUser(data, user.userData.access_token, controller)
      .then((data) => {
        dispatch({
          type: AdminTypes.CREATE_USER_SUCCESS,
          payload: {
            active: true,
            company_name: data.company_name,
            email: data.email,
            last_name: data.last_name,
            name: data.name,
            user_id: data.user_id,
          },
        });
        callback();
        SnackBarUtils.success(data.status);
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.CREATE_USER_FAILED });
        generateErrorMessage(error);
      });
  };

export const changeUserPassword =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.CHANGE_USER_PASSWORD_START });
    const { user } = getState();
    return AdminApi.changeUserPassword(
      data,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: AdminTypes.CHANGE_USER_PASSWORD_SUCCESS,
        });
        callback();
        SnackBarUtils.success(data.Status);
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.CHANGE_USER_PASSWORD_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateUserData =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.UPDATE_USER_DATA_START });
    const { user } = getState();
    return AdminApi.updateUserData(data, user.userData.access_token, controller)
      .then((data) => {
        batch(() => {
          if (user.userData.id === data.user_id) {
            dispatch(updataCurrentUserName(data.user_name));
          }
          dispatch({
            type: AdminTypes.UPDATE_USER_DATA_SUCCESS,
            payload: {
              user_id: data.user_id,
              name: data.user_name,
              last_name: data.last_name,
            },
          });
        });
        callback();
        SnackBarUtils.success(data.message);
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.UPDATE_USER_DATA_FAILED });
        generateErrorMessage(error);
      });
  };

export const getAdminWorkingGroups = (controller) => async (dispatch) => {
  dispatch({ type: AdminTypes.GET_WORKING_GROUPS_START });
  return AdminApi.getWorkingGroups(controller)
    .then((data) => {
      dispatch({
        type: AdminTypes.GET_WORKING_GROUPS_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: AdminTypes.GET_WORKING_GROUPS_FAILED });
      generateErrorMessage(error);
    });
};

export const getAdminCompanies = (controller) => async (dispatch) => {
  dispatch({ type: AdminTypes.GET_COMPANIES_START });
  return AdminApi.getCompanies(controller)
    .then((data) => {
      dispatch({
        type: AdminTypes.GET_COMPANIES_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: AdminTypes.GET_COMPANIES_FAILED });
      generateErrorMessage(error);
    });
};

export const getAdminRoles = (controller) => async (dispatch) => {
  dispatch({ type: AdminTypes.GET_ROLES_START });
  return AdminApi.getRoles(controller)
    .then((data) => {
      dispatch({
        type: AdminTypes.GET_ROLES_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: AdminTypes.GET_ROLES_FAILED });
      generateErrorMessage(error);
    });
};

export const getUsers = (controller) => async (dispatch, getState) => {
  dispatch({ type: AdminTypes.GET_USERS_START });
  const { user } = getState();
  return AdminApi.getExistingUsers(user?.userData?.access_token, controller)
    .then((data) => {
      dispatch({
        type: AdminTypes.GET_USERS_SUCCESS,
        payload: data,
      });
      return data.admin;
    })
    .catch((error) => {
      dispatch({ type: AdminTypes.GET_USERS_FAILED });
      generateErrorMessage(error);
    });
};

export const isAdmin = () => async (dispatch, getState) => {
  const { user } = getState();
  dispatch({ type: AdminTypes.IS_ADMIN_START });
  return AdminApi.isAdmin(user?.userData?.access_token)
    .then((data) => {
      dispatch({
        type: AdminTypes.IS_ADMIN_SUCCESS,
        payload: data,
      });
      return data.admin;
    })
    .catch((error) => {
      dispatch({ type: AdminTypes.IS_ADMIN_FAILED });
      generateErrorMessage(error);
    });
};

export const createWorkingGroup =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.ADD_WORKING_GROUP_START });
    const { user } = getState();
    return AdminApi.insertWorkingGroup(
      data,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        const { message, ...group } = data;
        dispatch({
          type: AdminTypes.ADD_WORKING_GROUP_SUCCESS,
          payload: group,
        });
        callback();
        SnackBarUtils.success(message);
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.ADD_WORKING_GROUP_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateCompany =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.UPDATE_COMPANY_START });
    const { user } = getState();
    return AdminApi.updateCompany(data, user.userData.access_token, controller)
      .then((data) => {
        const { message, ...companyData } = data;
        dispatch({
          type: AdminTypes.UPDATE_COMPANY_SUCCESS,
          payload: companyData,
        });
        callback();
        SnackBarUtils.success(message);
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.UPDATE_COMPANY_FAILED });
        generateErrorMessage(error);
      });
  };

export const getListCompanyStrategies =
  (data, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.GET_LIST_STRATEGIES_START });
    const { user } = getState();
    return AdminApi.getListStrategies(
      data,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: AdminTypes.GET_LIST_STRATEGIES_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.GET_LIST_STRATEGIES_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateUserStatus =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.UPDATE_USER_STATUS_START });
    const { user } = getState();
    return AdminApi.updateUserStatus(
      data,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: AdminTypes.UPDATE_USER_STATUS_SUCCESS,
          payload: {
            user_id: data.user_id,
            active: data.activate,
          },
        });
        SnackBarUtils.success(data.message);
        callback();
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.UPDATE_USER_STATUS_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateCompanyDefaultStrategies =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.UPDATA_COMPANY_DEFAULT_STRATEGIES_START });
    const { user } = getState();
    return AdminApi.updateCompanyDefaultStrategies(
      data,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: AdminTypes.UPDATA_COMPANY_DEFAULT_STRATEGIES_SUCCESS,
        });
        SnackBarUtils.success(data.message);
        callback();
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.UPDATA_COMPANY_DEFAULT_STRATEGIES_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateWorkingGroup =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.UPDATE_WORKING_GROUP_START });
    const { user } = getState();
    return AdminApi.updateWorkingGroup(
      data,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        const { message, ...rest } = data;

        const workingGroupData = {
          ...rest,
          default_work_group: rest.default,
        };

        delete workingGroupData.default;

        dispatch({
          type: AdminTypes.UPDATE_WORKING_GROUP_SUCCESS,
          payload: workingGroupData,
        });
        callback();
        SnackBarUtils.success(message);
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.UPDATE_WORKING_GROUP_FAILED });
        generateErrorMessage(error);
      });
  };

export const deleteWorkingGroup =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: AdminTypes.DELETE_WORKING_GROUP_START });
    const { user } = getState();
    return AdminApi.deleteWorkingGroup(
      data,
      user.userData.access_token,
      controller
    )
      .then(({ message }) => {
        dispatch({
          type: AdminTypes.DELETE_WORKING_GROUP_SUCCESS,
          payload: data,
        });
        callback();
        SnackBarUtils.success(message);
      })
      .catch((error) => {
        dispatch({ type: AdminTypes.DELETE_WORKING_GROUP_FAILED });
        generateErrorMessage(error);
      });
  };

export const updataCurrentUserName = (newName) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));
  localStorage.setItem("user", JSON.stringify({ ...user, name: newName }));
  dispatch({ type: UPDATE_CURRENT_USER_NAME, payload: newName });
};
