import nextId from "react-id-generator";
import { filtered, union, getStrategyNameOnAdd } from "../helpers/Utils.js";
import { generatePastHistory } from "../helpers/Strategy";

export const ASSET_TYPES = {
  portfolio: "portfolio",
  benchmark: "benchmark",
  nestedStrategies: "nestedStrategies",
  security: "security",
};

export const ADDITIONAL_ASSET_TYPES = {
  index: "index",
  methodology: "methodology",
  strategy: "strategy",
};

export const ASSET_TYPE_IDS = {
  portfolio: "port-",
  benchmark: "bench-",
  nestedStrategies: "nested-",
  security: "security-",
};

export const ASSET_STRATEGY_KEYS = {
  portfolios: "portfolios",
  benchmarks: "benchmarks",
  nestedStrategies: "nestedStrategies",
  security: "security",
};

class Asset {
  getAssetCore(asset_type, asset_id) {
    return {
      asset_type: asset_type,
      asset_id: asset_id,
    };
  }
}

class PortfolioAsset extends Asset {
  constructor(
    asset_id,
    asset_type,
    cnt,
    cur,
    filter_description,
    fltr,
    mtl,
    pos,
    rbl,
    rebalance_description,
    size,
    trade_date
  ) {
    super();

    this.asset = {
      ...super.getAssetCore(asset_type, asset_id),
      cnt: cnt,
      cur: cur,
      filter_description: filter_description,
      fltr: fltr,
      mtl: mtl,
      pos: pos,
      rbl: rbl,
      rebalance_description: rebalance_description,
      size: size,
      trade_date: trade_date,
    };
  }
}

export class Portfolio extends PortfolioAsset {
  constructor(
    asset_id,
    asset_type,
    cnt,
    cur,
    filter_description,
    fltr,
    mtl,
    pos,
    rbl,
    rebalance_description,
    size,
    trade_date,
    id,
    editMode,
    strategyId,
    weight,
    position,
    status
  ) {
    super(
      asset_id,
      asset_type,
      cnt,
      cur,
      filter_description,
      fltr,
      mtl,
      pos,
      rbl,
      rebalance_description,
      size,
      trade_date
    );
    this.id = id;
    this.editMode = editMode;
    this.strategyId = strategyId;
    this.weight = weight;
    this.position = position;
    this.status = status;
  }
}

class BenchmarkAsset extends Asset {
  constructor(asset_id, country_id, name, trade_date) {
    super();

    this.asset = {
      ...super.getAssetCore(ADDITIONAL_ASSET_TYPES.index, asset_id),
      country_id: country_id,
      name: name,
      trade_date: trade_date,
    };
  }
}

export class Benchmark extends BenchmarkAsset {
  constructor(
    asset_id,
    country_id,
    name,
    trade_date,
    id,
    strategyId,
    weight,
    position,
    status
  ) {
    super(asset_id, country_id, name, trade_date);
    this.id = id;
    this.strategyId = strategyId;
    this.weight = weight;
    this.position = position;
    this.status = status;
  }
}

class NestedStrategyAsset extends Asset {
  constructor(
    asset_type,
    asset_id,
    id,
    in_favorites,
    initialization_date,
    name,
    name_to_show,
    nested,
    portfolio_id,
    position,
    public_status,
    status_id,
    status_type,
    strategy_id,
    strategy_type_id,
    strategy_type_name,
    trade_date,
    user_id,
    user_name,
    weight
  ) {
    super();

    this.nestedStrategy = {
      ...super.getAssetCore(asset_type, asset_id),
      id: id,
      in_favorites: in_favorites,
      initialization_date: initialization_date,
      name: name,
      name_to_show: name_to_show,
      nested: nested,
      portfolio_id: portfolio_id,
      position: position,
      public_status: public_status,
      status_id: status_id,
      status_type: status_type,
      strategy_id: strategy_id,
      strategy_type_id: strategy_type_id,
      strategy_type_name: strategy_type_name,
      trade_date: trade_date,
      user_id: user_id,
      user_name: user_name,
      weight: weight,
    };
  }
}

export class NestedStrategy extends NestedStrategyAsset {
  constructor(
    asset_type,
    asset_id,
    id,
    in_favorites,
    initialization_date,
    name,
    name_to_show,
    nested,
    portfolio_id,
    position,
    public_status,
    status_id,
    status_type,
    strategy_id,
    strategy_type_id,
    strategy_type_name,
    trade_date,
    user_id,
    user_name,
    weight,
    nestedId,
    editMode,
    strategyId,
    status
  ) {
    super(
      asset_type,
      asset_id,
      id,
      in_favorites,
      initialization_date,
      name,
      name_to_show,
      nested,
      portfolio_id,
      position,
      public_status,
      status_id,
      status_type,
      strategy_id,
      strategy_type_id,
      strategy_type_name,
      trade_date,
      user_id,
      user_name,
      weight
    );
    this.nestedId = nestedId;
    this.editMode = editMode;
    this.strategyId = strategyId;
    this.status = status;
  }
}

class SecurityAsset extends Asset {
  constructor(asset_id, country_id, name, trade_date) {
    super();

    this.asset = {
      ...super.getAssetCore(ASSET_TYPES.security, asset_id),
      country_id: country_id,
      name: name,
      trade_date: trade_date,
    };
  }
}

export class Security extends SecurityAsset {
  constructor(
    asset_id,
    country_id,
    name,
    trade_date,
    id,
    strategyId,
    weight,
    position,
    status
  ) {
    super(asset_id, country_id, name, trade_date);
    this.id = id;
    this.strategyId = strategyId;
    this.weight = weight;
    this.position = position;
    this.status = status;
  }
}

export function generateIdForAssetByType(type) {
  switch (type) {
    case ASSET_TYPES.portfolio:
      return nextId(ASSET_TYPE_IDS.portfolio);

    case ASSET_TYPES.benchmark:
    case ADDITIONAL_ASSET_TYPES.index:
      return nextId(ASSET_TYPE_IDS.benchmark);

    case ASSET_TYPES.nestedStrategies:
    case ADDITIONAL_ASSET_TYPES.strategy:
      return nextId(ASSET_TYPE_IDS.nestedStrategies);

    case ASSET_TYPES.security:
      return nextId(ASSET_TYPE_IDS.security);

    default:
      return nextId(ASSET_TYPE_IDS.portfolio);
  }
}

export const setNewAsset = (
  key,
  state,
  id,
  asset,
  weight,
  position,
  editMode
) => {
  return {
    ...state[key],
    byId: {
      ...state[key].byId,
      [id]: { ...asset, id, weight, position, editMode },
    },
  };
};

export const removeAllAssetsBySelectedStrategy = (key, state, assets) => ({
  ...state[key],
  byId: filtered(state[key].byId, assets),
});

export const updateStrategyWithNewAsset = (
  key,
  state,
  strategyId,
  id,
  asset
) => {
  return {
    ...state.strategies,
    byId: {
      ...state.strategies.byId,
      [strategyId]: {
        ...state.strategies.byId[strategyId],
        name: getStrategyNameOnAdd(state.strategies.byId[strategyId], asset),
        present: {
          ...state.strategies.byId[strategyId].present,
          [key]: {
            byId: [...state.strategies.byId[strategyId].present[key].byId, id],
            byAstId: union(
              state.strategies.byId[strategyId].present[key].byAstId,
              asset.asset_id
            ),
          },
        },
        past: generatePastHistory(state, strategyId),
        order: [...state.strategies.byId[strategyId].order, id],
      },
    },
  };
};

export function updateAssetBulk(state, action) {
  const { key, data } = action.payload;
  return {
    ...state,
    [key]: {
      ...state[key],
      byId: data,
    },
  };
}

export function setAssetPosition(state, action) {
  const { payload } = action;
  const { key, value, id, strategyId } = payload;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          past: generatePastHistory(state, strategyId),
        },
      },
    },
    [key]: {
      ...state[key],
      byId: {
        ...state[key].byId,
        [id]: {
          ...state[key].byId[id],
          position: value,
        },
      },
    },
  };
}

export function setAssetWeight(state, action) {
  const { payload } = action;
  const { key, value, id, strategyId } = payload;

  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          past: generatePastHistory(state, strategyId),
        },
      },
    },
    [key]: {
      ...state[key],
      byId: {
        ...state[key].byId,
        [id]: {
          ...state[key].byId[id],
          weight: value,
        },
      },
    },
  };
}

export function getAssetKeyById(id) {
  if (id.includes(ASSET_TYPE_IDS.benchmark)) {
    return ASSET_STRATEGY_KEYS.benchmarks;
  } else if (id.includes(ASSET_TYPE_IDS.portfolio)) {
    return ASSET_STRATEGY_KEYS.portfolios;
  } else if (id.includes(ASSET_TYPE_IDS.nestedStrategies)) {
    return ASSET_STRATEGY_KEYS.nestedStrategies;
  } else if (id.includes(ASSET_TYPE_IDS.security)) {
    return ASSET_STRATEGY_KEYS.security;
  } else {
    return ASSET_STRATEGY_KEYS.portfolios;
  }
}

export function setAssetBulkWeight(state, action) {
  const { payload } = action;
  const { key, values } = payload;
  const prevState = {
    ...state,
    [key]: {
      ...state[key],
      byId: {
        ...state[key].byId,
      },
    },
  };
  values.forEach(({ id, value }) => {
    prevState[key].byId[id].weight = value;
  });
  return prevState;
}
