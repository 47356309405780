export const ADD_TO_FAVORITES_START = "STRATEGIES.ADD_TO_FAVORITES_START";
export const ADD_TO_FAVORITES_SUCCESS = "STRATEGIES.ADD_TO_FAVORITES_SUCCESS";
export const ADD_TO_FAVORITES_FAILED = "STRATEGIES.ADD_TO_FAVORITES_FAILED";

export const REMOVE_FROM_FAVORITES_START =
  "STRATEGIES.REMOVE_FROM_FAVORITES_START";
export const REMOVE_FROM_FAVORITES_SUCCESS =
  "STRATEGIES.REMOVE_FROM_FAVORITES_SUCCESS";
export const REMOVE_FROM_FAVORITES_FAILED =
  "STRATEGIES.REMOVE_FROM_FAVORITES_FAILED";

export const GET_STRATEGY_TITLES_BY_USER_ID_START =
  "STRATEGIES.GET_STRATEGY_TITLES_BY_USER_ID_START";
export const GET_STRATEGY_TITLES_BY_USER_ID_SUCCESS =
  "STRATEGIES.GET_STRATEGY_TITLES_BY_USER_ID_SUCCESS";
export const GET_STRATEGY_TITLES_BY_USER_ID_FAILED =
  "STRATEGIES.GET_STRATEGY_TITLES_BY_USER_ID_FAILED";
