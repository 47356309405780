export const SEARCH_PORTFOLIO_START = "SEARCH_PORTFOLIO_START";
export const SEARCH_PORTFOLIO_SUCCESS = "SEARCH_PORTFOLIO_SUCCESS";

export const SEARCH_FAILED = "SEARCH_FAILED";

export const LOAD_PORTFOLIO_START = "LOAD_PORTFOLIO_START";

export const LOAD_PORTFOLIO_COMPLETED = "LOAD_PORTFOLIO_COMPLETED";

export const GET_STATISTICS_START = "GET_STATISTICS_START";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
export const GET_STATISTICS_FAILED = "GET_STATISTICS_FAILED";

export const GET_SHORT_ASSET_LIST_START = "GET_SHORT_ASSET_LIST_START";
export const GET_SHORT_ASSET_LIST_SUCCESS = "GET_SHORT_ASSET_LIST_SUCCESS";
export const GET_SHORT_ASSET_LIST_FAILED = "GET_SHORT_ASSET_LIST_FAILED";

export const SET_ORDER_OF_STRATEGIES = "SET_ORDER_OF_STRATEGIES";

export const DELETE_HIGHLIGHTED_ROWS = "DELETE_HIGHLIGHTED_ROWS";

export const DUPLICATE_HIGHLIGHTED_ROWS = "DUPLICATE_HIGHLIGHTED_ROWS";

export const UPDATE_WEIGHT_HIGHLIGHTED_ROWS = "UPDATE_WEIGHT_HIGHLIGHTED_ROWS";

export const REPLACE_BENCHMARK_START = "REPLACE_BENCHMARK_START";
export const REPLACE_BENCHMARK_SUCCESS = "REPLACE_BENCHMARK_SUCCESS";
export const REPLACE_BENCHMARK_FAILED = "REPLACE_BENCHMARK_FAILED";
export const GET_WORKING_GROUP_START = "GET_WORKING_GROUP_START";
export const GET_WORKING_GROUP_FAILED = "GET_WORKING_GROUP_FAILED";

export const SELECTED_STATISTICS_STRATEGIES_CHANGE =
  "SELECTED_STATISTICS_STRATEGIES_CHANGE";

export const SET_REVISION_VERSION = "SET_REVISION_VERSION";
export const REPLACE_NESTED_STRATEGY = "REPLACE_NESTED_STRATEGY";
export const REPLACE_SINGLE_SECURITY = "REPLACE_SINGLE_SECURITY";

export const SET_CONSTRUCTOR_ACTIVE_TAB = "SET_CONSTRUCTOR_ACTIVE_TAB";

export const UPDATE_ORDER_STRATEGY = "UPDATE_ORDER_STRATEGY";

export const SET_HIGHLIGHTED_ROWS = "SET_HIGHLIGHTED_ROWS";