import React from "react";
import { useSelector } from "react-redux";
import LoadingCircular from "../../../components/Atoms/LoadingCircular/LoadingCircular";

function TopBarLoader() {
  const isPortfolioLoading = useSelector(
    (s) => s.portfolio.loaders.isPortfolioLoading
  );
  const isXRayLoading = useSelector((s) => s.xray.isLoading);
  const isXRayContributionAnalysisLoading = useSelector(
    (s) => s.xray.contributionAnalysisLoader
  );

  const isCalculatorLoading = useSelector(
    (s) => s.calculator.calculator.isLoading
  );

  const isFactorTestingLoading = useSelector((s) => s.factorTesting.loading);
  const initLoader = useSelector((s) => s.user.initLoader);

  const isDashboardLoading = useSelector((s) =>
    Object.values(s.dashboard.loaders).some((item) => item === true)
  );

  const loaders = [
    isPortfolioLoading,
    isXRayLoading,
    isXRayContributionAnalysisLoading,
    isFactorTestingLoading,
    isDashboardLoading,
    initLoader,
    isCalculatorLoading
  ];

  return loaders.some((item) => item === true) ? <LoadingCircular /> : null;
}

export default TopBarLoader;
