import { cutExcelSheetName, union } from "../helpers/Utils";
import {
  summary10YearOptions,
  summary15YearOptions,
  summary3YearOptions,
  summary5YearOptions,
  summaryAlphaOptions,
  summaryBetaMinusOptions,
  summaryBetaOptions,
  summaryBetaPlusOptions,
  summaryBetaStdOptions,
  summaryFullOptions,
  summaryJensenAlphaOptions,
  summaryJensenSharpeOptions,
  summaryMaxddOptions,
  summaryPValueOptions,
  summaryQuarterddOptions,
  summarySince2010Options,
  summarySortinoOptions,
  summaryTAlphaOptions,
  summaryTStatOptions,
  summaryYearOptions,
} from "../features/StrategyConstructor/consts";
import XLSX from "xlsx-js-style";

export const handleUpdateSetOfAssetIds = (
  state,
  strategyId,
  asset,
  prevAssetId
) => {
  let tempSet = state.strategies.byId[strategyId].present.portfolios.byAstId;
  tempSet.delete(prevAssetId);
  return union(tempSet, asset.asset_id);
};

export const checkSummaryAllGroupMenu = (selectedEditTableOptions, key) => {
  switch (key) {
    case "beta":
      return summaryBetaOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "beta_std":
      return summaryBetaStdOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "alpha":
      return summaryAlphaOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "p_value":
      return summaryPValueOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "t_stat":
      return summaryTStatOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "jensen_alpha":
      return summaryJensenAlphaOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "jensen_sharpe":
      return summaryJensenSharpeOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "beta_plus":
      return summaryBetaPlusOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "beta_minus":
      return summaryBetaMinusOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
    case "maxdd":
      return summaryMaxddOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "quarter_dd":
      return summaryQuarterddOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "sortino":
      return summarySortinoOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "t_alpha":
      return summaryTAlphaOptions.every((item) =>
        selectedEditTableOptions.includes(item)
      );
  }
};

export const checkSummaryIndeterminateAllGroupMenu = (
  selectedEditTableOptions,
  key
) => {
  if (checkSummaryAllGroupMenu(selectedEditTableOptions, key)) {
    return false;
  }

  switch (key) {
    case "1 y":
      return selectedEditTableOptions.some((item) =>
        summaryYearOptions.includes(item)
      );

    case "3 y":
      return selectedEditTableOptions.some((item) =>
        summary3YearOptions.includes(item)
      );

    case "5 y":
      return selectedEditTableOptions.some((item) =>
        summary5YearOptions.includes(item)
      );

    case "10 y":
      return selectedEditTableOptions.some((item) =>
        summary10YearOptions.includes(item)
      );

    case "15 y":
      return selectedEditTableOptions.some((item) =>
        summary15YearOptions.includes(item)
      );

    case "2010-01-01":
      return selectedEditTableOptions.some((item) =>
        summarySince2010Options.includes(item)
      );

    case "full":
      return selectedEditTableOptions.some((item) =>
        summaryFullOptions.includes(item)
      );
  }
};

export const filterSummaryCellWithEditTableOptions = (
  value,
  selectedEditTableOptions,
  templateSort
) => {
  let result;

  switch (value) {
    case "beta":
      if (
        summaryBetaOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryBetaOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryBetaOptions,
        ]);
        result = Array.from(res);
      }
      break;
    case "beta_std":
      if (
        summaryBetaStdOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryBetaStdOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryBetaStdOptions,
        ]);
        result = Array.from(res);
      }
      break;
    case "alpha":
      if (
        summaryAlphaOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryAlphaOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryAlphaOptions,
        ]);
        result = Array.from(res);
      }
      break;
    case "p_value":
      if (
        summaryPValueOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryPValueOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryPValueOptions,
        ]);
        result = Array.from(res);
      }
      break;
    case "t_stat":
      if (
        summaryTStatOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryTStatOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryTStatOptions,
        ]);
        result = Array.from(res);
      }
      break;
    case "jensen_alpha":
      if (
        summaryJensenAlphaOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryJensenAlphaOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryJensenAlphaOptions,
        ]);
        result = Array.from(res);
      }
      break;
    case "jensen_sharpe":
      if (
        summaryJensenSharpeOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryJensenSharpeOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryJensenSharpeOptions,
        ]);
        result = Array.from(res);
      }
      break;
    case "beta_plus":
      if (
        summaryBetaPlusOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryBetaPlusOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryBetaPlusOptions,
        ]);
        result = Array.from(res);
      }
      break;
    case "beta_minus":
      if (
        summaryBetaMinusOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryBetaMinusOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryBetaMinusOptions,
        ]);
        result = Array.from(res);
      }
      break;

    case "maxdd":
      if (
        summaryMaxddOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryMaxddOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryMaxddOptions,
        ]);
        result = Array.from(res);
      }
      break;

    case "quarter_dd":
      if (
        summaryQuarterddOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryQuarterddOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryQuarterddOptions,
        ]);
        result = Array.from(res);
      }
      break;

    case "sortino":
      if (
        summarySortinoOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summarySortinoOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summarySortinoOptions,
        ]);
        result = Array.from(res);
      }
      break;

    case "t_alpha":
      if (
        summaryTAlphaOptions.every((item) =>
          selectedEditTableOptions.includes(item)
        )
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !summaryTAlphaOptions.includes(item)
        );
        result = res;
      } else {
        const res = new Set([
          ...selectedEditTableOptions,
          ...summaryTAlphaOptions,
        ]);
        result = Array.from(res);
      }
      break;

    default:
      break;
  }

  result.sort((a, b) => templateSort.indexOf(a) - templateSort.indexOf(b));

  return result;
};

function printDefaultElements(printElem, items) {
  for (let index = 0; index < items.length; index++) {
    const element = items[index];

    if (element.classList.contains("pageBreak")) {
      element.style.cssText = "page-break-before: always !important;";
    }

    printElem.appendChild(element);
  }
}

export function printSummaryTab(tab) {
  const PrintElem = document.createElement("div");
  const summaryTable = tab.querySelector('[data-printid="summaryTable"]');
  const yearTable = tab.querySelector('[data-printid="yearTable"]');
  const printElements = tab.querySelectorAll('[data-print="true"]');
  const strategyLength = summaryTable
    .querySelector("tbody")
    .querySelectorAll("tr").length;

  PrintElem.appendChild(summaryTable);

  if (strategyLength > 12) {
    yearTable.style.cssText = "page-break-before: always !important;";
  }

  PrintElem.appendChild(yearTable);

  if (printElements.length) {
    printDefaultElements(PrintElem, printElements);
  }

  return PrintElem;
}

export function printDrowdawnTab(tab) {
  const PrintElem = document.createElement("div");
  const drawDownTable = tab.querySelector('[data-testid="drawDownTable"]');
  const printElements = tab.querySelectorAll('[data-print="true"]');

  const strategyLength = drawDownTable
    .querySelector("tbody")
    .querySelectorAll("tr").length;

  if (strategyLength > 18) {
    drawDownTable.style.cssText = "page-break-after: always !important;";
  }

  PrintElem.appendChild(drawDownTable);

  if (printElements.length) {
    printDefaultElements(PrintElem, printElements);
  }

  return PrintElem;
}

export function printTab(tab) {
  const PrintElem = document.createElement("div");
  const printElements = tab.querySelectorAll('[data-print="true"]');

  if (printElements.length) {
    printDefaultElements(PrintElem, printElements);
  } else {
    tab.style.cssText =
      "page-break-after: always !important;page-break-before: always !important;";

    PrintElem.appendChild(tab);
  }

  return PrintElem;
}

function getBgStyles(index) {
  return {
    fgColor: { rgb: index % 2 > 0 ? "F3F6F7" : "FFFFFF" },
  };
}

function getReturnTableExcelData(nodes) {
  return Array.from(nodes).map((item) => {
    const tr = Array.from(item.querySelectorAll("td"));
    return tr.map((elem) => {
      return {
        v: elem.innerText,
        t:
          elem.innerText === "N.A" ||
            elem.getAttribute("data-testid") === "monthRow" ||
            elem.getAttribute("data-testid") === "yearRow"
            ? "s"
            : "n",
        s: {
          fill: {
            fgColor: {
              rgb:
                elem.innerText === "N.A" ||
                  elem.getAttribute("data-testid") === "monthRow" ||
                  elem.getAttribute("data-testid") === "yearRow" ||
                  (!elem.innerText && +elem.innerText !== 0)
                  ? "FFFFFF"
                  : elem.getAttribute("data-color"),
            },
          },
        },
      };
    });
  });
}

function getExcelWinrateData(tableHead, tableBody) {
  const labelsArr = [];

  for (let index = 0; index < tableHead.length; index++) {
    const element = tableHead[index];
    const bgStyles = getBgStyles(index);

    if (index === 0) {
      labelsArr.push({
        v: element.innerText,
        s: {
          fill: bgStyles,
        },
      });
    } else {
      labelsArr.push({
        v: element
          .querySelector('[data-testid="strategyName"]')
          .getAttribute("aria-label"),
        s: {
          fill: bgStyles,
        },
      });
    }
  }

  const data = [];

  for (let index = 0; index < tableBody.length; index++) {
    const bgStyles = getBgStyles(index);
    const tr = tableBody[index].querySelectorAll("td");
    const trData = [];
    for (let i = 0; i < tr.length; i++) {
      const element = tr[i];

      if (i === 0) {
        trData.push({
          v: element.innerText,
          s: {
            fill: bgStyles,
          },
        });
      } else {
        trData.push({
          v: element.innerText,
          t: "n",
          s: {
            fill: bgStyles,
          },
        });
      }
    }
    data.push(trData);
  }

  return [labelsArr, data];
}

export function excelExportSummaryTab(tab) {
  // ================ summary table

  const summaryTable = tab
    .querySelector('[data-printid="summaryTable"]')
    .querySelector("table");
  const summaryTableHeadTrs = summaryTable
    .querySelector("thead")
    .querySelectorAll("tr");
  const summaryTableBodyTrs = summaryTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const firstHead = summaryTableHeadTrs[0].querySelectorAll("th");
  const secondHead = summaryTableHeadTrs[1].querySelectorAll("th");

  const labelsArr = [];
  let dateCell = {};

  for (let index = 0; index < firstHead.length; index++) {
    const element = firstHead[index];

    if (index === 0) {
      const startDate = element
        .querySelector('[data-testid="startDate"]')
        .querySelector("div").innerText;
      const endDate = element
        .querySelector('[data-testid="endDate"]')
        .querySelector("div").innerText;
      dateCell = { v: `start - ${startDate}, end - ${endDate}` };
      continue;
    }
    if (element.getAttribute("colspan")) {
      labelsArr.push({
        v: element.innerText,
      });
      for (let i = 1; i < +element.getAttribute("colspan"); i++) {
        labelsArr.push({
          v: "",
        });
      }
      continue;
    }
    labelsArr.push({
      v: element.innerText,
    });
  }

  const labelsFiltersArr = [];

  for (let index = 0; index < secondHead.length; index++) {
    const element = secondHead[index];

    labelsFiltersArr.push({
      v: element.innerText,
    });
  }

  const dataSummary = [];

  for (let index = 0; index < summaryTableBodyTrs.length; index++) {
    const bgStyles = getBgStyles(index);

    const arr = summaryTableBodyTrs[index].querySelectorAll("td");
    const trData = [];
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];

      if (i === 0) {
        trData.push({
          v: element
            .querySelector('[data-testid="strategyName"]')
            .getAttribute("aria-label"),
          s: {
            fill: bgStyles,
          },
        });
      } else {
        trData.push({
          v: element.innerText,
          t: "n",
          s: {
            fill: bgStyles,
          },
        });
      }
    }

    dataSummary.push(trData);
  }

  const wsSummary = XLSX.utils.aoa_to_sheet([
    [dateCell, ...labelsArr],
    labelsFiltersArr,
    ...dataSummary,
  ]);

  wsSummary["!cols"] = [{ width: 40 }, ...labelsArr.map(() => ({ width: 15 }))];

  // ================ year table

  const yearTable = tab
    .querySelector('[data-printid="yearTable"]')
    .querySelector("table");

  const yearTableHead = yearTable.querySelector("thead").querySelectorAll("th");
  const yearTableBodyTrs = yearTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const yearsLabelsArr = [];

  for (let index = 0; index < yearTableHead.length; index++) {
    const element = yearTableHead[index];

    yearsLabelsArr.push({
      v: element.innerText,
    });
  }

  const dataYear = [];

  for (let index = 0; index < yearTableBodyTrs.length; index++) {
    const bgStyles = getBgStyles(index);

    const arr = yearTableBodyTrs[index].querySelectorAll("td");
    if (
      index === yearTableBodyTrs.length - 1 &&
      yearTableBodyTrs[index].getAttribute("data-testid") === "rowExcessReturn"
    ) {
      const trData = [];
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];

        if (i === 0) {
          trData.push({
            v: element.querySelector('[data-testid="titleExcessReturn"]')
              .innerText,
            s: {
              fill: bgStyles,
            },
          });
        } else {
          trData.push({
            v: element.innerText,
            t: "n",
            s: {
              fill: bgStyles,
            },
          });
        }
      }
      dataYear.push(trData);
    } else {
      const trData = [];
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];

        if (i === 0) {
          trData.push({
            v: element
              .querySelector('[data-testid="strategyName"]')
              .getAttribute("aria-label"),
            s: {
              fill: bgStyles,
            },
          });
        } else {
          trData.push({
            v: element.innerText,
            t: "n",
            s: {
              fill: bgStyles,
            },
          });
        }
      }
      dataYear.push(trData);
    }
  }

  const wsYear = XLSX.utils.aoa_to_sheet([yearsLabelsArr, ...dataYear]);

  wsYear["!cols"] = yearsLabelsArr.map((_, index) =>
    index === 0 ? { width: 40 } : { width: 10 }
  );

  // winrate table

  const winrateTable = tab
    .querySelector('[data-excel="summary-winrate"]')
    .querySelector("table");

  const winrateTableHead = winrateTable
    .querySelector("thead")
    .querySelectorAll("th");
  const winrateTableBody = winrateTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const [winrateLabelsArr, winrateData] = getExcelWinrateData(
    winrateTableHead,
    winrateTableBody
  );

  const wsWinrate = XLSX.utils.aoa_to_sheet([winrateLabelsArr, ...winrateData]);

  wsWinrate["!cols"] = winrateLabelsArr.map(() => ({
    width: 40,
  }));

  const wbAll = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wbAll, wsSummary, "Summary");
  XLSX.utils.book_append_sheet(wbAll, wsYear, "Yearly");
  XLSX.utils.book_append_sheet(wbAll, wsWinrate, "Winrate");

  XLSX.writeFile(wbAll, "Summary.xlsx");
}

export function excelExportReturnTab(tab, strategiesStatistics) {
  // bestWorst

  const bestWorstPeriodsTable = tab
    .querySelector('[data-testid="gridBestWorstPeriodsTableWrapper"]')
    .querySelector("table");

  const bestWorstPeriodsTableHead = bestWorstPeriodsTable
    .querySelector("thead")
    .querySelectorAll("th");
  const bestWorstPeriodsTableBody = bestWorstPeriodsTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const [bestWorstPeriodsLabelsArr, bestWorstPeriodsData] = getExcelWinrateData(
    bestWorstPeriodsTableHead,
    bestWorstPeriodsTableBody
  );
  console.log([
    bestWorstPeriodsLabelsArr,
    ...bestWorstPeriodsData,
  ])
  const wsBestWorst = XLSX.utils.aoa_to_sheet([
    bestWorstPeriodsLabelsArr,
    ...bestWorstPeriodsData,
  ]);

  wsBestWorst["!cols"] = bestWorstPeriodsLabelsArr.map(() => ({
    width: 40,
  }));

  // monthly

  const monthlyReturn = tab.querySelector(
    '[data-testid="gridMonthlyReturnWrapper"]'
  );

  const monthlyReturnStrategy =
    "MR-" +
    monthlyReturn
      .querySelector('[data-testid="strategyName"]')
      .getAttribute("aria-label");

  const monthlyReturnTable = monthlyReturn.querySelector("table");

  const monthlyReturnHead = Array.from(
    monthlyReturnTable.querySelector("thead").querySelectorAll("th")
  ).map((item) => item.innerText);

  const monthlyReturnTableBody = monthlyReturnTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const monthlyReturnData = getReturnTableExcelData(monthlyReturnTableBody);

  const wsMonthlyReturn = XLSX.utils.aoa_to_sheet([
    monthlyReturnHead,
    ...monthlyReturnData,
  ]);

  // compare monthly

  const compareMonthlyReturn = tab.querySelector(
    '[data-testid="gridMonthlyExcessReturnWrapper"]'
  );

  const compareMonthlyReturnTable = compareMonthlyReturn.querySelector("table");

  const compareMonthlyReturnTableHead = Array.from(
    compareMonthlyReturnTable.querySelector("thead").querySelectorAll("th")
  ).map((item) => item.innerText);

  const compareMonthlyReturnTableBody = compareMonthlyReturnTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const compareMonthlyReturnData = getReturnTableExcelData(
    compareMonthlyReturnTableBody
  );

  const compareMonthlyReturnStrategy =
    compareMonthlyReturn.querySelector('[aria-pressed="true"]').innerText ===
      "Monthly Return"
      ? "MR-"
      : "MER-" +
      compareMonthlyReturn
        .querySelector('[data-testid="strategyName"]')
        .getAttribute("aria-label");

  const wsCompareMonthlyReturn = XLSX.utils.aoa_to_sheet([
    compareMonthlyReturnTableHead,
    ...compareMonthlyReturnData,
  ]);

  // weekly

  const weeklyReturn = tab.querySelector(
    '[data-testid="gridWeeklyReturnWrapper"]'
  );

  const weeklyReturnTableHead = Array.from(
    weeklyReturn.querySelector("thead").querySelectorAll("th")
  ).map((item) => item.innerText);

  const weeklyReturnTableBody = weeklyReturn
    .querySelector("tbody")
    .querySelectorAll("tr");

  const weeklyReturnData = getReturnTableExcelData(weeklyReturnTableBody);

  const weeklyReturnStrategy =
    "WR-" +
    weeklyReturn
      .querySelector('[data-testid="strategyName"]')
      .getAttribute("aria-label");

  const wsWeeklyReturn = XLSX.utils.aoa_to_sheet([
    weeklyReturnTableHead,
    ...weeklyReturnData,
  ]);

  function createDaylyReturns(strategiesStatistics) {
    const arr = [];

    const strategyNames = Object.values(strategiesStatistics).map(strategy => strategy.name);
    const strategyCount = strategyNames.length;
    const row = [];
    strategyNames.forEach((name, index) => {
      row.push({
        v: name,
        s: {
          fill: { fgColor: { rgb: "FFFFFF" } },
          font: { sz: 13, bold: true },
        },
      });
      if (index < strategyCount - 1) { row.push("", "") }
    });
    arr.push(row);

    const headerRow = [];
    for (let i = 0; i < strategyCount; i++) {
      headerRow.push(
        {
          v: "Date",
          s: {
            fill: { fgColor: { rgb: "F3F6F7" } },
            font: { bold: true },
            alignment: { horizontal: "left" },
          },
        },
        {
          v: "Returns",
          s: {
            fill: { fgColor: { rgb: "F3F6F7" } },
            font: { bold: true },
            alignment: { horizontal: "right" },
          },
        }, "");
    }
    arr.push(headerRow);

    Object.values(strategiesStatistics).forEach(strategy => {
      const returns = JSON.parse(strategy.stats.strategy_returns).strategy_returns;
      const strategyReturns = Object.entries(returns);

      strategyReturns.forEach(([date, value], i) => {
        const formattedDate = new Date(Number(date)).toISOString().slice(0, 10);
        const row = [];
        const bgStyles = getBgStyles(i);

        strategyNames.forEach((index) => {
          if (index === 0) {
            row.push(
              {
                v: formattedDate,
                s: {
                  fill: bgStyles,
                  alignment: { horizontal: "right" },
                },
              },
              {
                v: value.toFixed(4),
                s: {
                  fill: bgStyles,
                  alignment: { horizontal: "right" },
                },
              });
          } else {
            row.push({
              v: formattedDate,
              s: {
                fill: bgStyles,
                alignment: { horizontal: "left" },
              },
            }, {
              v: value.toFixed(4),
              s: {
                fill: bgStyles,
                alignment: { horizontal: "right" },
              },
            }, "");
          }
        });
        arr.push(row);
      });
    });
    return arr
  }

  const daylyReturns = createDaylyReturns(strategiesStatistics);

  const wsDaylyReturns = XLSX.utils.aoa_to_sheet(daylyReturns)
  wsDaylyReturns["!cols"] = daylyReturns.map(() => ({ width: 15 }));

  const wbAll = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wbAll, wsBestWorst, "Best-Worst");
  XLSX.utils.book_append_sheet(
    wbAll,
    wsMonthlyReturn,
    cutExcelSheetName(monthlyReturnStrategy)
  );
  XLSX.utils.book_append_sheet(
    wbAll,
    wsCompareMonthlyReturn,
    cutExcelSheetName(compareMonthlyReturnStrategy)
  );
  XLSX.utils.book_append_sheet(
    wbAll,
    wsWeeklyReturn,
    cutExcelSheetName(weeklyReturnStrategy)
  );
  XLSX.utils.book_append_sheet(
    wbAll,
    wsDaylyReturns,
    "Daily returns"
  );

  XLSX.writeFile(wbAll, "Return.xlsx");
}

export function excelExportDrawDownTab(tab) {
  const drawDownTable = tab.querySelector('[data-testid="drawDownTable"]');

  const drawDownTableHead = drawDownTable
    .querySelector("thead")
    .querySelectorAll("tr");
  const drawDownTableBody = drawDownTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const drawDownLabels = [];

  for (let index = 0; index < drawDownTableHead.length; index++) {
    const tr = drawDownTableHead[index].querySelectorAll("th");
    const trData = [];
    for (let i = 0; i < tr.length; i++) {
      const th = tr[i];
      if (index === 0) {
        if (i === 0) {
          const startDate = th.querySelector(
            '[data-testid="startDate"]'
          ).innerText;
          const endDate = th.querySelector('[data-testid="endDate"]').innerText;
          trData.push({ v: `${startDate}, ${endDate}` });
          continue;
        }

        if (th.getAttribute("colspan")) {
          trData.push({
            v: th.innerText,
          });
          for (let i = 1; i < +th.getAttribute("colspan"); i++) {
            trData.push({
              v: "",
            });
          }
          continue;
        }
      } else {
        trData.push({
          v: th.innerText,
        });
      }
    }
    drawDownLabels.push(trData);
  }

  const drawDownBody = Array.from(drawDownTableBody).map((item, index) => {
    const tr = item.querySelectorAll("td");
    const bgStyles = getBgStyles(index);
    return Array.from(tr).map((elem, i) => {
      if (i === 0) {
        return {
          v: elem
            .querySelector('[data-testid="strategyName"]')
            .getAttribute("aria-label"),
          s: {
            fill: bgStyles,
          },
        };
      }

      return {
        v: elem.innerText,
        t: "n",
        s: {
          fill: bgStyles,
        },
      };
    });
  });

  const wsDrawDown = XLSX.utils.aoa_to_sheet([
    ...drawDownLabels,
    ...drawDownBody,
  ]);

  wsDrawDown["!cols"] = [
    { width: 40 },
    ...drawDownLabels[1].map(() => ({
      width: 10,
    })),
  ];

  const worstDrawdowns = tab.querySelector('[data-excel="worstDrawdowns"]');

  const worstDrawdownsStrategy = worstDrawdowns
    .querySelector('[data-testid="strategyName"]')
    .getAttribute("aria-label");

  const worstDrawdownsTable = worstDrawdowns.querySelector("table");

  const worstDrawdownsTableHead = worstDrawdownsTable
    .querySelector("thead")
    .querySelectorAll("th");
  const worstDrawdownsTableBody = worstDrawdownsTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const worstDrawdownsLabels = Array.from(worstDrawdownsTableHead).map(
    (item) => item.innerText
  );

  const worstDrawDownBody = Array.from(worstDrawdownsTableBody).map(
    (item, index) => {
      const tr = item.querySelectorAll("td");
      const bgStyles = getBgStyles(index);
      return Array.from(tr).map((elem, i) => {
        return {
          v: elem.innerText,
          t: i === 0 || i === 1 ? "s" : "n",
          s: {
            fill: bgStyles,
            alignment: { horizontal: "right" },
          },
        };
      });
    }
  );

  const wsWorstDrawDown = XLSX.utils.aoa_to_sheet([
    worstDrawdownsLabels,
    ...worstDrawDownBody,
  ]);

  wsWorstDrawDown["!cols"] = worstDrawdownsLabels.map(() => ({
    width: 15,
  }));

  const wbAll = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wbAll, wsDrawDown, "DrawDown");
  XLSX.utils.book_append_sheet(
    wbAll,
    wsWorstDrawDown,
    cutExcelSheetName("WorstDD-" + worstDrawdownsStrategy)
  );

  XLSX.writeFile(wbAll, "DrawDown.xlsx");
}

export function excelExportCorrelationTab(tab) {
  const selectedTab = tab
    .querySelector('[aria-label="alpha-beta calculation"]')
    .querySelector('[aria-selected="true"]').innerText;

  const tableMap = {
    ["Alpha Table"]: "correlationAlphaTable",
    ["Beta Table"]: "correlationBetaTable",
  };

  const correlationTable = tab.querySelector(
    `[data-testid=${tableMap[selectedTab]}]`
  );

  const correlationTableHead = Array.from(
    correlationTable
      .querySelector("thead")
      .querySelector("tr")
      .querySelectorAll("th")
  ).map((item) => item.innerText);

  const correlationTableBody = correlationTable
    .querySelector("tbody")
    .querySelectorAll("tr");

  const correlationData = Array.from(correlationTableBody).map(
    (item, index) => {
      const tr = item.querySelectorAll("td");
      const bgStyles = getBgStyles(index);
      return Array.from(tr).map((elem, i) => {
        if (i === 0) {
          return {
            v: elem.querySelector("span").innerText,
            t: "s",
            s: {
              fill: bgStyles,
              alignment: { horizontal: "right" },
            },
          };
        }

        return {
          v: elem.innerText,
          t: "n",
          s: {
            fill: bgStyles,
            alignment: { horizontal: "right" },
          },
        };
      });
    }
  );

  const wsCorrelation = XLSX.utils.aoa_to_sheet([
    correlationTableHead,
    ...correlationData,
  ]);

  wsCorrelation["!cols"] = correlationTableHead.map(() => ({
    width: 40,
  }));

  const wbAll = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(
    wbAll,
    wsCorrelation,
    cutExcelSheetName(selectedTab)
  );

  XLSX.writeFile(wbAll, "Correlation.xlsx");
}

export function getFactorTitle(
  portfolios,
  row,
  array,
  property,
  editable,
  selectedOption
) {
  if (!editable) {
    return portfolios[row][property];
  }
  if (selectedOption || selectedOption === "") {
    return selectedOption;
  }
  if (array.length === 0) {
    return portfolios[row][property];
  }
  return array.some((e) => e.name === portfolios[row][property])
    ? portfolios[row][property]
    : array[0].name;
}
