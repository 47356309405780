import {
  ADD_NEW_ASSET_TOGGLE_FETCHING,
  ADD_NEW_BENCHMARK,
  ADD_NEW_PORTFOLIO,
  ADD_NESTED_STRATEGY,
  STOCKS_FULFILLED,
  STOCKS_REJECTED,
  STOCKS_TOGGLE_FETCHING,
  STRATEGY_EDIT,
  TYPE_ID_DOES_NOT_HANDLED,
  UPDATE_PORTFOLIO_BETA_TABLE,
  UPDATE_PORTFOLIO_BETA_TABLE_FULFILLED,
  UPDATE_PORTFOLIO_BETA_TABLE_LOADING,
  ADD_NEW_SECURITY,
  SET_ASSET_POSITION,
  SET_ASSET_WEIGHT,
  SET_ASSET_BULK_WEIGHT,
  DELETE_ASSET_ROW,
} from "./Type";

import {
  GET_STATISTICS_FAILED,
  GET_STATISTICS_START,
  GET_STATISTICS_SUCCESS,
  SEARCH_FAILED,
  SEARCH_PORTFOLIO_START,
  SEARCH_PORTFOLIO_SUCCESS,
  SET_ORDER_OF_STRATEGIES,
  DELETE_HIGHLIGHTED_ROWS,
  DUPLICATE_HIGHLIGHTED_ROWS,
  UPDATE_WEIGHT_HIGHLIGHTED_ROWS,
  REPLACE_BENCHMARK_START,
  REPLACE_BENCHMARK_FAILED,
  REPLACE_BENCHMARK_SUCCESS,
  SELECTED_STATISTICS_STRATEGIES_CHANGE,
  GET_SHORT_ASSET_LIST_START,
  GET_SHORT_ASSET_LIST_SUCCESS,
  GET_SHORT_ASSET_LIST_FAILED,
  SET_REVISION_VERSION,
  REPLACE_NESTED_STRATEGY,
  SET_CONSTRUCTOR_ACTIVE_TAB,
  UPDATE_ORDER_STRATEGY,
  REPLACE_SINGLE_SECURITY,
  SET_HIGHLIGHTED_ROWS
} from "../types/Portfolio";

import { batch } from "react-redux";
import nextId from "react-id-generator";
import portfolioRequest from "../../services/Portfolio.js";
import SnackbarUtils from "../../helpers/SnackBarUtils.js";
import { generateErrorMessage } from "../../helpers/Error";
import { requestStatuses } from "../../helpers/Constants";
import { generatePortfolioList } from "../../helpers/Utils";
import {
  clearAllStrategies,
  initializeStrategy,
  loadStrategy,
} from "./Strategy";
import {
  Benchmark,
  generateIdForAssetByType,
  NestedStrategy,
  Portfolio,
  ASSET_TYPES,
  Security,
  ASSET_TYPE_IDS,
  OtherPortfolio,
  ADDITIONAL_ASSET_TYPES,
} from "../../helpers/Assets";

const { loading, succeeded, failed } = requestStatuses;

export const assets = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user?.userData?.access_token) {
    return;
  }

  dispatch({ type: SEARCH_PORTFOLIO_START });
  return portfolioRequest
    .assets("", user.userData.access_token)
    .then((responce) => {
      dispatch({
        type: SEARCH_PORTFOLIO_SUCCESS,
        payload: responce,
      });
    })
    .catch((error) => {
      generateErrorMessage(error);
      dispatch({
        type: SEARCH_FAILED,
        payload: generateErrorMessage(error),
      });
    });
};

export const getShortAssetsList = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user?.userData?.access_token) {
    return;
  }

  dispatch({ type: GET_SHORT_ASSET_LIST_START });
  return portfolioRequest
    .assetsShortList(user.userData.access_token)
    .then((responce) => {
      dispatch({
        type: GET_SHORT_ASSET_LIST_SUCCESS,
        payload: responce,
      });
    })
    .catch((error) => {
      generateErrorMessage(error);
      dispatch({
        type: GET_SHORT_ASSET_LIST_FAILED,
        payload: generateErrorMessage(error),
      });
    });
};

export const generatePortfolioListFromStore = () => (dispatch, getState) => {
  const state = getState();

  const { benchmarks, strategies, portfolios, nestedStrategies, security } =
    state.portfolio;

  const portfolio_list = generatePortfolioList(
    strategies.byId,
    benchmarks.byId,
    portfolios.byId,
    nestedStrategies.byId,
    security.byId
  );

  return portfolio_list;
};

export const getStatistics = (data) => async (dispatch) => {
  dispatch({ type: GET_STATISTICS_START });
  return portfolioRequest
    .portfolioCalculation(data)
    .then((responce) => {
      dispatch({
        type: GET_STATISTICS_SUCCESS,
        payload: responce,
      });
    })
    .catch((error) => {
      dispatch({ type: GET_STATISTICS_FAILED });
      generateErrorMessage(error);
    });
};

export const addNested = (nested, strategyId) => (dispatch) => {
  const nestedId = nextId(ASSET_TYPE_IDS.nestedStrategies);

  const nestedStrategy = new NestedStrategy(
    ASSET_TYPES.nestedStrategies,
    nested.asset_id,
    nestedId,
    nested.in_favorites,
    nested.initialization_date,
    nested.name,
    nested.name_to_show,
    nested.nested,
    nested.portfolio_id,
    "long",
    nested.public_status,
    nested.status_id,
    nested.status_type,
    nested.strategy_id,
    nested.strategy_type_id,
    nested.strategy_type_name,
    nested.trade_date,
    nested.user_id,
    nested.user_name,
    100,
    nestedId,
    false,
    strategyId,
    false
  );
  dispatch({
    type: ADD_NESTED_STRATEGY,
    payload: nestedStrategy,
  });
};

export const getAssetById =
  (assetId, typeId, strategyId, editedWeight = "", editedPosition = "") =>
    async (dispatch) => {
      dispatch({
        type: ADD_NEW_ASSET_TOGGLE_FETCHING,
        payload: true,
      });

      return portfolioRequest
        .assetById(assetId, typeId)
        .then(async (asset) => {
          const id = generateIdForAssetByType(typeId);

          let editMode = false;
          const weight = asset.hasOwnProperty("weight")
            ? asset.weight
            : editedWeight || editedWeight === 0
              ? editedWeight
              : 100;
          const position = asset.hasOwnProperty("position")
            ? asset.position
            : editedPosition
              ? editedPosition
              : asset.pos === "btm"
                ? "short"
                : "long";
          switch (typeId) {
            case ADDITIONAL_ASSET_TYPES.methodology:
            case ASSET_TYPES.portfolio:
              editMode = false;

              const portfolio = new Portfolio(
                asset.asset_id,
                typeId,
                asset.cnt,
                asset.cur,
                asset.filter_description,
                asset.fltr,
                asset.mtl,
                asset.pos,
                asset.rbl,
                asset.rebalance_description,
                asset.size,
                asset.trade_date,
                id,
                editMode,
                strategyId,
                weight,
                position,
                false
              );

              dispatch({
                type: ADD_NEW_PORTFOLIO,
                payload: portfolio,
              });
              break;

            case ASSET_TYPES.benchmark:
              const benchmark = new Benchmark(
                asset.asset_id,
                asset.country_id,
                asset.name,
                asset.trade_date,
                id,
                strategyId,
                weight,
                position,
                false
              );

              dispatch({
                type: ADD_NEW_BENCHMARK,
                payload: benchmark,
              });
              break;

            case ASSET_TYPES.security:
              const security = new Security(
                asset.asset_id,
                asset.country_id,
                asset.name,
                asset.trade_date,
                id,
                strategyId,
                weight,
                position,
                false
              );

              dispatch({
                type: ADD_NEW_SECURITY,
                payload: security,
              });
              break;

            default:
              dispatch({
                type: TYPE_ID_DOES_NOT_HANDLED,
                payload: { typeId },
              });
          }
        })
        .catch((exception) => {
          generateErrorMessage(exception);
          dispatch({
            type: ADD_NEW_ASSET_TOGGLE_FETCHING,
            payload: false,
          });
        });
    };

export const replaceBenchmarkById =
  (assetId, typeId, strategyId, currentId) => async (dispatch) => {
    dispatch({
      type: REPLACE_BENCHMARK_START,
      payload: true,
    });

    return portfolioRequest
      .assetById(assetId, typeId)
      .then(async (asset) => {
        asset = { ...asset, ...{ asset_type: typeId } };
        dispatch({
          type: REPLACE_BENCHMARK_SUCCESS,
          payload: {
            currentId,
            data: {
              asset,
              strategyId,
              status: false,
            },
          },
        });
      })
      .catch((error) => {
        const msg = generateErrorMessage(error);
        SnackbarUtils.error(msg);
        dispatch({
          type: REPLACE_BENCHMARK_FAILED,
          payload: false,
        });
      });
  };

export const replaceNestedStrategy =
  (
    newNestedStrategy,
    strategyId,
    oldNestedStrategyId,
    prevWeight,
    prevPosition,
    prevAssetId
  ) =>
    (dispatch) => {
      dispatch({
        type: REPLACE_NESTED_STRATEGY,
        payload: {
          nestedStrategy: {
            ...newNestedStrategy,
            position: prevPosition,
            weight: prevWeight,
            id: oldNestedStrategyId,
            asset_type: ASSET_TYPES.nestedStrategies,
          },
          oldNestedStrategyId,
          strategyId,
          prevAssetId,
        },
      });
    };

export const replaceSecurity =
  (
    security,
    strategyId,
    oldSecurityId,
    prevWeight,
    prevPosition,
    prevAssetId
  ) =>
    (dispatch) => {
      dispatch({
        type: REPLACE_SINGLE_SECURITY,
        payload: {
          security: {
            ...security,
            position: prevPosition,
            weight: prevWeight,
            id: oldSecurityId,
            asset_type: ASSET_TYPES.security,
          },
          oldSecurityId,
          strategyId,
          prevAssetId,
        },
      });
    };

export const handleStrategyEdit =
  (
    key,
    assetId,
    typeId,
    strategyId,
    weight,
    position,
    prevPortfolioId,
    prevAssetId,
    asset_type
  ) =>
    async (dispatch) => {
      return portfolioRequest
        .assetById(assetId, typeId)
        .then((asset) => {
          asset = { ...asset, ...{ asset_type: asset_type } };
          dispatch({
            type: STRATEGY_EDIT,
            payload: {
              key,
              asset,
              id: prevPortfolioId,
              strategyId,
              weight,
              position,
              prevPortfolioId,
              prevAssetId,
            },
          });
        })
        .catch((error) => {
          generateErrorMessage(error);
        });
    };

export const setPosition = (key, value, id, strategyId) => (dispatch) => {
  dispatch({
    type: SET_ASSET_POSITION,
    payload: { key, value, id, strategyId },
  });
};

export const setWeight = (key, value, id, strategyId) => (dispatch) => {
  dispatch({
    type: SET_ASSET_WEIGHT,
    payload: { key, value, id, strategyId },
  });
};

export const setWeightBulk = (key, values) => (dispatch) => {
  dispatch({
    type: SET_ASSET_BULK_WEIGHT,
    payload: { key, values },
  });
};

export const deleteAssetRow =
  (key, rowId, strategyId, assetId) => (dispatch) => {
    dispatch({
      type: DELETE_ASSET_ROW,
      payload: { key, rowId, strategyId, assetId },
    });
  };

export const updatePortfolioBetaTable =
  (startDate, endDate, strategyReturnsList, benchmark) => async (dispatch) => {
    dispatch({
      type: UPDATE_PORTFOLIO_BETA_TABLE_LOADING,
    });
    return portfolioRequest
      .updateBetaTable(startDate, endDate, strategyReturnsList, benchmark)
      .then((responce) => {
        dispatch({
          type: UPDATE_PORTFOLIO_BETA_TABLE,
          payload: {
            beta: { beta_list: responce.beta_list },
            alpha: { alpha_list: responce.alpha_list },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PORTFOLIO_BETA_TABLE_FULFILLED,
        });
        generateErrorMessage(error);
      });
  };

export const getStocks = (strategyId, assetIds, user) => async (dispatch) => {
  dispatch({
    type: STOCKS_TOGGLE_FETCHING,
    payload: { strategyId, status: loading },
  });
  return portfolioRequest
    .getStocks(assetIds, user.access_token)
    .then((response) => {
      if (response) {
        dispatch({
          type: STOCKS_FULFILLED,
          payload: { response, status: succeeded, strategyId },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: STOCKS_REJECTED,
        payload: {
          error: generateErrorMessage(error),
          status: failed,
          strategyId,
        },
      });
      generateErrorMessage(error);
    });
};

export const setOrderOfStrategies = (order) => (dispatch) => {
  dispatch({
    type: SET_ORDER_OF_STRATEGIES,
    payload: order,
  });
};

export const deleteHighlightedRows = (data) => (dispatch) => {
  dispatch({
    type: DELETE_HIGHLIGHTED_ROWS,
    payload: data,
  });
};

export const duplicateHighlightedRows = (data) => (dispatch) => {
  dispatch({
    type: DUPLICATE_HIGHLIGHTED_ROWS,
    payload: data,
  });
};

export const updateWeightHighlightedRows = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_WEIGHT_HIGHLIGHTED_ROWS,
    payload: data,
  });
};

export const getPortfolioList = () => (dispatch) => {
  const portfolio_list = dispatch(generatePortfolioListFromStore());

  try {
    dispatch(
      getStatistics({
        start_date: "2001-01-01",
        include_regression: false,
        end_date: new Date().toISOString().slice(0, 10),
        portfolio_list,
      })
    );
    return portfolio_list;
  } catch (error) {
    generateErrorMessage(error);
  }
};

export const createNestedStrats = 
(idsArray, user, positionType, strgyId = null, cleanup = true) =>
    (dispatch, getState) => {
      const state = getState();
      let callsArray = [];

      if (cleanup) {
        callsArray.push(clearAllStrategies(strgyId));
      }

      const strategyWeight = 100 / idsArray.length;
      let generalStrategyId = nextId("strategy-");
      callsArray.push(initializeStrategy(user, generalStrategyId));
      
      idsArray.forEach(({ id, type }) => {
        const asst_id = id
        const nestedId = nextId(ASSET_TYPE_IDS.nestedStrategies);
        const nested = state.model.strategiesStatistics[id]
        const nestedStrategy = new NestedStrategy(
          ASSET_TYPES.nestedStrategies,
          asst_id,
          nestedId,
          nested.in_favorites,
          nested.initialization_date,
          nested.name,
          nested.name_to_show,
          nested.nested,
          nested.portfolio_id,
          "long",
          nested.public_status,
          nested.status_id,
          nested.status_type,
          nested.strategy_id,
          nested.strategy_type_id,
          nested.strategy_type_name,
          nested.trade_date,
          nested.user_id,
          nested.user_name,
          strategyWeight,
          nestedId,
          false,
          generalStrategyId,
          false
        );

        callsArray.push({
          type: ADD_NESTED_STRATEGY,
          payload: nestedStrategy,
        });

      });

      
      try {
        batch(() => {
          Promise.all(callsArray.map((item) => dispatch(item))).then(() => {
            dispatch(getPortfolioList());
          });
        });
      } catch (error) {
        generateErrorMessage(error);
      }

    }

export const createPortfolios =
  (idsArray, user, positionType, strgyId = null, cleanup = true) =>
    (dispatch, getState) => {
      const state = getState();

      let callsArray = [];

      if (cleanup) {
        callsArray.push(clearAllStrategies(strgyId));
      }

      const strategyWeight = 100 / idsArray.length;

      let generalStrategyId = nextId("strategy-");
      callsArray.push(initializeStrategy(user, generalStrategyId));

      switch (positionType.toLowerCase()) {
        case "top":
        case "btm":
          idsArray.forEach(({ id, type }) => {
            const factorItem = Object.values(
              state.factor.portfoliosStatistic
            ).find((item) => item.asset_id === +id);

            const portfolio = new Portfolio(
              factorItem.asset_id,
              type,
              factorItem.country_id,
              factorItem.currency_id,
              factorItem.filter_description,
              factorItem.filter_id,
              factorItem.methodology_id,
              factorItem.position_id,
              factorItem.rebalance_id,
              factorItem.rebalance_description,
              factorItem.size_id,
              factorItem.last_trade_date,
              nextId(ASSET_TYPE_IDS.portfolio),
              false,
              generalStrategyId,
              strategyWeight,
              "long",
              false
            );

            callsArray.push({
              type: ADD_NEW_PORTFOLIO,
              payload: portfolio,
            });
          });

          break;

        case "top-index":
        case "btm-index":
          let benchArr = [];

          idsArray.forEach(({ id, type }) => {
            const factorItem = Object.values(
              state.factor.portfoliosStatistic
            ).find((item) => item.asset_id === +id);

            const benchItem = Object.values(
              state.factor.benchmarksStatistic
            ).find((item) => +item.asset_id === +factorItem.benchmark_asset_id);

            const portfolio = new Portfolio(
              factorItem.asset_id,
              type,
              factorItem.country_id,
              factorItem.currency_id,
              factorItem.filter_description,
              factorItem.filter_id,
              factorItem.methodology_id,
              factorItem.position_id,
              factorItem.rebalance_id,
              factorItem.rebalance_description,
              factorItem.size_id,
              factorItem.last_trade_date,
              nextId(ASSET_TYPE_IDS.portfolio),
              false,
              generalStrategyId,
              +strategyWeight,
              "long",
              false
            );

            callsArray.push({
              type: ADD_NEW_PORTFOLIO,
              payload: portfolio,
            });

            benchArr.push(benchItem);
          });

          const benchData = benchArr.reduce((acc, cur) => {
            return {
              ...acc,
              [cur.asset_id]: acc[cur.asset_id] ? acc[cur.asset_id] + 1 : 1,
            };
          }, {});

          Object.keys(benchData).forEach((item) => {
            const elem = benchArr.find((e) => +e.asset_id === +item);

            const benchmark = new Benchmark(
              +item,
              elem.country_id,
              elem.name,
              elem.last_trade_date,
              nextId(ASSET_TYPE_IDS.benchmark),
              generalStrategyId,
              Number((100 / benchArr.length) * +benchData[item]).toFixed(2),
              "short",
              false
            );

            callsArray.push({
              type: ADD_NEW_BENCHMARK,
              payload: benchmark,
            });
          });

          break;

        case "top-btm":
          idsArray.forEach(({ id, type }) => {
            const factorItem = Object.values(
              state.factor.portfoliosStatistic
            ).find((item) => item.asset_id === +id);

            const portfolioBtm = new Portfolio(
              +factorItem.btm_asset_id,
              type,
              factorItem.country_id,
              factorItem.currency_id,
              factorItem.filter_description,
              factorItem.filter_id,
              factorItem.methodology_id,
              "btm",
              factorItem.rebalance_id,
              factorItem.rebalance_description,
              factorItem.size_id,
              factorItem.last_trade_date,
              nextId(ASSET_TYPE_IDS.portfolio),
              false,
              generalStrategyId,
              +strategyWeight,
              "short",
              false
            );

            const portfolio = new Portfolio(
              factorItem.asset_id,
              type,
              factorItem.country_id,
              factorItem.currency_id,
              factorItem.filter_description,
              factorItem.filter_id,
              factorItem.methodology_id,
              factorItem.position_id,
              factorItem.rebalance_id,
              factorItem.rebalance_description,
              factorItem.size_id,
              factorItem.last_trade_date,
              nextId(ASSET_TYPE_IDS.portfolio),
              false,
              generalStrategyId,
              +strategyWeight,
              "long",
              false
            );

            callsArray.push({
              type: ADD_NEW_PORTFOLIO,
              payload: portfolioBtm,
            });

            callsArray.push({
              type: ADD_NEW_PORTFOLIO,
              payload: portfolio,
            });
          });

          break;

        default:
          break;
      }

      try {
        batch(() => {
          Promise.all(callsArray.map((item) => dispatch(item))).then(() => {
            dispatch(getPortfolioList());
          });
        });
      } catch (error) {
        generateErrorMessage(error);
      }
    };

export const explode =
  (idsArray, user, strgyId = null, cleanup = true) =>
    (dispatch, getState) => {
      let callsArray = [];
      if (cleanup) {
        callsArray.push(clearAllStrategies(strgyId));
      }

      const state = getState();

      const { portfolios, benchmarks, nestedStrategies, security } =
        state.portfolio;

      idsArray.forEach(({ id, type }) => {
        const stId = nextId("strategy-");
        callsArray.push(initializeStrategy(user, stId));

        if (
          type === ASSET_TYPES.portfolio ||
          type === ADDITIONAL_ASSET_TYPES.methodology
        ) {
          const factorItem = portfolios.byId[id];

          const portfolio = new Portfolio(
            factorItem.asset_id,
            type,
            factorItem.cnt,
            factorItem.cur,
            factorItem.filter_description,
            factorItem.fltr,
            factorItem.mtl,
            factorItem.pos,
            factorItem.rbl,
            factorItem.rebalance_description,
            factorItem.size,
            factorItem.trade_date,
            nextId(ASSET_TYPE_IDS.portfolio),
            false,
            stId,
            100,
            "long",
            false
          );

          callsArray.push({
            type: ADD_NEW_PORTFOLIO,
            payload: portfolio,
          });
        } else if (type === ASSET_TYPES.nestedStrategies) {
          const nestedItem = nestedStrategies.byId[id];
          const nestedId = nextId(ASSET_TYPE_IDS.nestedStrategies);

          const nestedStrategy = new NestedStrategy(
            nestedItem.asset_type,
            nestedItem.asset_id,
            nestedItem.id,
            nestedItem.in_favorites,
            nestedItem.initialization_date,
            nestedItem.name,
            nestedItem.name_to_show,
            nestedItem.nested,
            nestedItem.portfolio_id,
            nestedItem.position,
            nestedItem.public_status,
            nestedItem.status_id,
            nestedItem.status_type,
            nestedItem.strategy_id,
            nestedItem.strategy_type_id,
            nestedItem.strategy_type_name,
            nestedItem.trade_date,
            nestedItem.user_id,
            nestedItem.user_name,
            nestedItem.weight,
            nestedId,
            false,
            stId,
            false
          );

          callsArray.push({
            type: ADD_NESTED_STRATEGY,
            payload: nestedStrategy,
          });
        } else if (type === ASSET_TYPES.security) {
          const securityItem = security.byId[id];

          const newSecurity = new Security(
            securityItem.asset_id,
            securityItem.country_id,
            securityItem.name,
            securityItem.trade_date,
            nextId(ASSET_TYPE_IDS.security),
            stId,
            100,
            "long",
            false
          );
          callsArray.push({
            type: ADD_NEW_SECURITY,
            payload: newSecurity,
          });
        } else {
          const benchItem = benchmarks.byId[id];

          const benchmark = new Benchmark(
            benchItem.asset_id,
            benchItem.country_id,
            benchItem.name,
            benchItem.trade_date,
            nextId(ASSET_TYPE_IDS.benchmark),
            stId,
            100,
            "short",
            false
          );

          callsArray.push({
            type: ADD_NEW_BENCHMARK,
            payload: benchmark,
          });
        }
      });
      try {
        batch(() => {
          Promise.all(callsArray.map((item) => dispatch(item))).then(() => {
            dispatch(getPortfolioList());
          });
        });
      } catch (error) {
        generateErrorMessage(error);
      }
    };

export const getDefaultPortfolios = (user, idx_lst) => (dispatch) => {
  try {
    batch(() => {
      Promise.all(
        [user.default_strategy, ...user.strategies_col].map(
          (strategy) => (
            dispatch(initializeStrategy(user, idx_lst[idx_lst.length - 1])),
            dispatch(
              loadStrategy(strategy, idx_lst.pop(), user && user.access_token)
            )
          )
        )
      ).then(() => {
        dispatch(getPortfolioList());
      });
    });
  } catch (error) {
    generateErrorMessage(error);
  }
};

export const firstInitializationWithDefaultPortfolios =
  (user) => (dispatch) => {
    let id1 = nextId("strategy-");
    let idx_lst = [];
    for (const _ of [user.default_strategy, ...user.strategies_col]) {
      idx_lst.push(nextId("strategy-"));
    }
    if (id1 === "strategy-1") {
      dispatch(getDefaultPortfolios(user, idx_lst));
    }
  };

export const reInitializationWithDefaultPortfolios = (user) => (dispatch) => {
  let idx_lst = [];
  for (const _ of [user.default_strategy, ...user.strategies_col]) {
    idx_lst.push(nextId("strategy-"));
  }
  if (idx_lst.length) {
    dispatch(getDefaultPortfolios(user, idx_lst));
  }
};

export const selectedStatisticsStrategiesChange = (id) => (dispatch) => {
  dispatch({
    type: SELECTED_STATISTICS_STRATEGIES_CHANGE,
    payload: id,
  });
};

export const setRevisionRersion = (strategy_id, revision) => (dispatch) => {
  dispatch({
    type: SET_REVISION_VERSION,
    payload: { strategy_id, revision },
  });
};

export const setConstructorActiveTab = (tabIndex) => (dispatch) => {
  dispatch({
    type: SET_CONSTRUCTOR_ACTIVE_TAB,
    payload: tabIndex,
  });
};

export const updateOrderStrategy =
  (strategyId, order, prevOrder) => (dispatch) => {
    dispatch({
      type: UPDATE_ORDER_STRATEGY,
      payload: { strategyId, order, prevOrder },
    });
  };

export const setHighlightedRows = (highlightedRows, strategyId) => (dispatch) => {
  dispatch({
    type: SET_HIGHLIGHTED_ROWS,
    payload: { highlightedRows, strategyId },
  });
}