import {
  CLEAR_STRATEGIES,
  CLEAR_STRATEGIES_ERROR,
  STRATEGIES_LOADED,
  STRATEGIES_NOT_LOADED,
  DELETE_USER_STRATEGY,
  LOGOUT,
} from "../actions/Type";

import {
  ADD_TO_FAVORITES_START,
  ADD_TO_FAVORITES_SUCCESS,
  ADD_TO_FAVORITES_FAILED,
  REMOVE_FROM_FAVORITES_START,
  REMOVE_FROM_FAVORITES_SUCCESS,
  REMOVE_FROM_FAVORITES_FAILED,
  GET_STRATEGY_TITLES_BY_USER_ID_START,
  GET_STRATEGY_TITLES_BY_USER_ID_SUCCESS,
  GET_STRATEGY_TITLES_BY_USER_ID_FAILED,
} from "../types/Strategy";

const initialState = {
  strategies: [],
  strategyTitlesByUserId: [],
  listOfFavorites: {},
  error: "",
  loaders: {
    isStrategyTitlesByUserIdLoading: false,
  },
};

export default function strategiesReducer(state = initialState, action) {
  switch (action.type) {
    case STRATEGIES_LOADED:
      return { ...state, strategies: action.payload };

    case STRATEGIES_NOT_LOADED:
      return { ...state, error: action.payload };

    case CLEAR_STRATEGIES:
      return { ...state, strategies: [] };

    case CLEAR_STRATEGIES_ERROR:
      return { ...state, error: "" };

    case DELETE_USER_STRATEGY:
      return {
        ...state,
        strategies: state.strategies.filter(
          (item) => item.portfolio_id !== action.payload
        ),
      };

    case GET_STRATEGY_TITLES_BY_USER_ID_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isStrategyTitlesByUserIdLoading: true,
        },
      };

    case GET_STRATEGY_TITLES_BY_USER_ID_SUCCESS:
      const data = action.payload.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.portfolio_id]: cur.in_favorites,
        };
      }, {});

      return {
        ...state,
        strategyTitlesByUserId: action.payload,
        listOfFavorites: data,
        loaders: {
          ...state.loaders,
          isStrategyTitlesByUserIdLoading: false,
        },
      };

    case GET_STRATEGY_TITLES_BY_USER_ID_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isStrategyTitlesByUserIdLoading: false,
        },
      };

    case ADD_TO_FAVORITES_START:
      return {
        ...state,
        listOfFavorites: {
          ...state.listOfFavorites,
          [action.payload]: 1,
        },
      };
    case ADD_TO_FAVORITES_SUCCESS:
      return {
        ...state,
      };
    case ADD_TO_FAVORITES_FAILED:
      return {
        ...state,
        listOfFavorites: {
          ...state.listOfFavorites,
          [action.payload]: 0,
        },
      };

    case REMOVE_FROM_FAVORITES_START:
      return {
        ...state,
        listOfFavorites: {
          ...state.listOfFavorites,
          [action.payload]: 0,
        },
      };
    case REMOVE_FROM_FAVORITES_SUCCESS:
      return {
        ...state,
      };
    case REMOVE_FROM_FAVORITES_FAILED:
      return {
        ...state,
        listOfFavorites: {
          ...state.listOfFavorites,
          [action.payload]: 1,
        },
      };

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
