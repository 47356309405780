import {
  filtered,
  getStrategyNameOnClone,
  getStrategyNameOnEmpty,
} from "./Utils";
import nextId from "react-id-generator";
import { requestStatuses } from "./Constants";
import { ASSET_TYPE_IDS } from "./Assets";

const { idle } = requestStatuses;

export const cloneStrategy = (id, strategyId, state) => {
  const cloneFromBenchmarks =
    state.strategies.byId[strategyId].present.benchmarks.byId;
  const cloneFromPortfolios =
    state.strategies.byId[strategyId].present.portfolios.byId;
  const cloneFromNestedStrategies =
    state.strategies.byId[strategyId].present.nestedStrategies.byId;
  const cloneFromSecurity =
    state.strategies.byId[strategyId].present.security.byId;

  function _clone(array_of_objects_to_clone, objects_container, id_type) {
    const clonedObject = {};
    const mappingObject = {};
    for (let j = 0; j < array_of_objects_to_clone.length; j++) {
      let portId = nextId(id_type);
      mappingObject[array_of_objects_to_clone[j]] = portId;
      clonedObject[portId] = Object.assign(
        {},
        objects_container[array_of_objects_to_clone[j]]
      );
      clonedObject[portId].id = portId;
    }
    return [clonedObject, mappingObject];
  }

  const [clonedBenchmarks, mappingBenchmarks] = _clone(
    cloneFromBenchmarks,
    state.benchmarks.byId,
    ASSET_TYPE_IDS.benchmark
  );
  const [clonedPortfolios, mappingPortfolios] = _clone(
    cloneFromPortfolios,
    state.portfolios.byId,
    ASSET_TYPE_IDS.portfolio
  );
  const [clonedNestedStrategies, mappingNestedStrategies] = _clone(
    cloneFromNestedStrategies,
    state.nestedStrategies.byId,
    ASSET_TYPE_IDS.nestedStrategies
  );

  const [clonedSecurity, mappingSecurity] = _clone(
    cloneFromSecurity,
    state.security.byId,
    ASSET_TYPE_IDS.security
  );

  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [id]: {
          ...state.strategies.byId[strategyId],
          id: id,
          name: getStrategyNameOnClone(
            state.strategies.byId[strategyId].name,
            state.count
          ),
          editedStrategyName: false,
          defaultName: `New Strategy ${state.count}`,
          color: generateRandomColor(),
          present: {
            benchmarks: {
              byId: Object.keys(clonedBenchmarks),
              byAstId: new Set(
                Object.values(clonedBenchmarks).map((obj) => obj.asset_id)
              ),
            },
            portfolios: {
              byId: Object.keys(clonedPortfolios),
              byAstId: new Set(
                Object.values(clonedPortfolios).map((obj) => obj.asset_id)
              ),
            },
            nestedStrategies: {
              byId: Object.keys(clonedNestedStrategies),
              byAstId: new Set(
                Object.values(clonedNestedStrategies).map((obj) => obj.asset_id)
              ),
            },
            security: {
              byId: Object.keys(clonedSecurity),
              byAstId: new Set(
                Object.values(clonedSecurity).map((obj) => obj.asset_id)
              ),
            },
          },
          past: [...state.strategies.byId[strategyId].past],
          stats: {
            rollingRets: {},
          },
          order: generateNewOrder(
            state,
            strategyId,
            mappingBenchmarks,
            mappingPortfolios,
            mappingNestedStrategies,
            mappingSecurity
          ),
        },
      },
    },
    portfolios: {
      ...state.portfolios,
      byId: { ...state.portfolios.byId, ...clonedPortfolios },
    },
    benchmarks: {
      ...state.benchmarks,
      byId: { ...state.benchmarks.byId, ...clonedBenchmarks },
    },
    nestedStrategies: {
      ...state.nestedStrategies,
      byId: { ...state.nestedStrategies.byId, ...clonedNestedStrategies },
    },
    security: {
      ...state.security,
      byId: { ...state.security.byId, ...clonedSecurity },
    },
  };
};

function generateNewOrder(
  state,
  strategyId,
  mappingBenchmarks,
  mappingPortfolios,
  mappingNestedStrategies,
  mappingSecurity
) {
  function _translateElement(
    element,
    mappingBenchmarks,
    mappingPortfolios,
    mappingNestedStrategies,
    mappingSecurity
  ) {
    if (mappingBenchmarks.hasOwnProperty(element)) {
      return mappingBenchmarks[element];
    } else if (mappingPortfolios.hasOwnProperty(element)) {
      return mappingPortfolios[element];
    } else if (mappingNestedStrategies.hasOwnProperty(element)) {
      return mappingNestedStrategies[element];
    } else if (mappingSecurity.hasOwnProperty(element)) {
      return mappingSecurity[element];
    } else {
      throw new Error("id was not recognized. Can't clone the strategy.");
    }
  }
  let previousOrder = state.strategies.byId[strategyId].order;
  return previousOrder.map((element) => {
    return _translateElement(
      element,
      mappingBenchmarks,
      mappingPortfolios,
      mappingNestedStrategies,
      mappingSecurity
    );
  });
}

export const initializeNewStrategy = (state, id, strategyCreator) => {
  return {
    ...state.strategies,
    byId: {
      ...state.strategies.byId,
      [id]: {
        id: id,
        name: `New Strategy ${state.count}`,
        defaultName: `New Strategy ${state.count}`,
        editedStrategyName: false,
        strategyCreator: strategyCreator,
        color: generateRandomColor(),
        longPos: 0,
        shortPos: 0,
        exposure: 0,
        past: [],
        present: {
          benchmarks: { byId: [], byAstId: new Set() },
          portfolios: { byId: [], byAstId: new Set() },
          nestedStrategies: { byId: [], byAstId: new Set() },
          security: { byId: [], byAstId: new Set() },
        },
        future: [],
        stats: {
          rollingRets: {},
        },
        date: new Date().toISOString().slice(0, 10),
        initializationDates: [],
        stocks: {
          stocks: [],
          status: idle,
          error: null,
        },
        order: [],
      },
    },
  };
};

export let palette = [
  "#1e90ff",
  "#556b2f",
  "#7f0000",
  "#4682b4",
  "#da70d6",
  "#8fbc8f",
  "#d2691e",
  "#9acd32",
  "#00008b",
  "#daa520",
  "#7f007f",
  "#00ff00",
  "#808080",
  "#0000ff",
  "#ff0000",
  "#ffff00",
  "#00ff7f",
  "#9400d3",
  "#e9967a",
  "#dc143c",
  "#00ffff",
  "#228b22",
  "#483d8b",
  "#db7093",
  "#008b8b",
  "#f0e68c",
  "#90ee90",
  "#add8e6",
  "#ff1493",
  "#7b68ee",
];

export const generateRandomColor = () => {
  const fullPalette = [
    "#1e90ff",
    "#556b2f",
    "#7f0000",
    "#4682b4",
    "#da70d6",
    "#8fbc8f",
    "#d2691e",
    "#9acd32",
    "#00008b",
    "#daa520",
    "#7f007f",
    "#00ff00",
    "#808080",
    "#0000ff",
    "#ff0000",
    "#ffff00",
    "#00ff7f",
    "#9400d3",
    "#e9967a",
    "#dc143c",
    "#00ffff",
    "#228b22",
    "#483d8b",
    "#db7093",
    "#008b8b",
    "#f0e68c",
    "#90ee90",
    "#add8e6",
    "#ff1493",
    "#7b68ee",
  ];

  if (palette.length === 0) {
    palette = fullPalette;
  }

  const color = palette.shift();

  return color;
};

export const removeStrategyById = (state, stratId) => {
  const strategies =
    state.strategies.hasOwnProperty("winRate") &&
    state.strategies["winRate"] !== undefined
      ? new Set([...Object.keys(state.strategies.winRate)])
      : new Set();
  return {
    ...state.strategies,
    byId: filtered(state.strategies.byId, new Set([stratId])),
    winRate: strategies.has(stratId) ? {} : state.strategies.winRate,
  };
};

export const emptySelectedStrategy = (state, strategyId) => {
  return {
    ...state.strategies,
    byId: {
      ...state.strategies.byId,
      [strategyId]: {
        ...state.strategies.byId[strategyId],
        name: getStrategyNameOnEmpty(state.strategies.byId[strategyId]),
        present: {
          ...state.strategies.byId[strategyId].present,
          benchmarks: {
            byId: [],
            byAstId: new Set(),
          },
          portfolios: {
            byId: [],
            byAstId: new Set(),
          },
          nestedStrategies: {
            byId: [],
            byAstId: new Set(),
          },
          security: { byId: [], byAstId: new Set() },
        },
        initializationDates: [],
        past: generatePastHistory(state, strategyId),
        order: [],
      },
    },
  };
};

export function calcData(arr, keys) {
  let res = {};

  keys.forEach((key) => {
    res[key] = arr[key];
  });

  return res;
}

export const generatePastHistory = (state, strategyId, prevOrder = null) => {
  const currentIds = {
    benchmarks: {
      byId: state.strategies.byId[strategyId].present.benchmarks.byId,
      byAstId: new Set(
        state.strategies.byId[strategyId].present.benchmarks.byAstId
      ),
    },
    portfolios: {
      byId: state.strategies.byId[strategyId].present.portfolios.byId,
      byAstId: new Set(
        state.strategies.byId[strategyId].present.portfolios.byAstId
      ),
    },
    nestedStrategies: {
      byId: state.strategies.byId[strategyId].present.nestedStrategies.byId,
      byAstId: new Set(
        state.strategies.byId[strategyId].present.nestedStrategies.byAstId
      ),
    },
    security: {
      byId: state.strategies.byId[strategyId].present.security.byId,
      byAstId: new Set(
        state.strategies.byId[strategyId].present.security.byAstId
      ),
    },
  };

  const currentValues = {
    benchmarks: calcData(
      state.benchmarks.byId,
      state.strategies.byId[strategyId].present.benchmarks.byId
    ),
    portfolios: calcData(
      state.portfolios.byId,
      state.strategies.byId[strategyId].present.portfolios.byId
    ),
    nestedStrategies: calcData(
      state.nestedStrategies.byId,
      state.strategies.byId[strategyId].present.nestedStrategies.byId
    ),
    security: calcData(
      state.security.byId,
      state.strategies.byId[strategyId].present.security.byId
    ),
  };

  const order = prevOrder || state.strategies.byId[strategyId].order;

  const newPast = state.strategies.byId[strategyId].past;

  newPast.length >= 10 && newPast.shift();

  newPast.push({ ids: currentIds, values: currentValues, order });
  return newPast;
};

export const generateFutureHistory = (state, strategyId) => {
  const currentIds = {
    benchmarks: {
      byId: state.strategies.byId[strategyId].present.benchmarks.byId,
      byAstId: new Set(
        state.strategies.byId[strategyId].present.benchmarks.byAstId
      ),
    },
    portfolios: {
      byId: state.strategies.byId[strategyId].present.portfolios.byId,
      byAstId: new Set(
        state.strategies.byId[strategyId].present.portfolios.byAstId
      ),
    },
    nestedStrategies: {
      byId: state.strategies.byId[strategyId].present.nestedStrategies.byId,
      byAstId: new Set(
        state.strategies.byId[strategyId].present.nestedStrategies.byAstId
      ),
    },
    security: {
      byId: state.strategies.byId[strategyId].present.security.byId,
      byAstId: new Set(
        state.strategies.byId[strategyId].present.security.byAstId
      ),
    },
  };

  const currentValues = {
    benchmarks: calcData(
      state.benchmarks.byId,
      state.strategies.byId[strategyId].present.benchmarks.byId
    ),
    portfolios: calcData(
      state.portfolios.byId,
      state.strategies.byId[strategyId].present.portfolios.byId
    ),
    nestedStrategies: calcData(
      state.nestedStrategies.byId,
      state.strategies.byId[strategyId].present.nestedStrategies.byId
    ),
    security: calcData(
      state.security.byId,
      state.strategies.byId[strategyId].present.security.byId
    ),
  };

  const order = state.strategies.byId[strategyId].order;

  const newFuture = state.strategies.byId[strategyId].future;

  newFuture.length >= 10 && newFuture.shift();

  newFuture.push({ ids: currentIds, values: currentValues, order });
  return newFuture;
};

export const updateStrategiesStatistics = (
  state,
  start_date,
  end_date,
  win_rate_table,
  rolling_returns,
  rolling_correlation,
  beta_table,
  alpha_table,
  correlation_matrix,
  strats,
  payload
) => {
  return {
    byId: {
      ...Object.assign(
        {},
        ...Object.keys(strats).map(
          (key) =>
            state.strategies.byId[key] && {
              [key]: {
                color: state.strategies.byId[key].color,
                id: state.strategies.byId[key].id,
                name: state.strategies.byId[key].name,
                stats: {
                  ...state.strategies.byId[key].stats.summary,
                  periodRets: payload[key]["performance_summary"],
                  rollingRets: rolling_returns[key],
                  cumulitiveRets: payload[key]["cumulative_returns"],
                  yearly: payload[key]["year_returns"],
                  monthly: payload[key]["month_returns"],
                  weekly: payload[key]["week_returns"],
                  strategy_returns: payload[key]["strategy_returns"],
                  best_worst_periods: payload[key]["best_worst_periods"],
                  returns_distribution: payload[key]["returns_distribution"],
                  underwater: payload[key]["underwater"],
                  worst_drawdowns: payload[key]["worst_drawdowns"],
                },
              },
            }
        )
      ),
    },
    winRate: win_rate_table != null ? win_rate_table : {},
    beta: beta_table != null ? beta_table : {},
    alpha: alpha_table != null ? alpha_table : {},
    correllation_matrix:
      correlation_matrix != null && Object.keys(correlation_matrix).length > 0
        ? correlation_matrix
        : {},
    rollingCorellation: rolling_correlation,
    startDate: start_date,
    endDate: end_date,
  };
};

export function checkStrategyFactorsLength(strategy) {
  const { benchmarks, portfolios, nestedStrategies, security } =
    strategy.present;
  return (
    benchmarks.byId.length +
    portfolios.byId.length +
    nestedStrategies.byId.length +
    security.byId.length
  );
}
