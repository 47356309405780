import { EXCEPTION, STRATEGIES_LOADED, STRATEGIES_NOT_LOADED } from "./Type";
import CatalogApi from "../../services/Catalog";
import * as CatalogTypes from "../types/Catalog";
import { generateErrorMessage } from "../../helpers/Error";
import { requestStatuses } from "../../helpers/Constants";

const { loading, succeeded, failed } = requestStatuses;

export const getMethodologies = () => (dispatch, getState) => {
  dispatch({
    type: CatalogTypes.GET_METHODOLOGIES_START,
    payload: loading,
  });
  const { user } = getState();
  CatalogApi.getMethodologies(user.userData.access_token)
    .then((response) => {
      if (response) {
        dispatch({
          type: CatalogTypes.GET_METHODOLOGIES_SUCCESS,
          payload: { response, status: succeeded },
        });
      }
    })
    .catch((error) => {
      generateErrorMessage(error);
      dispatch({
        type: CatalogTypes.GET_METHODOLOGIES_FAILED,
        payload: { error: generateErrorMessage(error), status: failed },
      });
    });
};

export const getBenchmarks = () => (dispatch) => {
  dispatch({
    type: CatalogTypes.GET_BENCHMARKS_START,
    payload: loading,
  });
  CatalogApi.getBenchmarks()
    .then((response) => {
      if (response) {
        dispatch({
          type: CatalogTypes.GET_BENCHMARKS_SUCCESS,
          payload: { response, status: succeeded },
        });
      }
    })
    .catch((error) => {
      generateErrorMessage(error);
      dispatch({
        type: CatalogTypes.GET_BENCHMARKS_FAILED,
        payload: { error: generateErrorMessage(error), status: failed },
      });
    });
};

export const getPublicAndPrivateStrategiesAction =
  (token) => async (dispatch) => {
    return CatalogApi.getPublicAndPrivateStrategies(token)
      .then((response) => {
        if (response) {
          dispatch({
            type: STRATEGIES_LOADED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        generateErrorMessage(error);
        dispatch({
          type: EXCEPTION,
          payload: generateErrorMessage(error),
        });
      });
  };

export const getPublicStrategiesAction = () => async (dispatch) => {
  return CatalogApi.getPublicStrategies()
    .then((response) => {
      if (response) {
        dispatch({
          type: STRATEGIES_LOADED,
          payload: response,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: STRATEGIES_NOT_LOADED,
        payload: generateErrorMessage(error),
      });
    });
};

export const getStrategiesAction = () => (dispatch, getState) => {
  const state = getState();

  const { isLoggedIn, userData } = state.user;

  if (isLoggedIn) {
    dispatch(getPublicAndPrivateStrategiesAction(userData.access_token));
  } else {
    dispatch(getPublicStrategiesAction());
  }
};

export const getSecurity = () => async (dispatch) => {
  dispatch({
    type: CatalogTypes.GET_SECURITY_START,
    payload: loading,
  });
  CatalogApi.getSecurity()
    .then((response) => {
      if (response) {
        dispatch({
          type: CatalogTypes.GET_SECURITY_SUCCESS,
          payload: { response, status: succeeded },
        });
      }
    })
    .catch((error) => {
      generateErrorMessage(error);
      dispatch({
        type: CatalogTypes.GET_SECURITY_FAILED,
        payload: { error: generateErrorMessage(error), status: failed },
      });
    });
};
