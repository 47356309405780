export const GET_FACTORS_GROUPING_START = "USER.GET_FACTORS_GROUPING_START";
export const GET_FACTORS_GROUPING_SUCCESS = "USER.GET_FACTORS_GROUPING_SUCCESS";
export const GET_FACTORS_GROUPING_FAILED = "USER.GET_FACTORS_GROUPING_FAILED";

export const UPDATE_USER_FACTORS_GROUPING_START =
  "USER.UPDATE_USER_FACTORS_GROUPING_START";
export const UPDATE_USER_FACTORS_GROUPING_SUCCESS =
  "USER.UPDATE_USER_FACTORS_GROUPING_SUCCESS";
export const UPDATE_USER_FACTORS_GROUPING_FAILED =
  "USER.UPDATE_USER_FACTORS_GROUPING_FAILED";

export const INIT_APP_START = "USER.INIT_APP_START";
export const INIT_APP_SUCCESS = "USER.INIT_APP_SUCCESS";
export const INIT_APP_FAILED = "USER.INIT_APP_FAILED";

export const UPDATE_CURRENT_USER_NAME = "USER.UPDATE_CURRENT_USER_NAME";
