import React from "react";
import style from "../../../features/Login/Login.module.scss";
import ResetPasswordForm from "../../../features/Login/ResetPasswordForm";

function ResetPassword() {
  return (
    <div className={style.formContainer}>
      <ResetPasswordForm />
    </div>
  );
}

export default ResetPassword;
