import { Backdrop, Box, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import BasicButton from "../../../../components/Atoms/BasicButton";
import {
  getUserFactorGroups,
  updateUserFactorsGrouping,
} from "../../../../store/actions/User";
import { useLocation } from "react-router-dom";

function FactorGrouping({ onClose }) {
  const controller = useRef(new AbortController());

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const userGroups = useSelector((s) =>
    s.user.userData?.source_factors?.length
      ? s.user.userData.source_factors
      : []
  );

  const userRobustGroup = useSelector((s) => s.user.userData?.robust);

  const factorsGroups = useSelector((s) => s.user.factorsGroups);

  const factorsGroupsLoader = useSelector((s) => s.user.factorsGroupsLoader);

  const userGroupsData = useMemo(
    () => (userRobustGroup ? [...userGroups, "Robust"] : userGroups),
    [userGroups, userRobustGroup]
  );

  const factorsGroupsUpdateLoader = useSelector(
    (s) => s.user.factorsGroupsUpdateLoader
  );

  const [selectedGroups, setSelectedGroups] = useState(
    userGroupsData
    // !userGroupsData.length ? ["AlphaBeta Factors"] : userGroupsData
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGroups(typeof value === "string" ? value.split(",") : value);
  };

  const handleSave = () => {
    let params = {};
    if (selectedGroups.includes("Robust")) {
      params.robust = true;
      params.user_group = selectedGroups.filter((item) => item !== "Robust");
    } else {
      params.robust = false;
      params.user_group = selectedGroups;
    }

    dispatch(updateUserFactorsGrouping(params, pathname, controller.current));
  };

  useEffect(() => {
    if (!factorsGroups.length) {
      dispatch(getUserFactorGroups(controller.current));
    }
  }, [factorsGroups]);

  useEffect(() => {
    return () => controller.current.abort();
  }, []);

  return (
    <Box>
      <FormControl sx={{ width: "100%" }}>
        <Select
          multiple
          value={selectedGroups}
          disabled={factorsGroupsLoader || factorsGroups.length === 0}
          onChange={handleChange}
          renderValue={(selected) =>
            factorsGroupsLoader ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              selected.join(", ")
            )
          }
        >
          {factorsGroups.map((group) => (
            <MenuItem key={group} value={group}>
              <Checkbox checked={selectedGroups.indexOf(group) > -1} />
              <ListItemText primary={group} />
            </MenuItem>
          ))}
        </Select>

        <Stack direction="row" justifyContent="space-between" mt={1}>
          <BasicButton title={"Cancel"} onClick={onClose} />
          <BasicButton
            title={"Save"}
            highlighted
            variant="contained"
            onClick={handleSave}
          />
        </Stack>
      </FormControl>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={factorsGroupsUpdateLoader}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  );
}

export default FactorGrouping;
