export const itemsTypesConfig = [
  {
    value: "all",
    label: "Show All",
  },
  {
    value: "benchmarks",
    label: "Benchmarks",
  },
  {
    value: "strategies",
    label: "Strategies",
  },
  {
    value: "portfolios",
    label: "Portfolios",
  },
];

export const dashboardPresetCountryOptions = [
  "United States",
  "Israel",
  "France",
  "Germany",
  "Japan",
  "United Kingdom",
  "Australia",
];

export const dashboardReturnPeriodOptions = [
  { label: "1M", value: "periodReturn_month", styleValue: "month" },
  { label: "3M", value: "periodReturn_quarter", styleValue: "quarter" },
  { label: "1Y", value: "annualReturn_year", styleValue: "year" },
  { label: "YTD", value: "periodReturn_ytd", styleValue: "ytd" },
];

export const dashboardBreakdownHeaderRows = {
  periodReturn_day: { label: "1D", id: "periodReturn_day" },
  periodReturn_month: { label: "1M", id: "periodReturn_month" },
  periodReturn_quarter: {
    label: "3M",
    id: "periodReturn_quarter",
  },
  periodReturn_halfYear: {
    label: "6M",
    id: "periodReturn_halfYear",
  },
  periodReturn_ytd: { label: "YTD", id: "periodReturn_ytd" },
  annualReturn_year: { label: "1Y Ret.", id: "annualReturn_year" },
  volatility_year: { label: "1Y Std.", id: "volatility_year" },
  sharpRatio_year: { label: "1Y Sharp", id: "sharpRatio_year" },
  annualReturn_3years: {
    label: "3Ys Ret.",
    id: "annualReturn_3years",
  },
  volatility_3years: { label: "3Ys Std.", id: "volatility_3years" },
  drawDown_3years: { label: "3Ys DD", id: "drawDown_3years" },
  sharpRatio_3years: { label: "3Ys Sharp", id: "sharpRatio_3years" },
  beta_3years: { label: "3Ys beta", id: "beta_3years" },
  beta_std_3years: { label: "3Ys beta std", id: "beta_std_3years" },
  alpha_3years: { label: "3Ys alpha", id: "alpha_3years" },
  p_value_3years: { label: "3Ys p value", id: "p_value_3years" },
  t_stat_3years: { label: "3Ys t stat", id: "t_stat_3years" },
  beta_plus_3years: { label: "3Ys beta plus", id: "beta_plus_3years" },
  beta_minus_3years: { label: "3Ys beta minus", id: "beta_minus_3years" },
  jensen_alpha_3years: {
    label: "3Ys jensen alpha",
    id: "jensen_alpha_3years",
  },
  // jensen_sharpe_3years: {
  //   label: "3Ys jensen sharpe",
  //   id: "jensen_sharpe_3years",
  // },
  annualReturn_5years: {
    label: "5Ys Ret.",
    id: "annualReturn_5years",
  },
  volatility_5years: { label: "5Ys Std.", id: "volatility_5years" },
  drawDown_5years: { label: "5Ys DD", id: "drawDown_5years" },
  sharp_5years: { label: "5Ys Sharp", id: "sharp_5years" },
  beta_5years: { label: "5Ys beta", id: "beta_5years" },
  beta_std_5years: { label: "5Ys beta std", id: "beta_std_5years" },
  alpha_5years: { label: "5Ys alpha", id: "alpha_5years" },
  p_value_5years: { label: "5Ys p value", id: "p_value_5years" },
  t_stat_5years: { label: "5Ys t stat", id: "t_stat_5years" },
  beta_plus_5years: { label: "5Ys beta plus", id: "beta_plus_5years" },
  beta_minus_5years: { label: "5Ys beta minus", id: "beta_minus_5years" },
  jensen_alpha_5years: {
    label: "5Ys jensen alpha",
    id: "jensen_alpha_5years",
  },
  // jensen_sharpe_5years: {
  //   label: "5Ys jensen sharpe",
  //   id: "jensen_sharpe_5years",
  // },
  annualReturn_since2010: {
    label: "S. 2010 Ret.",
    id: "annualReturn_since2010",
  },
  volatility_since2010: {
    label: "S. 2010 Std.",
    id: "volatility_since2010",
  },
  drawDown_since2010: { label: "S. 2010 DD", id: "drawDown_since2010" },
  sharpRatio_since2010: {
    label: "S. 2010 Sharp",
    id: "sharpRatio_since2010",
  },
  beta_since2010: { label: "S. 2010 beta", id: "beta_since2010" },
  beta_std_since2010: { label: "S. 2010 beta std", id: "beta_std_since2010" },
  alpha_since2010: { label: "S. 2010 alpha", id: "alpha_since2010" },
  p_value_since2010: { label: "S. 2010 p value", id: "p_value_since2010" },
  t_stat_since2010: { label: "S. 2010 t stat", id: "t_stat_since2010" },
  beta_plus_since2010: {
    label: "S. 2010 beta plus",
    id: "beta_plus_since2010",
  },
  beta_minus_since2010: {
    label: "S. 2010 beta minus",
    id: "beta_minus_since2010",
  },
  jensen_alpha_since2010: {
    label: "S. 2010 jensen alpha",
    id: "jensen_alpha_since2010",
  },
  // jensen_sharpe_since2010: {
  //   label: "S. 2010 jensen sharpe",
  //   id: "jensen_sharpe_since2010",
  // },
  annualReturn_10years: {
    label: "10Ys Ret.",
    id: "annualReturn_10years",
  },
  volatility_10years: { label: "10Ys Std.", id: "volatility_10years" },
  drawDown_10years: { label: "10Ys DD", id: "drawDown_10years" },
  sharpRatio_10years: { label: "10Ys Sharp", id: "sharpRatio_10years" },
  beta_10years: { label: "10Ys beta", id: "beta_10years" },
  beta_std_10years: { label: "10Ys beta std", id: "beta_std_10years" },
  alpha_10years: { label: "10Ys alpha", id: "alpha_10years" },
  p_value_10years: { label: "10Ys p value", id: "p_value_10years" },
  t_stat_10years: { label: "10Ys t stat", id: "t_stat_10years" },
  beta_plus_10years: { label: "10Ys beta plus", id: "beta_plus_10years" },
  beta_minus_10years: { label: "10Ys beta minus", id: "beta_minus_10years" },
  jensen_alpha_10years: {
    label: "10Ys jensen alpha",
    id: "jensen_alpha_10years",
  },
  // jensen_sharpe_10years: {
  //   label: "10Ys jensen sharpe",
  //   id: "jensen_sharpe_10years",
  // },
  annualReturn_15years: {
    label: "15Ys Ret.",
    id: "annualReturn_15years",
  },
  volatility_15years: { label: "15Ys Std.", id: "volatility_15years" },
  drawDown_15years: { label: "15Ys DD", id: "drawDown_15years" },
  sharpRatio_15years: { label: "15Ys Sharp", id: "sharpRatio_15years" },
  beta_15years: { label: "15Ys beta", id: "beta_15years" },
  beta_std_15years: { label: "15Ys beta std", id: "beta_std_15years" },
  alpha_15years: { label: "15Ys alpha", id: "alpha_15years" },
  p_value_15years: { label: "15Ys p value", id: "p_value_15years" },
  t_stat_15years: { label: "15Ys t stat", id: "t_stat_15years" },
  beta_plus_15years: { label: "15Ys beta plus", id: "beta_plus_15years" },
  beta_minus_15years: { label: "15Ys beta minus", id: "beta_minus_15years" },
  jensen_alpha_15years: {
    label: "15Ys jensen alpha",
    id: "jensen_alpha_15years",
  },
  // jensen_sharpe_15years: {
  //   label: "15Ys jensen sharpe",
  //   id: "jensen_sharpe_15years",
  // },
  annualReturn_full: { label: "Full Ret.", id: "annualReturn_full" },
  volatility_full: { label: "Full Std.", id: "volatility_full" },
  drawDown_full: { label: "Full DD", id: "drawDown_full" },
  sharpRatio_full: { label: "Full Sharp", id: "sharpRatio_full" },
  beta_full: { label: "Full beta", id: "beta_full" },
  beta_std_full: { label: "Full beta std", id: "beta_std_full" },
  alpha_full: { label: "Full alpha", id: "alpha_full" },
  p_value_full: { label: "Full p value", id: "p_value_full" },
  t_stat_full: { label: "Full t stat", id: "t_stat_full" },
  beta_plus_full: { label: "Full beta plus", id: "beta_plus_full" },
  beta_minus_full: { label: "Full beta_minus", id: "beta_minus_full" },
  jensen_alpha_full: { label: "Full jensen alpha", id: "jensen_alpha_full" },
  // jensen_sharpe_full: { label: "Full jensen sharpe", id: "jensen_sharpe_full" },
};

export const dashboardReturnToVolatilityPeriod = [
  {
    label: "1Y",
    value: "annualReturn_year&volatility_year",
    styleValue: "year",
  },
  {
    label: "3Y",
    value: "annualReturn_3years&volatility_3years",
    styleValue: "3years",
  },
  {
    label: "5Y",
    value: "annualReturn_5years&volatility_5years",
    styleValue: "5years",
  },
  {
    label: "10Y",
    value: "annualReturn_10years&volatility_10years",
    styleValue: "10years",
  },
];

export const DashboardStatsFirstColWidth = 220;
