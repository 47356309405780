import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  rightToolbar: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  menuButton: {
    marginRight: 16,
    marginLeft: -12,
  },
  pageTitle: ({ isOpen }) => ({
    fontSize: "20px",
    color: theme.palette.contrastText,
    fontFamily: theme.typography.fontFamilyBold,
    marginLeft: isOpen ? "40px" : "10px",
  }),
  topBar: ({ drawerwidth, isOpen }) => ({
    "& .MuiToolbar-root": {
      height: "88px",
      backgroundColor: theme.palette.background.light,
      paddingRight: "68px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "15px",
        paddingLeft: "15px",
        minWidth: "480px",
      },
    },
    "&.MuiAppBar-root": {
      zIndex: 990,
      backgroundColor: "transparent",
      boxShadow: "none",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "100%",
      ...(isOpen && {
        width: `calc(100% - ${drawerwidth}px)`,
        marginLeft: `${drawerwidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    },
  }),
  userIcon: {
    "& svg": {
      width: "14px",
      height: "17px",
    },
  },
  listItem: {
    minHeight: "40px",
    "&.MuiButtonBase-root": {
      padding: "0 15px",
    },
  },
  notifyIcon: {
    marginRight: "5px",
    "& svg": {
      width: "17px",
      height: "19px",
    },
    "& .MuiBadge-anchorOriginTopRightRectangular": {
      backgroundColor: theme.palette.danger.main,
      color: "#fff",
      border: "1px solid #fff",
      top: "10px",
      right: "-5px",
    },
  },
  useText: {
    color: theme.palette.grey["900"],
    fontSize: "16px",
    marginTop: 0,
    marginBottom: 0,
    position: "relative",
    top: "2px",
    marginLeft: "2px",
  },
  menuIcon: ({ isOpen }) => ({
    color: theme.palette.grey["900"],
    "&.MuiButtonBase-root": {
      borderRadius: 0,
    },
    "&:hover": {
      color: theme.palette.contrastText,
    },
    ...(isOpen && { display: "none" }),
  }),
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#fff",
    border: "none",
    padding: 20,
    borderRadius: 10,
  },
  feedback: {
    position: "relative",
    "&::before": {
      content: "'Feedback'",
      position: "absolute",
      top: 7,
      right: "calc( 50% - 12px )",
      color: "#000",
      width: "44px",
      fontSize: "10px",
    },
  },
}));

export default useStyles;
