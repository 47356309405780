import SnackbarUtils from "./SnackBarUtils";

export const generateErrorMessage = (error) => {
  if (error.message === "canceled") {
    return;
  }

  const errData =
    error?.response?.data?.message || error?.response?.data?.detail
      ? error.response.data.message || error.response.data.detail
      : error.toString();

  if (!errData) {
    return;
  }

  if (typeof errData === "string") {
    SnackbarUtils.error(errData);
  } else {
    errData.forEach((element) => {
      SnackbarUtils.error(element.msg);
    });
  }
};
