export const SET_STOCK_FILTER = "STOCK.SET_STOCK_FILTER";
export const SET_STOCK_FACTOR_GROUP_INDEX =
  "STOCK.SET_STOCK_FACTOR_GROUP_INDEX";
export const ADD_STOCK_FACTOR = "STOCK.ADD_STOCK_FACTOR";
export const UPDATE_STOCK_FACTOR = "STOCK.UPDATE_STOCK_FACTOR";

export const RESET_STOCK = "STOCK.RESET_STOCK";
export const SET_HIGHLIGHTED_STOCK_FACTORS =
  "STOCK.SET_HIGHLIGHTED_STOCK_FACTORS";

export const CREATE_MULTIFACTOR = "STOCK.CREATE_MULTIFACTOR";

export const GET_STOCK_UNIVERSES_START = "STOCK.GET_STOCK_UNIVERSES_START";
export const GET_STOCK_UNIVERSES_SUCCESS = "STOCK.GET_STOCK_UNIVERSES_SUCCESS";
export const GET_STOCK_UNIVERSES_FAILED = "STOCK.GET_STOCK_UNIVERSES_FAILED";

export const GET_STOCK_COUNTRIES_START = "STOCK.GET_STOCK_COUNTRIES_START";
export const GET_STOCK_COUNTRIES_SUCCESS = "STOCK.GET_STOCK_COUNTRIES_SUCCESS";
export const GET_STOCK_COUNTRIES_FAILED = "STOCK.GET_STOCK_COUNTRIES_FAILED";

export const GET_STOCK_ECONOMIES_START = "STOCK.GET_STOCK_ECONOMIES_START";
export const GET_STOCK_ECONOMIES_SUCCESS = "STOCK.GET_STOCK_ECONOMIES_SUCCESS";
export const GET_STOCK_ECONOMIES_FAILED = "STOCK.GET_STOCK_ECONOMIES_FAILED";

export const GET_STOCK_SECTORS_START = "STOCK.GET_STOCK_SECTORS_START";
export const GET_STOCK_SECTORS_SUCCESS = "STOCK.GET_STOCK_SECTORS_SUCCESS";
export const GET_STOCK_SECTORS_FAILED = "STOCK.GET_STOCK_SECTORS_FAILED";

export const GET_DEFAULT_SCREEN_START = "STOCK.GET_DEFAULT_SCREEN_START";
export const GET_DEFAULT_SCREEN_SUCCESS = "STOCK.GET_DEFAULT_SCREEN_SUCCESS";
export const GET_DEFAULT_SCREEN_FAILED = "STOCK.GET_DEFAULT_SCREEN_FAILED";

export const GET_VARIABLES_START = "STOCK.GET_VARIABLES_START";
export const GET_VARIABLES_SUCCESS = "STOCK.GET_VARIABLES_SUCCESS";
export const GET_VARIABLES_FAILED = "STOCK.GET_VARIABLES_FAILED";

export const UPDATE_DEFALT_VARIABLES_START =
  "STOCK.UPDATE_DEFALT_VARIABLES_START";
export const UPDATE_DEFALT_VARIABLES_SUCCESS =
  "STOCK.UPDATE_DEFALT_VARIABLES_SUCCESS";
export const UPDATE_DEFALT_VARIABLES_FAILED =
  "STOCK.UPDATE_DEFALT_VARIABLES_FAILED";

export const GET_USER_SETTINGS_START = "STOCK.GET_USER_SETTINGS_START";
export const GET_USER_SETTINGS_SUCCESS = "STOCK.GET_USER_SETTINGS_SUCCESS";
export const GET_USER_SETTINGS_FAILED = "STOCK.GET_USER_SETTINGS_FAILED";

export const UPDATE_USER_SETTINGS_START = "STOCK.UPDATE_USER_SETTINGS_START";
export const UPDATE_USER_SETTINGS_SUCCESS =
  "STOCK.UPDATE_USER_SETTINGS_SUCCESS";
export const UPDATE_USER_SETTINGS_FAILED = "STOCK.UPDATE_USER_SETTINGS_FAILED";

export const CREATE_MULTIFACTOR_START = "STOCK.CREATE_MULTIFACTOR_START";
export const CREATE_MULTIFACTOR_SUCCESS = "STOCK.CREATE_MULTIFACTOR_SUCCESS";
export const CREATE_MULTIFACTOR_FAILED = "STOCK.CREATE_MULTIFACTOR_FAILED";

export const CREATE_RATIO_START = "STOCK.CREATE_RATIO_START";
export const CREATE_RATIO_SUCCESS = "STOCK.CREATE_RATIO_SUCCESS";
export const CREATE_RATIO_FAILED = "STOCK.CREATE_RATIO_FAILED";

export const CREATE_BACKTEST_START = "STOCK.CREATE_BACKTEST_START";
export const CREATE_BACKTEST_SUCCESS = "STOCK.CREATE_BACKTEST_SUCCESS";
export const CREATE_BACKTEST_FAILED = "STOCK.CREATE_BACKTEST_FAILED";

export const GET_PRESETS_START = "STOCK.GET_PRESETS_START";
export const GET_PRESETS_SUCCESS = "STOCK.GET_PRESETS_SUCCESS";
export const GET_PRESETS_FAILED = "STOCK.GET_PRESETS_FAILED";

export const SAVE_PRESET_START = "STOCK.SAVE_PRESET_START";
export const SAVE_PRESET_SUCCESS = "STOCK.SAVE_PRESET_SUCCESS";
export const SAVE_PRESET_FAILED = "STOCK.SAVE_PRESET_FAILED";

export const UPDATE_PRESET_START = "STOCK.UPDATE_PRESET_START";
export const UPDATE_PRESET_SUCCESS = "STOCK.UPDATE_PRESET_SUCCESS";
export const UPDATE_PRESET_FAILED = "STOCK.UPDATE_PRESET_FAILED";

export const DELETE_PRESET_START = "STOCK.DELETE_PRESET_START";
export const DELETE_PRESET_SUCCESS = "STOCK.DELETE_PRESET_SUCCESS";
export const DELETE_PRESET_FAILED = "STOCK.DELETE_PRESET_FAILED";

export const GET_PRESET_BY_ID_START = "STOCK.GET_PRESET_BY_ID_START";
export const GET_PRESET_BY_ID_SUCCESS = "STOCK.GET_PRESET_BY_ID_SUCCESS";
export const GET_PRESET_BY_ID_FAILED = "STOCK.GET_PRESET_BY_ID_FAILED";
