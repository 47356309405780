import { mainUrl } from "../config";
import axios from "axios";

const getRebalanceList = async (token) =>
  axios
    .get(`${mainUrl.API_URL}/api/statistic/rebalance_list`,{
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getMethodologyList = async (token) =>
  axios
    .get(`${mainUrl.API_URL}/api/statistic/methodology_list`,{
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getPositionList = async () =>
  axios
    .get(`${mainUrl.API_URL}/api/statistic/position_list`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getFilterList = async (token) =>
  axios
    .get(`${mainUrl.API_URL}/api/statistic/filter_list`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getSizeList = async (token) =>
  axios
    .get(`${mainUrl.API_URL}/api/statistic/size_list`,{
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getCountriesList = async (token) =>
  axios
    .get(`${mainUrl.API_URL}/api/statistic/countries_list`,{
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getBenchmarksStatistic = async (data) =>
  axios
    .post(`${mainUrl.API_URL}/api/statistic/benchmarks`, data)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getPortfoliosStatistic = async (data, token) =>
  axios
    .post(`${mainUrl.API_URL}/api/statistic/portfolios`, data,
      {headers: { Authorization: `Bearer ${token}` }})
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getMtdlStyle = async () =>
  axios
    .get(`${mainUrl.API_URL}/api/statistic/mtdlg_to_style`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getHistoryDates = async () =>
  axios
    .get(`${mainUrl.API_URL}/api/statistic/history_dates`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const FactorApi = {
  getRebalanceList,
  getMethodologyList,
  getPositionList,
  getFilterList,
  getSizeList,
  getCountriesList,
  getBenchmarksStatistic,
  getPortfoliosStatistic,
  getMtdlStyle,
  getHistoryDates,
};

export default FactorApi;
