import * as DashboardTypes from "../types/Dashboard";
import { LOGIN_SUCCESS, LOGOUT, RESET_DEFAULT } from "../actions/Type";
import {
  dashboardReturnPeriodOptions,
  dashboardReturnToVolatilityPeriod,
} from "../../features/Dashboard/consts";
import { SelectedPreset } from "../../helpers/Dashboard";

export const user = JSON.parse(localStorage.getItem("user"));

const preset =
  user?.preset &&
  new SelectedPreset(
    user.preset.preset_id,
    user.preset.name,
    user.preset.date,
    true,
    user.preset.default_factors_return,
    user.preset.default_style_return,
    user.preset.default_universe,
    user.preset.stats
  );

const initialState = {
  loaders: {
    universesLoading: false,
    statsLoading: false,
    benchmarksLoading: false,
    strategiesLoading: false,
    portfoliosLoading: false,
    styleReturnLoading: false,
    compositionLoading: false,
    methodologyInfoLoading: false,
    presetBenchmarkContentLoading: false,
    presetStrategiesContentLoading: false,
    presetPortfoliosContentLoading: false,
    presetTitlesLoading: false,
    presetLoading: false,
  },
  statistics: {
    benchmarks: [],
    strategies: [],
    portfolios: [],
  },
  universes: [],
  styleReturn: [],
  composition: [],
  returnToVolatility: [],
  methodReturnToVolatility: [],
  compositionName: "",
  returnPeriod: (user?.preset &&
    dashboardReturnPeriodOptions.find(
      (item) => item.label.toLowerCase() === preset.default_style_return
    )) || { label: "YTD", value: "periodReturn_ytd", styleValue: "ytd" },
  selectedUniverse: "",
  selectedFactor: "",
  factorsInfo: {
    name: "",
    portfolio_type: "",
    portfolio_style_id: "",
    formula: "",
    definition: "",
    paper: "",
    authors: "",
    link: "",
  },
  methodologyInfo: null,
  returnToVolatilityPeriod: (user?.preset &&
    dashboardReturnToVolatilityPeriod.find(
      (item) => item.label.toLowerCase() === preset.default_factors_return
    )) || {
    label: "1Y",
    value: "annualReturn_year&volatility_year",
    styleValue: "year",
  },
  benchmarkTitles: [],
  strategiesTitles: [],
  portfoliosTitles: [],
  presetTitles: [],
  defaultPreset: user?.preset || null,
  selectedPreset: user?.preset ? preset : "",
  editablePreset: null,
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const { preset } = action.payload;

      const userPreset = preset
        ? new SelectedPreset(
            preset.preset_id,
            preset.name,
            preset.date,
            true,
            preset.default_factors_return,
            preset.default_style_return,
            preset.default_universe,
            preset.stats
          )
        : "";

      return {
        ...state,
        defaultPreset: preset || null,
        selectedPreset: userPreset,
        returnPeriod: (preset &&
          dashboardReturnPeriodOptions.find(
            (item) =>
              item.label.toLowerCase() ===
              preset.default_style_return.toLowerCase()
          )) || { label: "YTD", value: "periodReturn_ytd", styleValue: "ytd" },
        returnToVolatilityPeriod: (preset &&
          dashboardReturnToVolatilityPeriod.find(
            (item) =>
              item.label.toLowerCase() ===
              preset.default_factors_return.toLowerCase()
          )) || {
          label: "1Y",
          value: "annualReturn_year&volatility_year",
          styleValue: "year",
        },
      };
    }

    case DashboardTypes.GET_ALL_UNIVERSES_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          universesLoading: true,
        },
      };
    }
    case DashboardTypes.GET_ALL_UNIVERSES_SUCCESS: {
      return {
        ...state,
        universes: action.payload,
        loaders: {
          ...state.loaders,
          universesLoading: false,
        },
      };
    }
    case DashboardTypes.GET_ALL_UNIVERSES_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          universesLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_BENCHMARKS_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          benchmarksLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_BENCHMARKS_SUCCESS: {
      return {
        ...state,

        statistics: {
          ...state.statistics,
          benchmarks: action.payload,
        },

        loaders: {
          ...state.loaders,
          benchmarksLoading: false,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_BENCHMARKS_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          benchmarksLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_ACTIVE_STRATEGIES_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          strategiesLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_ACTIVE_STRATEGIES_SUCCESS: {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          strategies: action.payload,
        },

        loaders: {
          ...state.loaders,
          strategiesLoading: false,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_ACTIVE_STRATEGIES_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          strategiesLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_PORTFOLIOS_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          portfoliosLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_PORTFOLIOS_SUCCESS: {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          portfolios: action.payload,
        },

        loaders: {
          ...state.loaders,
          portfoliosLoading: false,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_PORTFOLIOS_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          portfoliosLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_ALL_STATS_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          statsLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_ALL_STATS_SUCCESS: {
      return {
        ...state,
        statistics: action.payload,
        loaders: {
          ...state.loaders,
          statsLoading: false,
        },
        selectedPreset: "",
      };
    }
    case DashboardTypes.GET_DASHBOARD_ALL_STATS_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          statsLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_STYLE_RETURN_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          styleReturnLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_STYLE_RETURN_SUCCESS: {
      return {
        ...state,
        styleReturn: action.payload.assets,
        loaders: {
          ...state.loaders,
          styleReturnLoading: false,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_STYLE_RETURN_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          styleReturnLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_COMPOSITION_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          compositionLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_COMPOSITION_SUCCESS: {
      return {
        ...state,
        composition: action.payload.data.composition,
        compositionName: action.payload.name,
        loaders: {
          ...state.loaders,
          compositionLoading: false,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_COMPOSITION_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          compositionLoading: false,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_METHODOLOGY_INFO_CLEAR: {
      return {
        ...state,
        factorsInfo: {
          name: "",
          portfolio_type: "",
          portfolio_style_id: "",
          formula: "",
          definition: "",
          paper: "",
          authors: "",
          link: "",
        },
        loaders: {
          ...state.loaders,
          methodologyInfoLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_METHODOLOGY_INFO_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          methodologyInfoLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_METHODOLOGY_INFO_SUCCESS: {
      return {
        ...state,
        factorsInfo: action.payload[0],
        loaders: {
          ...state.loaders,
          methodologyInfoLoading: false,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_METHODOLOGY_INFO_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          methodologyInfoLoading: false,
        },
      };
    }

    case DashboardTypes.SET_RETURN_PERIOD: {
      return {
        ...state,
        returnPeriod: action.payload,
      };
    }

    case DashboardTypes.SET_UNIVERSE: {
      return {
        ...state,
        selectedUniverse: action.payload,
        selectedFactor: "",
      };
    }

    case DashboardTypes.SET_FACTOR: {
      return {
        ...state,
        selectedFactor: action.payload,
        selectedUniverse: "",
      };
    }

    case RESET_DEFAULT: {
      const defaultUniverse = state.universes.length
        ? state.universes.find(
            (universe) => universe.country_id === "United States"
          )
        : "";

      return {
        ...state,
        selectedUniverse: defaultUniverse,
        selectedFactor: "",
        methodologyInfo: {},
        returnPeriod: {
          label: "YTD",
          value: "periodReturn_ytd",
          styleValue: "ytd",
        },
        returnToVolatilityPeriod: {
          label: "1Y",
          value: "annualReturn_year&volatility_year",
          styleValue: "year",
        },
      };
    }

    case DashboardTypes.SET_RETURN_TO_VOLATILITY_PERIOD: {
      return {
        ...state,
        returnToVolatilityPeriod: action.payload,
      };
    }

    case DashboardTypes.SET_METHODOLOGY_INFORMATION: {
      return {
        ...state,
        methodologyInfo: action.payload,
      };
    }

    case DashboardTypes.GET_DASHBOARD_RETURN_TO_VOLATILITY_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          returnToVolatilityLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_RETURN_TO_VOLATILITY_SUCCESS: {
      return {
        ...state,
        returnToVolatility: action.payload.assets,
        loaders: {
          ...state.loaders,
          returnToVolatilityLoading: false,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_RETURN_TO_VOLATILITY_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          returnToVolatilityLoading: false,
        },
      };
    }

    case DashboardTypes.METHODOLOGY_RETURN_TO_VOLATILITY_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          methodReturnToVolatilityLoading: true,
        },
      };
    }
    case DashboardTypes.METHODOLOGY_RETURN_TO_VOLATILITY_SUCCESS: {
      return {
        ...state,
        methodReturnToVolatility: action.payload,
        loaders: {
          ...state.loaders,
          methodReturnToVolatilityLoading: false,
        },
      };
    }
    case DashboardTypes.METHODOLOGY_RETURN_TO_VOLATILITY_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          methodReturnToVolatilityLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_BENCHMARK_TITLES_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetBenchmarkContentLoading: true,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_BENCHMARK_TITLES_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetBenchmarkContentLoading: false,
        },
        benchmarkTitles: action.payload,
      };
    }

    case DashboardTypes.GET_DASHBOARD_BENCHMARK_TITLES_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetBenchmarkContentLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_STRATEGIES_TITLES_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetStrategiesContentLoading: true,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_STRATEGIES_TITLES_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetStrategiesContentLoading: false,
        },
        strategiesTitles: action.payload,
      };
    }

    case DashboardTypes.GET_DASHBOARD_STRATEGIES_TITLES_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetStrategiesContentLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_ALL_PORTFOLIOS_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetPortfoliosContentLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_ALL_PORTFOLIOS_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetPortfoliosContentLoading: false,
        },
        portfoliosTitles: action.payload,
      };
    }
    case DashboardTypes.GET_DASHBOARD_ALL_PORTFOLIOS_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetPortfoliosContentLoading: false,
        },
      };
    }

    case DashboardTypes.GET_DASHBOARD_PRESET_TITLES_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetTitlesLoading: true,
        },
      };
    }
    case DashboardTypes.GET_DASHBOARD_PRESET_TITLES_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetTitlesLoading: false,
        },
        presetTitles: action.payload,
      };
    }
    case DashboardTypes.GET_DASHBOARD_PRESET_TITLES_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetTitlesLoading: false,
        },
      };
    }

    case DashboardTypes.SAVE_DASHBOARD_PRESET_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: true,
        },
      };
    }
    case DashboardTypes.SAVE_DASHBOARD_PRESET_SUCCESS: {
      const presetTitle = {
        ...action.payload,
        id: action.payload.preset_id,
        stats: action.payload.displayed_statistics,
      };

      delete presetTitle.displayed_statistics;
      delete presetTitle.preset_id;

      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },

        presetTitles: [...state.presetTitles, presetTitle],
      };
    }
    case DashboardTypes.SAVE_DASHBOARD_PRESET_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
      };
    }

    case DashboardTypes.LOAD_DASHBOARD_PRESET_STATS_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: true,
        },
      };
    }
    case DashboardTypes.LOAD_DASHBOARD_PRESET_STATS_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
        statistics: {
          benchmarks: action.payload?.benchmark?.length
            ? action.payload.benchmark
            : [],
          strategies: action.payload?.strategy?.length
            ? action.payload.strategy
            : [],
          portfolios: action.payload?.portfolio?.length
            ? action.payload.portfolio
            : [],
        },
      };
    }
    case DashboardTypes.LOAD_DASHBOARD_PRESET_STATS_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
      };
    }

    case DashboardTypes.LOAD_EDITABLE_DASHBOARD_PRESET_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: true,
        },
      };
    }
    case DashboardTypes.LOAD_EDITABLE_DASHBOARD_PRESET_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
        editablePreset: action.payload,
      };
    }
    case DashboardTypes.LOAD_EDITABLE_DASHBOARD_PRESET_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
      };
    }

    case DashboardTypes.RESET_EDITABLE_DASHBOARD_PRESET: {
      return {
        ...state,
        editablePreset: null,
      };
    }

    case DashboardTypes.UPDATE_DASHBOARD_PRESET_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: true,
        },
      };
    }
    case DashboardTypes.UPDATE_DASHBOARD_PRESET_SUCCESS: {
      const presetTitle = {
        ...action.payload,
        id: action.payload.preset_id,
        stats: action.payload.displayed_statistics,
      };

      delete presetTitle.displayed_statistics;
      delete presetTitle.preset_id;

      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
        presetTitles: state.presetTitles.map((preset) =>
          preset.id === presetTitle.id ? presetTitle : preset
        ),
      };
    }
    case DashboardTypes.UPDATE_DASHBOARD_PRESET_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
      };
    }

    case DashboardTypes.DELETE_DASHBOARD_PRESET_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: true,
        },
      };
    }
    case DashboardTypes.DELETE_DASHBOARD_PRESET_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
        presetTitles: state.presetTitles.filter(
          (preset) => preset.id !== action.payload.id
        ),
      };
    }
    case DashboardTypes.DELETE_DASHBOARD_PRESET_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          presetLoading: false,
        },
      };
    }

    case DashboardTypes.SET_SELECTED_PRESET: {
      const universe =
        state.universes.length &&
        state.universes.find(
          (universe) => universe.country_id === action.payload.default_universe
        );

      return {
        ...state,
        selectedPreset: action.payload,
        selectedUniverse: universe || state.selectedUniverse,
        returnPeriod:
          dashboardReturnPeriodOptions.find(
            (item) =>
              item.label.toLowerCase() === action.payload.default_style_return
          ) || state.returnPeriod,
        returnToVolatilityPeriod:
          dashboardReturnToVolatilityPeriod.find(
            (item) =>
              item.label.toLowerCase() === action.payload.default_factors_return
          ) || state.returnToVolatilityPeriod,
      };
    }

    case DashboardTypes.UPDATE_DEFAULT_DASHBOARD_PRESET: {
      return {
        ...state,
        presetTitles: state.presetTitles.map((preset) =>
          preset.id !== action.payload.preset_id
            ? {
                ...preset,
                default: false,
              }
            : preset
        ),
        defaultPreset: action.payload,
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
