import { LOGOUT, RESET_DEFAULT } from "../actions/Type";
import * as XRayTypes from "../types/XRay";

const initialState = {
  refetchDefaultStrategy: false,
  activeTab: 0,
  strategiesTitles: [],
  selectedStrategyTitle: null,
  xRayStrategy: "",
  strategyStat: null,
  componentsOverview: null,
  contributionAnalysis: null,
  contributionAnalysisLoader: false,
  economicCycles: null,
  economicCyclesIsLoading: false,
  macroExposures: null,
  macroExposuresIsLoading: false,
  consistencyAnalysis: null,
  consistencyUnderwater: null,
  consistencyUnderwaterLoader: false,
  economicEvents: [],
  isLoading: false,
  dirtyData: {
    componentsOverview: true,
    contributionAnalysis: true,
    economicCycles: true,
    macroExposures: true,
    strategyStat: true,
    consistencyUnderwater: true,
  },
};

export default function xray(state = initialState, action) {
  switch (action.type) {
    case XRayTypes.GET_TITLES_DATA_START:
      return {
        ...state,
        isLoading: true,
      };
    case XRayTypes.GET_TITLES_DATA_SUCCESS:
      return {
        ...state,
        strategiesTitles: action.payload,
        isLoading: false,
      };
    case XRayTypes.GET_TITLES_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case XRayTypes.SET_SELECTED_STRATEGY_TITLE:
      if (action.payload === null) {
        return {
          ...state,
          selectedStrategyTitle: action.payload,
          xRayStrategy: "",
          strategyStat: null,
          componentsOverview: null,
          contributionAnalysis: null,
          economicCycles: null,
          macroExposures: null,
          strategyStat: null,
          consistencyAnalysis: null,
          consistencyUnderwater: null,
        };
      } else {
        return {
          ...state,
          selectedStrategyTitle: action.payload,
        };
      }

    case XRayTypes.GET_X_RAY_STRATEGY_START:
      return {
        ...state,
        isLoading: true,
        consistencyAnalysis: null,
        consistencyUnderwater: null,
      };
    case XRayTypes.GET_X_RAY_STRATEGY_SUCCESS:
      return {
        ...state,
        refetchDefaultStrategy: false,
        xRayStrategy: action.payload,
        isLoading: false,
        dirtyData: {
          componentsOverview: true,
          contributionAnalysis: true,
          economicCycles: true,
          macroExposures: true,
          strategyStat: true,
          consistencyUnderwater: true,
        },
      };
    case XRayTypes.GET_X_RAY_STRATEGY_FAILED:
      return {
        ...state,
        isLoading: false,
        xRayStrategy: "",
      };

    case XRayTypes.GET_COMPONENTS_OVERVIEW_START:
      return {
        ...state,
        isLoading: true,
      };
    case XRayTypes.GET_COMPONENTS_OVERVIEW_SUCCESS:
      return {
        ...state,
        componentsOverview: action.payload,
        isLoading: false,
        dirtyData: {
          ...state.dirtyData,
          componentsOverview: false,
        },
      };
    case XRayTypes.GET_COMPONENTS_OVERVIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        componentsOverview: null,
      };

    case XRayTypes.GET_STRATEGY_STRAT_START:
      return {
        ...state,
        isLoading: true,
      };
    case XRayTypes.GET_STRATEGY_STRAT_SUCCESS:
      return {
        ...state,
        strategyStat: action.payload,
        isLoading: false,
        dirtyData: {
          ...state.dirtyData,
          strategyStat: false,
        },
      };
    case XRayTypes.GET_STRATEGY_STRAT_FAILED:
      return {
        ...state,
        isLoading: false,
        strategyStat: null,
      };

    case XRayTypes.GET_CONTRIBUTION_ANALYSIS_START:
      return {
        ...state,
        contributionAnalysisLoader: true,
      };
    case XRayTypes.GET_CONTRIBUTION_ANALYSIS_SUCCESS:
      return {
        ...state,
        contributionAnalysisLoader: false,
        contributionAnalysis: action.payload,
        dirtyData: {
          ...state.dirtyData,
          contributionAnalysis: false,
        },
      };
    case XRayTypes.GET_CONTRIBUTION_ANALYSIS_FAILED:
      return {
        ...state,
        contributionAnalysis: null,
        contributionAnalysisLoader: false,
      };

    case XRayTypes.GET_ECONOMIC_CYCLES_START:
      return {
        ...state,
        isLoading: true,
        economicCyclesIsLoading: true,
      };
    case XRayTypes.GET_ECONOMIC_CYCLES_SUCCESS:
      const economicCyclesResult = {
        strategy: {
          ...action.payload.strategy,
          stats: JSON.parse(action.payload.strategy.stats),
        },
        data: action.payload.data.map((item) => ({
          ...item,
          stats: JSON.parse(item.stats),
        })),
      };

      return {
        ...state,
        isLoading: false,
        economicCyclesIsLoading: false,
        economicCycles: economicCyclesResult,
        dirtyData: {
          ...state.dirtyData,
          economicCycles: false,
        },
      };
    case XRayTypes.GET_ECONOMIC_CYCLES_FAILED:
      return {
        ...state,
        isLoading: false,
        economicCyclesIsLoading: false,
        economicCycles: null,
      };

    case XRayTypes.GET_MACRO_EXPOSURES_START:
      return {
        ...state,
        isLoading: true,
        macroExposuresIsLoading: true,
      };
    case XRayTypes.GET_MACRO_EXPOSURES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        macroExposuresIsLoading: false,
        macroExposures: action.payload,
        dirtyData: {
          ...state.dirtyData,
          macroExposures: false,
        },
      };
    case XRayTypes.GET_MACRO_EXPOSURES_FAILED:
      return {
        ...state,
        isLoading: false,
        macroExposuresIsLoading: false,
        macroExposures: null,
      };

    case XRayTypes.GET_CONSISTENCY_ANALYSIS_START:
      return {
        ...state,
        isLoading: true,
      };
    case XRayTypes.GET_CONSISTENCY_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        consistencyAnalysis: action.payload,
      };
    case XRayTypes.GET_CONSISTENCY_ANALYSIS_FAILED:
      return {
        ...state,
        isLoading: false,
        consistencyAnalysis: null,
      };

    case XRayTypes.GET_ECONOMIC_EVENTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case XRayTypes.GET_ECONOMIC_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        economicEvents: action.payload,
      };
    case XRayTypes.GET_ECONOMIC_EVENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case XRayTypes.GET_CONSISTENCY_UNDERWATER_START:
      return {
        ...state,
        consistencyUnderwaterLoader: true,
      };
    case XRayTypes.GET_CONSISTENCY_UNDERWATER_SUCCESS:
      return {
        ...state,
        consistencyUnderwater: action.payload,
        consistencyUnderwaterLoader: false,
        dirtyData: {
          ...state.dirtyData,
          consistencyUnderwater: false,
        },
      };
    case XRayTypes.GET_CONSISTENCY_UNDERWATER_FAILED:
      return {
        ...state,
        consistencyUnderwater: null,
        consistencyUnderwaterLoader: false,
      };

    case XRayTypes.SET_XRAY_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }

    case RESET_DEFAULT: {
      const defaultStrategy =
        state.strategiesTitles.length &&
        state.strategiesTitles.find(
          (x) => x.initialization_name === action.payload.default_strategy
        );
      return {
        ...state,
        refetchDefaultStrategy: true,
        activeTab: 0,
        selectedStrategyTitle: defaultStrategy
          ? defaultStrategy
          : state.strategiesTitles.length
          ? state.strategiesTitles[0]
          : null,
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
