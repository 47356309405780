import {
  yearValues,
  years3Values,
  years5Values,
  years10Values,
  years15Values,
  since2010Values,
  fullYearsValues,
  templateSortArr,
  editTableOptions,
} from "../features/FactorScreening/consts";
import XLSX from "xlsx-js-style";
import moment from "moment";
import { TableFiltersLabels } from "../features/XRay/consts";

export const checkAllGroupMenu = (selectedEditTableOptions, key) => {
  switch (key) {
    case "year":
      return yearValues.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "3years":
      return years3Values.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "5years":
      return years5Values.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "10years":
      return years10Values.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "15years":
      return years15Values.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "since2010":
      return since2010Values.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "full":
      return fullYearsValues.every((item) =>
        selectedEditTableOptions.includes(item)
      );
  }
};

export const checkIndeterminateAllGroupMenu = (
  selectedEditTableOptions,
  key
) => {
  if (checkAllGroupMenu(selectedEditTableOptions, key)) {
    return false;
  }

  switch (key) {
    case "year":
      return selectedEditTableOptions.some((item) => yearValues.includes(item));

    case "3years":
      return selectedEditTableOptions.some((item) =>
        years3Values.includes(item)
      );

    case "5years":
      return selectedEditTableOptions.some((item) =>
        years5Values.includes(item)
      );

    case "10years":
      return selectedEditTableOptions.some((item) =>
        years10Values.includes(item)
      );

    case "15years":
      return selectedEditTableOptions.some((item) =>
        years15Values.includes(item)
      );

    case "since2010":
      return selectedEditTableOptions.some((item) =>
        since2010Values.includes(item)
      );

    case "full":
      return selectedEditTableOptions.some((item) =>
        fullYearsValues.includes(item)
      );
  }
};

export const checkSelectedEditTableOptions = (selectedEditTableOptions) => {
  let arr = [];

  if (selectedEditTableOptions.includes("year")) {
    arr.push("annualReturn_year", "volatility_year", "sharpRatio_year");
  }

  if (selectedEditTableOptions.includes("3years")) {
    arr.push("annualReturn_3years", "volatility_3years", "sharpRatio_3years");
  }

  if (selectedEditTableOptions.includes("5years")) {
    arr.push("annualReturn_5years", "volatility_5years", "sharp_5years");
  }

  if (selectedEditTableOptions.includes("10years")) {
    arr.push(
      "annualReturn_10years",
      "volatility_10years",
      "sharpRatio_10years"
    );
  }

  if (selectedEditTableOptions.includes("15years")) {
    arr.push(
      "annualReturn_15years",
      "volatility_15years",
      "sharpRatio_15years"
    );
  }

  if (selectedEditTableOptions.includes("since2010")) {
    arr.push(
      "annualReturn_since2010",
      "volatility_since2010",
      "sharpRatio_since2010"
    );
  }

  if (selectedEditTableOptions.includes("full")) {
    arr.push("annualReturn_full", "volatility_full", "sharpRatio_full");
  }

  return arr;
};

export const isNeedHeader = (arr, subArr) =>
  arr.some((item) => subArr.includes(item));

export const headerLength = (arr, subArr) => {
  let count = 0;

  arr.some((item) => {
    if (subArr.includes(item)) {
      count++;
    }
  });

  return count;
};

export const getHighestValues = (rows, maxOptions, minOptions) => {
  let resultMax;
  for (const item of maxOptions) {
    const arr = rows.map((row) => row[item]);
    const highest = Math.max(...arr);
    resultMax = { ...resultMax, [item]: highest };
  }

  let resultMin;
  for (const item of minOptions) {
    const arr = rows.map((row) => row[item]);
    const lowest = Math.min(...arr);
    resultMin = { ...resultMin, [item]: lowest };
  }

  return { ...resultMax, ...resultMin };
};

export const isLastCell = (arrLength, index) => {
  return arrLength === index + 1;
};

export const filterCellWithEditTableOptions = (
  value,
  selectedEditTableOptions
) => {
  let result;

  switch (value) {
    case "all":
      result =
        selectedEditTableOptions.length === editTableOptions.length
          ? []
          : editTableOptions;
      break;

    case "year":
      if (yearValues.every((item) => selectedEditTableOptions.includes(item))) {
        const res = selectedEditTableOptions.filter(
          (item) => !yearValues.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...yearValues]);
        result = Array.from(res);
      }
      break;

    case "3years":
      if (
        years3Values.every((item) => selectedEditTableOptions.includes(item))
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !years3Values.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...years3Values]);
        result = Array.from(res);
      }
      break;

    case "5years":
      if (
        years5Values.every((item) => selectedEditTableOptions.includes(item))
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !years5Values.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...years5Values]);
        result = Array.from(res);
      }
      break;

    case "10years":
      if (
        years10Values.every((item) => selectedEditTableOptions.includes(item))
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !years10Values.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...years10Values]);
        result = Array.from(res);
      }
      break;

    case "15years":
      if (
        years15Values.every((item) => selectedEditTableOptions.includes(item))
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !years15Values.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...years15Values]);
        result = Array.from(res);
      }
      break;

    case "since2010":
      if (
        since2010Values.every((item) => selectedEditTableOptions.includes(item))
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !since2010Values.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...since2010Values]);
        result = Array.from(res);
      }
      break;

    case "full":
      if (
        fullYearsValues.every((item) => selectedEditTableOptions.includes(item))
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !fullYearsValues.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...fullYearsValues]);
        result = Array.from(res);
      }
      break;

    default:
      if (selectedEditTableOptions.includes(value)) {
        const res = selectedEditTableOptions.filter((item) => item !== value);
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, value]);
        result = Array.from(res);
      }
  }

  result.sort(
    (a, b) => templateSortArr.indexOf(a) - templateSortArr.indexOf(b)
  );

  return result;
};

export const getFactorSummaryPerfomanceExcelData = (
  selectedEditTableOptions,
  date,
  strategyStat,
  tableData
) => {
  const cols = [
    "periodReturn_day",
    "periodReturn_month",
    "periodReturn_halfYear",
    "periodReturn_ytd",
    ...selectedEditTableOptions,
  ];

  const values = tableData.map((row, index) => {
    let vals = [];

    const {
      methodology_id,
      portfolio_style,
      country_id,
      size_id,
      rebalance_id,
      filter_id,
    } = row;

    vals.push({
      v: `${methodology_id}, ${
        country_id ? `(${country_id})` : ""
      }, ${size_id}, ${rebalance_id}, ${filter_id}`,
      s: {
        fill: {
          fgColor: { rgb: index % 2 > 0 ? "F3F6F7" : "FFFFFF" },
        },
        font: (methodology_id.toLowerCase().includes("mlt") ||
          portfolio_style === "Multi Factor") && {
          color: { rgb: "BD8C1D" },
        },
      },
    });

    cols.forEach((key) => {
      vals.push({
        t: "n",
        v: row[key],
        s: {
          fill: {
            fgColor: { rgb: index % 2 > 0 ? "F3F6F7" : "FFFFFF" },
          },
          alignment: { horizontal: "center" },
        },
      });
    });

    return vals;
  });

  const filters = cols.map((col) => ({
    v: TableFiltersLabels[col],
    s: { alignment: { horizontal: "center" } },
  }));

  let strategyValues = [];
  strategyValues.push({
    v: `${strategyStat.name} (${strategyStat.country_id})`,
    s: { fill: { fgColor: { rgb: "D0EEF1" } } },
  });
  cols.forEach((key) => {
    strategyValues.push({
      t: "n",
      v: strategyStat[key],
      s: {
        fill: { fgColor: { rgb: "D0EEF1" } },
        alignment: { horizontal: "center" },
      },
    });
  });

  let groupedHeaders = [];

  if (isNeedHeader(selectedEditTableOptions, yearValues)) {
    groupedHeaders.push({
      name: "Year",
      count: headerLength(selectedEditTableOptions, yearValues),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, years3Values)) {
    groupedHeaders.push({
      name: "3 Years",
      count: headerLength(selectedEditTableOptions, years3Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, years5Values)) {
    groupedHeaders.push({
      name: "5 Years",
      count: headerLength(selectedEditTableOptions, years5Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, since2010Values)) {
    groupedHeaders.push({
      name: "Since 2010",
      count: headerLength(selectedEditTableOptions, since2010Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, years10Values)) {
    groupedHeaders.push({
      name: "10 Years",
      count: headerLength(selectedEditTableOptions, years10Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, years15Values)) {
    groupedHeaders.push({
      name: "15 Years",
      count: headerLength(selectedEditTableOptions, years15Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, fullYearsValues)) {
    groupedHeaders.push({
      name: "Full",
      count: headerLength(selectedEditTableOptions, fullYearsValues),
    });
  }

  let labelsArr = [
    {
      v: "Day",
    },
    {
      v: "Month",
    },
    {
      v: "Half Year",
    },
    {
      v: "YTD",
    },
  ];

  groupedHeaders.forEach((item) => {
    labelsArr.push({
      v: item.name,
    });
    for (let index = 1; index < item.count; index++) {
      labelsArr.push({
        v: "",
      });
    }
  });

  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.aoa_to_sheet([
    [
      {
        v: `As of day ${moment(date).format("D/MM/YYYY")}`,
      },
      ...labelsArr,
    ],
    [{ v: "Factor" }, ...filters],
    strategyValues,
    ...values,
  ]);

  ws["!cols"] = [{ width: 70 }, ...labelsArr.map(() => ({ width: 15 }))];

  XLSX.utils.book_append_sheet(wb, ws, "summary");

  XLSX.writeFile(wb, "Summary_perfomance.xlsx");
};


export const getStrategySummaryPerfomanceExcelData = (
  selectedEditTableOptions,
  date,
  strategyStat,
  tableData
) => {
  const cols = [
    "periodReturn_day",
    "periodReturn_month",
    "periodReturn_halfYear",
    "periodReturn_ytd",
    ...selectedEditTableOptions,
  ];

  const values = tableData.map((row, index) => {
    let vals = [];

    const {
      name_to_show
    } = row;

    vals.push({
      v: `${name_to_show}`
    });

    cols.forEach((key) => {
      vals.push({
        t: "n",
        v: row[key],
        s: {
          fill: {
            fgColor: { rgb: index % 2 > 0 ? "F3F6F7" : "FFFFFF" },
          },
          alignment: { horizontal: "center" },
        },
      });
    });

    return vals;
  });

  const filters = cols.map((col) => ({
    v: TableFiltersLabels[col],
    s: { alignment: { horizontal: "center" } },
  }));

  let strategyValues = [];
  strategyValues.push({
    v: `${strategyStat.name} (${strategyStat.country_id})`,
    s: { fill: { fgColor: { rgb: "D0EEF1" } } },
  });
  cols.forEach((key) => {
    strategyValues.push({
      t: "n",
      v: strategyStat[key],
      s: {
        fill: { fgColor: { rgb: "D0EEF1" } },
        alignment: { horizontal: "center" },
      },
    });
  });

  let groupedHeaders = [];

  if (isNeedHeader(selectedEditTableOptions, yearValues)) {
    groupedHeaders.push({
      name: "Year",
      count: headerLength(selectedEditTableOptions, yearValues),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, years3Values)) {
    groupedHeaders.push({
      name: "3 Years",
      count: headerLength(selectedEditTableOptions, years3Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, years5Values)) {
    groupedHeaders.push({
      name: "5 Years",
      count: headerLength(selectedEditTableOptions, years5Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, since2010Values)) {
    groupedHeaders.push({
      name: "Since 2010",
      count: headerLength(selectedEditTableOptions, since2010Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, years10Values)) {
    groupedHeaders.push({
      name: "10 Years",
      count: headerLength(selectedEditTableOptions, years10Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, years15Values)) {
    groupedHeaders.push({
      name: "15 Years",
      count: headerLength(selectedEditTableOptions, years15Values),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, fullYearsValues)) {
    groupedHeaders.push({
      name: "Full",
      count: headerLength(selectedEditTableOptions, fullYearsValues),
    });
  }

  let labelsArr = [
    {
      v: "Day",
    },
    {
      v: "Month",
    },
    {
      v: "Half Year",
    },
    {
      v: "YTD",
    },
  ];

  groupedHeaders.forEach((item) => {
    labelsArr.push({
      v: item.name,
    });
    for (let index = 1; index < item.count; index++) {
      labelsArr.push({
        v: "",
      });
    }
  });

  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.aoa_to_sheet([
    [
      {
        v: `As of day ${moment(date).format("D/MM/YYYY")}`,
      },
      ...labelsArr,
    ],
    [{ v: "Strategy" }, ...filters],
    strategyValues,
    ...values,
  ]);

  ws["!cols"] = [{ width: 70 }, ...labelsArr.map(() => ({ width: 15 }))];

  XLSX.utils.book_append_sheet(wb, ws, "summary");

  XLSX.writeFile(wb, "Summary_perfomance.xlsx");
};
