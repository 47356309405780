const ReportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 512 512"
  >
    <path d="M414 28.1h-56.3C342.8 10.9 320.9 0 296.5 0h-81c-24.4 0-46.3 10.9-61.2 28.1H98c-24.8 0-45 20.2-45 45.1V467c0 24.9 20.2 45.1 45 45.1h316c24.8 0 45-20.2 45-45.1V73.1c0-24.8-20.2-45-45-45zm-198.5 2h81c23.2 0 42.4 15.5 48.8 36.1H166.8c6.3-20.6 25.5-36.1 48.7-36.1zM429 466.9c0 8.3-6.7 15-15 15H98c-8.3 0-15-6.7-15-15V73.1c0-8.3 6.7-15 15-15h39.8c-2.2 7.3-3.3 15-3.3 23 0 8.3 6.7 15 15 15h213c8.3 0 15-6.7 15-15 0-8-1.2-15.7-3.3-23H414c8.3 0 15 6.7 15 15v393.8z" />
    <path d="m289.4 333.2 53.8-53.9v2.1c0 9.6 7.8 17.4 17.4 17.4S378 291 378 281.4c0-49.3.1-44.7-.2-46.7-1.2-8.5-8.5-14.9-17.2-14.9h-44.1c-9.6 0-17.4 7.8-17.4 17.4 0 9.6 7.8 17.4 17.4 17.4h2.1L277 296.2l-52.7-52.8c-6.8-6.8-17.8-6.8-24.6 0L137 306.2c-6.8 6.8-6.8 17.9 0 24.7 6.8 6.8 17.8 6.8 24.6 0l50.3-50.4 52.7 52.8c6.9 6.7 18 6.7 24.8-.1z" />
  </svg>
);

export default ReportIcon;
