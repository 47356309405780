import React, { useState, Suspense, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/system/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Switch, useHistory } from "react-router-dom";
import { batch, useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import routes from "../../routes/routes";
import { SidebarState } from "../../contexts/SidebarState";
import useMediaQuery from "@mui/material/useMediaQuery";
import PrivatRoute from "../../components/Atoms/PrivatRoute/PrivatRoute";
import { initApp } from "../../store/actions/User";
import { isAdmin } from "../../store/actions/Admin";

// const drawerwidth = 288;

/* TODO optimization component */

const Main = styled("main", {
  shouldFornavigationListwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerwidth }) => ({
  flexGrow: 1,
  padding: "88px 68px 24px 68px",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerwidth}px`,
  width: "100%",
  ...(open && {
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  [theme.breakpoints.down("lg")]: {
    padding: "88px 20px 24px 20px",
  },
}));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

export default function PersistentDrawerLeft() {
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const drawerwidth = lgDown ? 70 : 288;

  const [open, setOpen] = useState(false);

  const history = useHistory();

  const curUser = useSelector((state) => state.user);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const messagesRoute = () => {
    const path = "messages";
    history.push(path);
  };

  const loginRoute = () => {
    const path = "login";
    history.push(path);
  };

  const dispatch = useDispatch();

  const isLoggedIn = useSelector((s) => s.user.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      batch(() => {
        dispatch(initApp());
        dispatch(isAdmin());
      });
    } else {
      history.push("/login");
    }
  }, [isLoggedIn]);

  return (
    <Box display="flex">
      <CssBaseline />
      <TopBar
        drawerwidth={drawerwidth}
        isOpen={open}
        curUser={curUser}
        notifyCount={2}
        onLoginRoute={loginRoute}
        onMessagesRoute={messagesRoute}
        onOpen={handleDrawerOpen}
      />
      <Sidebar
        drawerwidth={drawerwidth}
        onClose={handleDrawerClose}
        isOpen={open}
      />
      <SidebarState.Provider value={{ isOpen: open, width: drawerwidth }}>
        <Main
          open={open}
          drawerwidth={drawerwidth}
          sx={{ backgroundColor: "#f3f6f7" }}
        >
          <>
            <Suspense fallback={loading}>
              <Switch>
                {routes.map((route) =>
                  route.privat ? (
                    <PrivatRoute
                      key={route.name}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      role={route.role}
                      auth={route.auth}
                      redirect={route.redirect}
                    >
                      <route.component />
                    </PrivatRoute>
                  ) : (
                    route.component && (
                      <Route
                        key={route.name}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        role={route.role}
                        render={(props) => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <route.component {...props} />
                        )}
                      />
                    )
                  )
                )}
              </Switch>
            </Suspense>
          </>
        </Main>
      </SidebarState.Provider>
    </Box>
  );
}
