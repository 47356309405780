import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    fontSize: "16px",
    padding: "3px 5px",
    textTransform: "capitalize",
    minWidth: "30px",
    minHeight: "30px",
    color: theme.palette.primary.main,
    boxShadow: "none",
    border: "none",

    "&.MuiButton-contained": {
      backgroundColor: theme.palette.background.light,
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      boxShadow: "none",
      "& svg": {
        fill: "#fff",
      },
    },

    "& svg": {
      fill: theme.palette.primary.main,
    },

    "& svg + .iconText": {
      marginLeft: "4px",
    },
  },
}));

export default useStyles;
