import * as AdminTypes from "../types/Admin";

export const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  isLoading: false,
  isUsersLoading: true,
  isWorkingGroupsLoading: true,
  isCompaniesLoading: true,
  users: [],
  workingGroups: [],
  companies: [],
  roles: [],
};

export default function admin(state = initialState, action) {
  switch (action.type) {
    case AdminTypes.CREATE_USER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.CREATE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: [...state.users, action.payload],
      };
    }
    case AdminTypes.CREATE_USER_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.CHANGE_USER_PASSWORD_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.CHANGE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminTypes.CHANGE_USER_PASSWORD_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.GET_WORKING_GROUPS_START: {
      return {
        ...state,
        isWorkingGroupsLoading: true,
      };
    }
    case AdminTypes.GET_WORKING_GROUPS_SUCCESS: {
      return {
        ...state,
        isWorkingGroupsLoading: false,
        workingGroups: action.payload.working_groups,
      };
    }
    case AdminTypes.GET_WORKING_GROUPS_FAILED: {
      return {
        ...state,
        isWorkingGroupsLoading: false,
      };
    }

    case AdminTypes.GET_COMPANIES_START: {
      return {
        ...state,
        isCompaniesLoading: true,
      };
    }
    case AdminTypes.GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        isCompaniesLoading: false,
        companies: action.payload.companies,
      };
    }
    case AdminTypes.GET_COMPANIES_FAILED: {
      return {
        ...state,
        isCompaniesLoading: false,
      };
    }

    case AdminTypes.GET_ROLES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.GET_ROLES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        roles: action.payload.roles,
      };
    }
    case AdminTypes.GET_ROLES_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.GET_USERS_START: {
      return {
        ...state,
        isUsersLoading: true,
      };
    }
    case AdminTypes.GET_USERS_SUCCESS: {
      return {
        ...state,
        isUsersLoading: false,
        users: action.payload,
      };
    }
    case AdminTypes.GET_USERS_FAILED: {
      return {
        ...state,
        isUsersLoading: false,
      };
    }

    case AdminTypes.IS_ADMIN_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.IS_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminTypes.IS_ADMIN_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.ADD_WORKING_GROUP_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.ADD_WORKING_GROUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        workingGroups: [...state.workingGroups, action.payload],
      };
    }
    case AdminTypes.ADD_WORKING_GROUP_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.UPDATE_WORKING_GROUP_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.UPDATE_WORKING_GROUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        workingGroups: state.workingGroups.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    }
    case AdminTypes.UPDATE_WORKING_GROUP_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.DELETE_WORKING_GROUP_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.DELETE_WORKING_GROUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        workingGroups: state.workingGroups.filter(
          (item) => item.id !== action.payload
        ),
      };
    }
    case AdminTypes.DELETE_WORKING_GROUP_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.UPDATE_COMPANY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        companies: state.companies.map((company) =>
          company.id === action.payload.id ? action.payload : company
        ),
      };
    }
    case AdminTypes.UPDATE_COMPANY_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.GET_LIST_STRATEGIES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.GET_LIST_STRATEGIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminTypes.GET_LIST_STRATEGIES_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.UPDATE_USER_DATA_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.UPDATE_USER_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: state.users.map((user) =>
          user.user_id === action.payload.user_id
            ? {
                ...user,
                name: action.payload.name,
                last_name: action.payload.last_name,
              }
            : user
        ),
      };
    }
    case AdminTypes.UPDATE_USER_DATA_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.UPDATE_USER_STATUS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.UPDATE_USER_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: state.users.map((user) =>
          user.user_id === action.payload.user_id
            ? {
                ...user,
                active: action.payload.active,
              }
            : user
        ),
      };
    }
    case AdminTypes.UPDATE_USER_STATUS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case AdminTypes.UPDATA_COMPANY_DEFAULT_STRATEGIES_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AdminTypes.UPDATA_COMPANY_DEFAULT_STRATEGIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case AdminTypes.UPDATA_COMPANY_DEFAULT_STRATEGIES_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}
