export const tableMenu = {
  year: {
    id: "year",
    children: [
      { id: "annualReturn_year", sortable: true, label: "Ann. Ret. (%)" },
      { id: "volatility_year", sortable: true, label: "Ann. Std. (%)" },
      { id: "sharpRatio_year", sortable: true, label: "Sharpe" },
    ],
    label: "Year",
  },

  "3years": {
    id: "3years",
    children: [
      { id: "annualReturn_3years", sortable: true, label: "Ann. Ret. (%)" },
      { id: "volatility_3years", sortable: true, label: "Ann. Std. (%)" },
      { id: "drawDown_3years", sortable: true, label: "DD12M (%)" },
      { id: "sharpRatio_3years", sortable: true, label: "Sharpe" },
      { id: "beta_3years", sortable: true, label: "beta" },
      { id: "beta_std_3years", sortable: true, label: "beta std" },
      { id: "alpha_3years", sortable: true, label: "alpha" },
      { id: "p_value_3years", sortable: true, label: "p value" },
      { id: "t_stat_3years", sortable: true, label: "t stat" },
      { id: "beta_plus_3years", sortable: true, label: "beta plus" },
      { id: "beta_minus_3years", sortable: true, label: "beta minus" },
      { id: "jensen_alpha_3years", sortable: true, label: "jensen alpha" },
      // { id: "jensen_sharpe_3years", sortable: true, label: "jensen sharpe" },
    ],
    label: "3 Years",
  },

  "5years": {
    id: "5years",
    children: [
      { id: "annualReturn_5years", sortable: true, label: "Ann. Ret. (%)" },
      { id: "volatility_5years", sortable: true, label: "Ann. Std. (%)" },
      { id: "drawDown_5years", sortable: true, label: "DD12M (%)" },
      { id: "sharp_5years", sortable: true, label: "Sharpe" },
      { id: "beta_5years", sortable: true, label: "beta" },
      { id: "beta_std_5years", sortable: true, label: "beta std" },
      { id: "alpha_5years", sortable: true, label: "alpha" },
      { id: "p_value_5years", sortable: true, label: "p value" },
      { id: "t_stat_5years", sortable: true, label: "t stat" },
      { id: "beta_plus_5years", sortable: true, label: "beta plus" },
      { id: "beta_minus_5years", sortable: true, label: "beta minus" },
      { id: "jensen_alpha_5years", sortable: true, label: "jensen alpha" },
      // { id: "jensen_sharpe_5years", sortable: true, label: "jensen sharpe" },
    ],
    label: "5 Years",
  },

  since2010: {
    id: "since2010",
    children: [
      { id: "annualReturn_since2010", sortable: true, label: "Ann. Ret. (%)" },
      { id: "volatility_since2010", sortable: true, label: "Ann. Std. (%)" },
      { id: "drawDown_since2010", sortable: true, label: "DD12M (%)" },
      { id: "sharpRatio_since2010", sortable: true, label: "Sharpe" },
      { id: "beta_since2010", sortable: true, label: "beta" },
      { id: "beta_std_since2010", sortable: true, label: "beta std" },
      { id: "alpha_since2010", sortable: true, label: "alpha" },
      { id: "p_value_since2010", sortable: true, label: "p value" },
      { id: "t_stat_since2010", sortable: true, label: "t stat" },
      { id: "beta_plus_since2010", sortable: true, label: "beta plus" },
      { id: "beta_minus_since2010", sortable: true, label: "beta minus" },
      { id: "jensen_alpha_since2010", sortable: true, label: "jensen alpha" },
      // {
      //   id: "jensen_sharpe_since2010",
      //   sortable: true,
      //   label: "jensen sharpe",
      // },
    ],
    label: "Since 2010",
  },

  "10years": {
    id: "10years",
    children: [
      { id: "annualReturn_10years", sortable: true, label: "Ann. Ret. (%)" },
      { id: "volatility_10years", sortable: true, label: "Ann. Std. (%)" },
      { id: "drawDown_10years", sortable: true, label: "DD12M (%)" },
      { id: "sharpRatio_10years", sortable: true, label: "Sharpe" },
      { id: "beta_10years", sortable: true, label: "beta" },
      { id: "beta_std_10years", sortable: true, label: "beta std" },
      { id: "alpha_10years", sortable: true, label: "alpha" },
      { id: "p_value_10years", sortable: true, label: "p value" },
      { id: "t_stat_10years", sortable: true, label: "t stat" },
      { id: "beta_plus_10years", sortable: true, label: "beta plus" },
      { id: "beta_minus_10years", sortable: true, label: "beta minus" },
      { id: "jensen_alpha_10years", sortable: true, label: "jensen alpha" },
      // { id: "jensen_sharpe_10years", sortable: true, label: "jensen sharpe" },
    ],
    label: "10 Years",
  },

  "15years": {
    id: "15years",
    children: [
      { id: "annualReturn_15years", sortable: true, label: "Ann. Ret. (%)" },
      { id: "volatility_15years", sortable: true, label: "Ann. Std. (%)" },
      { id: "drawDown_15years", sortable: true, label: "DD12M (%)" },
      { id: "sharpRatio_15years", sortable: true, label: "Sharpe" },
      { id: "beta_15years", sortable: true, label: "beta" },
      { id: "beta_std_15years", sortable: true, label: "beta std" },
      { id: "alpha_15years", sortable: true, label: "alpha" },
      { id: "p_value_15years", sortable: true, label: "p value" },
      { id: "t_stat_15years", sortable: true, label: "t stat" },
      { id: "beta_plus_15years", sortable: true, label: "beta plus" },
      { id: "beta_minus_15years", sortable: true, label: "beta minus" },
      { id: "jensen_alpha_15years", sortable: true, label: "jensen alpha" },
      // { id: "jensen_sharpe_15years", sortable: true, label: "jensen sharpe" },
    ],
    label: "15 Years",
  },

  full: {
    id: "full",
    children: [
      { id: "annualReturn_full", sortable: true, label: "Ann. Ret. (%)" },
      { id: "volatility_full", sortable: true, label: "Ann. Std. (%)" },
      { id: "drawDown_full", sortable: true, label: "DD12M (%)" },
      { id: "sharpRatio_full", sortable: true, label: "Sharpe" },
      { id: "beta_full", sortable: true, label: "beta" },
      { id: "beta_std_full", sortable: true, label: "beta std" },
      { id: "alpha_full", sortable: true, label: "alpha" },
      { id: "p_value_full", sortable: true, label: "p value" },
      { id: "t_stat_full", sortable: true, label: "t stat" },
      { id: "beta_plus_full", sortable: true, label: "beta plus" },
      { id: "beta_minus_full", sortable: true, label: "beta minus" },
      { id: "jensen_alpha_full", sortable: true, label: "jensen alpha" },
      // { id: "jensen_sharpe_full", sortable: true, label: "jensen sharpe" },
    ],
    label: "Full",
  },
};

export const headerRows = {
  factor: { id: "factor", sortable: false, label: "Factor" },
  periodReturn_day: {
    id: "periodReturn_day",
    sortable: true,
    label: "Return (%)",
  },
  periodReturn_month: {
    id: "periodReturn_month",
    sortable: true,
    label: "Return (%)",
  },
  periodReturn_halfYear: {
    id: "periodReturn_halfYear",
    sortable: true,
    label: "Return (%)",
  },
  periodReturn_ytd: {
    id: "periodReturn_ytd",
    sortable: true,
    label: "Return (%)",
  },
  ...tableMenu,
};

export const editTableOptions = [
  "annualReturn_year",
  "volatility_year",
  "sharpRatio_year",
  "annualReturn_3years",
  "volatility_3years",
  "drawDown_3years",
  "sharpRatio_3years",
  "beta_3years",
  "beta_std_3years",
  "alpha_3years",
  "p_value_3years",
  "t_stat_3years",
  "beta_plus_3years",
  "beta_minus_3years",
  "jensen_alpha_3years",
  "jensen_sharpe_3years",
  "annualReturn_5years",
  "volatility_5years",
  "drawDown_5years",
  "sharp_5years",
  "beta_5years",
  "beta_std_5years",
  "alpha_5years",
  "p_value_5years",
  "t_stat_5years",
  "beta_plus_5years",
  "beta_minus_5years",
  "jensen_alpha_5years",
  "jensen_sharpe_5years",

  "annualReturn_10years",
  "volatility_10years",
  "drawDown_10years",
  "sharpRatio_10years",
  "beta_10years",
  "beta_std_10years",
  "alpha_10years",
  "p_value_10years",
  "t_stat_10years",
  "beta_plus_10years",
  "beta_minus_10years",
  "jensen_alpha_10years",
  "jensen_sharpe_10years",

  "annualReturn_15years",
  "volatility_15years",
  "drawDown_15years",
  "sharpRatio_15years",
  "beta_15years",
  "beta_std_15years",
  "alpha_15years",
  "p_value_15years",
  "t_stat_15years",
  "beta_plus_15years",
  "beta_minus_15years",
  "jensen_alpha_15years",
  "jensen_sharpe_15years",

  "annualReturn_since2010",
  "volatility_since2010",
  "drawDown_since2010",
  "sharpRatio_since2010",
  "beta_since2010",
  "beta_std_since2010",
  "alpha_since2010",
  "p_value_since2010",
  "t_stat_since2010",
  "beta_plus_since2010",
  "beta_minus_since2010",
  "jensen_alpha_since2010",
  "jensen_sharpe_since2010",

  "annualReturn_full",
  "volatility_full",
  "drawDown_full",
  "sharpRatio_full",
  "beta_full",
  "beta_std_full",
  "alpha_full",
  "p_value_full",
  "t_stat_full",
  "beta_plus_full",
  "beta_minus_full",
  "jensen_alpha_full",
  "jensen_sharpe_full",
];

export const yearValues = [
  "annualReturn_year",
  "volatility_year",
  "sharpRatio_year",
];

export const years3Values = [
  "annualReturn_3years",
  "volatility_3years",
  "drawDown_3years",
  "sharpRatio_3years",
  "beta_3years",
  "beta_std_3years",
  "alpha_3years",
  "p_value_3years",
  "t_stat_3years",
  "beta_plus_3years",
  "beta_minus_3years",
  "jensen_alpha_3years",
  "jensen_sharpe_3years",
];

export const years5Values = [
  "annualReturn_5years",
  "volatility_5years",
  "drawDown_5years",
  "sharp_5years",
  "beta_5years",
  "beta_std_5years",
  "alpha_5years",
  "p_value_5years",
  "t_stat_5years",
  "beta_plus_5years",
  "beta_minus_5years",
  "jensen_alpha_5years",
  "jensen_sharpe_5years",
];

export const years10Values = [
  "annualReturn_10years",
  "volatility_10years",
  "drawDown_10years",
  "sharpRatio_10years",
  "beta_10years",
  "beta_std_10years",
  "alpha_10years",
  "p_value_10years",
  "t_stat_10years",
  "beta_plus_10years",
  "beta_minus_10years",
  "jensen_alpha_10years",
  "jensen_sharpe_10years",
];

export const years15Values = [
  "annualReturn_15years",
  "volatility_15years",
  "drawDown_15years",
  "sharpRatio_15years",
  "beta_15years",
  "beta_std_15years",
  "alpha_15years",
  "p_value_15years",
  "t_stat_15years",
  "beta_plus_15years",
  "beta_minus_15years",
  "jensen_alpha_15years",
  "jensen_sharpe_15years",
];

export const since2010Values = [
  "annualReturn_since2010",
  "volatility_since2010",
  "drawDown_since2010",
  "sharpRatio_since2010",
  "beta_since2010",
  "beta_std_since2010",
  "alpha_since2010",
  "p_value_since2010",
  "t_stat_since2010",
  "beta_plus_since2010",
  "beta_minus_since2010",
  "jensen_alpha_since2010",
  "jensen_sharpe_since2010",
];

export const fullYearsValues = [
  "annualReturn_full",
  "volatility_full",
  "drawDown_full",
  "sharpRatio_full",
  "beta_full",
  "beta_std_full",
  "alpha_full",
  "p_value_full",
  "t_stat_full",
  "beta_plus_full",
  "beta_minus_full",
  "jensen_alpha_full",
  "jensen_sharpe_full",
];

export const GroupByOptions = [
  "Style",
  "Country",
  "Size",
  "Rebalance",
  "Position",
  "Filter",
  "Factor(Methodology)",
];

export const templateSortArr = [
  ...yearValues,
  ...years3Values,
  ...years5Values,
  ...since2010Values,
  ...years10Values,
  ...years15Values,
  ...fullYearsValues,
];

export const CELL_WIDTH = 75;

export const FACTORS_TABLE_TITLE_CELL_WIDTH = 300;
