import { mainUrl } from "../config";
import axios from "axios";

const getAllUniverses = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/dashboard/universes`, {
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getDashboardBenchmarks = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/dashboard/benchmarks`, {
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getDashboardActiveStrategies = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/dashboard/active_strategies`, {
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getDashboardPortfolios = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/api/dashboard/portfolios`, params, {
      signal: controller?.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardAllStats = async (data, controller, token) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/dashboard/show_all`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardComposition = async (params, controller, token) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/dashboard/compositions`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardStyleReturn = async (params, controller, token) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/dashboard/style_return`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardMethodologyInfo = async (params, controller) =>
  axios
    .get(
      `${mainUrl.API_URL}/api/dashboard/get_methodology_information?asset_id=${params.assetId}`,
      { signal: controller?.signal }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardReturnToVolatility = async (params, controller, token) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/dashboard/factor_return_to_volatility`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getMethodologyReturnToVolatility = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/api/dashboard/methodology_returns`, params, {
      signal: controller?.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardBenchmarkTitles = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/dashboard/benchmark_titles`, {
      signal: controller?.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardStrategiesTitles = async (token, controller) =>
  axios
    .get(
      `${mainUrl.API_URL}/api/dashboard/strategies_titles`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardAllPortfolios = async (universe, controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/dashboard/get_all_portfolios`, {
      params: { universe },
      signal: controller?.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDashboardPresetTitles = async (token, controller) =>
  axios
    .get(
      `${mainUrl.API_URL}/api/dashboard/preset_titles`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const saveDashboardPreset = async (data, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/dashboard/save_preset`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const updateDashboardPreset = async (data, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/dashboard/update_preset`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const loadDashboardPreset = async (preset_id, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/dashboard/load_preset`,
      { preset_id },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const loadDashboardPresetStats = async (preset_id, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/api/dashboard/load_preset_stats`,
      { preset_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller?.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const deleteDashboardPreset = async (id, token, controller) => {
  if (token) {
    const data = {
      id,
    };
    return axios
      .delete(`${mainUrl.API_URL}/api/dashboard/delete_preset`, {
        headers: { Authorization: `Bearer ${token}` },
        signal: controller?.signal,
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((exception) => {
        throw exception;
      });
  }
};

const DashboardApi = {
  getAllUniverses,
  getDashboardBenchmarks,
  getDashboardActiveStrategies,
  getDashboardPortfolios,
  getDashboardAllStats,
  getDashboardComposition,
  getDashboardStyleReturn,
  getDashboardReturnToVolatility,
  getDashboardMethodologyInfo,
  getMethodologyReturnToVolatility,
  getDashboardBenchmarkTitles,
  getDashboardStrategiesTitles,
  getDashboardAllPortfolios,
  getDashboardPresetTitles,
  saveDashboardPreset,
  loadDashboardPreset,
  updateDashboardPreset,
  deleteDashboardPreset,
  loadDashboardPresetStats,
};

export default DashboardApi;
