import { mainUrl } from "../config";
import axios from "axios";

const sendFeedback = async (data, token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return axios
    .post(`${mainUrl.API_URL}/api/feedback`, data, config)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
};

const SupportApi = {
  sendFeedback,
};

export default SupportApi;
