export const GET_REBALANCE_LIST_START = "FACTORS.GET_REBALANCE_LIST_START";
export const GET_REBALANCE_LIST_SUCCESS = "FACTORS.GET_REBALANCE_LIST_SUCCESS";
export const GET_REBALANCE_LIST_FAILED = "FACTORS.GET_REBALANCE_LIST_FAILED";

export const GET_COUNTRIES_LIST_START = "FACTORS.GET_COUNTRIES_LIST_START";
export const GET_COUNTRIES_LIST_SUCCESS = "FACTORS.GET_COUNTRIES_LIST_SUCCESS";
export const GET_COUNTRIES_LIST_FAILED = "FACTORS.GET_COUNTRIES_LIST_FAILED";

export const GET_METHODOLOGY_LIST_START = "FACTORS.GET_METHODOLOGY_LIST_START";
export const GET_METHODOLOGY_LIST_SUCCESS =
  "FACTORS.GET_METHODOLOGY_LIST_SUCCESS";
export const GET_METHODOLOGY_LIST_FAILED =
  "FACTORS.GET_METHODOLOGY_LIST_FAILED";

export const GET_POSITION_LIST_START = "FACTORS.GET_POSITION_LIST_START";
export const GET_POSITION_LIST_SUCCESS = "FACTORS.GET_POSITION_LIST_SUCCESS";
export const GET_POSITION_LIST_FAILED = "FACTORS.GET_POSITION_LIST_FAILED";

export const GET_FILTER_LIST_START = "FACTORS.GET_FILTER_LIST_START";
export const GET_FILTER_LIST_SUCCESS = "FACTORS.GET_FILTER_LIST_SUCCESS";
export const GET_FILTER_LIST_FAILED = "FACTORS.GET_FILTER_LIST_FAILED";

export const GET_SIZE_LIST_START = "FACTORS.GET_SIZE_LIST_START";
export const GET_SIZE_LIST_SUCCESS = "FACTORS.GET_SIZE_LIST_SUCCESS";
export const GET_SIZE_LIST_FAILED = "FACTORS.GET_SIZE_LIST_FAILED";

export const GET_MTDLG_STYLE_START = "FACTORS.GET_MTDLG_STYLE_START";
export const GET_MTDLG_STYLE_SUCCESS = "FACTORS.GET_MTDLG_STYLE_SUCCESS";
export const GET_MTDLG_STYLE_FAILED = "FACTORS.GET_MTDLG_STYLE_FAILED";

export const GET_BENCHMARKS_STATISTIC_START =
  "FACTORS.GET_BENCHMARKS_STATISTIC_START";
export const GET_BENCHMARKS_STATISTIC_SUCCESS =
  "FACTORS.GET_BENCHMARKS_STATISTIC_SUCCESS";
export const GET_BENCHMARKS_STATISTIC_FAILED =
  "FACTORS.GET_BENCHMARKS_STATISTIC_FAILED";

export const GET_PORTFOLIOS_STATISTIC_START =
  "FACTORS.GET_PORTFOLIOS_STATISTIC_START";
export const GET_PORTFOLIOS_STATISTIC_SUCCESS =
  "FACTORS.GET_PORTFOLIOS_STATISTIC_SUCCESS";
export const GET_PORTFOLIOS_STATISTIC_FAILED =
  "FACTORS.GET_PORTFOLIOS_STATISTIC_FAILED";

export const UPDATE_FACTORS_FILTERS_VALUES_START =
  "FACTORS.UPDATE_FACTORS_FILTERS_VALUES_START";
export const UPDATE_FACTORS_FILTERS_VALUES_SUCCESS =
  "FACTORS.UPDATE_FACTORS_FILTERS_VALUES_SUCCESS";
export const UPDATE_FACTORS_FILTERS_VALUES_FAILED =
  "FACTORS.UPDATE_FACTORS_FILTERS_VALUES_FAILED";

export const CLEAR_PORTFOLIOS_STATISTIC = "FACTORS.CLEAR_PORTFOLIOS_STATISTIC";

export const ADD_BOOKMARKED_PORTFOLIO = "FACTORS.ADD_BOOKMARKED_PORTFOLIO";

export const REMOVE_BOOKMARKED_PORTFOLIO =
  "FACTORS.REMOVE_BOOKMARKED_PORTFOLIO";

export const CLEAR_NEW_STATISTIC = "FACTORS.CLEAR_NEW_STATISTIC";

export const ADD_PORTFOLIO_TO_STATISTIC = "FACTORS.ADD_PORTFOLIO_TO_STATISTIC";

export const REMOVE_ALL_PORTFOLIO_FROM_STATISTIC =
  "FACTORS.REMOVE_ALL_PORTFOLIO_FROM_STATISTIC";
export const REMOVE_PORTFOLIO_FROM_STATISTIC =
  "FACTORS.REMOVE_PORTFOLIO_FROM_STATISTIC";

export const GET_PORTFOLIOS_STRATEGIES_START =
  "FACTORS.GET_PORTFOLIOS_STRATEGIES_START";

export const GET_PORTFOLIOS_STRATEGIES_SUCCESS =
  "FACTORS.GET_PORTFOLIOS_STRATEGIES_SUCCESS";

export const GET_PORTFOLIOS_STRATEGIES_FAILED =
  "FACTORS.GET_PORTFOLIOS_STRATEGIES_FAILED";

export const SET_SELECTED_PORTFOLIO_STRATEGY =
  "FACTORS.SET_SELECTED_PORTFOLIO_STRATEGY";

export const SET_ALL_PORTFOLIOS_TO_STATISTIC =
  "FACTORS.SET_ALL_PORTFOLIOS_TO_STATISTIC";

export const SET_FACTOR_POSITION = "FACTORS.SET_FACTOR_POSITION";

export const SET_FACTOR_ACTIVE_TAB = "FACTORS.SET_FACTOR_ACTIVE_TAB";

export const SET_FACTOR_FILTER_VALUE = "FACTORS.SET_FACTOR_FILTER_VALUE";

export const SET_FACTOR_SUMMARY_PERFOMANCE_GROUP_BY =
  "FACTORS.SET_FACTOR_SUMMARY_PERFOMANCE_GROUP_BY";

export const GET_HISTORY_DATES_START = "FACTORS.GET_HISTORY_DATES_START";

export const GET_HISTORY_DATES_SUCCESS = "FACTORS.GET_HISTORY_DATES_SUCCESS";

export const GET_HISTORY_DATES_FAILED = "FACTORS.GET_HISTORY_DATES_FAILED";

export const SET_HISTORY_DATE = "FACTORS.SET_HISTORY_DATE";
