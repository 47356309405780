import { useLocation } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import Badge from "@mui/material/Badge";
import ListItemText from "@mui/material/ListItemText";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import propTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import { AvatarIcon, BellIcon } from "../../../assets/Icons";
import { pageTitles } from "../../../configs/pageTitles";
import useStyles from "./style";
import TopBarLoader from "./TopBarLoader";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout, resetDefault } from "../../../store/actions/Auth";
import { useDispatch, useSelector } from "react-redux";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FeedbackForm from "./FeedbackForm";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import CachedIcon from "@mui/icons-material/Cached";
import { useMemo } from "react";
import FactorGrouping from "./FactorGrouping";

const TopBar = ({
  isOpen,
  curUser,
  drawerwidth,
  notifyCount,
  onLoginRoute,
  onOpen,
  onMessagesRoute,
}) => {
  const controller = useRef(new AbortController());

  const dispatch = useDispatch();

  const isLoggedIn = useSelector((s) => s.user.isLoggedIn);

  const classes = useStyles({ drawerwidth, isOpen });

  const { pathname } = useLocation();

  const [title, setTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [factorGropingOpenModal, setFactorGropingOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleFactorGropingOpenModal = () => {
    handleCloseMenu();
    setFactorGropingOpenModal(true);
  };
  const handleFactorGropingCloseModal = () => setFactorGropingOpenModal(false);

  const onUserMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function onLogout() {
    dispatch(logout());
    handleCloseMenu();
  }

  // const user = JSON.parse(localStorage.getItem("user"));

  const user = useSelector((state) => state.user.userData);

  function resetDefaultHandle() {
    dispatch(resetDefault(user, controller.current));
  }

  const selectedUniverse = useSelector((s) => s.dashboard.selectedUniverse);
  const selectedFactor = useSelector((s) => s.dashboard.selectedFactor);

  const renderDashboardSelectedItem = useMemo(() => {
    if (selectedUniverse) {
      return `Selected Universe: ${selectedUniverse?.country_id}`;
    } else if (selectedFactor) {
      return `Selected Factor: ${
        selectedFactor?.short_name ||
        selectedFactor?.name ||
        selectedFactor?.methodology_id
      }`;
    } else {
      return "";
    }
  }, [selectedUniverse, selectedFactor]);

  useEffect(() => {
    const route = pathname.split("/");
    setTitle(pageTitles[route[1]]);
  }, [pathname]);

  return (
    <AppBar
      position="fixed"
      drawerwidth={drawerwidth}
      open={isOpen}
      className={classes.topBar}
    >
      <Toolbar style={{ gap: "16px" }}>
        {!isOpen && (
          <IconButton
            aria-label="open drawer"
            onClick={onOpen}
            edge="start"
            className={classes.menuIcon}
          >
            <MenuIcon />
          </IconButton>
        )}
        {isLoggedIn && <div className={classes.pageTitle}>{title}</div>}

        {title === "Dashboard" && (
          <Typography variant="h6" color={"#000"}>
            {renderDashboardSelectedItem}
          </Typography>
        )}

        <TopBarLoader />
        <section className={classes.rightToolbar}>
          <Tooltip
            title="Revert screen to default setting"
            arrow
            placement="bottom"
          >
            <ListItem
              button
              className={classes.listItem}
              onClick={resetDefaultHandle}
            >
              <CachedIcon sx={{ fill: "#25292e !important" }} />
            </ListItem>
          </Tooltip>
          {isLoggedIn && (
            <ListItem
              button
              className={classes.listItem}
              onClick={handleOpenModal}
            >
              <ChatOutlinedIcon sx={{ fill: "#25292e !important" }} />
              <span className={classes.feedback}></span>
            </ListItem>
          )}
          {/* <ListItem button key="msg" className={classes.listItem}>
            <Badge
              badgeContent={notifyCount}
              className={classes.notifyIcon}
              onClick={onMessagesRoute}
            >
              <BellIcon />
            </Badge>
          </ListItem> */}
          <Tooltip title="User sing-in" arrow placement="bottom">
            <ListItem
              button
              key="login"
              onClick={curUser.user ? onUserMenuClick : onLoginRoute}
              className={classes.listItem}
            >
              <Badge className={classes.userIcon}>
                <AvatarIcon />
              </Badge>
              <ListItemText
                className={classes.useText}
                primary={curUser.user ? curUser.user : "Login"}
              />
            </ListItem>
          </Tooltip>
        </section>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleFactorGropingOpenModal}>
          <PermDataSettingIcon fontSize={"10px"} style={{ margin: "0 5px" }} />
          Manage Factor Grouping
        </MenuItem>

        <MenuItem onClick={onLogout}>
          <LogoutIcon fontSize={"10px"} style={{ margin: "0 5px" }} /> Logout
        </MenuItem>
      </Menu>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box className={classes.modal}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleCloseModal}>
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Typography
              align="center"
              variant="h6"
              component="h2"
              sx={{ mb: 1 }}
            >
              Feedback
            </Typography>
            <FeedbackForm />
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={factorGropingOpenModal}
        onClose={handleFactorGropingCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={factorGropingOpenModal}>
          <Box className={classes.modal}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleFactorGropingCloseModal}>
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Typography
              align="center"
              variant="h6"
              component="h2"
              sx={{ mb: 1 }}
            >
              Factor Grouping Manager
            </Typography>
            <FactorGrouping onClose={handleFactorGropingCloseModal} />
          </Box>
        </Fade>
      </Modal>
    </AppBar>
  );
};

TopBar.defaultProps = {
  notifyCount: 0,
};

TopBar.propTypes = {
  onOpen: propTypes.func.isRequired,
  onLoginRoute: propTypes.func.isRequired,
  onMessagesRoute: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  drawerwidth: propTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  curUser: propTypes.object.isRequired,
  notifyCount: propTypes.number,
};

export default TopBar;
