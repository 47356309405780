import DashboardApi from "../../services/Dashboard";
import * as DashboardTypes from "../types/Dashboard";
import { generateErrorMessage } from "../../helpers/Error";
import { batch } from "react-redux";
import SnackBarUtils from "../../helpers/SnackBarUtils";
import { SelectedPreset } from "../../helpers/Dashboard";

export const getAllUniverses = (controller) => async (dispatch, getState) => {
  dispatch({ type: DashboardTypes.GET_ALL_UNIVERSES_START });
  return DashboardApi.getAllUniverses(controller)
    .then((data) => {
      dispatch({
        type: DashboardTypes.GET_ALL_UNIVERSES_SUCCESS,
        payload: data,
      });

      const selectedUniverse = getState().dashboard.selectedUniverse;

      if (!selectedUniverse) {
        const defaultPreset = getState().dashboard.defaultPreset;

        const defaultUniverse = data.find(
          (universe) =>
            universe.country_id === defaultPreset?.default_universe ||
            universe.country_id === "United States"
        );
        if (defaultUniverse) {
          dispatch(setUniverse(defaultUniverse));
        }
      }
    })
    .catch((error) => {
      dispatch({ type: DashboardTypes.GET_ALL_UNIVERSES_FAILED });
      generateErrorMessage(error);
    });
};

export const getDashboardBenchmarks = (controller) => async (dispatch) => {
  dispatch({ type: DashboardTypes.GET_DASHBOARD_BENCHMARKS_START });
  return DashboardApi.getDashboardBenchmarks(controller)
    .then((data) => {
      dispatch({
        type: DashboardTypes.GET_DASHBOARD_BENCHMARKS_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: DashboardTypes.GET_DASHBOARD_BENCHMARKS_FAILED });
      generateErrorMessage(error);
    });
};

export const getDashboardActiveStrategies =
  (controller) => async (dispatch) => {
    dispatch({ type: DashboardTypes.GET_DASHBOARD_ACTIVE_STRATEGIES_START });
    return DashboardApi.getDashboardActiveStrategies(controller)
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_ACTIVE_STRATEGIES_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_ACTIVE_STRATEGIES_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getDashboardPortfolios =
  (params, controller) => async (dispatch) => {
    dispatch({ type: DashboardTypes.GET_DASHBOARD_PORTFOLIOS_START });
    return DashboardApi.getDashboardPortfolios(params, controller)
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_PORTFOLIOS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_PORTFOLIOS_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getDashboardAllStats =
  (data, controller) => async (dispatch, getState) => {
    dispatch({ type: DashboardTypes.GET_DASHBOARD_ALL_STATS_START });
    const { user } = getState();
    return DashboardApi.getDashboardAllStats(
      data,
      controller,
      user.userData.access_token
    )
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_ALL_STATS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_ALL_STATS_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getDashboardStyleReturn =
  (params, controller) => async (dispatch, getState) => {
    dispatch({ type: DashboardTypes.GET_DASHBOARD_STYLE_RETURN_START });
    const { user } = getState();
    return DashboardApi.getDashboardStyleReturn(
      params,
      controller,
      user.userData.access_token
    )
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_STYLE_RETURN_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_STYLE_RETURN_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getDashboardComposition =
  (params, controller) => async (dispatch, getState) => {
    dispatch({ type: DashboardTypes.GET_DASHBOARD_COMPOSITION_START });
    const { user } = getState();
    return DashboardApi.getDashboardComposition(
      params,
      controller,
      user.userData.access_token
    )
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_COMPOSITION_SUCCESS,
          payload: { data, name: params.name },
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_COMPOSITION_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getDashboardMethodologyInfo =
  (params, controller) => async (dispatch) => {
    batch(() => {
      dispatch({
        type: DashboardTypes.GET_DASHBOARD_METHODOLOGY_INFO_CLEAR,
      });
      dispatch({
        type: DashboardTypes.GET_DASHBOARD_METHODOLOGY_INFO_START,
      });
    });
    return DashboardApi.getDashboardMethodologyInfo(params, controller)
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_METHODOLOGY_INFO_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_METHODOLOGY_INFO_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const setReturnPeriod = (period) => (dispatch) => {
  dispatch({
    type: DashboardTypes.SET_RETURN_PERIOD,
    payload: period,
  });
};

export const setUniverse = (universe) => (dispatch) => {
  dispatch({
    type: DashboardTypes.SET_UNIVERSE,
    payload: universe,
  });
};

export const setFactor = (factor) => (dispatch) => {
  dispatch({
    type: DashboardTypes.SET_FACTOR,
    payload: factor,
  });
};

export const setReturnToVolatilityPeriod = (period) => (dispatch) => {
  dispatch({
    type: DashboardTypes.SET_RETURN_TO_VOLATILITY_PERIOD,
    payload: period,
  });
};

export const resetEditableDashboardPreset = () => (dispatch) => {
  dispatch({
    type: DashboardTypes.RESET_EDITABLE_DASHBOARD_PRESET,
  });
};

export const setSelectedPreset = (preset) => (dispatch) => {
  dispatch({
    type: DashboardTypes.SET_SELECTED_PRESET,
    payload: preset,
  });
};

export const setMethodologyInformation =
  (name, country, assetId) => (dispatch) => {
    dispatch({
      type: DashboardTypes.SET_METHODOLOGY_INFORMATION,
      payload: {
        name,
        country,
        assetId,
      },
    });
  };

export const updateDefaultDashboardPreset =
  (preset) => async (dispatch, getState) => {
    const { dashboard } = getState();

    const user = JSON.parse(localStorage.getItem("user"));

    if (preset.default) {
      const newPreset = {
        ...preset,
        stats: preset.displayed_statistics,
      };

      delete newPreset.displayed_statistics;
      delete newPreset.asset_list;

      localStorage.setItem(
        "user",
        JSON.stringify({ ...user, preset: newPreset })
      );
      dispatch({
        type: DashboardTypes.UPDATE_DEFAULT_DASHBOARD_PRESET,
        payload: preset,
      });
    } else if (preset.preset_id === dashboard.defaultPreset?.preset_id) {
      localStorage.setItem("user", JSON.stringify({ ...user, preset: null }));
      dispatch({
        type: DashboardTypes.UPDATE_DEFAULT_DASHBOARD_PRESET,
        payload: null,
      });
    }
  };

export const getDashboardReturnToVolatility =
  (params, controller) => async (dispatch, getState) => {
    dispatch({
      type: DashboardTypes.GET_DASHBOARD_RETURN_TO_VOLATILITY_START,
    });
    const { user } = getState();
    return DashboardApi.getDashboardReturnToVolatility(
      params,
      controller,
      user.userData.access_token
    )
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_RETURN_TO_VOLATILITY_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_RETURN_TO_VOLATILITY_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getMethodologyReturnToVolatility =
  (params, controller) => async (dispatch) => {
    dispatch({
      type: DashboardTypes.METHODOLOGY_RETURN_TO_VOLATILITY_START,
    });
    return DashboardApi.getMethodologyReturnToVolatility(params, controller)
      .then((data) => {
        dispatch({
          type: DashboardTypes.METHODOLOGY_RETURN_TO_VOLATILITY_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.METHODOLOGY_RETURN_TO_VOLATILITY_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getDashboardBenchmarkTitles = (controller) => async (dispatch) => {
  dispatch({
    type: DashboardTypes.GET_DASHBOARD_BENCHMARK_TITLES_START,
  });
  return DashboardApi.getDashboardBenchmarkTitles(controller)
    .then((data) => {
      dispatch({
        type: DashboardTypes.GET_DASHBOARD_BENCHMARK_TITLES_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: DashboardTypes.GET_DASHBOARD_BENCHMARK_TITLES_FAILED,
      });
      generateErrorMessage(error);
    });
};

export const getDashboardStrategiesTitles =
  (controller) => async (dispatch, getState) => {
    dispatch({
      type: DashboardTypes.GET_DASHBOARD_STRATEGIES_TITLES_START,
    });
    const { user } = getState();

    return DashboardApi.getDashboardStrategiesTitles(
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_STRATEGIES_TITLES_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_STRATEGIES_TITLES_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getDashboardAllPortfolios =
  (universe, controller) => async (dispatch) => {
    dispatch({
      type: DashboardTypes.GET_DASHBOARD_ALL_PORTFOLIOS_START,
    });
    return DashboardApi.getDashboardAllPortfolios(universe, controller)
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_ALL_PORTFOLIOS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_ALL_PORTFOLIOS_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const getDashboardPresetTitles =
  (controller) => async (dispatch, getState) => {
    dispatch({
      type: DashboardTypes.GET_DASHBOARD_PRESET_TITLES_START,
    });
    const { user } = getState();
    return DashboardApi.getDashboardPresetTitles(
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_PRESET_TITLES_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_PRESET_TITLES_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const saveDashboardPreset =
  (presetData, controller, callback) => async (dispatch, getState) => {
    dispatch({
      type: DashboardTypes.SAVE_DASHBOARD_PRESET_START,
    });

    const { user } = getState();

    return DashboardApi.saveDashboardPreset(
      presetData,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        batch(() => {
          dispatch({
            type: DashboardTypes.SAVE_DASHBOARD_PRESET_SUCCESS,
            payload: data,
          });

          const preset = {
            ...data,
            stats: data.displayed_statistics,
          };

          delete preset.displayed_statistics;
          dispatch(updateDefaultDashboardPreset(data));
          dispatch(setSelectedPreset(preset));
          dispatch(loadDashboardPresetStats(data.preset_id));
          callback();
        });

        SnackBarUtils.success("Preset was successfully saved");
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.SAVE_DASHBOARD_PRESET_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const updateSelectedDashboardPreset =
  (preset) => async (dispatch, getState) => {
    const { dashboard } = getState();

    if (preset.preset_id === dashboard.selectedPreset.id) {
      const newPreset = new SelectedPreset(
        preset.preset_id,
        preset.name,
        preset.default,
        preset.date,
        preset.default_factors_return,
        preset.default_style_return,
        preset.default_universe,
        preset.displayed_statistics
      );

      batch(() => {
        dispatch(setSelectedPreset(newPreset));
        dispatch(loadDashboardPresetStats(newPreset.id));
      });
    }
  };

export const updateDashboardPreset =
  (presetData, controller, callback) => async (dispatch, getState) => {
    dispatch({
      type: DashboardTypes.UPDATE_DASHBOARD_PRESET_START,
    });
    const { user } = getState();

    return DashboardApi.updateDashboardPreset(
      presetData,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        batch(() => {
          dispatch({
            type: DashboardTypes.UPDATE_DASHBOARD_PRESET_SUCCESS,
            payload: data,
          });
          dispatch(updateDefaultDashboardPreset(data));
          dispatch(updateSelectedDashboardPreset(data));
          callback();
        });

        SnackBarUtils.success("Preset was successfully updated");
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.UPDATE_DASHBOARD_PRESET_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const loadDashboardPresetStats =
  (id, controller) => async (dispatch, getState) => {
    dispatch({
      type: DashboardTypes.LOAD_DASHBOARD_PRESET_STATS_START,
    });
    const { user } = getState();
    return DashboardApi.loadDashboardPresetStats(
      id,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: DashboardTypes.LOAD_DASHBOARD_PRESET_STATS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.LOAD_DASHBOARD_PRESET_STATS_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const loadEditableDashboardPreset =
  (preset_id, controller) => async (dispatch) => {
    dispatch({
      type: DashboardTypes.LOAD_EDITABLE_DASHBOARD_PRESET_START,
    });
    return DashboardApi.loadDashboardPreset(preset_id, controller)
      .then((data) => {
        dispatch({
          type: DashboardTypes.LOAD_EDITABLE_DASHBOARD_PRESET_SUCCESS,
          payload: { ...data, preset_id },
        });
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.LOAD_EDITABLE_DASHBOARD_PRESET_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const deleteDashboardPreset =
  (id, controller) => async (dispatch, getState) => {
    dispatch({
      type: DashboardTypes.DELETE_DASHBOARD_PRESET_START,
    });

    const { user } = getState();

    return DashboardApi.deleteDashboardPreset(
      id,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        batch(() => {
          dispatch({
            type: DashboardTypes.DELETE_DASHBOARD_PRESET_SUCCESS,
            payload: { id },
          });
          dispatch(updateDefaultDashboardPreset({ preset_id: id }));
        });

        SnackBarUtils.success(data.status);
      })
      .catch((error) => {
        dispatch({
          type: DashboardTypes.DELETE_DASHBOARD_PRESET_FAILED,
        });
        generateErrorMessage(error);
      });
  };
