import { applyMiddleware, createStore, compose } from "redux";
import reducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

const composeEnhancers = composeWithDevTools({});

const middlewares = [thunk];

if (process.env.NODE_ENV === "development") {
  const logger = createLogger({ collapsed: true });
  middlewares.push(logger);
}

const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(reducer, composeEnhancers(compose(...enhancers)));

export default store;
