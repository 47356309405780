import { mainUrl, mainUrl as url } from "../config";
import axios from "axios";
import { WIN_RATE_KEYS } from "../helpers/Utils";

const assets = async (prm, token) => {
  return axios
    .post(
      `${url.API_URL}/api/portfolio/assets`,
      { params: prm },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const assetsShortList = async (token) => {
  return axios
    .get(`${url.API_URL}/api/portfolio/short_asset_list`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const getWinRate = async (key, prm) => {
  if (key === WIN_RATE_KEYS.testing) {
    axios
      .post(`${mainUrl.API_URL}/api/factor_testing/win_rate_table`, prm)
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  }

  return axios
    .post(`${url.API_URL}/api/portfolio/win_rate_table_update`, prm)
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const assetById = async (assetId, typeId) => {
  return axios
    .post(`${url.API_URL}/api/portfolio/asset/by_id`, {
      asset_id: assetId,
      type_id: typeId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const getPortfolioAttributes = async (params) => {
  return axios
    .post(`${url.API_URL}/api/portfolio/portfolio_attributes`, params)
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const portfolioCalculation = async (data) => {
  return axios
    .post(`${url.API_URL}/api/portfolio/calculation`, data)
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const updateBetaTable = async (
  startDate,
  endDate,
  strategyReturnsList,
  benchmark
) => {
  return axios
    .post(`${url.API_URL}/api/portfolio/regression_table_update`, {
      start_date: startDate,
      end_date: endDate,
      strategy_returns_list: strategyReturnsList,
      benchmark_id: benchmark,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const getStrategy = async (strategyId, token) => {
  if (token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios
      .get(`${url.API_URL}/api/portfolio/private_user/${strategyId}`, config)
      .then((response) => {
        return response.data;
      })
      .catch((exception) => {
        throw exception;
      });
  }
  return axios
    .get(`${url.API_URL}/api/portfolio/public_user/${strategyId}`)
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });
};

const saveStrategy = async (
  strategyId,
  token,
  name,
  description,
  activeStrategy,
  publicStrategy,
  strategyData,
  strategyType,
  nestedStrategy
) => {
  if (token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      name: name,
      public: publicStrategy,
      status_id: activeStrategy ? 2 : 1,
      description: description,
      strategy: strategyData,
      type: strategyType,
      nested: nestedStrategy,
    };

    return axios
      .post(`${url.API_URL}/api/portfolio/save`, bodyParameters, config)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }
};

const getDataForOnEdit = async (
  country,
  filter,
  direction,
  size,
  freq,
  currency,
  methodology
) =>
  axios
    .post(`${mainUrl.API_URL}/api/portfolio/portfolio_attributes`, {
      country: country,
      filter: filter,
      direction: direction,
      size: size,
      freq: freq,
      currency: currency,
      methodology: methodology,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getBeta = async (startDate, endDate, name, returns, benchmarkId, years) =>
  axios
    .post(`${mainUrl.API_URL}/api/portfolio/rolling_beta_chart_update`, {
      start_date: startDate,
      end_date: endDate,
      strategy_name: name,
      strategy_returns: returns,
      benchmark_id: benchmarkId,
      n_years_window: years,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getCorellation = async (
  startDate,
  endDate,
  name,
  returns,
  benchmarkId,
  years
) =>
  axios
    .post(`${mainUrl.API_URL}/api/portfolio/rolling_correlation_chart_update`, {
      start_date: startDate,
      end_date: endDate,
      strategy_name: name,
      strategy_returns: returns,
      benchmark_id: benchmarkId,
      n_years_window: years,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getRollingReturns = async (prm) =>
  axios
    .post(`${mainUrl.API_URL}/api/portfolio/rolling_returns_chart_update`, prm)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStocks = async (assetIds, token) => {
  if (token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      asset_ids: [...assetIds],
    };

    return axios
      .post(`${url.API_URL}/api/portfolio/stocks`, bodyParameters, config)
      .then((response) => {
        return response.data;
      })
      .catch((exception) => {
        throw exception;
      });
  }
};

const getPublicTitles = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/portfolio/public_titles`, {
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getTitles = async (token, controller) => {
  if (token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      signal: controller?.signal,
    };
    return axios
      .get(`${mainUrl.API_URL}/api/portfolio/titles`, config)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }
};

const deleteStrategy = async (strategy_id, token) => {
  if (token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      strategy_id,
    };

    return axios
      .delete(
        `${url.API_URL}/api/portfolio/${strategy_id}`,
        config,
        bodyParameters
      )
      .then((response) => {
        return response.data;
      })
      .catch((exception) => {
        throw exception;
      });
  }
};

const getWorkingGroups = async (token, user_id) =>
  axios
    .get(`${mainUrl.API_URL}/api/portfolio/work_group/${user_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStrategyTitlesByUserId = async (user_id, token) => {
  if (token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { user_id },
    };
    return axios
      .get(`${mainUrl.API_URL}/api/portfolio/titles_by_user`, config)
      .then((response) => {
        return JSON.parse(response.data);
      })
      .catch((err) => {
        throw err;
      });
  }
};

const addStrategyToFavorites = async (portfolio_id, user_id, access_token) => {
  if (access_token) {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const bodyParameters = {
      user_id,
      portfolio_id,
    };

    return axios
      .post(
        `${mainUrl.API_URL}/api/portfolio/add_to_favorites`,
        bodyParameters,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }
};

const getAssetInfo = async (data, controller) => {
  return axios
    .post(`${mainUrl.API_URL}/api/portfolio/information`, data, {
      signal: controller?.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

const removeStrategyFromFavorites = async (
  portfolio_id,
  user_id,
  access_token
) => {
  if (access_token) {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    return axios
      .delete(
        `${mainUrl.API_URL}/api/portfolio/remove_from_favorites/${user_id}/${portfolio_id}`,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }
};

const portfolioRequest = {
  assets,
  assetsShortList,
  assetById,
  portfolioCalculation,
  getStrategy,
  saveStrategy,
  getWinRate,
  getDataForOnEdit,
  getRollingReturns,
  getCorellation,
  updateBetaTable,
  getBeta,
  getStocks,
  getPublicTitles,
  getTitles,
  deleteStrategy,
  getWorkingGroups,
  getStrategyTitlesByUserId,
  addStrategyToFavorites,
  removeStrategyFromFavorites,
  getAssetInfo,
  getPortfolioAttributes,
};

export default portfolioRequest;
