export const GroupByOptionsContributionAnalysis = [
  { value: "style", label: "Style" },
  { value: "country", label: "Country" },
  { value: "factor", label: "Factor(Methodology)" },
];

export const GroupByOptions = ["Style", "Country", "Factor(Methodology)"];

export const PositionOptions = [
  { label: "Strategy Components", value: false },
  { label: "Component Minus Index", value: true },
];

export const TableFiltersLabels = {
  periodReturn_day: "Return (%)",
  periodReturn_month: "Return (%)",
  periodReturn_halfYear: "Return (%)",
  periodReturn_ytd: "Return (%)",
  annualReturn_year: "Ann. Ret. (%)",
  volatility_year: "Ann. Std. (%)",
  sharpRatio_year: "Sharpe",
  annualReturn_3years: "Ann. Ret. (%)",
  volatility_3years: "Ann. Std. (%)",
  drawDown_3years: "DD12M (%)",
  sharpRatio_3years: "Sharpe",
  annualReturn_5years: "Ann. Ret. (%)",
  volatility_5years: "Ann. Std. (%)",
  drawDown_5years: "DD12M (%)",
  sharp_5years: "Sharpe",
  annualReturn_since2010: "Ann. Ret. (%)",
  volatility_since2010: "Ann. Std. (%)",
  drawDown_since2010: "DD12M (%)",
  sharpRatio_since2010: "Sharpe",
  annualReturn_10years: "Ann. Ret. (%)",
  volatility_10years: "Ann. Std. (%)",
  drawDown_10years: "DD12M (%)",
  sharpRatio_10years: "Sharpe",
  annualReturn_15years: "Ann. Ret. (%)",
  volatility_15years: "Ann. Std. (%)",
  drawDown_15years: "DD12M (%)",
  sharpRatio_15years: "Sharpe",
  annualReturn_full: "Ann. Ret. (%)",
  volatility_full: "Ann. Std. (%)",
  drawDown_full: "DD12M (%)",
  sharpRatio_full: "Sharpe",
  beta_3years: "beta",
  beta_std_3years: "beta std",
  alpha_3years: "alpha",
  p_value_3years: "p value",
  t_stat_3years: "t stat",
  beta_plus_3years: "beta plus",
  beta_minus_3years: "beta minus",
  jensen_alpha_3years: "jensen alpha",
  jensen_sharpe_3years: "jensen_sharpe",
  beta_5years: "beta",
  beta_std_5years: "beta std",
  alpha_5years: "alpha",
  p_value_5years: "p value",
  t_stat_5years: "t stat",
  beta_plus_5years: "beta plus",
  beta_minus_5years: "beta minus",
  jensen_alpha_5years: "jensen alpha",
  jensen_sharpe_5years: "jensen_sharpe",
  beta_since2010: "beta",
  beta_std_since2010: "beta std010",
  alpha_since2010: "alpha",
  p_value_since2010: "p value",
  t_stat_since2010: "t stat",
  beta_plus_since2010: "beta plus",
  beta_minus_since2010: "beta minus",
  jensen_alpha_since2010: "jensen alpha",
  jensen_sharpe_since2010: "jensen_sharpe",
  beta_10years: "beta",
  beta_std_10years: "beta std",
  alpha_10years: "alpha",
  p_value_10years: "p value",
  t_stat_10years: "t stat",
  beta_plus_10years: "beta plus",
  beta_minus_10years: "beta minus",
  jensen_alpha_10years: "jensen alpha",
  jensen_sharpe_10years: "jensen_sharpe",
  beta_15years: "beta",
  beta_std_15years: "beta std",
  alpha_15years: "alpha",
  p_value_15years: "p value",
  t_stat_15years: "t stat",
  beta_plus_15years: "beta plus",
  beta_minus_15years: "beta minus",
  jensen_alpha_15years: "jensen alpha",
  jensen_sharpe_15years: "jensen_sharpe",
  beta_full: "beta",
  beta_std_full: "beta std",
  alpha_full: "alpha",
  p_value_full: "p value",
  t_stat_full: "t stat",
  beta_plus_full: "beta plus",
  beta_minus_full: "beta minus",
  jensen_alpha_full: "jensen alpha",
  jensen_sharpe_ful: "jensen_sharpe",
};

export const StrategyGroups = {
  active: "Active",
  MF: "MF",
  Test: "Test",
  SmartBeta: "SmartBeta",
  RPS: "RPS",
  HedgFund: "HedgFund",
  Etf: "Etf",
  Indices: "Indices",
  "Managed portfolios": "Managed portfolios",
  General: "General",
};

export const templateGroupSortArr = ["RPS", "MF", "SmartBeta", "Test"];

export const VIEWS_VALUES = [
  { value: "0", alias: "Factor" },
  { value: "1", alias: "Factor-Index" },
];

export const businessCycleTableGroups = {
  early: {
    id: "early",
    children: [
      { id: "early&AnnRet", sortable: true, label: "AnnRet" },
      { id: "early&Avg_Monthly_Ret", sortable: true, label: "Monthly" },
      { id: "early&DD_Max", sortable: true, label: "Max DD" },
      { id: "early&beta", sortable: true, label: "Beta" },
      { id: "early&sharpe", sortable: true, label: "sharpe" },
    ],
    label: "Early (Rebounds)",
  },

  middle: {
    id: "middle",
    children: [
      { id: "middle&AnnRet", sortable: true, label: "AnnRet" },
      { id: "middle&Avg_Monthly_Ret", sortable: true, label: "Monthly" },
      { id: "middle&DD_Max", sortable: true, label: "Max DD" },
      { id: "middle&beta", sortable: true, label: "Beta" },
      { id: "middle&sharpe", sortable: true, label: "sharpe" },
    ],
    label: "Mid (Peaks)",
  },

  late: {
    id: "late",
    children: [
      { id: "late&AnnRet", sortable: true, label: "AnnRet" },
      { id: "late&Avg_Monthly_Ret", sortable: true, label: "Monthly" },
      { id: "late&DD_Max", sortable: true, label: "Max DD" },
      { id: "late&beta", sortable: true, label: "Beta" },
      { id: "late&sharpe", sortable: true, label: "sharpe" },
    ],
    label: "Late (Moderates)",
  },

  recession: {
    id: "recession",
    children: [
      { id: "recession&AnnRet", sortable: true, label: "AnnRet" },
      {
        id: "recession&Avg_Monthly_Ret",
        sortable: true,
        label: "Monthly",
      },
      { id: "recession&DD_Max", sortable: true, label: "Max DD" },
      { id: "recession&beta", sortable: true, label: "Beta" },
      { id: "recession&sharpe", sortable: true, label: "sharpe" },
    ],
    label: "Recession (Contracts)",
  },
};

export const EarlyValues = [
  "early&AnnRet",
  "early&Avg_Monthly_Ret",
  "early&DD_Max",
  "early&beta",
  "early&sharpe",
];

export const MidValues = [
  "middle&AnnRet",
  "middle&Avg_Monthly_Ret",
  "middle&DD_Max",
  "middle&beta",
  "middle&sharpe",
];

export const LateValues = [
  "late&AnnRet",
  "late&Avg_Monthly_Ret",
  "late&DD_Max",
  "late&beta",
  "late&sharpe",
];

export const RecessionValues = [
  "recession&AnnRet",
  "recession&Avg_Monthly_Ret",
  "recession&DD_Max",
  "recession&beta",
  "recession&sharpe",
];

export const templateBusinessCycleTableFiltersSortArr = [
  "early&AnnRet",
  "early&Avg_Monthly_Ret",
  "early&DD_Max",
  "early&beta",
  "early&sharpe",
  "middle&AnnRet",
  "middle&Avg_Monthly_Ret",
  "middle&DD_Max",
  "middle&beta",
  "middle&sharpe",
  "late&AnnRet",
  "late&Avg_Monthly_Ret",
  "late&DD_Max",
  "late&beta",
  "late&sharpe",
  "recession&AnnRet",
  "recession&Avg_Monthly_Ret",
  "recession&DD_Max",
  "recession&beta",
  "recession&sharpe",
];

export const MacroAndFactorInfluencesTableHeaders = [
  {
    label: "US CPI MoM",
    value: "cpi_mom",
    description: "Consumer Price Index USA, calculated month-on-month",
  },
  {
    label: "US 10Y Yields",
    value: "us_10_treasuries",
    description: "10-year yield on US government bonds",
  },
  {
    label: "US GDP Growth",
    value: "Monthly Real GDP Index",
    description: "Monthly growth in US Real Gross Domestic Product",
  },
  {
    label: "US 2Y Yields",
    value: "us_2y_treasuries",
    description: "2-year yield on US government bonds",
  },
  {
    label: "10s 2s",
    value: "us_10s2s_treasuries",
    description: "10-year yield minus 2-year yield on US government bonds",
  },
  {
    label: "Value/Growth",
    value: "value-growth",
    description:
      "Return of  value stock portfolios  minus the return of growth stock portfolios",
  },
  {
    label: "Corporate/ Government Bonds",
    value: "corp-gov",
    description: "Yield on corporate bonds minus the yield on government bonds",
  },
  {
    label: "Junk/AA",
    value: "junk-aaa",
    description: "Yield on junk bonds minus the yield on triple A bonds",
  },
  {
    label: "Rm-Rf",
    value: "mkt-rf",
    description: "The market premium ( market return minus risk-free rate)",
  },
  {
    label: "SMB",
    value: "smb",
    description:
      "Small Minus Big - Average return of small-stock portfolios minus the average return of big-stock portfolios. One of the Fama-French five factors",
  },
  {
    label: "HML",
    value: "hml",
    description:
      "High Minus Low - Average return of value portfolios minus the average return of growth portfolios. One of the Fama-French five factors",
  },
  {
    label: "RMW",
    value: "rmw",
    description:
      "Robust Minus Weak - Average return of robust operating profitability portfolios minus the average return of weak operating proftability portfolios",
  },
  {
    label: "CMA",
    value: "cma",
    description:
      "Conservative Minus Aggressive - Average return of conservative investment portfolios minus the average return of aggressive investment portoflios. One of the Fama-French five factors",
  },
  {
    label: "Crude Oil",
    value: "CL=F",
    description: "Return on Crude Oil ",
  },
  {
    label: "Gold",
    value: "GC=F",
    description: "Return on Gold",
  },
  {
    label: "USD/ILS",
    value: "ILS=X",
    description:
      "The exchange rate between the US Dollar and the New Israeli Shekel",
  },
  {
    label: "DXY",
    value: "DX-Y.NYB",
    description:
      "The US Dollar Index. A composite meaure of the strength of the US Dollar against a basket of major foreign currencies",
  },
  {
    label: "Silver",
    value: "SI=F",
    description: "Return on Silver",
  },
];

export const SIGNIFICANCE_SCHEMA = [
  {
    color: "#ffffff",
    label: "",
    tooltip: "[infnity, 0,1)",
  },

  {
    color: "#d4e9ec",
    label: "*",
    tooltip: "[10%, 5%)",
  },

  {
    color: "#6eafb8",
    label: "**",
    tooltip: "[5%, 1%)",
  },

  {
    color: "#21686f",
    label: "***",
    tooltip: "[1%, 0]",
  },
  {
    color: "#ffacc4",
    label: "Out ranges",
    tooltip: "(0, -infinity]",
  },
];

export const ConsistencyAnalyticsHeaderTable = [
  {
    label: "Max DD",
    value: "max_dds",
  },
  {
    label: "Beta to Strategy",
    value: "betas_to_strategy",
  },
  {
    label: "Beta to home Market",
    value: "betas_to_benchmark",
  },
  {
    label: "% of period underwater",
    value: "underwater",
  },
  {
    label: "Contribution to Return",
    value: "return_contribution",
  },
  {
    label: "Contribution to STD",
    value: "std_contribution",
  },
  {
    label: "Contribution to Sharpe",
    value: "sharpe_contribution",
  },
];

export const ConsistencyAnalyticsDatesOptions = [
  { value: "dates", alias: "Select dates" },
  { value: "scenario", alias: "Select Scenario" },
];

export const ConsistencyAnalyticsCorrelationMatrixOptions = [
  { value: "corellation_selected_dates", alias: "During Period" },
  { value: "corellation_record_start", alias: "Before Period" },
  { value: "correlation_delta", alias: "Correlation Delta" },
];

export const CorrelationMatrixFilterOptionsVals = [
  { value: "corellation_less", alias: "Correlation < (- 0.50)" },
  { value: "corellation_more", alias: "Correlation > (+ 0.50)" },
  { value: "corellation_less_zero", alias: "Corellation < 0" },
  { value: "corellation_more_zero", alias: "Corellation > 0" },
];

export const CorrelationMatrixFilterOptionsGroup = [
  { value: "corellation_countries", alias: "Countries" },
  { value: "corellation_family", alias: "Family" },
  { value: "corellation_factor", alias: "Factor" },
];

export const MatrixValueSteps = [
  "1.0",
  "0.8",
  "0.6",
  "0.4",
  "0.2",
  "0.0",
  "-0.2",
  "-0.4",
  "-0.6",
  "-0.8",
  "-1.0",
];

export const CorrelationMatrixViewBoxSquare = 220;
