import * as Yup from "yup";

export const SignUpValidation = Yup.object({
  username: Yup.string().required("Username is a required field"),
  lastname: Yup.string().required("Last name is a required field"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Email is not valid"),
  password: Yup.string()
    .required("Password is a required field")
    .min(6, "Password must have at least 6 characters")
    .notOneOf(
      [Yup.ref("email"), Yup.ref("username")],
      "Password must be not equals to username or email"
    ),
});

export const SignInValidation = Yup.object({
  email: Yup.string()
    .required("Email is a required field")
    .email("Email is not valid"),
  password: Yup.string().required("Password is a required field"),
});

export const RestorePasswordValidation = Yup.object({
  email: Yup.string()
    .required("Email is a required field")
    .email("Email is not valid"),
});
