import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_MESSAGE,
  RESET_DEFAULT,
} from "./Type";
import { resetId } from "react-id-generator";
import { generateErrorMessage } from "../../helpers/Error";
import AuthService from "../../services/Auth.js";
import jwt from "jwt-decode";
import { batch } from "react-redux";
import SnackBarUtils from "../../helpers/SnackBarUtils";
import { reInitializationWithDefaultPortfolios } from "./Portfolio";
import { resetStock } from "./Stock";

export const loginAsGuest= () => async (dispatch) => {
  return AuthService.guestLogin()
    .then((data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    })
    .catch((error) => {
      batch(() => {
        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: generateErrorMessage(error),
        });
      });
      return Promise.reject();
    });
};

export const login = (mail, password) => async (dispatch) => {
  return AuthService.login(mail, password)
    .then((data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    })
    .catch((error) => {
      batch(() => {
        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: generateErrorMessage(error),
        });
      });
      return Promise.reject();
    });
};

export const register =
  (username, lastname, email, password) => async (dispatch) => {
    return AuthService.register(username, lastname, email, password)
      .then((responce) => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: jwt(responce["access_token"]),
        });

        return Promise.resolve();
      })
      .catch((error) => {
        batch(() => {
          dispatch({
            type: REGISTER_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: generateErrorMessage(error),
          });
        });
        return Promise.reject();
      });
  };

export const logout = () => (dispatch) => {
  AuthService.logout();
  resetId();
  batch(() => {
    dispatch({
      type: LOGOUT,
    });
  });
};

export const authVerify = () => (dispatch) => {
  if (!AuthService.authVerify()) {
    dispatch(logout());
  }
};

export const resetDefault = (user, controller) => (dispatch) => {
  dispatch({
    type: RESET_DEFAULT,
    payload: user,
  });
  dispatch(resetStock(controller));
  dispatch(reInitializationWithDefaultPortfolios(user));
  SnackBarUtils.success("Reseted to default");
};
