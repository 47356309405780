import axios from "axios";
import { mainUrl } from "../config";

const getStockScreenerUniverses = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/universes`, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockScreenerCountries = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/countries`, params, {
      signal: controller.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getStockScreenerEconomies = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/economies`, params, {
      signal: controller.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getStockScreenerSectors = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/sectors`, params, {
      signal: controller.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getDefaultScreen = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/screen`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((exception) => {
      throw exception;
    });

const getStockScreenerVariables = async (token, controller) =>
  axios
    .get(
      `${mainUrl.API_URL}/variables`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const updateStockDefaultVariables = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/edit_stock_screening_columns_by_user`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const stockValueToPersentile = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/value_to_persentile`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const stockPersentileToValue = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/persentile_to_value`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockScreenerUserSettings = async (token, controller) =>
  axios
    .get(
      `${mainUrl.API_URL}/stock_screening_settings_by_user`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const updateStockScreenerUserSettings = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/update_stock_screening_settings_by_user`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const createStockScreenerMultifactor = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/multifactor`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const createStockScreenerRatio = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/ratio`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const createStockScreenerBacktest = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/backtest`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockInfo = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/get_stock_info`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockValuation = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/get_stock_valuation`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockIndustryComparison = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/get_stock_industry_comparison`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockMovingAveranges = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/get_stock_moving_averages`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockTechIndicators = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/get_stock_tech_indicators`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockVisuals = async (params, controller) =>
  axios
    .post(`${mainUrl.API_URL}/get_stock_visuals`, params, {
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockPresets = async (token, controller) =>
  axios
    .get(
      `${mainUrl.API_URL}/preset_titles`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const saveStockPreset = async (params, token, controller) =>
  axios
    .post(
      `${mainUrl.API_URL}/preset`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const updateStockPreset = async (preset_id, params, token, controller) =>
  axios
    .put(
      `${mainUrl.API_URL}/preset/${preset_id}`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const deleteStockPreset = async (preset_id, token, controller) =>
  axios
    .delete(`${mainUrl.API_URL}/preset/${preset_id}`, {
      headers: { Authorization: `Bearer ${token}` },
      signal: controller.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getStockPresetById = async (preset_id, controller) =>
  axios
    .get(
      `${mainUrl.API_URL}/preset/${preset_id}`,
      // {
      //   headers: { Authorization: `Bearer ${token}` },
      // },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const StockApi = {
  getStockScreenerUniverses,
  getStockScreenerCountries,
  getStockScreenerEconomies,
  getStockScreenerSectors,
  getDefaultScreen,
  getStockScreenerVariables,
  updateStockDefaultVariables,
  stockPersentileToValue,
  stockValueToPersentile,
  getStockScreenerUserSettings,
  updateStockScreenerUserSettings,
  createStockScreenerMultifactor,
  createStockScreenerRatio,
  createStockScreenerBacktest,
  getStockInfo,
  getStockValuation,
  getStockIndustryComparison,
  getStockMovingAveranges,
  getStockTechIndicators,
  getStockVisuals,
  getStockPresets,
  saveStockPreset,
  updateStockPreset,
  deleteStockPreset,
  getStockPresetById,
};

export default StockApi;
