import { useTheme } from "@mui/material";
import * as React from "react";
import propTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SidebarNavigation from "../SidebarNavigation";
import useStyles from "./style";
import backgroundImageURL from "./bg.webp";
import logoImageURL from "./logo.webp";
import mobLogoImageUrl from "./logo-mob.webp";
import useMediaQuery from "@mui/material/useMediaQuery";

const Sidebar = ({ drawerwidth, onClose, isOpen }) => {
  const theme = useTheme();
  const classes = useStyles({ drawerwidth, backgroundImageURL });
  const lgDown = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isOpen}
    >
      <IconButton className={classes.closeButton} onClick={onClose}>
        {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
      <div className={classes.header}>
        <img
          className={classes.logoImage}
          src={lgDown ? mobLogoImageUrl : logoImageURL}
          alt="logo"
        />

        {lgDown ? null : <div className={classes.subtitle}>Alpha Access</div>}
      </div>
      <SidebarNavigation />
    </Drawer>
  );
};

Sidebar.propTypes = {
  drawerwidth: propTypes.number.isRequired,
  onClose: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
};

export default Sidebar;
