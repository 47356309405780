import moment from "moment/moment";
import Fuse from "fuse.js";
import { rollingReturnsFilters } from "./Constants";
import store from "../store";
import { ASSET_TYPES, ASSET_TYPE_IDS, getAssetKeyById } from "./Assets";

export const WIN_RATE_KEYS = {
  portfolio: "portfolio",
  testing: "testing",
  factors: "factors",
};

export const groupBy = (arr, prop) => {
  const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
  arr.forEach((obj) => map.get(obj[prop]).push(obj));
  return [...map.values()];
};

export const filterOutObjectKeysIncludedInArray = (object, listOfIds) => {
  let temp = Object.assign({}, object);
  for (const id of listOfIds) {
    if (temp.hasOwnProperty(id)) {
      delete temp[id];
    }
  }
  return temp;
};

export const filterOutObjectKeysNotIncludedInArray = (object, listOfIds) => {
  let temp = Object.assign({}, object);
  const tempSet = new Set(listOfIds);
  for (const key in temp) {
    if (!tempSet.has(key)) {
      delete temp[key];
    }
  }
  return temp;
};

export const DataPreprocessing = (strategies, strategyId) => {
  const data = strategies.hasOwnProperty(strategyId)
    ? strategies[strategyId].stats.monthly
    : null;
  if (!data) {
    return null;
  }
  for (var key in data) break;
  const columns = Object.keys(data[key]);
  return { columns, data };
};

export const dataPreprocessingRollingChartGetAverage = (
  strategies,
  statsType,
  strategyIds
) => {
  const dataObject = {};
  strategyIds.forEach((strategy) => {
    const data =
      strategies[strategy]?.stats?.rollingRets !== null &&
      strategies[strategy]?.stats.hasOwnProperty("rollingRets")
        ? strategies[strategy]?.stats.rollingRets[statsType].average
        : null;
    if (!data) {
      return null;
    }
    if (!dataObject[strategy]) dataObject[strategies[strategy].name] = data;
  });
  return dataObject;
};

export const dataPreprocessingRollingChart = (
  strategies,
  statsType,
  strategyIds
) => {
  const result = {};

  strategyIds.forEach((strategy) => {
    const data =
      strategies[strategy]?.stats?.rollingRets !== null &&
      strategies[strategy]?.stats.hasOwnProperty("rollingRets")
        ? strategies[strategy]?.stats.rollingRets[statsType].values
        : null;
    if (!data) {
      return null;
    }
    let count = 0;
    for (const [key, value] of Object.entries(data)) {
      count += 1;
      if (count === 3) {
        const tempDate = moment(parseInt(key));
        const response = result[tempDate.format("ll")]
          ? result[tempDate.format("ll")]
          : {};
        if (!response.date) response.date = tempDate.format("ll");
        if (!response[strategies[strategy].name])
          response[strategies[strategy].name] = value;
        result[tempDate.format("ll")] = response;
        count = 0;
      }
    }
  });
  if (!result || Object.keys(result).length === 0) {
    return null;
  }
  return Object.keys(result).map((k) => result[k]);
};

export const generatePortfolioList = (
  strategyCol,
  benchmarks,
  portfolioCol,
  nestedStrategiesCol,
  securityCol
) => {
  const portfolio_list = [];
  Object.entries(strategyCol).map(([key]) => {
    const benchLst = strategyCol[key].present.benchmarks.byId
      .map((obj) => {
        const bch = {};
        bch[String(benchmarks[obj].asset_id)] =
          benchmarks[obj].position === "short"
            ? (benchmarks[obj].weight / 100) * -1
            : benchmarks[obj].weight / 100;
        return bch;
      })
      .reduce((acc, val) => Object.assign(acc, val), {});
    const portLst = strategyCol[key].present.portfolios.byId
      .map((prtfl) => {
        const prt = {};
        prt[String(portfolioCol[prtfl].asset_id)] =
          portfolioCol[prtfl].position === "short"
            ? (portfolioCol[prtfl].weight / 100) * -1
            : portfolioCol[prtfl].weight / 100;
        return prt;
      })
      .reduce((acc, val) => Object.assign(acc, val), {});
    const nestedStrategiesLst = strategyCol[key].present.nestedStrategies.byId
      .map((strategy) => {
        const str = {};
        str[String(nestedStrategiesCol[strategy].asset_id)] =
          nestedStrategiesCol[strategy].position === "short"
            ? (nestedStrategiesCol[strategy].weight / 100) * -1
            : nestedStrategiesCol[strategy].weight / 100;
        return str;
      })
      .reduce((acc, val) => Object.assign(acc, val), {});
    const securityLst = strategyCol[key].present.security.byId
      .map((strategy) => {
        const str = {};
        str[String(securityCol[strategy].asset_id)] =
          securityCol[strategy].position === "short"
            ? (securityCol[strategy].weight / 100) * -1
            : securityCol[strategy].weight / 100;
        return str;
      })
      .reduce((acc, val) => Object.assign(acc, val), {});

    if (
      Object.keys(portLst).length !== 0 ||
      Object.keys(benchLst).length !== 0 ||
      Object.keys(nestedStrategiesLst).length !== 0 ||
      Object.keys(securityLst).length !== 0
    ) {
      portfolio_list.push({
        [key]: {
          ...benchLst,
          ...portLst,
          ...nestedStrategiesLst,
          ...securityLst,
        },
      });
    }
  });

  return portfolio_list;
};

export const intersection = (setA, setB) => {
  if (setA.size === 0) {
    return setB;
  }
  const _intersection = new Set();
  for (const elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
};

export const yearsPreprocessing = (strategies) => {
  const res = {};
  for (const [key, value] of Object.entries(strategies)) {
    if (Object.values(strategies[key].stats.yearly).length !== 0) {
      res[key] = value;
    }
  }
  return res;
};

export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return true;
    }
  }

  return false;
};

export const getObjectsByRule = (obj, rule) => {
  const result = {};
  for (const key in obj) {
    if (rule(obj, key)) {
      result[key] = obj[key];
    }
  }

  return result;
};

export const getStrategiesWithYear = (strategies) => {
  const rule = (obj, key) => isEmptyObject(obj[key].stats.yearly);

  return getObjectsByRule(strategies, rule);
};
export const generateRollingData = (rolling, strategyName) => {
  const chartsData = new Map();
  rolling.dates.forEach((val, idx) => {
    let date = new Date(parseInt(val)).toLocaleDateString("en-US");
    if (!chartsData.has(date)) {
      const entry = {};
      entry["date"] = date;
      entry[String(strategyName)] = rolling.values[idx];
      chartsData.set(date, entry);
    } else {
      const entry = chartsData.get(date);
      entry[String(strategyName)] = rolling.values[idx];
      chartsData.set(date, entry);
    }
  });
  return chartsData;
};

export const generateChartData = (strategies, statsPointer) => {
  const chartsData = new Map();
  for (const strategy of Object.values(strategies)) {
    try {
      strategy.stats[statsPointer].dates.forEach((val, idx) => {
        let date = new Date(parseInt(val)).toLocaleDateString("en-US");
        if (!chartsData.has(date)) {
          const entry = {};
          entry["date"] = date;
          entry[String(strategy.name)] =
            strategy.stats[statsPointer].values[idx];
          chartsData.set(date, entry);
        } else {
          const entry = chartsData.get(date);
          entry[String(strategy.name)] =
            strategy.stats[statsPointer].values[idx];
          chartsData.set(date, entry);
        }
      });
    } catch (err) {
      console.log("Error fetch statistics for " + statsPointer + err);
    }
  }
  return chartsData;
};

export const rollingConverter = (rolling, strategyName, interval) => {
  const dateToStrategy = generateRollingData(rolling, strategyName);
  const data = [...dateToStrategy.values()];
  let output = [];
  let semaphore = false;
  for (let i = 0; i < data.length; i += interval) {
    if (data.length >= i + interval) {
      output.push(
        dateToStrategy.get(localMinDate(i, i + interval, data, strategyName))
      );
    } else {
      if (!semaphore) {
        output.push(...fetchTailValues(i, data, strategyName));
        semaphore = true;
      }
    }
  }
  return output;
};

export const converter = (strategies, interval, statsPointer) => {
  const dateToStrategy = generateChartData(strategies, statsPointer);
  const data = [...dateToStrategy.values()];
  let output = [];
  let semaphore = false;
  for (let i = 0; i < data.length; i += interval) {
    for (const strategy of Object.keys(strategies)) {
      if (data.length >= i + interval) {
        output.push(
          dateToStrategy.get(
            localMinDate(i, i + interval, data, strategies[strategy].name)
          )
        );
      } else {
        if (!semaphore) {
          output.push(...fetchTailValues(i, data, strategies[strategy].name));
          semaphore = true;
        }
      }
    }
  }
  return output;
};

const fetchTailValues = (startIndex, data, strategy) => {
  let output = [];
  for (let i = startIndex; i < data.length; i += 1) {
    output.push(data[i]);
  }
  return output;
};

const localMinDate = (startIndex, endIndex, data, strategy) => {
  let minLocalObj = data[startIndex];
  for (let i = startIndex + 1; i < endIndex; i++) {
    if (minLocalObj[strategy] > data[i][strategy]) {
      minLocalObj = data[i];
    }
  }
  return minLocalObj.date;
};

export const cumulitiveReturnsDataConverter = (
  strategies,
  returnsDataState
) => {
  if (Object.values(strategies).length) {
    const chartsData = new Map();

    for (const strategy of Object.values(strategies)) {
      for (const [key, ret] of Object.entries(strategy.stats.cumulitiveRets)) {
        const date = new Date(parseInt(key)).toLocaleDateString("en-US");
        if (!chartsData.has(date)) {
          const entry = {};
          entry["date"] = date;
          entry[strategy.id] = ret;
          chartsData.set(date, entry);
        } else {
          const entry = chartsData.get(date);
          entry[strategy.id] = ret;
          chartsData.set(date, entry);
        }
      }
    }

    return [...chartsData.values()].filter((key, index) => {
      if (index % returnsDataState.interval === 0) {
        const alignment = returnsDataState.isAlignmentNull
          ? returnsDataState.lastAlignmentValue
          : returnsDataState.alignment;
        switch (alignment) {
          case "10y":
            return moment(key.date) >= moment().subtract(10, "years");
          case "5y":
            return moment(key.date) >= moment().subtract(5, "years");
          case "3y":
            return moment(key.date) >= moment().subtract(3, "years");
          case "6m":
            return moment(key.date) >= moment().subtract(6, "months");
          case "YTD":
            return moment(key.date).year() === moment().year();
          default:
            return true;
        }
      } else {
        return false;
      }
    });
  } else {
    return [];
  }
};

export const isStatsExist = (strategies) => {
  for (const key of Object.keys(strategies)) {
    if (Object.values(strategies[key].stats.periodRets).length !== 0) {
      return true;
    }
  }
  return false;
};

const sortedYears = (strategies) =>
  Object.values(strategies)
    .filter((val) => Object.keys(val.stats.yearly).length !== 0)
    .map((key) => new Set([...Object.keys(key.stats.yearly)]))
    .reduce(
      (previousValue, currentValue) =>
        intersection(previousValue, currentValue),
      new Set()
    );

export const extractAnnualReturnData = (strategies, orderOfStrategies) => {
  const yearsSet = sortedYears(strategies);
  const headersList = [...yearsSet].sort((a, b) => b - a).concat(["CmlTotal"]);
  if (headersList[0] === new Date().getFullYear()) {
    headersList[0] = "YTD";
  }
  const rows = {};
  orderOfStrategies.map((strategy) => {
    if (strategies.hasOwnProperty(strategy)) {
      if (Object.keys(strategies[strategy].stats.yearly).length !== 0) {
        rows[strategy] = { ...strategies[strategy].stats.yearly };
        rows[strategy].CmlTotal =
          strategies[strategy].stats.periodRets.CumRet__full[strategy];
      }
    }
  });
  if (orderOfStrategies.length === 2) {
    const excessRow = {};
    if (
      rows.hasOwnProperty(orderOfStrategies[0]) &&
      rows.hasOwnProperty(orderOfStrategies[1])
    ) {
      const firstStrategy = rows[orderOfStrategies[0]];
      const secondStrategy = rows[orderOfStrategies[1]];
      Object.keys(firstStrategy).map((year) => {
        excessRow[year] =
          !firstStrategy.hasOwnProperty(year) ||
          !secondStrategy.hasOwnProperty(year)
            ? "N.A"
            : (firstStrategy[year] - secondStrategy[year]).toFixed(2);
      });
      rows["Excess Returns"] = excessRow;
    }
  }

  return { yearsSet, headersList, rows };
};

export const filtered = (raw, disAllowed) =>
  Object.keys(raw)
    .filter((key) => !disAllowed.has(key))
    .reduce((obj, key) => {
      obj[key] = raw[key];
      return obj;
    }, {});

export const union = (setA, newElm) => setA.add(newElm);

export const extractWinRate = (winRate) => {
  if (!winRate) {
    return { winRates: null, selectedStrategies: null };
  }
  const selectedStrategies = Object.keys(winRate);
  const winRates = {
    daily: { rowTitle: "Winning Days (%)" },
    month: { rowTitle: "Winning Weeks (%)" },
    week: { rowTitle: "Winning Months (%)" },
    year: { rowTitle: "Winning Years (%)" },
  };
  Object.keys(winRate).map((strat) => {
    winRates.daily[strat] = winRate[strat].hasOwnProperty("daily")
      ? winRate[strat].daily
      : "NA";
    winRates.month[strat] = winRate[strat].hasOwnProperty("week")
      ? winRate[strat].week
      : "NA";
    winRates.week[strat] = winRate[strat].hasOwnProperty("month")
      ? winRate[strat].month
      : "NA";
    winRates.year[strat] = winRate[strat].hasOwnProperty("year")
      ? winRate[strat].year
      : "NA";
  });
  return { winRates, selectedStrategies };
};

export const extractEndFilterBestWorstPeriods = (strategies) => {
  const bestWorst = {};
  for (const strategy in strategies) {
    if (strategies[strategy].stats.best_worst_periods) {
      bestWorst[strategy] = strategies[strategy].stats.best_worst_periods;
    }
  }
  return bestWorst;
};

export const dataPreprocessingBestWorstPeriods = (bestWorst) => {
  const bestWorsts = {
    best_day: { rowTitle: "Best Day" },
    worst_day: { rowTitle: "Worst Day" },
    best_month: { rowTitle: "Best Month" },
    worst_month: { rowTitle: "Worst Month" },
    best_year: { rowTitle: "Best Year" },
    worst_year: { rowTitle: "Worst Year" },
    avg_month_up: { rowTitle: "Avg. Up Month" },
    avg_month_down: { rowTitle: "Avg. Down Month" },
  };
  Object.keys(bestWorst).forEach((strategy) => {
    bestWorsts.best_day[strategy] = bestWorst[strategy].hasOwnProperty(
      "best_day"
    )
      ? bestWorst[strategy].best_day
      : "NA";
    bestWorsts.worst_day[strategy] = bestWorst[strategy].hasOwnProperty(
      "worst_day"
    )
      ? bestWorst[strategy].worst_day
      : "NA";
    bestWorsts.best_month[strategy] = bestWorst[strategy].hasOwnProperty(
      "best_month"
    )
      ? bestWorst[strategy].best_month
      : "NA";
    bestWorsts.worst_month[strategy] = bestWorst[strategy].hasOwnProperty(
      "worst_month"
    )
      ? bestWorst[strategy].worst_month
      : "NA";
    bestWorsts.best_year[strategy] = bestWorst[strategy].hasOwnProperty(
      "best_year"
    )
      ? bestWorst[strategy].best_year
      : "NA";
    bestWorsts.worst_year[strategy] = bestWorst[strategy].hasOwnProperty(
      "worst_year"
    )
      ? bestWorst[strategy].worst_year
      : "NA";
    bestWorsts.avg_month_up[strategy] = bestWorst[strategy].hasOwnProperty(
      "avg_month_up"
    )
      ? bestWorst[strategy].avg_month_up
      : "NA";
    bestWorsts.avg_month_down[strategy] = bestWorst[strategy].hasOwnProperty(
      "avg_month_down"
    )
      ? bestWorst[strategy].avg_month_down
      : "NA";
  });
  return bestWorsts;
};

export const isColored = (index) => index % 2 !== 0;

export const extractStrategyValue = (preparedData, rowType, strategyId) =>
  preparedData[rowType].hasOwnProperty(strategyId)
    ? preparedData[rowType][strategyId]
    : null;

const extractHighestValue = (preparedData, rowType, strategies) => {
  const { rowTitle, ...row } = preparedData[rowType];
  const filtered = strategies.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: row[currentValue],
    }),
    {}
  );
  return Math.max(...Object.values(filtered));
};

export const setFontWeightInBestWorstPeriodsTable = (
  markBold,
  preparedData,
  rowType,
  strategyId,
  strategies
) =>
  markBold
    ? extractStrategyValue(preparedData, rowType, strategyId) ===
      extractHighestValue(preparedData, rowType, strategies)
      ? "bold"
      : "normal"
    : "normal";

export const setFontWeight = (markBold, preparedData, rowType, strategyId) =>
  markBold
    ? extractStrategyValue(preparedData, rowType, strategyId) > 50
      ? "bold"
      : "normal"
    : "normal";

export const filterStrategies = (strategies, selectedStrategies, initValue) => {
  const parallelStrategy = selectedStrategies
    .filter((str) => str !== initValue)
    .join();
  const filterStrategies = Object.keys(strategies).filter(
    (strat) =>
      strat !== parallelStrategy &&
      (strategies[strat].stats.strategy_returns.length !== 0 ||
        Object.keys(strategies[strat].stats.strategy_returns).length !== 0)
  );

  return filterOutObjectKeysNotIncludedInArray(strategies, filterStrategies);
};

export const generateWinRateRequest = (
  origin,
  selectedStrategies,
  idx,
  strat,
  strategies
) => {
  const request = {};
  request.name_a = idx === 0 ? strat : selectedStrategies[0];
  request.name_b = idx === 1 ? strat : selectedStrategies[1];

  switch (origin) {
    case WIN_RATE_KEYS.testing:
      request.returns_a =
        strategies[request.name_a].stats.strategy_returns.strategy;
      request.returns_b =
        strategies[request.name_b].stats.strategy_returns.strategy;
      break;

    default:
      request.returns_a = strategies[request.name_a].stats.strategy_returns;
      request.returns_b = strategies[request.name_b].stats.strategy_returns;
      break;
  }

  return request;
};

export const diference = (setA, setB) =>
  new Set([...setA].filter((element) => !setB.has(element)));

export const collectRemovableAssets = (state, strategyId, asstType) => {
  const assets = new Set(
    Object.keys(state.strategies.byId)
      .filter((strategy) => strategy !== strategyId)
      .reduce((prev, str) => {
        const data = state.strategies.byId[str].present[asstType]?.byId || [];
        return [...prev, ...data];
      }, [])
  );

  return diference(
    new Set(state.strategies.byId[strategyId].present[asstType]?.byId),
    assets
  );
};

export const getData = (
  date,
  config = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  },
  regReplace = "$2.$1.$3"
) =>
  new Date(date)
    .toLocaleString("en-us", config)
    .replace(/(\d+)\/(\d+)\/(\d+)/, regReplace);

export const renderComponentByRule = (rule, component1, component2 = "") =>
  rule ? component1 : component2;

export const getReturnTableCellClassByValue = (value, classes) => {
  let result = classes.tableCell;

  if (!value && value !== 0) return result;
  if (typeof value === "string") return result;
  if (value > 1) {
    result += ` ${classes.positive}`;
  } else if (value >= 0) {
    result += ` ${classes.bitPositive}`;
  } else if (value < -1) {
    result += ` ${classes.negative}`;
  } else {
    result += ` ${classes.bitNegative}`;
  }

  return result;
};

const ISRAEL = "israel";
const HONG_KONG = "hong kong";
const ISRAEL_KEY = "ILS 350K Turnover";
const HONG_KONG_KEY = "top|btm 20 Beginning of Quarter";
const DEFAULT_KEY = "$1m Tunover";

export const filterOptions = (options, { inputValue }) => {
  const { portfolio } = store.getState();

  const frequencyOptionsList =
    portfolio.shortAssetsList.length > 0 ? portfolio.shortAssetsList : [];

  const searchStr = inputValue
    .trim()
    .split(" ")
    .map((s) => "'" + s)
    .join(" ");

  if (inputValue === "") {
    return options.map((val) => ({
      item: Object.assign(val, {}),
      matches: [],
      score: 1,
    }));
  }

  if (inputValue.length < 3) {
    return [];
  }

  const searchRegExp = new RegExp(inputValue, "gi");

  let keyRegExp;

  switch (searchRegExp) {
    case HONG_KONG.match(searchRegExp):
      keyRegExp = new RegExp(HONG_KONG_KEY, "gi");
      break;

    case ISRAEL.match(searchRegExp):
      keyRegExp = new RegExp(ISRAEL_KEY, "gi");
      break;

    default:
      keyRegExp = new RegExp(DEFAULT_KEY, "gi");
      break;
  }

  const params = {
    useExtendedSearch: true,
    keys: [ASSET_TYPES.portfolio],
    ignoreLocation: true,
    sortFn: (a, b) => {
      if (a.item[0].v.match(keyRegExp) < b.item[0].v.match(keyRegExp)) {
        return 1;
      } else {
        return -1;
      }
    },
  };

  const fuse = new Fuse(frequencyOptionsList, params);
  const low = new Fuse(options, params);

  if (fuse.search(searchStr).length > 0) {
    return fuse.search(searchStr);
  } else {
    return low.search(searchStr);
  }
};

export const decimalAdjust = (type, value, exp) => {
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  value = value.toString().split("e");
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
};

export const getStrategyName = (menuItems, state) => {
  return Object.values(menuItems).filter((item) => item.id === state).length !==
    0
    ? Object.values(menuItems)
        .filter((item) => item.id === state)
        .reduce((obj) => obj).name
    : "";
};

export const MathRound10 = function (value, exp) {
  return decimalAdjust("round", value, exp);
};

export const getSubHeaderStyle = (header, style) => {
  return Object.keys(header)[0].toLowerCase() === "day" ||
    Object.keys(header)[0].toLowerCase() === "0.5 year"
    ? style.daily
    : Object.keys(header)[0].toLowerCase() === "month"
    ? style.monthly
    : Object.keys(header)[0].toLowerCase() === "quarter"
    ? style.quarter
    : Object.keys(header)[0].toLowerCase() === "year"
    ? style.year
    : style.yearly;
};

export const getRoundedValueOrNaIfFalsy = (value, toFixed) =>
  value || value === 0
    ? typeof value === "string"
      ? "N.A"
      : value.toFixed(toFixed)
    : "N.A";

export const prepareGetRollingsRequest = (strategies, filter) => {
  if (!strategies) {
    return null;
  }
  const request = { returns_dict: {} };
  for (const strategy in strategies) {
    request["returns_dict"][strategy] =
      strategies[strategy].stats.strategy_returns;
  }
  request.n_years_window = rollingReturnsFilters.hasOwnProperty(filter)
    ? rollingReturnsFilters[filter]
    : 1;
  return request;
};

export const deepFind = (obj, path) => {
  let paths = path.split("."),
    current = obj,
    i;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }

  return current;
};

export const convertTimestampToDate = (timestamp) => {
  return new Date(parseInt(timestamp)).toLocaleDateString("en-US");
};

export const convertObjToArray = (obj, callBack) => {
  const result = [];

  for (let key in obj) {
    const value = callBack(key, obj);

    result.push(value);
  }

  return result;
};

export const worstDrawDownFilter = (strategies) => {
  const res = {};
  for (const [key, value] of Object.entries(strategies)) {
    if (!strategies[key].stats.worst_drawdowns) {
      return null;
    }
    if (Object.values(strategies[key].stats.worst_drawdowns)?.length !== 0) {
      res[key] = value;
    }
  }
  return res;
};

export const underWaterFilter = (strategies) => {
  const res = {};
  for (const [key, value] of Object.entries(strategies)) {
    if (!strategies[key].stats.underwater) {
      return null;
    }
    if (Object.values(strategies[key].stats.underwater).length !== 0) {
      res[key] = value;
    }
  }
  return res;
};

export const extractDate = (timeStamp) => {
  const date = new Date(timeStamp);
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
};

export const handleFilterObjectKeysViceVersa = (object, listOfIds) => {
  if (object === undefined) {
    return {};
  }
  let temp = Object.assign({}, object);
  const tempSet = new Set(listOfIds);
  for (const key in temp) {
    if (!tempSet.has(key)) {
      delete temp[key];
    }
  }
  return temp;
};

const getUnderwater = (key, data) => deepFind(data[key], "stats.underwater");
const createValueForChart = (key, data) => {
  return {
    date: convertTimestampToDate(key),
    value: data[key],
  };
};
export const getObjectForChart = (key, data) =>
  convertObjToArray(getUnderwater(key, data), createValueForChart);

export const filterBySelectedStrategies = (
  secondStrategy,
  firstStrategy,
  strategies
) => {
  return secondStrategy !== "empty"
    ? Object.keys(strategies)
        .filter((str) => str === firstStrategy || str === secondStrategy)
        .reduce((obj, key) => {
          obj[key] = strategies[key];
          return obj;
        }, {})
    : Object.keys(strategies)
        .filter((str) => str === firstStrategy)
        .reduce((obj, key) => {
          obj[key] = strategies[key];
          return obj;
        }, {});
};

export const convertToDate = (data) => {
  return new Date(data).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "2-digit",
  });
};

export function fixPrecision(number, precision = 2) {
  const ratio = 10 ** precision;
  return Math.round(number * ratio) / ratio;
}

export const getAverage = (data, statPointer, selectedStrategy = null) => {
  return selectedStrategy === null
    ? Object.keys(data).reduce((obj, key) => {
        obj[data[key].name] = data[key].stats[statPointer].average;
        return obj;
      }, {})
    : Object.keys(data)
        .filter((strat) => strat === selectedStrategy)
        .reduce((obj, key) => {
          obj[data[key].name] = data[key].stats[statPointer].average;
          return obj;
        }, {});
};

export const generateAverage = (average, strategyName) => {
  const obj = {};
  obj[strategyName] = average;
  return obj;
};

export const organizeBenchmarks = (benchmarks) => {
  const formattedBenchmarks = [];
  const benchmarkNameToId = new Map();
  benchmarks.forEach((elm, index) => {
    let bench = {};
    bench["name"] = elm.name;
    bench["value"] = elm.name;
    bench["id"] = elm.id;
    formattedBenchmarks.push(bench);
    benchmarkNameToId.set(elm.name, elm.id);
  });
  return { formattedBenchmarks, benchmarkNameToId };
};

export const checkIfRowIsDuplicate = (selectedItems, asset_id) => {
  let counter = 0;
  selectedItems.forEach((element) => {
    if (element.asset_id === asset_id) {
      counter++;
    }
  });
  return counter > 1;
};

export const checkIfRowIsHighlighted = (array, id) => {
  return array.some((e) => {
    return e.id === id;
  });
};

const setNewWeight = (
  item,
  benchmarksBulk,
  portfoliosBulk,
  newWeight,
  nestedBulk,
  securityBulk,
  otherPortfolioBulk
) => {
  if (item.id.includes(ASSET_TYPE_IDS.benchmark)) {
    benchmarksBulk[item.id] = {
      ...benchmarksBulk[item.id],
      weight: isNaN(newWeight) ? 0 : newWeight,
    };
  } else if (item.id.includes(ASSET_TYPE_IDS.nestedStrategies)) {
    nestedBulk[item.id] = {
      ...nestedBulk[item.id],
      weight: isNaN(newWeight) ? 0 : newWeight,
    };
  } else if (item.id.includes(ASSET_TYPE_IDS.security)) {
    securityBulk[item.id] = {
      ...securityBulk[item.id],
      weight: isNaN(newWeight) ? 0 : newWeight,
    };
  } else {
    portfoliosBulk[item.id] = {
      ...portfoliosBulk[item.id],
      weight: isNaN(newWeight) ? 0 : newWeight,
    };
  }
};

export const updatePosItems = (
  position,
  strategyPortfolios,
  strategyBenchmarks,
  currentTotalPos,
  portfolios,
  benchmarks,
  nestedStrategies,
  strategyNested,
  security,
  strategySecurity
) => {
  const filteredStrategyItems = Object.values(
    Object.assign(
      {},
      strategyPortfolios,
      strategyBenchmarks,
      strategyNested,
      strategySecurity
    )
  ).filter((portfolio) => portfolio.position === position);
  const prevTotalPos = filteredStrategyItems.reduce(
    (sumOfWeight, item) => sumOfWeight + item.weight,
    0
  );

  return updateStrategyItems(
    filteredStrategyItems,
    parseFloat(prevTotalPos),
    parseFloat(currentTotalPos),
    portfolios,
    benchmarks,
    nestedStrategies,
    security
  );
};

const updateStrategyItems = (
  filteredStrategyItems,
  prevTotalWeight,
  newTotalWeight,
  portfolios,
  benchmarks,
  nestedStrategies,
  security
) => {
  const portfoliosBulk = { ...portfolios };
  const benchmarksBulk = { ...benchmarks };
  const nestedBulk = { ...nestedStrategies };
  const securityBulk = { ...security };

  if (prevTotalWeight == newTotalWeight) {
    return {};
  }
  if (prevTotalWeight !== 0) {
    filteredStrategyItems.forEach((item) => {
      let newWeight = (item.weight / prevTotalWeight) * newTotalWeight;
      setNewWeight(
        item,
        benchmarksBulk,
        portfoliosBulk,
        newWeight,
        nestedBulk,
        securityBulk
      );
    });
  } else {
    const equalWeight = newTotalWeight / filteredStrategyItems.length;
    filteredStrategyItems.forEach((item) => {
      setNewWeight(
        item,
        benchmarksBulk,
        portfoliosBulk,
        equalWeight,
        nestedBulk,
        securityBulk,
        otherPortfolioBulk
      );
    });
  }
  return {
    benchmarksBulk,
    portfoliosBulk,
    nestedBulk,
    securityBulk,
  };
};

const fromShortToShort = (prevPosition, position) => {
  return prevPosition === position && position === "short";
};

const fromShortToLong = (prevPosition, position) => {
  return prevPosition === "short" && position === "long";
};

const fromLongToShort = (prevPosition, position) => {
  return prevPosition !== position;
};

export const getNewLong = (
  key,
  position,
  state,
  strategyId,
  weight,
  prevPortfolioId
) => {
  const prevWeight = state[key].byId[prevPortfolioId].weight;
  const prevPosition = state[key].byId[prevPortfolioId].position;
  return fromShortToShort(prevPosition, position)
    ? state.strategies.byId[strategyId].longPos
    : fromShortToLong(prevPosition, position)
    ? parseFloat(state.strategies.byId[strategyId].longPos) +
      parseFloat(prevWeight)
    : fromLongToShort(prevPosition, position)
    ? parseFloat(state.strategies.byId[strategyId].longPos) -
      parseFloat(prevWeight)
    : parseFloat(state.strategies.byId[strategyId].longPos) +
      (parseFloat(weight) - parseFloat(prevWeight));
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const getStrategyNameOnAdd = (strategy, asset) => {
  if (!editedByUser(strategy)) {
    if (
      strategy.present.benchmarks.byId.length +
        strategy.present.portfolios.byId.length ===
      0
    ) {
      if (asset.mtl && asset.cnt) {
        return `${asset.mtl} - ${asset.cnt}`;
      } else {
        return asset.name;
      }
    } else if (
      strategy.present.benchmarks.byId.length +
        strategy.present.portfolios.byId.length >=
      1
    ) {
      return strategy.defaultName;
    } else {
      return strategy.name;
    }
  } else {
    return strategy.name;
  }
};

const emptyBenchmark = (strategy) => {
  return strategy.present.benchmarks.byId.length === 0;
};

const singlePortfolio = (strategy) => {
  return strategy.present.portfolios.byId.length === 1;
};

const editedByUser = (strategy) => {
  return strategy.editedStrategyName;
};

export const getStrategyNameOnBenchmarkDelete = (
  strategy,
  portfoliosIds,
  benchmarksAfterDelete,
  state
) => {
  if (!editedByUser(strategy)) {
    switch (portfoliosIds.length) {
      case 0:
        if (benchmarksAfterDelete.length === 0) {
          return strategy.defaultName;
        } else if (benchmarksAfterDelete.length === 1) {
          return state.benchmarks.byId[benchmarksAfterDelete].name;
        } else {
          return strategy.name;
        }
      case 1:
        if (benchmarksAfterDelete.length === 0) {
          return `${state.portfolios.byId[portfoliosIds].mtl} - ${state.portfolios.byId[portfoliosIds].cnt}`;
        } else {
          return strategy.name;
        }
      default:
        return strategy.name;
    }
  } else {
    return strategy.name;
  }
};

export const getStrategyNameOnAssetDelete = (
  key,
  rowId,
  strategy,
  afterDeleteIds,
  state
) => {
  if (!editedByUser(strategy)) {
    if (
      strategy.name === state[key].byId[rowId].name &&
      afterDeleteIds.length
    ) {
      const newAssetTitleId = afterDeleteIds[0];

      const newAssetTitleType = getAssetKeyById(newAssetTitleId);

      return state[newAssetTitleType].byId[newAssetTitleId].name;
    } else {
      return strategy.defaultName;
    }
  } else {
    return strategy.name;
  }
};

export const getStrategyNameOnPortfolioDelete = (
  strategy,
  benchmarksIds,
  portfoliosAfterDelete,
  state
) => {
  if (!editedByUser(strategy)) {
    switch (benchmarksIds.length) {
      case 0:
        if (portfoliosAfterDelete.length === 0) {
          return strategy.defaultName;
        } else if (portfoliosAfterDelete.length === 1) {
          return `${state.portfolios.byId[portfoliosAfterDelete].mtl} - ${state.portfolios.byId[portfoliosAfterDelete].cnt}`;
        } else {
          return strategy.name;
        }
      case 1:
        if (portfoliosAfterDelete.length === 0) {
          return state.benchmarks.byId[benchmarksIds].name;
        } else {
          return strategy.name;
        }
      default:
        return strategy.name;
    }
  } else {
    return strategy.name;
  }
};

export const getStrategyNameOnUndo = (
  strategy,
  pastBenchmarks,
  pastPortfolios,
  stateBenchmarks,
  statePortfolios
) => {
  if (!editedByUser(strategy)) {
    if (pastBenchmarks.byId.length === 1 && pastPortfolios.byId.length === 0) {
      const benchmark = stateBenchmarks.byId[pastBenchmarks.byId[0]];
      return benchmark.name;
    } else if (
      pastBenchmarks.byId.length === 0 &&
      pastPortfolios.byId.length === 1
    ) {
      const obj = statePortfolios.byId[pastPortfolios.byId[0]];
      return `${obj.mtl} - ${obj.cnt}`;
    } else {
      return strategy.defaultName;
    }
  } else {
    return strategy.name;
  }
};

export const getStrategyNameOnClone = (strategyName, count) => {
  if (strategyName.includes("New Strategy")) {
    return `New Strategy ${count}`;
  } else {
    return strategyName;
  }
};

export const getStrategyNameOnEdit = (strategy, asset) => {
  if (singlePortfolio(strategy) && emptyBenchmark(strategy)) {
    return `${asset.mtl} - ${asset.cnt}`;
  } else {
    return strategy.name;
  }
};

export const getStrategyNameOnEmpty = (strategy) => {
  if (editedByUser(strategy)) {
    return strategy.name;
  } else {
    return strategy.defaultName;
  }
};

export function uuidv4() {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export function trimString(string, length) {
  return string.length > length ? string.substring(0, length) + "..." : string;
}

export function getPercentage(partialValue, totalValue) {
  return ((100 * partialValue) / totalValue).toFixed(1);
}

export function clearPrintWindow() {
  const printFrame = document.getElementById("printWindow");
  printFrame.innerHTML = "";
}

export const metricFractionalDigits = (metric) => {
  const metricsWithTwoFracDigits = ["sharp", "value"];
  return metricsWithTwoFracDigits.some((element) =>
    metric.toLowerCase().includes(element)
  );
};

export const getLowestItemByKey = (arr, key) =>
  (arr.length &&
    arr.reduce((prev, curr) => (prev[key] < curr[key] ? prev : curr))) ||
  null;

Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

export function cutExcelSheetName(name) {
  const length = 31;
  return name.substring(0, length);
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const GOLDEN_COLOR = "#bd8c1d";
