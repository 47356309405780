import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { theme } from "./theme";
import store from "./store";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={5}
        iconVariant={{
          success: "✅",
          warning: "⚠️",
          info: "ℹ️",
        }}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
