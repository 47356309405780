import { mainUrl } from "../config";
import axios from "axios";

const getFactorGroups = async (controller) =>
  axios
    .get(`${mainUrl.API_URL}/api/get_factor_groups`, {
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const updateUserTokenWithFactorGroups = async (params, token, controller) =>
  axios
    .post(`${mainUrl.API_URL}/api/update_token_with_factor_groups`, params, {
      headers: { Authorization: `Bearer ${token}` },
      signal: controller?.signal,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const initApp = async (token) =>
  axios
    .get(`${mainUrl.API_URL}/api/init`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const UserApi = {
  getFactorGroups,
  updateUserTokenWithFactorGroups,
  initApp,
};

export default UserApi;
