export const GET_BENCHMARKS_STATISTIC_START =
  "MODELS.GET_BENCHMARKS_STATISTIC_START";
export const GET_BENCHMARKS_STATISTIC_SUCCESS =
  "MODELS.GET_BENCHMARKS_STATISTIC_SUCCESS";
export const GET_BENCHMARKS_STATISTIC_FAILED =
  "MODELS.GET_BENCHMARKS_STATISTIC_FAILED";


export const GET_STRATEGIES_STATISTIC_START =
  "MODELS.GET_STRATEGIES_STATISTIC_START";
export const GET_STRATEGIES_STATISTIC_SUCCESS =
  "MODELS.GET_STRATEGIES_STATISTIC_SUCCESS";
export const GET_STRATEGIES_STATISTIC_FAILED =
  "MODELS.GET_STRATEGIES_STATISTIC_FAILED";
export const CLEAR_STRATEGIES_STATISTIC = 
  "MODELS.CLEAR_STRATEGIES_STATISTIC";


export const ADD_BOOKMARKED_STRATEGY = 
  "MODELS.ADD_BOOKMARKED_STRATEGY";

export const REMOVE_BOOKMARKED_STRATEGY =
  "MODELS.REMOVE_BOOKMARKED_STRATEGY";

export const ADD_STRATEGY_TO_STATISTIC = 
  "MODELS.ADD_STRATEGY_TO_STATISTIC";

export const REMOVE_ALL_STRATEGY_FROM_STATISTIC =
  "MODELS.REMOVE_ALL_STRATEGY_FROM_STATISTIC";
export const REMOVE_STRATEGY_FROM_STATISTIC =
  "MODELS.REMOVE_STRATEGY_FROM_STATISTIC";

export const CLEAR_NEW_STATISTIC = "MODELS.CLEAR_NEW_STATISTIC";

export const SET_ALL_STRATEGIES_TO_STATISTIC =
  "MODELS.SET_ALL_STRATEGIES_TO_STATISTIC";

export const GET_STRATEGIES_START =
  "MODELS.GET_STRATEGIES_START";

export const GET_STRATEGIES_SUCCESS =
  "MODELS.GET_STRATEGIES_SUCCESS";

export const GET_STRATEGIES_FAILED =
  "MODELS.GET_STRATEGIES_FAILED";