import nextId from "react-id-generator";

import {
  ADD_NEW_ASSET_TOGGLE_FETCHING,
  ADD_NEW_BENCHMARK,
  ADD_NEW_PORTFOLIO,
  ADD_NEW_SECURITY,
  ADD_NESTED_STRATEGY,
  CLEAR_STRATEGY,
  COPY_STRATEGY_CONTENT,
  DELETE_STRATEGY,
  INITIALIZE_STRATEGY,
  REDO,
  ROLLING_CORELLATION,
  ROLLING_CORRELATION_FULFILLED,
  ROLLING_CORRELATION_LOADING,
  ROLLING_RETURNS_FULFILLED,
  SET_STRATEGY_CREATOR_NAME_AND_DATES,
  STOCKS_FULFILLED,
  STOCKS_REJECTED,
  STOCKS_TOGGLE_FETCHING,
  STRATEGY_EDIT,
  UNDO,
  UPDATE_COUNTER,
  UPDATE_PORTFOLIO_BETA_TABLE,
  UPDATE_PORTFOLIO_BETA_TABLE_FULFILLED,
  UPDATE_PORTFOLIO_BETA_TABLE_LOADING,
  UPDATE_STRATEGY_INITIALIZATION_DATES,
  UPDATE_STRATEGY_LONG_POS,
  UPDATE_STRATEGY_NAME,
  UPDATE_STRATEGY_SHORT_POS,
  UPDATE_WIN_RATE_TABLE_SUCCESS,
  UPDATE_WIN_RATE_TABLE_START,
  UPDATE_WIN_RATE_TABLE_FAILED,
  CLEAR_ALL_STRATEGIES,
  GET_WORKING_GROUP,
  RESET_DEFAULT,
  LOGOUT,
  UPDATE_ASSET_BULK,
  SET_ASSET_POSITION,
  SET_ASSET_WEIGHT,
  SET_ASSET_BULK_WEIGHT,
  DELETE_ASSET_ROW,
  UPDATE_WIN_RATE_TABLE_FACTORS_SUCCESS,
  TYPE_ID_DOES_NOT_HANDLED,
} from "../actions/Type";

import {
  GET_STATISTICS_START,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_FAILED,
  SEARCH_PORTFOLIO_START,
  SEARCH_PORTFOLIO_SUCCESS,
  LOAD_PORTFOLIO_START,
  LOAD_PORTFOLIO_COMPLETED,
  SEARCH_FAILED,
  SET_ORDER_OF_STRATEGIES,
  DELETE_HIGHLIGHTED_ROWS,
  DUPLICATE_HIGHLIGHTED_ROWS,
  UPDATE_WEIGHT_HIGHLIGHTED_ROWS,
  REPLACE_BENCHMARK_START,
  REPLACE_BENCHMARK_SUCCESS,
  REPLACE_BENCHMARK_FAILED,
  GET_WORKING_GROUP_START,
  SELECTED_STATISTICS_STRATEGIES_CHANGE,
  GET_WORKING_GROUP_FAILED,
  GET_SHORT_ASSET_LIST_START,
  GET_SHORT_ASSET_LIST_SUCCESS,
  GET_SHORT_ASSET_LIST_FAILED,
  SET_REVISION_VERSION,
  REPLACE_NESTED_STRATEGY,
  SET_CONSTRUCTOR_ACTIVE_TAB,
  UPDATE_ORDER_STRATEGY,
  REPLACE_SINGLE_SECURITY,
  SET_HIGHLIGHTED_ROWS
} from "../types/Portfolio";

import {
  collectRemovableAssets,
  getNewLong,
  getStrategyNameOnUndo,
  getStrategyNameOnEdit,
  getStrategyNameOnBenchmarkDelete,
  getStrategyNameOnPortfolioDelete,
  filtered,
  getStrategyNameOnAssetDelete,
} from "../../helpers/Utils";
import { handleUpdateSetOfAssetIds } from "../../helpers/Portfolio.js";
import {
  ADDITIONAL_ASSET_TYPES,
  ASSET_STRATEGY_KEYS,
  ASSET_TYPES,
  ASSET_TYPE_IDS,
  removeAllAssetsBySelectedStrategy,
  setAssetBulkWeight,
  setAssetPosition,
  setAssetWeight,
  setNewAsset,
  updateAssetBulk,
  updateStrategyWithNewAsset,
} from "../../helpers/Assets";
import {
  cloneStrategy,
  emptySelectedStrategy,
  generateFutureHistory,
  generatePastHistory,
  initializeNewStrategy,
  removeStrategyById,
  updateStrategiesStatistics,
} from "../../helpers/Strategy.js";
import { UPDATE_USER_FACTORS_GROUPING_SUCCESS } from "../types/User";

const initialState = {
  activeTab: 0,
  assets: [],
  shortAssetsList: [],
  strategies: { byId: {} },
  strategiesStatistics: { byId: {} },
  selectedStatisticsStrategies: [],
  portfolios: { byId: {} },
  nestedStrategies: { byId: {} },
  dates: {},
  nestedPortfolios: {},
  benchmarks: { byId: {} },
  security: { byId: {} },
  orderOfStrategies: [],
  count: 0,
  loaders: {
    winrateData: false,
    isPortfolioLoading: false,
    betaTable: false,
    rollingCorrelation: false,
    isStatisticsLoading: false,
    isWorkingGroupsLoading: false,
    newAsset: false,
  },
  workingGroups: [],
};

function setOrderOfStrategies(state, { payload }) {
  return {
    ...state,
    orderOfStrategies: payload,
  };
}

function addNewPortfolio(state, action) {
  const { payload } = action;
  const { id, asset, editMode, strategyId, weight, position, status } = payload;
  return {
    ...state,
    portfolios: setNewAsset(
      ASSET_STRATEGY_KEYS.portfolios,
      state,
      id,
      asset,
      weight,
      position,
      editMode
    ),

    strategies: updateStrategyWithNewAsset(
      ASSET_STRATEGY_KEYS.portfolios,
      state,
      strategyId,
      id,
      asset
    ),
    loaders: {
      ...state.loaders,
      newAsset: status,
    },
  };
}

function addNewSecurity(state, action) {
  const { payload } = action;
  const { id, asset, strategyId, weight, position, status } = payload;
  return {
    ...state,
    security: setNewAsset(
      ASSET_STRATEGY_KEYS.security,
      state,
      id,
      asset,
      weight,
      position,
      false
    ),
    strategies: updateStrategyWithNewAsset(
      ASSET_STRATEGY_KEYS.security,
      state,
      strategyId,
      id,
      asset
    ),
    loaders: {
      ...state.loaders,
      newAsset: status,
    },
  };
}

function addNewBenchmark(state, action) {
  const { payload } = action;
  const { id, asset, strategyId, weight, position, status } = payload;
  return {
    ...state,
    benchmarks: setNewAsset(
      ASSET_STRATEGY_KEYS.benchmarks,
      state,
      id,
      asset,
      weight,
      position,
      false
    ),
    strategies: updateStrategyWithNewAsset(
      ASSET_STRATEGY_KEYS.benchmarks,
      state,
      strategyId,
      id,
      asset
    ),
    loaders: {
      ...state.loaders,
      newAsset: status,
    },
  };
}

function replaceBenchmark(state, action) {
  const { currentId, data } = action.payload;

  const { asset, strategyId, status } = data;

  return {
    ...state,

    benchmarks: setNewAsset(
      ASSET_STRATEGY_KEYS.benchmarks,
      state,
      currentId,
      asset,
      state.benchmarks.byId[currentId].weight,
      state.benchmarks.byId[currentId].position,
      false
    ),

    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          past: generatePastHistory(state, strategyId),
        },
      },
    },
    loaders: {
      ...state.loaders,
      newAsset: status,
    },
  };
}

function duplicateHighlightedRows(state, action) {
  const { strgyId, highlightedItems } = action.payload;

  let portfoliosIdsForDuplicate = [];
  let benchmarksIdsForDuplicate = [];
  let nestedStrategiesIdsForDuplicate = [];
  let securityIdsForDuplicate = [];

  highlightedItems.forEach((item) => {
    switch (item.type) {
      case ASSET_TYPES.portfolio:
      case ADDITIONAL_ASSET_TYPES.methodology:
        portfoliosIdsForDuplicate.push({
          id: item.id,
          newId: nextId(ASSET_TYPE_IDS.portfolio),
        });
        break;

      case ASSET_TYPES.nestedStrategies:
        nestedStrategiesIdsForDuplicate.push({
          id: item.id,
          newId: nextId(ASSET_TYPE_IDS.nestedStrategies),
        });
        break;

      case ASSET_TYPES.benchmark:
      case ADDITIONAL_ASSET_TYPES.index:
        benchmarksIdsForDuplicate.push({
          id: item.id,
          newId: nextId(ASSET_TYPE_IDS.benchmark),
        });
        break;

      case ASSET_TYPES.security:
        securityIdsForDuplicate.push({
          id: item.id,
          newId: nextId(ASSET_TYPE_IDS.security),
        });
        break;

      default:
        throw new Error("Unknown type " + item?.type);
    }
  });

  const portfoliosIds = state.strategies.byId[strgyId].present.portfolios.byId;
  const benchmarksIds = state.strategies.byId[strgyId].present.benchmarks.byId;
  const nestedStrategiesIds =
    state.strategies.byId[strgyId].present.nestedStrategies.byId;
  const securityIds = state.strategies.byId[strgyId].present.security.byId;

  const portfoliosByAstId =
    state.strategies.byId[strgyId].present.portfolios.byAstId;
  const afterDuplicatePortfoliosIds = [
    ...portfoliosIds,
    ...portfoliosIdsForDuplicate.map((item) => item.newId),
  ];

  const benchmarksbyAstId =
    state.strategies.byId[strgyId].present.benchmarks.byAstId;
  const afterDuplicateBenchmarksIds = [
    ...benchmarksIds,
    ...benchmarksIdsForDuplicate.map((item) => item.newId),
  ];

  const nestedStrategiesbyAstId =
    state.strategies.byId[strgyId].present.nestedStrategies.byAstId;
  const afterDuplicateNestedStrategiesIds = [
    ...nestedStrategiesIds,
    ...nestedStrategiesIdsForDuplicate.map((item) => item.newId),
  ];

  const securitybyAstId =
    state.strategies.byId[strgyId].present.security.byAstId;
  const afterDuplicateSecurityIds = [
    ...securityIds,
    ...securityIdsForDuplicate.map((item) => item.newId),
  ];

  const resPort = portfoliosIdsForDuplicate.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.newId]: {
        ...state.portfolios.byId[currentValue.id],
        id: currentValue.newId,
      },
    }),
    {}
  );

  const resBench = benchmarksIdsForDuplicate.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.newId]: {
        ...state.benchmarks.byId[currentValue.id],
        id: currentValue.newId,
      },
    }),
    {}
  );

  const resNestedStrategies = nestedStrategiesIdsForDuplicate.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.newId]: {
        ...state.nestedStrategies.byId[currentValue.id],
        id: currentValue.newId,
      },
    }),
    {}
  );

  const resSecurity = securityIdsForDuplicate.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.newId]: {
        ...state.security.byId[currentValue.id],
        id: currentValue.newId,
      },
    }),
    {}
  );

  return {
    ...state,
    portfolios: {
      ...state.portfolios,
      byId: {
        ...state.portfolios.byId,
        ...resPort,
      },
    },
    benchmarks: {
      ...state.benchmarks,
      byId: {
        ...state.benchmarks.byId,
        ...resBench,
      },
    },
    nestedStrategies: {
      ...state.nestedStrategies,
      byId: {
        ...state.nestedStrategies.byId,
        ...resNestedStrategies,
      },
    },
    security: {
      ...state.security,
      byId: {
        ...state.security.byId,
        ...resSecurity,
      },
    },

    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strgyId]: {
          ...state.strategies.byId[strgyId],
          present: {
            ...state.strategies.byId[strgyId].present,
            benchmarks: {
              ...state.strategies.byId[strgyId].present.benchmarks,
              byId: afterDuplicateBenchmarksIds,
              byAstId: benchmarksbyAstId,
            },
            portfolios: {
              ...state.strategies.byId[strgyId].present.portfolios,
              byId: afterDuplicatePortfoliosIds,
              byAstId: portfoliosByAstId,
            },
            nestedStrategies: {
              ...state.strategies.byId[strgyId].present.nestedStrategies,
              byId: afterDuplicateNestedStrategiesIds,
              byAstId: nestedStrategiesbyAstId,
            },
            security: {
              ...state.strategies.byId[strgyId].present.security,
              byId: afterDuplicateSecurityIds,
              byAstId: securitybyAstId,
            },
          },
          past: generatePastHistory(state, strgyId),
          order: [
            ...state.strategies.byId[strgyId].order,
            ...Object.keys(resBench),
            ...Object.keys(resPort),
            ...Object.keys(resNestedStrategies),
            ...Object.keys(resSecurity),
          ],
        },
      },
    },
  };
}

function updateWeightHighlightedRows(state, action) {
  const { strgyId, weight, highlightedItems } = action.payload;

  let portfoliosIdsForUpdate = [];
  let benchmarksIdsForUpdate = [];
  let nestedStrategiesIdsForUpdate = [];
  let securityIdsForUpdate = [];

  highlightedItems.forEach((item) => {
    if (
      item.type === ASSET_TYPES.portfolio ||
      item.type === ADDITIONAL_ASSET_TYPES.methodology
    ) {
      portfoliosIdsForUpdate.push(item.id);
    } else if (item.type === ASSET_TYPES.nestedStrategies) {
      nestedStrategiesIdsForUpdate.push(item.id);
    } else if (item.type === ADDITIONAL_ASSET_TYPES.index) {
      benchmarksIdsForUpdate.push(item.id);
    } else if (item.type === ASSET_TYPES.security) {
      securityIdsForUpdate.push(item.id);
    } else {
      throw new Error("Unknown type " + item?.type);
    }
  });

  const resPort = portfoliosIdsForUpdate.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: {
        ...state.portfolios.byId[currentValue],
        weight,
      },
    }),
    {}
  );

  const resBench = benchmarksIdsForUpdate.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: {
        ...state.benchmarks.byId[currentValue],
        weight,
      },
    }),
    {}
  );

  const resNestedStrategies = nestedStrategiesIdsForUpdate.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: {
        ...state.nestedStrategies.byId[currentValue],
        weight,
      },
    }),
    {}
  );

  const resSecurity = securityIdsForUpdate.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: {
        ...state.security.byId[currentValue],
        weight,
      },
    }),
    {}
  );

  return {
    ...state,
    portfolios: {
      ...state.portfolios,
      byId: {
        ...state.portfolios.byId,
        ...resPort,
      },
    },
    benchmarks: {
      ...state.benchmarks,
      byId: {
        ...state.benchmarks.byId,
        ...resBench,
      },
    },
    nestedStrategies: {
      ...state.nestedStrategies,
      byId: {
        ...state.nestedStrategies.byId,
        ...resNestedStrategies,
      },
    },
    security: {
      ...state.security,
      byId: {
        ...state.security.byId,
        ...resSecurity,
      },
    },
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strgyId]: {
          ...state.strategies.byId[strgyId],
          present: {
            ...state.strategies.byId[strgyId].present,
          },
          past: generatePastHistory(state, strgyId),
        },
      },
    },
  };
}

function initializeStrategy(state, action) {
  const { payload } = action;
  const { id, user } = payload;
  const strategyCreator = user ? user.name : "Guest";

  return {
    ...state,
    strategies: initializeNewStrategy(state, id, strategyCreator),
    orderOfStrategies: [...state.orderOfStrategies, id],
  };
}

function searchPortfolioRequest(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      isPortfolioLoading: true,
    },
  };
}

function searchPortfolio(state, action) {
  return {
    ...state,
    assets: action.payload,
    loaders: {
      ...state.loaders,
      isPortfolioLoading: false,
    },
  };
}

function clearStrategy(state, action) {
  const { strategyId } = action.payload;
  return {
    ...state,
    strategies: emptySelectedStrategy(state, strategyId),
  };
}

function clearAllStrategies(state, action) {
  let resById = {};
  let resOrderOfStrategies = [];

  if (action.payload) {
    resById[action.payload] = state.strategies.byId[action.payload];
    resOrderOfStrategies.push(action.payload);
  }

  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: resById,
    },
    strategiesStatistics: { byId: {} },
    orderOfStrategies: resOrderOfStrategies,
  };
}

function deleteAssetRow(state, action) {
  const { key, rowId, strategyId, assetId } = action.payload;
  const rowsIds = state.strategies.byId[strategyId].present[key].byId;

  const rowsbyAstId = state.strategies.byId[strategyId].present[key].byAstId;
  rowsbyAstId.delete(assetId);
  const afterDeleteIds = rowsIds.filter((element) => element !== rowId);
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          present: {
            ...state.strategies.byId[strategyId].present,
            [key]: {
              ...state.strategies.byId[strategyId].present[key],
              byId: afterDeleteIds,
              byAstId: rowsbyAstId,
            },
          },
          name: getStrategyNameOnAssetDelete(
            key,
            rowId,
            state.strategies.byId[strategyId],
            afterDeleteIds,
            state
          ),
          past: generatePastHistory(state, strategyId),
          order: state.strategies.byId[strategyId].order.filter(
            (item) => item !== rowId
          ),
        },
      },
    },
  };
}

function deleteHighlightedRows(state, action) {
  const { strgyId, highlightedItems } = action.payload;

  const portfoliosIds = state.strategies.byId[strgyId].present.portfolios.byId;
  const benchmarksIds = state.strategies.byId[strgyId].present.benchmarks.byId;
  const nestedStrategiesIds =
    state.strategies.byId[strgyId].present.nestedStrategies.byId;
  const securityIds = state.strategies.byId[strgyId].present.security.byId;

  const portfoliosByAstId =
    state.strategies.byId[strgyId].present.portfolios.byAstId;

  const benchmarksbyAstId =
    state.strategies.byId[strgyId].present.benchmarks.byAstId;

  const nestedStrategiesbyAstId =
    state.strategies.byId[strgyId].present.nestedStrategies.byAstId;

  const securitybyAstId =
    state.strategies.byId[strgyId].present.security.byAstId;

  let portfoliosForDelete = [];
  let benchmarksForDelete = [];
  let nestedStrategiesForDelete = [];
  let securityForDelete = [];

  highlightedItems.forEach((item) => {
    if (
      item.type === ASSET_TYPES.portfolio ||
      item.type === ADDITIONAL_ASSET_TYPES.methodology
    ) {
      portfoliosForDelete.push(item.id);
      portfoliosByAstId.delete(item.assetId);
    } else if (item.type === ASSET_TYPES.nestedStrategies) {
      nestedStrategiesForDelete.push(item.id);
      nestedStrategiesbyAstId.delete(item.assetId);
    } else if (item.type === ADDITIONAL_ASSET_TYPES.index) {
      benchmarksForDelete.push(item.id);
      benchmarksbyAstId.delete(item.assetId);
    } else if (item.type === ASSET_TYPES.security) {
      securityForDelete.push(item.id);
      securitybyAstId.delete(item.assetId);
    } else {
      throw new Error("Unknown type " + item?.type);
    }
  });

  const afterDeletePortfoliosIds = portfoliosIds.filter(
    (x) => !portfoliosForDelete.includes(x)
  );

  const afterDeleteBenchmarksIds = benchmarksIds.filter(
    (x) => !benchmarksForDelete.includes(x)
  );

  const afterDeleteNestedStrategiesIds = nestedStrategiesIds.filter(
    (x) => !nestedStrategiesForDelete.includes(x)
  );

  const afterDeleteSecurityIds = securityIds.filter(
    (x) => !securityForDelete.includes(x)
  );

  const afterDeleteIds = [
    ...afterDeletePortfoliosIds,
    ...afterDeleteBenchmarksIds,
    ...afterDeleteNestedStrategiesIds,
    ...afterDeleteSecurityIds,
  ];

  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strgyId]: {
          ...state.strategies.byId[strgyId],
          name:
            afterDeletePortfoliosIds.length > 0
              ? getStrategyNameOnPortfolioDelete(
                  state.strategies.byId[strgyId],
                  benchmarksIds,
                  afterDeletePortfoliosIds,
                  state
                )
              : getStrategyNameOnBenchmarkDelete(
                  state.strategies.byId[strgyId],
                  portfoliosIds,
                  afterDeleteBenchmarksIds,
                  state
                ),
          present: {
            ...state.strategies.byId[strgyId].present,
            portfolios: {
              ...state.strategies.byId[strgyId].present.portfolios,
              byId: afterDeletePortfoliosIds,
              byAstId: portfoliosByAstId,
            },
            benchmarks: {
              ...state.strategies.byId[strgyId].present.benchmarks,
              byId: afterDeleteBenchmarksIds,
              byAstId: benchmarksbyAstId,
            },
            nestedStrategies: {
              ...state.strategies.byId[strgyId].present.nestedStrategies,
              byId: afterDeleteNestedStrategiesIds,
              byAstId: nestedStrategiesbyAstId,
            },
            security: {
              ...state.strategies.byId[strgyId].present.security,
              byId: afterDeleteSecurityIds,
              byAstId: securitybyAstId,
            },
          },
          past: generatePastHistory(state, strgyId),
          order: state.strategies.byId[strgyId].order.filter((id) =>
            afterDeleteIds.includes(id)
          ),
        },
      },
    },
  };
}

function searchFailed(state) {
  return {
    ...state,
    assets: [],
    loaders: {
      ...state.loaders,
      isPortfolioLoading: false,
    },
  };
}

function loadCompleted(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      isPortfolioLoading: false,
    },
  };
}

function workingGroupRequest(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      isWorkingGroupsLoading: true,
    },
  };
}

function workingGroupRequestFailed(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      isWorkingGroupsLoading: false,
    },
  };
}

function statisticsRequest(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      isStatisticsLoading: true,
    },
  };
}

function saveStatistics(state, action) {
  const { payload } = action;
  const {
    start_date,
    end_date,
    win_rate_table,
    beta_table,
    correlation_matrix,
    rolling_returns,
    rolling_correlation,
    alpha_table,
    ...strats
  } = payload;

  return {
    ...state,
    loaders: {
      ...state.loaders,
      isStatisticsLoading: false,
    },
    strategiesStatistics: updateStrategiesStatistics(
      state,
      start_date,
      end_date,
      win_rate_table,
      rolling_returns,
      rolling_correlation,
      beta_table,
      alpha_table,
      correlation_matrix,
      strats,
      payload
    ),

    selectedStatisticsStrategies: Object.keys(strats).filter(
      (strategy) => Object.keys(strats[strategy].cumulative_returns).length > 0
    ),
  };
}

function statisticsFailed(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      isStatisticsLoading: false,
    },
  };
}

function setStrategyCreatorNameAndDates(state, action) {
  const { payload } = action;
  const { strategyTitle, strategyId } = payload;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          strategyCreator: strategyTitle.user_name,
          date: strategyTitle.initialization_date,
          name: strategyTitle.name,
          editedStrategyName: true,
        },
      },
    },
  };
}

function removeStrategy(state, action) {
  const { strategyId } = action.payload;

  return {
    ...state,
    strategies: removeStrategyById(state, strategyId),
    benchmarks: removeAllAssetsBySelectedStrategy(
      ASSET_STRATEGY_KEYS.benchmarks,
      state,
      collectRemovableAssets(state, strategyId, ASSET_STRATEGY_KEYS.benchmarks)
    ),
    portfolios: removeAllAssetsBySelectedStrategy(
      ASSET_STRATEGY_KEYS.portfolios,
      state,
      collectRemovableAssets(state, strategyId, ASSET_STRATEGY_KEYS.portfolios)
    ),
    nestedStrategies: removeAllAssetsBySelectedStrategy(
      "nestedStrategies",
      state,
      collectRemovableAssets(state, strategyId, "nestedStrategy")
    ),
    security: removeAllAssetsBySelectedStrategy(
      ASSET_STRATEGY_KEYS.security,
      state,
      collectRemovableAssets(state, strategyId, ASSET_STRATEGY_KEYS.security)
    ),
    orderOfStrategies: state.orderOfStrategies.filter(
      (item) => item !== strategyId
    ),
    strategiesStatistics: {
      ...state.strategiesStatistics,
      byId: filtered(state.strategiesStatistics.byId, new Set([strategyId])),
      winRate: state.strategiesStatistics.winRate
        ? filtered(state.strategiesStatistics.winRate, new Set([strategyId]))
        : {},
    },
  };
}

function updateStrategyName(state, action) {
  const { strategyName, strategyId } = action.payload;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          name: strategyName,
          editedStrategyName: true,
        },
      },
    },
  };
}

function updateStrategyLongPos(state, action) {
  const { longPos, strategyId } = action.payload;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          longPos,
          exposure: Math.abs(
            longPos - state.strategies.byId[strategyId].shortPos
          ),
        },
      },
    },
  };
}

function updateStrategyShortPos(state, action) {
  const { shortPos, strategyId } = action.payload;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          shortPos,
          exposure: Math.abs(
            shortPos - state.strategies.byId[strategyId].longPos
          ),
        },
      },
    },
  };
}

function updateCounter(state) {
  return {
    ...state,
    count: state.count + 1,
  };
}

function updateWinRateTable(state, action) {
  return {
    ...state,
    strategiesStatistics: {
      ...state.strategiesStatistics,
      winRate: action.payload,
    },
    loaders: {
      ...state.loaders,
      winrateData: false,
    },
  };
}

function checkRevision(state, dates, strategyId) {
  if (state.strategies.byId[strategyId].revisionVersion?.portfolio_id) {
    return state.strategies.byId[strategyId].revisionVersion;
  } else {
    return dates.reduce((a, b) => {
      return new Date(a.initialization_date) > new Date(b.initialization_date)
        ? a
        : b;
    });
  }
}

function updateListOfStrategyInitializationDates(state, action) {
  const { dates, strategyId } = action.payload;

  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          initializationDates: dates,
          revisionVersion: checkRevision(state, dates, strategyId),
        },
      },
    },
  };
}

function copyStrategyContent(state, action) {
  const { id, strategyId } = action.payload;
  return cloneStrategy(id, strategyId, state);
}

function undo(state, action) {
  const { strategyId } = action.payload;

  const past = state.strategies.byId[strategyId].past.pop();

  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          present: {
            ...state.strategies.byId[strategyId].present,
            benchmarks: past.ids.benchmarks,
            portfolios: past.ids.portfolios,
            nestedStrategies: past.ids.nestedStrategies,
            security: past.ids.security,
          },
          name: getStrategyNameOnUndo(
            state.strategies.byId[strategyId],
            past.ids.benchmarks,
            past.ids.portfolios,
            state.benchmarks,
            state.portfolios
          ),
          past: state.strategies.byId[strategyId].past,
          future: generateFutureHistory(state, strategyId),
          order: past.order,
        },
      },
    },
    benchmarks: {
      ...state.benchmarks,
      byId: {
        ...state.benchmarks.byId,
        ...past.values.benchmarks,
      },
    },

    portfolios: {
      ...state.portfolios,
      byId: {
        ...state.portfolios.byId,
        ...past.values.portfolios,
      },
    },

    nestedStrategies: {
      ...state.nestedStrategies,
      byId: {
        ...state.nestedStrategies.byId,
        ...past.values.nestedStrategies,
      },
    },
    security: {
      ...state.security,
      byId: {
        ...state.security.byId,
        ...past.values.security,
      },
    },
  };
}

function redo(state, action) {
  const { strategyId } = action.payload;

  const future = state.strategies.byId[strategyId].future.pop();

  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          present: {
            ...state.strategies.byId[strategyId].present,
            benchmarks: future.ids.benchmarks,
            portfolios: future.ids.portfolios,
            nestedStrategies: future.ids.nestedStrategies,
            security: future.ids.security,
          },
          name: getStrategyNameOnUndo(
            state.strategies.byId[strategyId],
            future.ids.benchmarks,
            future.ids.portfolios,
            state.benchmarks,
            state.portfolios
          ),
          past: generatePastHistory(state, strategyId),

          future: state.strategies.byId[strategyId].future,
          order: future.order,
        },
      },
    },
    benchmarks: {
      ...state.benchmarks,
      byId: {
        ...state.benchmarks.byId,
        ...future.values.benchmarks,
      },
    },

    portfolios: {
      ...state.portfolios,
      byId: {
        ...state.portfolios.byId,
        ...future.values.portfolios,
      },
    },

    nestedStrategies: {
      ...state.nestedStrategies,
      byId: {
        ...state.nestedStrategies.byId,
        ...future.values.nestedStrategies,
      },
    },

    security: {
      ...state.security,
      byId: {
        ...state.security.byId,
        ...future.values.security,
      },
    },
  };
}

const handleUpdatePortfolioIdsList = (array, id, prevPortfolioId) => {
  const index = array.indexOf(prevPortfolioId);
  if (index !== -1) {
    array[index] = id;
  }
  return array;
};

function edit(state, action) {
  const { payload } = action;
  const {
    key,
    asset,
    id,
    strategyId,
    weight,
    position,
    prevPortfolioId,
    prevAssetId,
  } = payload;
  const newLong = getNewLong(
    key,
    position,
    state,
    strategyId,
    weight,
    prevPortfolioId
  );

  return {
    ...state,
    portfolios: setNewAsset(
      key,
      state,
      id,
      asset,
      parseFloat(weight),
      position,
      false
    ),
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          name: getStrategyNameOnEdit(state.strategies.byId[strategyId], asset),
          present: {
            ...state.strategies.byId[strategyId].present,
            portfolios: {
              byId: [
                ...handleUpdatePortfolioIdsList(
                  state.strategies.byId[strategyId].present.portfolios.byId,
                  id,
                  prevPortfolioId
                ),
              ],
              byAstId: handleUpdateSetOfAssetIds(
                state,
                strategyId,
                asset,
                prevAssetId
              ),
            },
          },
          past: generatePastHistory(state, strategyId),
          longPos: newLong,
        },
      },
    },
  };
}

function updateRollingReturns(state, action) {
  const { payload } = action;
  return {
    ...state,
    strategiesStatistics: {
      ...state.strategiesStatistics,
      byId: {
        ...state.strategiesStatistics.byId,
        ...Object.assign(
          {},
          ...Object.keys(payload.response).map((key) => ({
            [key]: {
              ...state.strategiesStatistics.byId[key],
              stats: {
                ...state.strategiesStatistics.byId[key].stats,
                rollingRets: payload.response[key],
              },
            },
          }))
        ),
      },
    },
  };
}

function rollingCorellation(state, action) {
  const { payload } = action;
  return {
    ...state,
    strategiesStatistics: {
      ...state.strategiesStatistics,
      rollingCorellation: payload.response,
    },
    loaders: {
      ...state.loaders,
      rollingCorrelation: false,
    },
  };
}

function rollingCorellationLoading(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      rollingCorrelation: true,
    },
  };
}

function rollingCorellationFulfilled(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      rollingCorrelation: false,
    },
  };
}

function updatePortfolioBetaTable(state, action) {
  return {
    ...state,
    strategiesStatistics: {
      ...state.strategiesStatistics,
      beta: action.payload.beta,
      alpha: action.payload.alpha,
    },
    loaders: {
      ...state.loaders,
      betaTable: false,
    },
  };
}

function updatePortfolioBetaTableLoading(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      betaTable: true,
    },
  };
}

function updatePortfolioBetaTableFulfilled(state) {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      betaTable: false,
    },
  };
}

function toggleFetchingStocks(state, action) {
  const { payload } = action;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [payload.strategyId]: {
          ...state.strategies.byId[payload.strategyId],
          stocks: {
            ...state.strategies.byId[payload.strategyId].stocks,
            status: payload.status,
          },
        },
      },
    },
  };
}

function fetchingStocksFulfilled(state, action) {
  const { payload } = action;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [payload.strategyId]: {
          ...state.strategies.byId[payload.strategyId],
          stocks: {
            ...state.strategies.byId[payload.strategyId].stocks,
            status: payload.status,
            stocks: payload.response,
          },
        },
      },
    },
  };
}

function fetchingStocksRejected(state, action) {
  const { payload } = action;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [payload.strategyId]: {
          ...state.strategies.byId[payload.strategyId],
          stocks: {
            ...state.strategies.byId[payload.strategyId].stocks,
            status: payload.status,
            error: payload.error,
          },
        },
      },
    },
  };
}

function addNewAssetToggleFetching(state, action) {
  const { payload } = action;
  return {
    ...state,
    loaders: {
      ...state.loaders,
      newAsset: payload,
    },
  };
}

function addWorkingGroup(state, action) {
  const { payload } = action;
  return {
    ...state,
    workingGroups: payload,
    loaders: {
      ...state.loaders,
      isWorkingGroupsLoading: false,
    },
  };
}

function selectedStatisticsStrategiesChange(state, action) {
  const { payload } = action;
  return {
    ...state,
    selectedStatisticsStrategies:
      state.selectedStatisticsStrategies.indexOf(payload) >= 0
        ? state.selectedStatisticsStrategies.filter((item) => item !== payload)
        : [...state.selectedStatisticsStrategies, payload],
  };
}

function updateRevisionVersion(state, action) {
  const { strategy_id, revision } = action.payload;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategy_id]: {
          ...state.strategies.byId[strategy_id],
          revisionVersion: revision,
        },
      },
    },
  };
}

function addNestedStrategy(state, action) {
  const nestedIds =
    state.strategies.byId[action.payload.strategyId].present.nestedStrategies
      .byId;
  const nestedAstIds =
    state.strategies.byId[action.payload.strategyId].present.nestedStrategies
      .byAstId;
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [action.payload.strategyId]: {
          ...state.strategies.byId[action.payload.strategyId],
          present: {
            ...state.strategies.byId[action.payload.strategyId].present,
            nestedStrategies: {
              ...state.strategies.byId[action.payload.strategyId].present
                .nestedStrategies,
              byId: [...nestedIds, ...[action.payload.nestedId]],
              byAstId: new Set([
                ...nestedAstIds,
                ...new Set([action.payload.nestedStrategy.asset_id]),
              ]),
            },
          },
          past: generatePastHistory(state, action.payload.strategyId),
          order: [
            ...state.strategies.byId[action.payload.strategyId].order,
            action.payload.nestedId,
          ],
        },
      },
    },
    nestedStrategies: {
      ...state.nestedStrategies,
      byId: {
        ...state.nestedStrategies.byId,
        [action.payload.nestedId]: action.payload.nestedStrategy,
      },
    },
  };
}

function replaceNestedStrategy(state, action) {
  const { nestedStrategy, oldNestedStrategyId, strategyId, prevAssetId } =
    action.payload;
  let nestedIds =
    state.strategies.byId[strategyId].present.nestedStrategies.byId;
  nestedIds[nestedIds.indexOf(oldNestedStrategyId)] = nestedStrategy.id;
  let nestedAstIds =
    state.strategies.byId[strategyId].present.nestedStrategies.byAstId;
  nestedAstIds.delete(prevAssetId);
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          present: {
            ...state.strategies.byId[strategyId].present,
            nestedStrategies: {
              ...state.strategies.byId[strategyId].present.nestedStrategies,
              byId: nestedIds,
              byAstId: new Set([
                ...nestedAstIds,
                ...new Set([nestedStrategy.asset_id]),
              ]),
            },
          },
          past: generatePastHistory(state, strategyId),
        },
      },
    },
    nestedStrategies: {
      ...state.nestedStrategies,
      byId: {
        ...state.nestedStrategies.byId,
        [nestedStrategy.id]: nestedStrategy,
      },
    },
  };
}

function replaceSecurityStrategy(state, action) {
  const { security, oldSecurityId, strategyId, prevAssetId } = action.payload;
  let securityIds = state.strategies.byId[strategyId].present.security.byId;
  securityIds[securityIds.indexOf(oldSecurityId)] = security.id;
  let nestedAstIds = state.strategies.byId[strategyId].present.security.byAstId;
  nestedAstIds.delete(prevAssetId);
  return {
    ...state,
    strategies: {
      ...state.strategies,
      byId: {
        ...state.strategies.byId,
        [strategyId]: {
          ...state.strategies.byId[strategyId],
          present: {
            ...state.strategies.byId[strategyId].present,
            security: {
              ...state.strategies.byId[strategyId].present.security,
              byId: securityIds,
              byAstId: new Set([
                ...nestedAstIds,
                ...new Set([security.asset_id]),
              ]),
            },
          },
          past: generatePastHistory(state, strategyId),
        },
      },
    },
    security: {
      ...state.security,
      byId: {
        ...state.security.byId,
        [security.id]: security,
      },
    },
  };
}

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_OF_STRATEGIES:
      return setOrderOfStrategies(state, action);
    case LOAD_PORTFOLIO_START:
      return searchPortfolioRequest(state);
    case LOAD_PORTFOLIO_COMPLETED:
      return loadCompleted(state);
    case SEARCH_PORTFOLIO_START:
      return searchPortfolioRequest(state);
    case SEARCH_PORTFOLIO_SUCCESS:
      return searchPortfolio(state, action);
    case SEARCH_FAILED:
      return searchFailed(state);
    case ADD_NEW_PORTFOLIO:
      return addNewPortfolio(state, action);
    case INITIALIZE_STRATEGY:
      return initializeStrategy(state, action);
    case ADD_NEW_SECURITY:
      return addNewSecurity(state, action);
    case ADD_NEW_BENCHMARK:
      return addNewBenchmark(state, action);
    case GET_STATISTICS_START:
      return statisticsRequest(state);
    case GET_STATISTICS_SUCCESS:
      return saveStatistics(state, action);
    case GET_STATISTICS_FAILED:
      return statisticsFailed(state);

    case CLEAR_STRATEGY:
      return clearStrategy(state, action);
    case CLEAR_ALL_STRATEGIES:
      return clearAllStrategies(state, action);

    case SET_ASSET_POSITION:
      return setAssetPosition(state, action);

    case SET_ASSET_WEIGHT:
      return setAssetWeight(state, action);

    case SET_ASSET_BULK_WEIGHT:
      return setAssetBulkWeight(state, action);

    case SET_STRATEGY_CREATOR_NAME_AND_DATES:
      return setStrategyCreatorNameAndDates(state, action);
    case DELETE_STRATEGY:
      return removeStrategy(state, action);

    case DELETE_ASSET_ROW:
      return deleteAssetRow(state, action);
    case UPDATE_STRATEGY_NAME:
      return updateStrategyName(state, action);
    case UPDATE_STRATEGY_LONG_POS:
      return updateStrategyLongPos(state, action);
    case UPDATE_STRATEGY_SHORT_POS:
      return updateStrategyShortPos(state, action);

    case UPDATE_ASSET_BULK:
      return updateAssetBulk(state, action);
    case UPDATE_COUNTER:
      return updateCounter(state, action);

    case UPDATE_WIN_RATE_TABLE_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          winrateData: true,
        },
      };
    case UPDATE_WIN_RATE_TABLE_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          winrateData: false,
        },
      };

    case UPDATE_WIN_RATE_TABLE_SUCCESS:
      return updateWinRateTable(state, action);

    case UPDATE_WIN_RATE_TABLE_FACTORS_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          winrateData: false,
        },
      };

    case UPDATE_STRATEGY_INITIALIZATION_DATES:
      return updateListOfStrategyInitializationDates(state, action);
    case COPY_STRATEGY_CONTENT:
      return copyStrategyContent(state, action);
    case UNDO:
      return undo(state, action);
    case REDO:
      return redo(state, action);
    case ROLLING_CORELLATION:
      return rollingCorellation(state, action);
    case ROLLING_CORRELATION_LOADING:
      return rollingCorellationLoading(state);
    case ROLLING_CORRELATION_FULFILLED:
      return rollingCorellationFulfilled(state);
    case STRATEGY_EDIT:
      return edit(state, action);
    case ROLLING_RETURNS_FULFILLED:
      return updateRollingReturns(state, action);
    case UPDATE_PORTFOLIO_BETA_TABLE:
      return updatePortfolioBetaTable(state, action);
    case STOCKS_TOGGLE_FETCHING:
      return toggleFetchingStocks(state, action);
    case STOCKS_FULFILLED:
      return fetchingStocksFulfilled(state, action);
    case STOCKS_REJECTED:
      return fetchingStocksRejected(state, action);
    case UPDATE_PORTFOLIO_BETA_TABLE_LOADING:
      return updatePortfolioBetaTableLoading(state);
    case UPDATE_PORTFOLIO_BETA_TABLE_FULFILLED:
      return updatePortfolioBetaTableFulfilled(state);
    case ADD_NEW_ASSET_TOGGLE_FETCHING:
      return addNewAssetToggleFetching(state, action);
    case DELETE_HIGHLIGHTED_ROWS:
      return deleteHighlightedRows(state, action);
    case DUPLICATE_HIGHLIGHTED_ROWS:
      return duplicateHighlightedRows(state, action);
    case UPDATE_WEIGHT_HIGHLIGHTED_ROWS:
      return updateWeightHighlightedRows(state, action);
    case REPLACE_BENCHMARK_START:
      return addNewAssetToggleFetching(state, action);
    case REPLACE_BENCHMARK_SUCCESS:
      return replaceBenchmark(state, action);
    case REPLACE_BENCHMARK_FAILED:
      return addNewAssetToggleFetching(state, action);
    case GET_WORKING_GROUP:
      return addWorkingGroup(state, action);
    case GET_WORKING_GROUP_START:
      return workingGroupRequest(state, action);
    case GET_WORKING_GROUP_FAILED:
      return workingGroupRequestFailed(state, action);
    case SELECTED_STATISTICS_STRATEGIES_CHANGE:
      return selectedStatisticsStrategiesChange(state, action);

    case GET_SHORT_ASSET_LIST_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isPortfolioLoading: true,
        },
      };
    case GET_SHORT_ASSET_LIST_SUCCESS:
      return {
        ...state,
        shortAssetsList: action.payload.short_list,
        loaders: {
          ...state.loaders,
          isPortfolioLoading: false,
        },
      };
    case GET_SHORT_ASSET_LIST_FAILED:
      return {
        ...state,
        shortAssetsList: [],
        loaders: {
          ...state.loaders,
          isPortfolioLoading: false,
        },
      };

    case SET_REVISION_VERSION:
      return updateRevisionVersion(state, action);
    case ADD_NESTED_STRATEGY:
      return addNestedStrategy(state, action);

    case REPLACE_NESTED_STRATEGY:
      return replaceNestedStrategy(state, action);
    case REPLACE_SINGLE_SECURITY:
      return replaceSecurityStrategy(state, action);

    case SET_CONSTRUCTOR_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }

    case UPDATE_ORDER_STRATEGY: {
      return {
        ...state,
        strategies: {
          ...state.strategies,
          byId: {
            ...state.strategies.byId,
            [action.payload.strategyId]: {
              ...state.strategies.byId[action.payload.strategyId],
              past: generatePastHistory(
                state,
                action.payload.strategyId,
                action.payload.prevOrder
              ),
              order: action.payload.order,
            },
          },
        },
      };
    }

    case UPDATE_USER_FACTORS_GROUPING_SUCCESS: {
      return {
        ...state,
        assets: [],
      };
    }

    case SET_HIGHLIGHTED_ROWS: {
      return {
        ...state,
        strategies: {
          ...state.strategies,
          byId: {
            ...state.strategies.byId,
            [action.payload.strategyId]: {
              ...state.strategies.byId[action.payload.strategyId],
              highlightedRows: action.payload.highlightedRows,
            },
          },
        },
      };
    }

    case TYPE_ID_DOES_NOT_HANDLED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          newAsset: false,
        },
      };
    }

    case RESET_DEFAULT: {
      return {
        ...state,
        activeTab: 0,
        strategies: { byId: {} },
        strategiesStatistics: { byId: {} },
        selectedStatisticsStrategies: [],
        orderOfStrategies: [],
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
