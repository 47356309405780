const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
    <g data-name="Layer 2">
      <path d="M3.625 23h9.75A2.629 2.629 0 0 0 16 20.375V7.625A2.629 2.629 0 0 0 13.375 5h-9.75A2.629 2.629 0 0 0 1 7.625v12.75A2.629 2.629 0 0 0 3.625 23zM3 7.625A.625.625 0 0 1 3.625 7h9.75a.625.625 0 0 1 .625.625v12.75a.625.625 0 0 1-.625.625h-9.75A.625.625 0 0 1 3 20.375z" />
      <path d="M20.37 1h-9.74a2.629 2.629 0 0 0-2.421 1.61 1 1 0 1 0 1.842.78.63.63 0 0 1 .579-.39h9.74a.631.631 0 0 1 .63.63v12.74a.631.631 0 0 1-.63.63H18a1 1 0 0 0 0 2h2.37A2.633 2.633 0 0 0 23 16.37V3.63A2.633 2.633 0 0 0 20.37 1z" />
    </g>
  </svg>
);

export default CopyIcon;
