import React from "react";
import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
  Button,
} from "@mui/material";
import style from "./Login.module.scss";
import { useSnackbar } from "notistack";
import { RestorePasswordValidation } from "./validation";
import { useFormik } from "formik";
import { Box } from "@mui/system";

function ResetPasswordForm() {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (values) => {
    enqueueSnackbar("Feature not available right now", {
      variant: "info",
    });
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RestorePasswordValidation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <div className={style.formWrapper}>
      <form onSubmit={handleSubmit} className={style.form} noValidate>
        <h1>Reset Your Password</h1>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography
            className={style.formInputLogin}
            textAlign="center"
            mt={2}
            fontSize={"0.8rem"}
          >
            Please provide the email address you used when you signed up for
            your account.
          </Typography>
          <FormControl mt={2}>
            <OutlinedInput
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Email"
              size="small"
              error={touched.email && Boolean(errors.email)}
            />
            {touched.email && Boolean(errors.email) && (
              <FormHelperText error>{errors.email}</FormHelperText>
            )}
          </FormControl>
          <Box>
            <Button disabled={isSubmitting} variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
}

export default ResetPasswordForm;
