import {
  CLEAR_COUNTRIES_ERROR,
  LOGOUT,
  NOT_LOADED,
  UPDATE_EDIT_DATA,
} from "../actions/Type";

const initialState = {
  error: "",
  isLoading: false,
};

export default function editMode(state = initialState, action) {
  switch (action.type) {
    case NOT_LOADED:
      return { ...state, error: action.payload, isLoading: false };

    case UPDATE_EDIT_DATA:
      return {
        ...state,
        [action.payload.portfolioId]: {
          filters: action.payload.filters,
          directions: action.payload.directions,
          sizes: action.payload.sizes,
          frequencies: action.payload.frequencies,
          currencies: action.payload.currencies,
          asset_id: action.payload.asset_id,
          countries: action.payload.countries,
        },
        isLoading: false,
      };
    case CLEAR_COUNTRIES_ERROR:
      return { ...state, error: "" };

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
