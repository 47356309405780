import StockApi from "../../services/Stock";
import { generateErrorMessage } from "../../helpers/Error";
import * as StockTypes from "../types/Stock";
import SnackBarUtils from "../../helpers/SnackBarUtils";
import { batch } from "react-redux";
import {
  CUSTOM_MULTIFACTOR,
  CUSTOM_RATIO,
  DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION,
  DEFAULT_STOCK_SCREENER_UNIVERSE_FILTER_OPTION,
  StockScreenerScaleBySetting,
} from "../../features/StockScreener/consts";
import {
  compareArrays,
  convertResponseChildsDataToStockFactorChilds,
  createChildsForNewStock,
  EditedStockFactor,
  mapStockLocalParams,
} from "../../helpers/Stock";
import nextId from "react-id-generator";
import { calcPersentileToValue } from "../../helpers/Stock";

const getStockExtendLocalParamsFromState = (presetName, stock) => {
  const { selectedFactors, selectedFactorsOrder, settings, filters } = stock;

  const btm_filters = selectedFactorsOrder.map((id) =>
    selectedFactors[id].convertStockFactorToRequest()
  );

  const params = {
    preset: [
      {
        name: presetName,
        scale_financials: settings.scaleFinancials === "true" ? true : false,
        scale_by: settings.scaleBy,
        top_filters: [
          {
            universe: [filters.selected.universe],
            country: filters.selected.country,
            economy: filters.selected.economy,
            sector: filters.selected.sector,
          },
        ],
        btm_filters,
      },
    ],
  };

  return params;
};

const checkIsNeedExtendedParams = (
  selectedFactors,
  selectedFactorsOrder,
  requestArr
) => {
  const params = selectedFactorsOrder
    .filter(
      (id) =>
        requestArr.includes(selectedFactors[id].name) &&
        (selectedFactors[id].variable === CUSTOM_MULTIFACTOR ||
          selectedFactors[id].variable === CUSTOM_RATIO)
    )
    ?.map((id) => selectedFactors[id].convertStockFactorToRequest());

  const local_params = requestArr.filter(
    (item) => !params.find((el) => el.name === item)
  );

  return [local_params, params];
};

export const getStockLocalParamsFromState = () => (_, getState) => {
  const state = getState();

  return mapStockLocalParams(state.stock.selectedFactors);
};

export const getStockGlobalParams = () => (_, getState) => {
  const state = getState();

  const global_params = {
    universe: state.stock.filters.selected.universe,
    countries: state.stock.filters.selected.country,
    economy: state.stock.filters.selected.economy,
    sector: state.stock.filters.selected.sector,
  };

  return global_params;
};

export const setStockFilter = (key, value) => (dispatch) => {
  dispatch({
    type: StockTypes.SET_STOCK_FILTER,
    payload: { key, value },
  });
};

export const setStockFilterAction =
  (controller, key, value) => (dispatch, getState) => {
    const state = getState();

    const local_params = dispatch(getStockLocalParamsFromState());

    switch (key) {
      case "universe":
        dispatch(
          getStockScreenerCountries({ universe: value }, false, controller)
        );

        break;
      case "country":
        batch(() => {
          dispatch(setStockFilter(key, value));
          dispatch(
            getStockScreenerEconomies({ countries: value }, true, controller)
          );
          dispatch(
            getStockScreenerSectors(
              {
                countries: value,
                economies: state.stock.filters.selected.economy,
              },
              false,
              controller
            )
          );
        });

        break;
      case "economy":
        batch(() => {
          dispatch(setStockFilter(key, value));
          dispatch(
            getStockScreenerSectors(
              {
                countries: state.stock.filters.selected.country,
                economies: value,
              },
              false,
              controller
            )
          );
        });

        break;
      case "sector":
        batch(() => {
          dispatch(setStockFilter(key, value));
          dispatch(
            getDefaultScreen(
              {
                global_params: {
                  universe: state.stock.filters.selected.universe,
                  countries: state.stock.filters.selected.country,
                  economy: state.stock.filters.selected.economy,
                  sector: value,
                },
                local_params,
                scale_financials:
                  state.stock.settings.scaleFinancials === "true"
                    ? true
                    : false,
                scale_by_marketcap:
                  state.stock.settings.scaleBy ===
                  StockScreenerScaleBySetting.Market
                    ? true
                    : false,
              },
              controller
            )
          );
        });
        break;
    }
  };

export const setSelectedGroupIndex = (index) => (dispatch) => {
  dispatch({
    type: StockTypes.SET_STOCK_FACTOR_GROUP_INDEX,
    payload: index,
  });
};

export const addStockFactor = (factor, callback) => (dispatch) => {
  batch(() => {
    dispatch({
      type: StockTypes.ADD_STOCK_FACTOR,
      payload: factor,
    });
    callback && callback();
  });
};

export const updateStockFactor = (data, order) => (dispatch) => {
  // order.sort(function (a, b) {
  //   const itemA = data[a];
  //   const itemB = data[b];

  //   if (itemA.variable === "info" && itemB.variable !== "info") {
  //     return -1;
  //   } else if (itemA.variable !== "info" && itemB.variable === "info") {
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // });

  dispatch({
    type: StockTypes.UPDATE_STOCK_FACTOR,
    payload: { data, order },
  });
};

export const resetStock = (controller) => (dispatch, getState) => {
  const { settings, variables } = getState().stock;

  const allVariables = Object.values(variables).reduce(
    (acc, cur) => [...acc, ...cur],
    []
  );

  const factorsOrder = [];

  const defaultFactors = settings.defaultVariable.reduce((acc, cur) => {
    const curNumberId = Number(cur);
    factorsOrder.push(curNumberId);
    const currentFactor = allVariables.find((item) => item.id === curNumberId);
    return {
      ...acc,
      [curNumberId]: new EditedStockFactor(
        curNumberId,
        currentFactor.name,
        currentFactor.type
      ),
    };
  }, {});

  const local_params = mapStockLocalParams(defaultFactors);

  const params = {
    global_params: {
      universe: DEFAULT_STOCK_SCREENER_UNIVERSE_FILTER_OPTION,
      countries: [DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION],
      economy: [],
      sector: [],
    },
    local_params,
    scale_financials: settings.scaleFinancials === "true" ? true : false,
    scale_by_marketcap:
      settings.scaleBy === StockScreenerScaleBySetting.Market ? true : false,
  };

  dispatch({
    type: StockTypes.RESET_STOCK,
  });

  dispatch(updateStockFactor(defaultFactors, factorsOrder));

  dispatch(getDefaultScreen(params, controller));
};

export const setHighlightedFactors = (id, replace) => (dispatch) => {
  dispatch({
    type: StockTypes.SET_HIGHLIGHTED_STOCK_FACTORS,
    payload: { id, replace },
  });
};

export const getStockScreenerUniverses = (controller) => async (dispatch) => {
  dispatch({ type: StockTypes.GET_STOCK_UNIVERSES_START });
  return StockApi.getStockScreenerUniverses(controller)
    .then((data) => {
      dispatch({
        type: StockTypes.GET_STOCK_UNIVERSES_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: StockTypes.GET_STOCK_UNIVERSES_FAILED });
      generateErrorMessage(error);
    });
};

export const getStockScreenerCountries =
  (params, init, controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.GET_STOCK_COUNTRIES_START });
    return StockApi.getStockScreenerCountries(params, controller)
      .then((data) => {
        if (init) {
          dispatch({
            type: StockTypes.GET_STOCK_COUNTRIES_SUCCESS,
            payload: data,
          });
        } else {
          const state = getState();

          const defaultOption = data.filter((item) =>
            state.stock.filters.selected.country.includes(item)
          ) || [DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION];

          const economyParam = compareArrays(defaultOption, [
            DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION,
          ])
            ? state.stock.filters.options.economyOptions
            : state.stock.filters.selected.economy;

          const sectorParam = compareArrays(defaultOption, [
            DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION,
          ])
            ? state.stock.filters.options.sectorOptions
            : state.stock.filters.selected.sector;

          const local_params = dispatch(getStockLocalParamsFromState());

          batch(() => {
            dispatch(setStockFilter("universe", params.universe));
            dispatch(setStockFilter("country", defaultOption));
            dispatch(setStockFilter("economy", economyParam));
            dispatch(setStockFilter("sector", sectorParam));

            dispatch({
              type: StockTypes.GET_STOCK_COUNTRIES_SUCCESS,
              payload: data,
            });

            dispatch(
              getDefaultScreen(
                {
                  global_params: {
                    universe: params.universe,
                    countries: defaultOption,
                    economy: economyParam,
                    sector: sectorParam,
                  },
                  local_params,
                  scale_financials:
                    state.stock.settings.scaleFinancials === "true"
                      ? true
                      : false,
                  scale_by_marketcap:
                    state.stock.settings.scaleBy ===
                    StockScreenerScaleBySetting.Market
                      ? true
                      : false,
                },
                controller
              )
            );
          });
        }
      })
      .catch((error) => {
        dispatch({ type: StockTypes.GET_STOCK_COUNTRIES_FAILED });
        generateErrorMessage(error);
      });
  };

export const getStockScreenerEconomies =
  (params, init, controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.GET_STOCK_ECONOMIES_START });
    return StockApi.getStockScreenerEconomies(params, controller)
      .then((data) => {
        if (init) {
          dispatch({
            type: StockTypes.GET_STOCK_ECONOMIES_SUCCESS,
            payload: { init, data },
          });
        } else {
          const state = getState();

          const currentEconomyOption =
            data.filter((item) =>
              state.stock.filters.selected.economy.includes(item)
            ) || state.stock.filters.options.economyOptions;

          const local_params = dispatch(getStockLocalParamsFromState());

          batch(() => {
            dispatch(setStockFilter("country", params.countries));
            dispatch(setStockFilter("economy", currentEconomyOption));
            dispatch(setStockFilter("sector", []));
            dispatch({
              type: StockTypes.GET_STOCK_ECONOMIES_SUCCESS,
              payload: { init, data },
            });

            dispatch(
              getDefaultScreen(
                {
                  global_params: {
                    universe: state.stock.filters.selected.universe,
                    countries: params.countries,
                    economy: currentEconomyOption,
                    sector: [],
                  },
                  local_params,
                  scale_financials:
                    state.stock.settings.scaleFinancials === "true"
                      ? true
                      : false,
                  scale_by_marketcap:
                    state.stock.settings.scaleBy ===
                    StockScreenerScaleBySetting.Market
                      ? true
                      : false,
                },
                controller
              )
            );
          });
        }
      })
      .catch((error) => {
        dispatch({ type: StockTypes.GET_STOCK_ECONOMIES_FAILED });
        generateErrorMessage(error);
      });
  };

export const getStockScreenerSectors =
  (params, init, controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.GET_STOCK_SECTORS_START });
    return StockApi.getStockScreenerSectors(params, controller)
      .then((data) => {
        if (init) {
          dispatch({
            type: StockTypes.GET_STOCK_SECTORS_SUCCESS,
            payload: { init, data },
          });
        } else {
          const state = getState();

          const currentSectorOption =
            data.filter((item) =>
              state.stock.filters.selected.sector.includes(item)
            ) || state.stock.filters.options.sectorOptions;

          const local_params = dispatch(getStockLocalParamsFromState());

          batch(() => {
            dispatch(
              setStockFilter("country", state.stock.filters.selected.country)
            );
            dispatch(setStockFilter("economy", params.economies));
            dispatch(setStockFilter("sector", currentSectorOption));
            dispatch({
              type: StockTypes.GET_STOCK_SECTORS_SUCCESS,
              payload: { init, data },
            });

            dispatch(
              getDefaultScreen(
                {
                  global_params: {
                    universe: state.stock.filters.selected.universe,
                    countries: params.countries,
                    economy: params.economies,
                    sector: currentSectorOption,
                  },
                  local_params,
                  scale_financials:
                    state.stock.settings.scaleFinancials === "true"
                      ? true
                      : false,
                  scale_by_marketcap:
                    state.stock.settings.scaleBy ===
                    StockScreenerScaleBySetting.Market
                      ? true
                      : false,
                },
                controller
              )
            );
          });
        }
      })
      .catch((error) => {
        dispatch({ type: StockTypes.GET_STOCK_SECTORS_FAILED });
        generateErrorMessage(error);
      });
  };

export const getDefaultScreen =
  (reqParams, controller, isAdd = false) =>
  async (dispatch, getState) => {
    dispatch({ type: StockTypes.GET_DEFAULT_SCREEN_START });

    const { user, stock } = getState();

    const { selectedFactorsOrder, selectedFactors } = stock;

    const [local_params, params] = checkIsNeedExtendedParams(
      selectedFactors,
      selectedFactorsOrder,
      reqParams.local_params
    );

    const reqData = { ...reqParams, local_params, params };

    return StockApi.getDefaultScreen(
      reqData,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        batch(() => {
          const updatedFactors = Object.values(stock.selectedFactors).reduce(
            (acc, factor) => {
              if (data.screen[factor.name]) {
                const percentileValue = calcPersentileToValue(
                  Object.values(data.screen[factor.name]),
                  factor.percentile
                );
                return {
                  ...acc,
                  [factor.id]: factor.changeValueByKey(
                    "value",
                    percentileValue
                  ),
                };
              } else {
                return {
                  ...acc,
                  [factor.id]: factor,
                };
              }
            },
            {}
          );

          dispatch(
            updateStockFactor(updatedFactors, stock.selectedFactorsOrder)
          );

          dispatch({
            type: StockTypes.GET_DEFAULT_SCREEN_SUCCESS,
            payload: { data, isAdd },
          });
        });
      })
      .catch((error) => {
        dispatch({ type: StockTypes.GET_DEFAULT_SCREEN_FAILED });
        generateErrorMessage(error);
      });
  };

export const getStockScreenerVariables =
  (controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.GET_VARIABLES_START });
    const { user } = getState();
    return StockApi.getStockScreenerVariables(
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: StockTypes.GET_VARIABLES_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({ type: StockTypes.GET_VARIABLES_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateStockDefaultVariables =
  (params, controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.UPDATE_DEFALT_VARIABLES_START });
    const { user } = getState();
    return StockApi.updateStockDefaultVariables(
      params,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        batch(() => {
          dispatch({
            type: StockTypes.UPDATE_DEFALT_VARIABLES_SUCCESS,
            payload: params.columns,
          });
          SnackBarUtils.success(data);
        });
      })
      .catch((error) => {
        dispatch({ type: StockTypes.UPDATE_DEFALT_VARIABLES_FAILED });
        generateErrorMessage(error);
      });
  };

export const getStockScreenerUserSettings =
  (controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.GET_USER_SETTINGS_START });
    const { user } = getState();
    return StockApi.getStockScreenerUserSettings(
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: StockTypes.GET_USER_SETTINGS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({ type: StockTypes.GET_USER_SETTINGS_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateStockScreenerUserSettings =
  (params, controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.UPDATE_USER_SETTINGS_START });
    const { user } = getState();
    return StockApi.updateStockScreenerUserSettings(
      params,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        dispatch({
          type: StockTypes.UPDATE_USER_SETTINGS_SUCCESS,
          payload: params,
        });
        SnackBarUtils.success(data);
      })
      .catch((error) => {
        dispatch({ type: StockTypes.UPDATE_USER_SETTINGS_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateAllStockScreenerSettings =
  (params, callback, controller) => async (dispatch, getState) => {
    Promise.all([
      await dispatch(updateStockDefaultVariables(params.variables, controller)),
      await dispatch(
        updateStockScreenerUserSettings(params.settings, controller)
      ),
    ])
      .then(() => {
        const { selectedFactors, filters } = getState().stock;

        const local_params = mapStockLocalParams(selectedFactors);

        const requestParams = {
          global_params: {
            universe: filters.selected.universe,
            countries: filters.selected.country,
            economy: filters.selected.economy,
            sector: filters.selected.sector,
          },
          local_params,
          scale_financials:
            params.settings.scale_financials === "true" ? true : false,
          scale_by_marketcap:
            params.settings.scale_by === StockScreenerScaleBySetting.Market
              ? true
              : false,
        };

        dispatch(getDefaultScreen(requestParams, controller));
        callback && callback();
      })
      .catch((error) => {
        generateErrorMessage(error);
      });
  };

export const initStockScreener = (controller) => async (dispatch, getState) => {
  Promise.all([
    await dispatch(getStockScreenerUserSettings(controller)),
    await dispatch(getStockScreenerVariables(controller)),
  ])
    .then(() => {
      const { settings, selectedFactors } = getState().stock;

      const local_params = mapStockLocalParams(selectedFactors);

      const params = {
        global_params: {
          universe: DEFAULT_STOCK_SCREENER_UNIVERSE_FILTER_OPTION,
          countries: [DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION],
          economy: [],
          sector: [],
        },
        local_params,
        scale_financials: settings.scaleFinancials === "true" ? true : false,
        scale_by_marketcap:
          settings.scaleBy === StockScreenerScaleBySetting.Market
            ? true
            : false,
      };

      dispatch(getDefaultScreen(params, controller));
    })
    .catch((error) => {
      generateErrorMessage(error);
    });
};

export const createStockScreenerMultifactor =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.CREATE_MULTIFACTOR_START });
    return StockApi.createStockScreenerMultifactor(data.params, controller)
      .then((resp) => {
        batch(() => {
          const { stock } = getState();

          const { selectedFactors, selectedFactorsOrder } = stock;

          const childs = createChildsForNewStock(data.factorsData);

          const childsIds = Object.keys(childs);

          const updatedChildsData = Object.keys(selectedFactors).reduce(
            (acc, cur) => {
              return {
                ...acc,
                [cur]: childsIds.includes(cur)
                  ? selectedFactors[cur].changeValueByKey("inUse", [
                      ...selectedFactors[cur].inUse,
                      data.params.name,
                    ])
                  : selectedFactors[cur],
              };
            },
            {}
          );

          const newFactorId = nextId("stock-factor");

          const newFactorValue = calcPersentileToValue(
            Object.values(resp.screen[data.params.name]),
            0.5
          );

          const newFactorsData = {
            ...updatedChildsData,
            [newFactorId]: new EditedStockFactor(
              newFactorId,
              data.params.name,
              CUSTOM_MULTIFACTOR,
              true,
              newFactorValue,
              0.5,
              "",
              null,
              childs
            ),
          };

          dispatch({
            type: StockTypes.CREATE_MULTIFACTOR_SUCCESS,
            payload: resp,
          });

          dispatch(
            updateStockFactor(newFactorsData, [
              ...selectedFactorsOrder,
              newFactorId,
            ])
          );

          callback && callback();
        });
      })
      .catch((error) => {
        dispatch({ type: StockTypes.CREATE_MULTIFACTOR_FAILED });
        generateErrorMessage(error);
      });
  };

export const createStockScreenerRatio =
  (data, callback, controller) => async (dispatch, getState) => {
    dispatch({ type: StockTypes.CREATE_RATIO_START });
    return StockApi.createStockScreenerRatio(data.params, controller)
      .then((resp) => {
        batch(() => {
          const { stock } = getState();

          const { selectedFactors, selectedFactorsOrder } = stock;

          const childs = createChildsForNewStock(data.factorsData);

          const childsIds = Object.keys(childs);

          const updatedChildsData = Object.keys(selectedFactors).reduce(
            (acc, cur) => {
              return {
                ...acc,
                [cur]: childsIds.includes(cur)
                  ? selectedFactors[cur].changeValueByKey("inUse", [
                      ...selectedFactors[cur].inUse,
                      data.params.name,
                    ])
                  : selectedFactors[cur],
              };
            },
            {}
          );

          const newFactorId = nextId("stock-factor");

          const newFactorValue = calcPersentileToValue(
            Object.values(resp.screen[data.params.name]),
            0.5
          );

          const newFactorsData = {
            ...updatedChildsData,
            [newFactorId]: new EditedStockFactor(
              newFactorId,
              data.params.name,
              CUSTOM_RATIO,
              true,
              newFactorValue,
              0.5,
              "",
              null,
              childs
            ),
          };

          dispatch({
            type: StockTypes.CREATE_RATIO_SUCCESS,
            payload: resp,
          });

          dispatch(
            updateStockFactor(newFactorsData, [
              ...selectedFactorsOrder,
              newFactorId,
            ])
          );

          callback && callback();
        });
      })
      .catch((error) => {
        dispatch({ type: StockTypes.CREATE_RATIO_FAILED });
        generateErrorMessage(error);
      });
  };

export const createStockScreenerBacktest =
  (params, callback, controller) => async (dispatch) => {
    dispatch({ type: StockTypes.CREATE_BACKTEST_START });
    return StockApi.createStockScreenerBacktest(params, controller)
      .then((data) => {
        batch(() => {
          callback && callback();
          dispatch({
            type: StockTypes.CREATE_BACKTEST_SUCCESS,
            payload: data,
          });
        });
      })
      .catch((error) => {
        dispatch({ type: StockTypes.CREATE_BACKTEST_FAILED });
        generateErrorMessage(error);
      });
  };

export const getStockPresets = (controller) => async (dispatch, getState) => {
  const { access_token } = getState().user.userData;
  dispatch({ type: StockTypes.GET_PRESETS_START });
  return StockApi.getStockPresets(access_token, controller)
    .then((data) => {
      dispatch({
        type: StockTypes.GET_PRESETS_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: StockTypes.GET_PRESETS_FAILED });
      generateErrorMessage(error);
    });
};

export const saveStockPreset =
  (presetName, callback, controller) => async (dispatch, getState) => {
    const { user, stock } = getState();
    const { access_token } = user.userData;
    const params = getStockExtendLocalParamsFromState(presetName, stock);
    dispatch({ type: StockTypes.SAVE_PRESET_START });
    return StockApi.saveStockPreset(params, access_token, controller)
      .then((resp) => {
        dispatch({
          type: StockTypes.SAVE_PRESET_SUCCESS,
          payload: resp[0],
        });
        callback();
        SnackBarUtils.success("Preset was successfully saved");
      })
      .catch((error) => {
        dispatch({ type: StockTypes.SAVE_PRESET_FAILED });
        generateErrorMessage(error);
      });
  };

export const updateStockPreset =
  (presetId, presetName, callback, controller) =>
  async (dispatch, getState) => {
    const { user, stock } = getState();
    const { access_token } = user.userData;
    const params = getStockExtendLocalParamsFromState(presetName, stock);
    dispatch({ type: StockTypes.UPDATE_PRESET_START });
    return StockApi.updateStockPreset(
      presetId,
      params,
      access_token,
      controller
    )
      .then((resp) => {
        dispatch({
          type: StockTypes.UPDATE_PRESET_SUCCESS,
          payload: resp[0],
        });
        callback();
        SnackBarUtils.success("Preset was successfully updated");
      })
      .catch((error) => {
        dispatch({ type: StockTypes.UPDATE_PRESET_FAILED });
        generateErrorMessage(error);
      });
  };

export const getStockPresetById = (id, controller) => async (dispatch) => {
  dispatch({ type: StockTypes.GET_PRESET_BY_ID_START });
  return StockApi.getStockPresetById(id, controller)
    .then((data) => {
      const [presetGlobalSettings, localParams] = data;

      const order = [];
      const factors = {};

      for (const factor of localParams) {
        const factorId = factor.item_id;
        order.push(factorId.toString());

        const factorItem = new EditedStockFactor(
          factorId,
          factor.name,
          factor.item_type,
          factor.normalize,
          factor.value,
          factor.percentile,
          factor.screen_by ? factor.screen_by : "",
          factor.ratio_operator
        );

        const factorChilds = convertResponseChildsDataToStockFactorChilds(
          factor.childs
        );

        factorItem.changeValueByKey("childs", factorChilds);

        factors[factorId] = factorItem;
      }

      const inUseSetup = {};
      for (const factorKey of Object.keys(factors)) {
        const item = factors[factorKey];

        const childsKeys = Object.keys(item.childs);

        if (childsKeys.length) {
          for (const childKey of childsKeys) {
            const child = item.childs[childKey];

            inUseSetup[child.name] = inUseSetup[child.name]?.length
              ? [...inUseSetup[child.name], item.name]
              : [item.name];
          }
        }
      }

      const updatedFactors = Object.keys(factors).reduce((acc, cur) => {
        const factor = factors[cur];

        return {
          ...acc,
          [cur]: inUseSetup[factor.name]
            ? factor.changeValueByKey("inUse", inUseSetup[factor.name])
            : factor,
        };
      }, {});

      const local_params = mapStockLocalParams(updatedFactors);

      const params = {
        global_params: {
          universe: presetGlobalSettings.universe[0],
          countries: presetGlobalSettings.country,
          economy: presetGlobalSettings.economy,
          sector: presetGlobalSettings.sector,
        },
        local_params,
        scale_financials: presetGlobalSettings.scale_financials,
        scale_by_marketcap:
          presetGlobalSettings.scale_by === StockScreenerScaleBySetting.Market
            ? true
            : false,
      };

      batch(() => {
        dispatch({
          type: StockTypes.GET_PRESET_BY_ID_SUCCESS,
          payload: presetGlobalSettings,
        });

        dispatch(updateStockFactor(updatedFactors, order));

        dispatch(getDefaultScreen(params, controller));
      });
    })
    .catch((error) => {
      dispatch({ type: StockTypes.GET_PRESET_BY_ID_FAILED });
      generateErrorMessage(error);
    });
};

export const deleteStockPreset =
  (id, controller) => async (dispatch, getState) => {
    const { access_token } = getState().user.userData;
    dispatch({ type: StockTypes.DELETE_PRESET_START });
    return StockApi.deleteStockPreset(id, access_token, controller)
      .then(() => {
        dispatch({
          type: StockTypes.DELETE_PRESET_SUCCESS,
          payload: id,
        });
      })
      .catch((error) => {
        dispatch({ type: StockTypes.DELETE_PRESET_FAILED });
        generateErrorMessage(error);
      });
  };
