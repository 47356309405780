import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  drawer: ({ drawerwidth, backgroundImageURL }) => ({
    flexShrink: 0,
    width: drawerwidth,
    "& .MuiDrawer-paper": {
      background: theme.palette.primary.light,
      boxSizing: "border-box",
      width: drawerwidth,
      overflow: "inherit",
      backgroundImage: `url(${backgroundImageURL})`,
      // backgroundPosition: 'center bottom',
      backgroundRepeat: "no-repeat",
      // backgroundSize: 'contain',
      backgroundAttachment: "fixed",
      backgroundSize: "288px 870px",
      backgroundPosition: "0px 356px",
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(-180deg, rgba(28,88,94,1) 0%, rgba(28,88,94,0) 100%)",
      },
    },
  }),
  closeButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      right: "-25px",
      top: "24px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0 15px 15px 0",
      color: "#fff",
      padding: "6px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  subtitle: {
    color: "#fff",
    fontSize: "14px",
    padding: "5px 10px",
    width: "136px",
    textAlign: "center",
    backgroundColor: theme.palette.primary.light,
  },
  logoImage: {
    marginBottom: "15px",
    maxWidth: "136px",
  },
  header: {
    paddingTop: "34px",
    paddingBottom: "34px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
  },
}));

export default useStyles;
