export const summaryHeader = () => {
  return [
    { Day: 0 },
    { Month: 0 },
    { Quarter: 0 },
    { "0.5 Year": 0 },
    { Year: 3 },
    { "3 Years": 4 },
    { "5 Years": 4 },
    { "1/1/2010": 4 },
    { "15 Years": 4 },
    { "Full Period": 4 },
  ];
};

export const drawDownHeader = () => {
  return [
    { "3 Years": 3 },
    { "5 Years": 3 },
    { "1/1/2010": 3 },
    { "10 Years": 3 },
    { "Full Period": 3 },
  ];
};

export const drawDownSubHeader = () => {
  return [
    "",
    "Quarter",
    "Month",
    "Week",
    "Quarter",
    "Month",
    "Week",
    "Quarter",
    "Month",
    "Week",
    "Quarter",
    "Month",
    "Week",
    "Quarter",
    "Month",
    "Week",
  ];
};

export const drawDownColumns = () => {
  return [
    "quarter_dd_3 y",
    "month_dd_3 y",
    "week_dd_3 y",
    "quarter_dd_5 y",
    "month_dd_5 y",
    "week_dd_5 y",
    "quarter_dd_2010-01-01",
    "month_dd_2010-01-01",
    "week_dd_2010-01-01",
    "quarter_dd_10 y",
    "month_dd_10 y",
    "week_dd_10 y",
    "quarter_dd_full",
    "month_dd_full",
    "week_dd_full",
  ];
};

export const summarySubHeader = () => {
  return [
    "Strategy Name",
    "Return (%)",
    "Return (%)",
    "Return (%)",
    "Return (%)",
    "Ann. Ret. (%)",
    "Ann. Std. (%)",
    "Sharpe",
    "Ann. Ret. (%)",
    "Ann. Std. (%)",
    "DD12M (%)",
    "Sharpe",
    "Ann. Ret. (%)",
    "Ann. Std. (%)",
    "DD12M (%)",
    "Sharpe",
    "Ann. Ret. (%)",
    "Ann. Std. (%)",
    "DD12M (%)",
    "Sharpe",
    "Ann. Ret. (%)",
    "Ann. Std. (%)",
    "DD12M (%)",
    "Sharpe",
    "Ann. Ret. (%)",
    "Ann. Std. (%)",
    "DD12M (%)",
    "Sharpe",
  ];
};

export const summaryColumns = () => {
  return [
    "AnnRet__1 d",
    "AnnRet__mtd",
    "AnnRet__3 m",
    "AnnRet__6 m",
    "AnnRet__1 y",
    "AnnVol__1 y",
    "Sharpe__1 y",
    "AnnRet__3 y",
    "AnnVol__3 y",
    "12MDD__3 y",
    "Sharpe__3 y",
    "AnnRet__5 y",
    "AnnVol__5 y",
    "12MDD__5 y",
    "Sharpe__5 y",
    "AnnRet__2010-01-01",
    "AnnVol__2010-01-01",
    "12MDD__2010-01-01",
    "Sharpe__2010-01-01",
    "AnnRet__15 y",
    "AnnVol__15 y",
    "12MDD__15 y",
    "Sharpe__15 y",
    "AnnRet__full",
    "AnnVol__full",
    "12MDD__full",
    "Sharpe__full",
  ];
};

export const monthDesc = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "June",
  7: "July",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const requestStatuses = {
  idle: "idle",
  loading: "loading",
  succeeded: "succeeded",
  failed: "failed",
};

export const rollingReturnsFilters = {
  "0.5 year": 0.5,
  "1 year": 1,
  '2 years':2,
  "3 years": 3,
  "5 years": 5,
};

export const worstDrawdownColumns = () => {
  return [
    { Started: "timeStamp" },
    { Recovered: "timeStamp" },
    { Drawdown: "int" },
    { Days: "int" },
  ];
};

export const compositionColumns = [
  { Date: "timeStamp" },
  { Rebalance: "timeStamp" },
  { "Company name": "string" },
  { Ticker: "string" },
  { ISIN: "string" },
  { "BBG ID": "string" },
  { Exchange: "string" },
  { Economy: "string" },
  { Sector: "string" },
  { Return: "int" },
  { "Return from inclusion": "int" },
  { "Market Cap": "int" },
  { Price: "int" },
  { "Price ILS": "int" },
];

export const EmptyStrategy = () => {
  return {
    empty: { id: "empty", name: "Select Another Strategy", color: "#a1a4a9" },
  };
};

export const countryCodes = {
  Europe: "EU",
  "United States": "US",
  "United Kingdom": "GB",
  Canada: "CA",
  Italy: "IT",
  Sweden: "SE",
  Australia: "AU",
  France: "FR",
  Switzerland: "CH",
  Japan: "JP",
  Israel: "IL",
  Germany: "DE",
  "Hong Kong": "CN",
};
export const matrixColors = [
  { from: Infinity, to: 1.01, color: "#1e5155" },
  { from: 1.0, to: 0.79, color: "#1e5155" },
  { from: 0.8, to: 0.59, color: "#5d8185" },
  { from: 0.6, to: 0.39, color: "#729b9f" },
  { from: 0.4, to: 0.19, color: "#acbfc1" },
  { from: 0.2, to: 0.01, color: "#d4dedf" },
  { from: 0.0, to: 0.0, color: "#f1f0f1" },
  { from: -0.01, to: -0.19, color: "#fde0dc" },
  { from: -0.2, to: -0.39, color: "#f6bdb6" },
  { from: -0.4, to: -0.59, color: "#e4897e" },
  { from: -0.6, to: -0.79, color: "#cc5247" },
  { from: -0.8, to: -1.0, color: "#b91e19" },
  { from: -1.0, to: -Infinity, color: "#b91e19" },
];

export const StrategiesColors = {
  odd: "#ffffff",
  even: "#e3e3e3",
};
