import FactorApi from "../../services/Factor";
import { generateErrorMessage } from "../../helpers/Error";
import * as FactorsTypes from "../types/Factor";
import portfolioRequest from "../../services/Portfolio";

export const getRebalanceList = () => async (dispatch, getState) => {
  dispatch({ type: FactorsTypes.GET_REBALANCE_LIST_START });
  const { user } = getState();
  return FactorApi.getRebalanceList(user.userData.access_token)
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_REBALANCE_LIST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_REBALANCE_LIST_FAILED });
      generateErrorMessage(error);
    });
};

export const getCountriesList = () => async (dispatch, getState) => {
  dispatch({ type: FactorsTypes.GET_COUNTRIES_LIST_START });
  const { user } = getState();
  return FactorApi.getCountriesList(user.userData.access_token)
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_COUNTRIES_LIST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_COUNTRIES_LIST_FAILED });
      generateErrorMessage(error);
    });
};

export const getMethodologyList = () => async (dispatch, getState) => {
  dispatch({ type: FactorsTypes.GET_METHODOLOGY_LIST_START });
  const { user } = getState();
  return FactorApi.getMethodologyList(user.userData.access_token)
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_METHODOLOGY_LIST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_METHODOLOGY_LIST_FAILED });
      generateErrorMessage(error);
    });
};

export const getPositionList = () => async (dispatch) => {
  dispatch({ type: FactorsTypes.GET_POSITION_LIST_START });
  return FactorApi.getPositionList()
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_POSITION_LIST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_POSITION_LIST_FAILED });
      generateErrorMessage(error);
    });
};

export const getFilterList = () => async (dispatch, getState) => {
  dispatch({ type: FactorsTypes.GET_FILTER_LIST_START });
  const { user } = getState();
  return FactorApi.getFilterList(user.userData.access_token)
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_FILTER_LIST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_FILTER_LIST_FAILED });
      generateErrorMessage(error);
    });
};

export const getSizeList = () => async (dispatch, getState) => {
  dispatch({ type: FactorsTypes.GET_SIZE_LIST_START });
  const { user } = getState();
  return FactorApi.getSizeList(user.userData.access_token)
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_SIZE_LIST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_SIZE_LIST_FAILED });
      generateErrorMessage(error);
    });
};

export const getMtdlgToStlyle = () => async (dispatch) => {
  dispatch({ type: FactorsTypes.GET_MTDLG_STYLE_START });
  return FactorApi.getMtdlStyle()
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_MTDLG_STYLE_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_MTDLG_STYLE_FAILED });
      generateErrorMessage(error);
    });
};

export const getBenchmarksStatistic = (data) => async (dispatch) => {
  dispatch({ type: FactorsTypes.GET_BENCHMARKS_STATISTIC_START });
  return FactorApi.getBenchmarksStatistic(data)
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_BENCHMARKS_STATISTIC_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_BENCHMARKS_STATISTIC_FAILED });
      generateErrorMessage(error);
    });
};

export const getPortfoliosStatistic = (data) => async (dispatch, getState) => {
  dispatch({ type: FactorsTypes.GET_PORTFOLIOS_STATISTIC_START });
  const { user } = getState();
  return FactorApi.getPortfoliosStatistic(data, user.userData.access_token)
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_PORTFOLIOS_STATISTIC_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_PORTFOLIOS_STATISTIC_FAILED });
      generateErrorMessage(error);
    });
};

export const clearPortfoliosStatistic = () => (dispatch) => {
  dispatch({ type: FactorsTypes.CLEAR_PORTFOLIOS_STATISTIC });
};

export const addBookmarkedPortfolio = (portfolio) => (dispatch) => {
  dispatch({ type: FactorsTypes.ADD_BOOKMARKED_PORTFOLIO, payload: portfolio });
};

export const removeBookmarkedPortfolio = (portfolioId) => (dispatch) => {
  dispatch({
    type: FactorsTypes.REMOVE_BOOKMARKED_PORTFOLIO,
    payload: portfolioId,
  });
};

export const clearNewPortfolioStatistic = () => (dispatch) => {
  dispatch({ type: FactorsTypes.CLEAR_NEW_STATISTIC });
};

export const addToPortfolioStatistic = (portfolio) => (dispatch) => {
  dispatch({
    type: FactorsTypes.ADD_PORTFOLIO_TO_STATISTIC,
    payload: portfolio,
  });
};

export const removePortfolioFromStatistic = (portfolioId) => (dispatch) => {
  dispatch({
    type: FactorsTypes.REMOVE_PORTFOLIO_FROM_STATISTIC,
    payload: portfolioId,
  });
};

export const removeAllPortfoliosFromStatistic = () => (dispatch) => {
  dispatch({ type: FactorsTypes.REMOVE_ALL_PORTFOLIO_FROM_STATISTIC });
};

export const getStatistics = (data) => async (dispatch) => {
  dispatch({
    type: FactorsTypes.GET_PORTFOLIOS_STRATEGIES_START,
    payload: data.include_regression,
  });
  return portfolioRequest
    .portfolioCalculation(data)
    .then((responce) => {
      dispatch({
        type: FactorsTypes.GET_PORTFOLIOS_STRATEGIES_SUCCESS,
        payload: responce,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_PORTFOLIOS_STRATEGIES_FAILED });
      generateErrorMessage(error);
    });
};

export const updateFactorsFiltersValues = () => async (dispatch, getState) => {
  dispatch({
    type: FactorsTypes.UPDATE_FACTORS_FILTERS_VALUES_START,
  });

  try {
    const { user } = getState();

    const rebalanceList = await FactorApi.getRebalanceList(
      user.userData.access_token
    );

    const countriesList = await FactorApi.getCountriesList(
      user.userData.access_token
    );

    const filterList = await FactorApi.getFilterList(
      user.userData.access_token
    );

    const sizeList = await FactorApi.getSizeList(user.userData.access_token);

    const methodologyList = await FactorApi.getMethodologyList(
      user.userData.access_token
    );

    dispatch({
      type: FactorsTypes.UPDATE_FACTORS_FILTERS_VALUES_SUCCESS,
      payload: {
        rebalanceList,
        countriesList,
        filterList,
        sizeList,
        methodologyList,
      },
    });
  } catch (error) {
    dispatch({ type: FactorsTypes.UPDATE_FACTORS_FILTERS_VALUES_FAILED });
    generateErrorMessage(error);
  }
};

export const selectedStatisticsStrategiesChange = (id) => (dispatch) => {
  dispatch({
    type: FactorsTypes.SET_SELECTED_PORTFOLIO_STRATEGY,
    payload: id,
  });
};

export const setAllPortfoliosToStatistic = () => (dispatch) => {
  dispatch({
    type: FactorsTypes.SET_ALL_PORTFOLIOS_TO_STATISTIC,
  });
};

export const setFactorPosition = (position) => (dispatch) => {
  dispatch({
    type: FactorsTypes.SET_FACTOR_POSITION,
    payload: position,
  });
};

export const setFactorActiveTab = (tabIndex) => (dispatch) => {
  dispatch({
    type: FactorsTypes.SET_FACTOR_ACTIVE_TAB,
    payload: tabIndex,
  });
};

export const setFactorFilterValue = (key, value) => (dispatch) => {
  dispatch({
    type: FactorsTypes.SET_FACTOR_FILTER_VALUE,
    payload: { key, value },
  });
};

export const setFactorSummaryPerfomanceGroupBy = (value) => (dispatch) => {
  dispatch({
    type: FactorsTypes.SET_FACTOR_SUMMARY_PERFOMANCE_GROUP_BY,
    payload: value,
  });
};

export const getHistoryDates = () => async (dispatch) => {
  dispatch({ type: FactorsTypes.GET_HISTORY_DATES_START });
  return FactorApi.getHistoryDates()
    .then((data) => {
      dispatch({
        type: FactorsTypes.GET_HISTORY_DATES_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: FactorsTypes.GET_HISTORY_DATES_FAILED });
      generateErrorMessage(error);
    });
};

export const setFactorHistoryDate = (value) => (dispatch) => {
  dispatch({
    type: FactorsTypes.SET_HISTORY_DATE,
    payload: value,
  });
};
