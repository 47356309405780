import { CLEAR_MESSAGE, LOGOUT, SET_MESSAGE } from "../actions/Type";

const initialState = {};

export default function Message(state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return { message: action.payload };
    case CLEAR_MESSAGE:
      return { message: "" };

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
