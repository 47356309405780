import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useRouteAuth } from "../../../hooks/UseRouteAuth.jsx";

function PrivatRoute({ children, auth, redirect, ...rest }) {
  const [isLoading, isAllow] = useRouteAuth(auth);

  if (isLoading) {
    return null;
  }

  return (
    <Route
      render={() =>
        isAllow ? (
          <Route {...rest} render={() => children} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
            }}
          />
        )
      }
    />
  );
}

export default PrivatRoute;
