import {
  ADD_NEW_BENCHMARK,
  ADD_NEW_PORTFOLIO,
  ADD_NESTED_STRATEGY,
  CLEAR_ALL_STRATEGIES,
  CLEAR_STRATEGY,
  COPY_STRATEGY_CONTENT,
  DELETE_STRATEGY,
  DELETE_USER_STRATEGY,
  INITIALIZE_STRATEGY,
  REDO,
  SET_STRATEGY_CREATOR_NAME_AND_DATES,
  TYPE_ID_DOES_NOT_HANDLED,
  UNDO,
  UPDATE_COUNTER,
  UPDATE_STRATEGY_INITIALIZATION_DATES,
  UPDATE_STRATEGY_LONG_POS,
  UPDATE_STRATEGY_NAME,
  UPDATE_STRATEGY_SHORT_POS,
  GET_WORKING_GROUP,
  UPDATE_ASSET_BULK,
  ADD_NEW_SECURITY,
} from "./Type";

import {
  GET_STRATEGY_TITLES_BY_USER_ID_START,
  GET_STRATEGY_TITLES_BY_USER_ID_SUCCESS,
  GET_STRATEGY_TITLES_BY_USER_ID_FAILED,
  ADD_TO_FAVORITES_START,
  ADD_TO_FAVORITES_SUCCESS,
  ADD_TO_FAVORITES_FAILED,
  REMOVE_FROM_FAVORITES_START,
  REMOVE_FROM_FAVORITES_SUCCESS,
  REMOVE_FROM_FAVORITES_FAILED,
} from "../types/Strategy";

import nextId from "react-id-generator";
import { batch } from "react-redux";
import portfolioRequest from "../../services/Portfolio";
import SnackbarUtils from "../../helpers/SnackBarUtils";
import { getPublicAndPrivateStrategiesAction } from "./Catalog";
import { generateErrorMessage } from "../../helpers/Error";
import searchSrvs from "../../services/Portfolio";
import {
  GET_WORKING_GROUP_START,
  GET_WORKING_GROUP_FAILED,
  LOAD_PORTFOLIO_START,
  LOAD_PORTFOLIO_COMPLETED,
} from "../types/Portfolio";
import {
  Benchmark,
  generateIdForAssetByType,
  Portfolio,
  ASSET_TYPES,
  Security,
  ADDITIONAL_ASSET_TYPES,
  OtherPortfolio,
} from "../../helpers/Assets";

export const initializeStrategy = (user, id) => (dispatch) => {
  batch(() => {
    dispatch({
      type: UPDATE_COUNTER,
    });
    dispatch({
      type: INITIALIZE_STRATEGY,
      payload: { id, user },
    });
  });
};

export const deleteStrategy = (strategyId) => (dispatch) => {
  dispatch({
    type: DELETE_STRATEGY,
    payload: { strategyId },
  });
};

export const cloneStrategy = (strategyId, user) => (dispatch) => {
  const id = nextId("strategy-");
  batch(() => {
    dispatch({
      type: UPDATE_COUNTER,
    });
    dispatch({
      type: INITIALIZE_STRATEGY,
      payload: { id, user },
    });
    dispatch({
      type: COPY_STRATEGY_CONTENT,
      payload: { id, strategyId },
    });
  });
};

export const clearStrategy = (strategyId) => (dispatch) => {
  dispatch({
    type: CLEAR_STRATEGY,
    payload: { strategyId },
  });
};

export const clearAllStrategies = (strgyId) => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_STRATEGIES,
    payload: strgyId,
  });
};

export const loadStrategy =
  (strategyIdBack, strategyId, token = NaN) =>
  async (dispatch) => {
    dispatch({ type: LOAD_PORTFOLIO_START });
    strategyId = isNaN(strategyId)
      ? strategyId.replace(/\s/g, "_")
      : strategyId;
    return portfolioRequest
      .getStrategy(strategyIdBack, token)
      .then((responseObject) => {
        const strategyTitle = {
          user_name: responseObject["user_name"],
          initialization_date: responseObject["initialization_date"],
          name: responseObject["name_to_show"],
        };
        batch(() => {
          dispatch(clearStrategy(strategyId));
          dispatch(setStrategyCreatorNameAndDates(strategyTitle, strategyId));
          responseObject["dates"] &&
            dispatch(
              updateListOfStrategyInitializationDates(
                responseObject["dates"],
                strategyId
              )
            );
          responseObject.assets.forEach((asset) => {
            const id = generateIdForAssetByType(asset.asset_type);

            const editMode = false;
            const weight = asset.hasOwnProperty("weight") ? asset.weight : 1;
            const position = asset.hasOwnProperty("position")
              ? asset.position
              : "long";

            switch (asset.asset_type) {
              case ADDITIONAL_ASSET_TYPES.strategy:
                dispatch({
                  type: ADD_NESTED_STRATEGY,
                  payload: {
                    nestedStrategy: {
                      ...asset,
                      asset_type: ASSET_TYPES.nestedStrategies,
                      id,
                      nested: true,
                    },
                    nestedId: id,
                    editMode,
                    strategyId,
                    weight,
                    position,
                    status: false,
                  },
                });
                break;
              case ASSET_TYPES.portfolio:
                const portfolio = new Portfolio(
                  asset.asset_id,
                  asset.asset_type,
                  asset.cnt,
                  asset.cur,
                  asset.filter_description,
                  asset.fltr,
                  asset.mtl,
                  asset.pos,
                  asset.rbl,
                  asset.rebalance_description,
                  asset.size,
                  asset.trade_date,
                  id,
                  editMode,
                  strategyId,
                  weight,
                  position,
                  false
                );

                dispatch({
                  type: ADD_NEW_PORTFOLIO,
                  payload: portfolio,
                });
                break;
              case ASSET_TYPES.benchmark:
                const benchmark = new Benchmark(
                  asset.asset_id,
                  asset.country_id,
                  asset.name,
                  asset.trade_date,
                  id,
                  strategyId,
                  weight,
                  position,
                  false
                );

                dispatch({
                  type: ADD_NEW_BENCHMARK,
                  payload: benchmark,
                });
                break;

              case ASSET_TYPES.security:
                const security = new Security(
                  asset.asset_id,
                  asset.country_id,
                  asset.name,
                  asset.trade_date,
                  id,
                  strategyId,
                  weight,
                  position,
                  false
                );

                dispatch({
                  type: ADD_NEW_SECURITY,
                  payload: security,
                });
                break;

              default:
                dispatch({
                  type: TYPE_ID_DOES_NOT_HANDLED,
                  payload: asset.asset_type,
                });
            }
          });
        });
        dispatch({ type: LOAD_PORTFOLIO_COMPLETED });
      })
      .catch((error) => {
        generateErrorMessage(error);
        dispatch({ type: LOAD_PORTFOLIO_COMPLETED });
      });
  };

export const saveStrategy =
  (
    strategyId,
    user,
    name,
    description,
    activeStrategy,
    publicStrategy,
    strategyData,
    strategyType,
    nestedStrategy
  ) =>
  async (dispatch) => {
    return portfolioRequest
      .saveStrategy(
        strategyId,
        user.access_token,
        name,
        description,
        activeStrategy,
        publicStrategy,
        strategyData,
        strategyType,
        nestedStrategy
      )
      .then((response) => {
        if (response) {
          SnackbarUtils.info(
            `Strategy was saved successfully with id: ${response["strategy_id"]}`
          );
          batch(() => {
            dispatch(getPublicAndPrivateStrategiesAction(user.access_token));
            dispatch(
              loadStrategy(response["strategy_id"], strategyId, user.access_token)
            );
          });
        } else {
          SnackbarUtils.error(
            `Empty response. Problem with strategy saving`
          );
        }
      })
      .catch((error) => {
        generateErrorMessage(error);
      });
  };

export const deleteUserStrategy =
  (strategyId) => async (dispatch, getState) => {
    const state = getState();

    const { access_token } = state.user.userData;

    return portfolioRequest
      .deleteStrategy(strategyId, access_token)
      .then(() => {
        SnackbarUtils.info(`Strategy was successfully deleted`);
        dispatch({ type: DELETE_USER_STRATEGY, payload: strategyId });
      })
      .catch((error) => {
        generateErrorMessage(error);
      });
  };

export const setStrategyCreatorNameAndDates =
  (strategyTitle, strategyId) => (dispatch) => {
    dispatch({
      type: SET_STRATEGY_CREATOR_NAME_AND_DATES,
      payload: { strategyTitle, strategyId },
    });
  };
export const updateStrategyName = (strategyName, strategyId) => (dispatch) => {
  dispatch({
    type: UPDATE_STRATEGY_NAME,
    payload: { strategyName, strategyId },
  });
};
export const updateStrategyLongPos = (longPos, strategyId) => (dispatch) => {
  dispatch({
    type: UPDATE_STRATEGY_LONG_POS,
    payload: { longPos, strategyId },
  });
};
export const updateStrategyShortPos = (shortPos, strategyId) => (dispatch) => {
  dispatch({
    type: UPDATE_STRATEGY_SHORT_POS,
    payload: { shortPos, strategyId },
  });
};

export const updateAssetBulk = (key, data) => (dispatch) => {
  dispatch({
    type: UPDATE_ASSET_BULK,
    payload: { key, data },
  });
};

export const updateListOfStrategyInitializationDates =
  (dates, strategyId) => (dispatch) => {
    dispatch({
      type: UPDATE_STRATEGY_INITIALIZATION_DATES,
      payload: { dates, strategyId },
    });
  };

export const undo = (strategyId) => (dispatch) => {
  dispatch({
    type: UNDO,
    payload: { strategyId },
  });
};

export const redo = (strategyId) => (dispatch) => {
  dispatch({
    type: REDO,
    payload: { strategyId },
  });
};

export const getWorkingGroups = (token, user_id) => async (dispatch) => {
  dispatch({ type: GET_WORKING_GROUP_START });
  return searchSrvs
    .getWorkingGroups(token, user_id)
    .then((responce) => {
      if (responce) {
        dispatch({
          type: GET_WORKING_GROUP,
          payload: responce,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: GET_WORKING_GROUP_FAILED });
      generateErrorMessage(error);
      dispatch({
        type: EXCEPTION,
        payload: generateErrorMessage(error),
      });
    });
};

export const getStrategyTitlesByUserId =
  (user_id) => async (dispatch, getState) => {
    const state = getState();

    const { access_token } = state.user.userData;

    dispatch({
      type: GET_STRATEGY_TITLES_BY_USER_ID_START,
    });
    return portfolioRequest
      .getStrategyTitlesByUserId(user_id, access_token)
      .then((response) => {
        dispatch({
          type: GET_STRATEGY_TITLES_BY_USER_ID_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_STRATEGY_TITLES_BY_USER_ID_FAILED,
        });
        generateErrorMessage(error);
      });
  };

export const addStrategyToFavorites =
  (portfolio_id) => async (dispatch, getState) => {
    const state = getState();

    const { id, access_token } = state.user.userData;

    dispatch({
      type: ADD_TO_FAVORITES_START,
      payload: portfolio_id,
    });
    return portfolioRequest
      .addStrategyToFavorites(portfolio_id, id, access_token)
      .then(() => {
        dispatch({
          type: ADD_TO_FAVORITES_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_TO_FAVORITES_FAILED,
          payload: portfolio_id,
        });
        generateErrorMessage(error);
      });
  };

export const removeStrategyFromFavorites =
  (portfolio_id) => async (dispatch, getState) => {
    const state = getState();

    const { id, access_token } = state.user.userData;

    dispatch({
      type: REMOVE_FROM_FAVORITES_START,
      payload: portfolio_id,
    });
    return portfolioRequest
      .removeStrategyFromFavorites(portfolio_id, id, access_token)
      .then(() => {
        dispatch({
          type: REMOVE_FROM_FAVORITES_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: REMOVE_FROM_FAVORITES_FAILED,
          payload: portfolio_id,
        });
        generateErrorMessage(error);
      });
  };
