import React, { forwardRef } from "react";
import Button from "@mui/material/Button";
import propTypes from "prop-types";
import useStyles from "./style";

const BasicButton = forwardRef(
  ({ title, icon, highlighted = false, disable=false, ...props }, ref) => {
    const classes = useStyles();

    return (
      <Button
        ref={ref}
        disabled={disable}
        className={classes.root}
        {...props}
        sx={(theme) =>
          highlighted && {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.contrastText} !important`,
          }
        }
      >
        {icon}
        {title ? <span className="iconText">{title}</span> : ""}
      </Button>
    );
  }
);

BasicButton.defaultProps = {
  title: "",
  icon: null,
};

BasicButton.propTypes = {
  title: propTypes.string,
  icon: propTypes.element,
};

export default BasicButton;
