export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SEARCH_PORTFOLIO = "SEARCH_PORTFOLIO";
export const SEARCH_FAILED = "SEARCH_FAILED";
export const STRATEGIES_LOADED = "STRATEGIES_LOADED";
export const STRATEGIES_NOT_LOADED = "STRATEGIES_NOT_LOADED";
export const CLEAR_STRATEGIES_ERROR = "CLEAR_STRATEGIES_ERROR";
export const DELETE_USER_STRATEGY = "DELETE_USER_STRATEGY";
export const CLEAR_STRATEGIES = "CLEAR_STRATEGIES";
export const EXCEPTION = "CLEAR_MESSAGE";
export const SHOW = "SHOW";
export const ADD_NEW_PORTFOLIO = "ADD_NEW_PORTFOLIO";
export const ADD_NESTED_STRATEGY = "ADD_NESTED_STRATEGY";
export const ADD_NEW_BENCHMARK = "ADD_NEW_BENCHMARK";
export const ADD_NEW_SECURITY = "ADD_NEW_SECURITY";

export const INITIALIZE_STRATEGY = "INITIALIZE_STRATEGY";
export const TYPE_ID_DOES_NOT_HANDLED = "TYPE_ID_DOES_NOT_HANDLED";
export const GET_STATISTICS = "GET_STATISTICS";
export const CLEAR_STRATEGY = "CLEAR_STRATEGY";
export const CLEAR_ALL_STRATEGIES = "CLEAR_ALL_STRATEGIES";

export const SET_ASSET_POSITION = "SET_ASSET_POSITION";
export const SET_ASSET_WEIGHT = "SET_ASSET_WEIGHT";
export const SET_ASSET_BULK_WEIGHT = "SET_ASSET_BULK_WEIGHT";

export const SET_STRATEGY_CREATOR_NAME_AND_DATES =
  "SET_STRATEGY_CREATOR_NAME_AND_DATES";
export const STORE_MONTHLY_RETURNS = "STORE_MONTHLY_RETURNS";
export const DELETE_ASSET_ROW = "DELETE_ASSET_ROW";

export const DELETE_STRATEGY = "DELETE_STRATEGY";
export const UPDATE_STRATEGY_NAME = "UPDATE_STRATEGY_NAME";
export const UPDATE_STRATEGY_LONG_POS = "UPDATE_STRATEGY_LONG_POS";
export const UPDATE_STRATEGY_SHORT_POS = "UPDATE_STRATEGY_SHORT_POS";
export const UPDATE_ASSET_BULK = "UPDATE_ASSET_BULK";

export const UPDATE_COUNTER = "UPDATE_COUNTER";
export const UPDATE_STRATEGY_INITIALIZATION_DATES =
  "UPDATE_STRATEGY_INITIALIZATION_DATES";
export const UPDATE_WIN_RATE_TABLE_START = "UPDATE_WIN_RATE_TABLE_START";
export const UPDATE_WIN_RATE_TABLE_SUCCESS = "UPDATE_WIN_RATE_TABLE_SUCCESS";

export const RESET_FACTOR_TESTING_REPORT = "RESET_FACTOR_TESTING_REPORT";

export const UPDATE_WIN_RATE_TABLE_FACTORS_SUCCESS =
  "UPDATE_WIN_RATE_TABLE_FACTORS_SUCCESS";
export const UPDATE_WIN_RATE_TABLE_FAILED = "UPDATE_WIN_RATE_TABLE_FAILED";
export const COPY_STRATEGY_CONTENT = "COPY_STRATEGY_CONTENT";
export const UNDO = "UNDO";
export const REDO = "REDO";
export const NOT_LOADED = "NOT_LOADED";
export const CLEAR_COUNTRIES_ERROR = "CLEAR_COUNTRIES_ERROR";
export const UPDATE_EDIT_DATA = "UPDATE_EDIT_DATA";
export const STRATEGY_EDIT = "STRATEGY_EDIT";
export const ROLLING_RETURNS_LOADING = "ROLLING_RETURNS_LOADING";
export const ROLLING_RETURNS_FULFILLED = "ROLLING_RETURNS_FULFILLED";
export const ROLLING_RETURNS_REJECTED = "ROLLING_RETURNS_REJECTED";
export const ROLLING_RETURNS_FULFILLED_STATUS_SUCCEEDED =
  "ROLLING_RETURNS_FULFILLED_STATUS_SUCCEEDED";
export const ROLLING_CORELLATION = "ROLLING_CORELLATION";
export const ROLLING_CORRELATION_LOADING = "ROLLING_CORRELATION_LOADING";
export const ROLLING_CORRELATION_FULFILLED = "ROLLING_CORRELATION_FULFILLED";
export const UPDATE_PORTFOLIO_BETA_TABLE = "UPDATE_PORTFOLIO_BETA_TABLE";
export const UPDATE_PORTFOLIO_BETA_TABLE_LOADING =
  "UPDATE_PORTFOLIO_BETA_TABLE_LOADING";
export const UPDATE_PORTFOLIO_BETA_TABLE_FULFILLED =
  "UPDATE_PORTFOLIO_BETA_TABLE_FULFILLED";
export const STOCKS_TOGGLE_FETCHING = "STOCKS_TOGGLE_FETCHING";
export const STOCKS_FULFILLED = "STOCKS_FULFILLED";
export const STOCKS_REJECTED = "STOCKS_REJECTED";
export const ADD_NEW_ASSET_TOGGLE_FETCHING = "ADD_NEW_ASSET_TOGGLE_FETCHING";
export const INIT_APP = "INIT_APP";
export const GET_WORKING_GROUP = "GET_WORKING_GROUP";
export const RESET_DEFAULT = "RESET_DEFAULT";
