import {
  CONFIG_GROUPS_ORDER,
  DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION,
  DEFAULT_STOCK_SCREENER_UNIVERSE_FILTER_OPTION,
} from "../../features/StockScreener/consts";
import { EditedStockFactor } from "../../helpers/Stock";
import { LOGOUT, RESET_DEFAULT } from "../actions/Type";
import * as StockTypes from "../types/Stock";

const initialState = {
  filters: {
    selected: {
      universe: DEFAULT_STOCK_SCREENER_UNIVERSE_FILTER_OPTION,
      country: [DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION],
      economy: [],
      sector: [],
    },
    options: {
      universeOptions: [],
      countryOptions: [],
      economyOptions: [],
      sectorOptions: [],
    },
    loaders: {
      universe: false,
      country: false,
      economy: false,
      sector: false,
    },
    isLoading: false,
  },
  settings: {
    scaleFinancials: "",
    scaleBy: "",
    defaultVariable: [],
  },
  actionButtonsLoaders: {
    isMultifactorLoading: false,
    isRatioLoading: false,
    isBacktestLoading: false,
  },
  isReceivedUserSettings: true,
  defaultVariableOptions: {},
  selectedGroupIndex: 0,
  selectedFactors: {},
  selectedFactorsOrder: [],
  highlightedFactors: [],
  stocks: {},
  stocksNormalized: {},
  variables: {},
  configGroupsValues: [],
  presets: [],
  loadedPreset: null,
  isStockLoading: false,
  isVariablesLoading: false,
  isSettingsLoading: false,
  isPresetLoading: false,
};

export default function stock(state = initialState, action) {
  switch (action.type) {
    case StockTypes.SET_STOCK_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...state.filters.selected,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    }

    case StockTypes.SET_STOCK_FACTOR_GROUP_INDEX: {
      return {
        ...state,
        selectedGroupIndex: action.payload,
      };
    }

    case StockTypes.ADD_STOCK_FACTOR: {
      return {
        ...state,
        selectedFactors: {
          ...state.selectedFactors,
          [action.payload.id]: action.payload,
        },
        selectedFactorsOrder: [
          ...state.selectedFactorsOrder,
          action.payload.id,
        ],
      };
    }

    case StockTypes.UPDATE_STOCK_FACTOR: {
      return {
        ...state,
        selectedFactors: action.payload.data,
        selectedFactorsOrder: action.payload.order,
        highlightedFactors: state.highlightedFactors.filter((item) =>
          action.payload.order.includes(item)
        ),
      };
    }

    case StockTypes.SET_HIGHLIGHTED_STOCK_FACTORS: {
      const { id, replace } = action.payload;

      const result = !replace
        ? state.highlightedFactors.includes(id)
          ? state.highlightedFactors.filter((item) => item !== id)
          : [...state.highlightedFactors, id]
        : id;

      return {
        ...state,
        highlightedFactors: result,
      };
    }

    case StockTypes.GET_STOCK_UNIVERSES_START: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loaders: { ...state.filters.loaders, universe: true },
        },
      };
    }
    case StockTypes.GET_STOCK_UNIVERSES_SUCCESS: {
      const defaultOption =
        action.payload.find(
          (item) => item === DEFAULT_STOCK_SCREENER_UNIVERSE_FILTER_OPTION
        ) || "";

      return {
        ...state,
        filters: {
          ...state.filters,
          options: {
            ...state.filters.options,
            universeOptions: action.payload,
          },
          selected: {
            ...state.filters.selected,
            universe: defaultOption,
          },
          loaders: { ...state.filters.loaders, universe: false },
        },
      };
    }

    case StockTypes.GET_STOCK_UNIVERSES_FAILED: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loaders: { ...state.filters.loaders, universe: false },
        },
      };
    }

    case StockTypes.GET_STOCK_COUNTRIES_START: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loaders: { ...state.filters.loaders, country: true },
        },
      };
    }
    case StockTypes.GET_STOCK_COUNTRIES_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          options: {
            ...state.filters.options,
            countryOptions: action.payload,
          },

          loaders: { ...state.filters.loaders, country: false },
        },
      };
    }

    case StockTypes.GET_STOCK_COUNTRIES_FAILED: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loaders: { ...state.filters.loaders, country: false },
        },
      };
    }

    case StockTypes.GET_STOCK_ECONOMIES_START: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loaders: { ...state.filters.loaders, economy: true },
        },
      };
    }
    case StockTypes.GET_STOCK_ECONOMIES_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          options: {
            ...state.filters.options,
            economyOptions: action.payload.data,
          },
          selected: action.payload.init
            ? {
                ...state.filters.selected,
                economy: action.payload.data,
              }
            : state.filters.selected,
          loaders: { ...state.filters.loaders, economy: false },
        },
      };
    }
    case StockTypes.GET_STOCK_ECONOMIES_FAILED: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loaders: { ...state.filters.loaders, economy: false },
        },
      };
    }

    case StockTypes.GET_STOCK_SECTORS_START: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loaders: { ...state.filters.loaders, sector: true },
        },
      };
    }
    case StockTypes.GET_STOCK_SECTORS_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          options: {
            ...state.filters.options,
            sectorOptions: action.payload.data,
          },

          selected: action.payload.init
            ? {
                ...state.filters.selected,
                sector: action.payload.data,
              }
            : state.filters.selected,
          loaders: { ...state.filters.loaders, sector: false },
        },
      };
    }
    case StockTypes.GET_STOCK_SECTORS_FAILED: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loaders: { ...state.filters.loaders, sector: false },
        },
      };
    }

    case StockTypes.GET_DEFAULT_SCREEN_START: {
      return {
        ...state,
        isStockLoading: true,
      };
    }
    case StockTypes.GET_DEFAULT_SCREEN_SUCCESS: {
      const { data, isAdd } = action.payload;
      const { screen, normalized_screen } = data;

      return {
        ...state,
        isStockLoading: false,
        stocks: isAdd ? { ...state.stocks, ...screen } : screen,
        stocksNormalized: isAdd
          ? { ...state.stocksNormalized, ...normalized_screen }
          : normalized_screen,
      };
    }
    case StockTypes.GET_DEFAULT_SCREEN_FAILED: {
      return {
        ...state,
        isStockLoading: false,
      };
    }

    case StockTypes.GET_VARIABLES_START: {
      return {
        ...state,
        isVariablesLoading: true,
      };
    }
    case StockTypes.GET_VARIABLES_SUCCESS: {
      let selectedVars = [];
      let optionVars = {};

      let defaultLocalParams = {};

      const data = action.payload.reduce((acc, cur) => {
        optionVars[cur.id] = cur.name;

        if (cur.selected) {
          selectedVars.push(`${cur.id}`);

          defaultLocalParams[cur.id] = new EditedStockFactor(
            cur.id,
            cur.name,
            cur.type
          );
        }

        if (acc[cur.type]) {
          return {
            ...acc,
            [cur.type]: [...acc[cur.type], cur],
          };
        } else {
          return {
            ...acc,
            [cur.type]: [cur],
          };
        }
      }, {});

      const configGroups = Object.keys(data).sort(
        (a, b) =>
          CONFIG_GROUPS_ORDER.indexOf(a) - CONFIG_GROUPS_ORDER.indexOf(b)
      );

      return {
        ...state,
        isVariablesLoading: false,
        variables: data,
        configGroupsValues: configGroups,
        settings: {
          ...state.settings,
          defaultVariable: selectedVars,
        },
        defaultVariableOptions: optionVars,
        selectedFactors: defaultLocalParams,
        selectedFactorsOrder: Object.keys(defaultLocalParams),
      };
    }
    case StockTypes.GET_VARIABLES_FAILED: {
      return {
        ...state,
        isVariablesLoading: false,
      };
    }

    case StockTypes.UPDATE_DEFALT_VARIABLES_START: {
      return {
        ...state,
        isSettingsLoading: true,
      };
    }
    case StockTypes.UPDATE_DEFALT_VARIABLES_SUCCESS: {
      return {
        ...state,
        isSettingsLoading: false,
        settings: {
          ...state.settings,
          defaultVariable: action.payload,
        },
      };
    }
    case StockTypes.UPDATE_DEFALT_VARIABLES_FAILED: {
      return {
        ...state,
        isSettingsLoading: false,
      };
    }

    case StockTypes.GET_USER_SETTINGS_START: {
      return {
        ...state,
        isSettingsLoading: true,
      };
    }
    case StockTypes.GET_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        isSettingsLoading: false,
        isReceivedUserSettings: true,
        settings: {
          ...state.settings,
          scaleFinancials: `${action.payload.scale_financials}`,
          scaleBy: action.payload.scale_by,
        },
      };
    }
    case StockTypes.GET_USER_SETTINGS_FAILED: {
      return {
        ...state,
        isSettingsLoading: false,
        isReceivedUserSettings: false,
      };
    }

    case StockTypes.UPDATE_USER_SETTINGS_START: {
      return {
        ...state,
        isSettingsLoading: true,
      };
    }
    case StockTypes.UPDATE_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        isSettingsLoading: false,
        settings: {
          ...state.settings,
          scaleFinancials: `${action.payload.scale_financials}`,
          scaleBy: action.payload.scale_by,
        },
      };
    }
    case StockTypes.UPDATE_USER_SETTINGS_FAILED: {
      return {
        ...state,
        isSettingsLoading: false,
      };
    }

    case StockTypes.CREATE_MULTIFACTOR_START: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isMultifactorLoading: true,
        },
      };
    }
    case StockTypes.CREATE_MULTIFACTOR_SUCCESS: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isMultifactorLoading: false,
        },
        stocks: action.payload.screen,
        stocksNormalized: action.payload.normalized_screen,
      };
    }
    case StockTypes.CREATE_MULTIFACTOR_FAILED: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isMultifactorLoading: false,
        },
      };
    }

    case StockTypes.CREATE_RATIO_START: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isRatioLoading: true,
        },
      };
    }
    case StockTypes.CREATE_RATIO_SUCCESS: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isRatioLoading: false,
        },
        stocks: action.payload.screen,
        stocksNormalized: action.payload.normalized_screen,
      };
    }
    case StockTypes.CREATE_RATIO_FAILED: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isRatioLoading: false,
        },
      };
    }

    case StockTypes.CREATE_BACKTEST_START: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isBacktestLoading: true,
        },
      };
    }
    case StockTypes.CREATE_BACKTEST_SUCCESS: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isBacktestLoading: false,
        },
      };
    }

    case StockTypes.CREATE_BACKTEST_FAILED: {
      return {
        ...state,
        actionButtonsLoaders: {
          ...state.actionButtonsLoaders,
          isBacktestLoading: false,
        },
      };
    }

    case StockTypes.GET_PRESETS_START: {
      return {
        ...state,
        isPresetLoading: true,
      };
    }
    case StockTypes.GET_PRESETS_SUCCESS: {
      return {
        ...state,
        isPresetLoading: false,
        presets: action.payload,
      };
    }
    case StockTypes.GET_PRESETS_FAILED: {
      return {
        ...state,
        isPresetLoading: false,
      };
    }

    case StockTypes.DELETE_PRESET_START: {
      return {
        ...state,
        isPresetLoading: true,
      };
    }
    case StockTypes.DELETE_PRESET_SUCCESS: {
      return {
        ...state,
        isPresetLoading: false,
        presets: state.presets.filter((preset) => preset.id !== action.payload),
        loadedPreset:
          state.loadedPreset?.id === action.payload ? null : state.loadedPreset,
      };
    }
    case StockTypes.DELETE_PRESET_FAILED: {
      return {
        ...state,
        isPresetLoading: false,
      };
    }

    case StockTypes.UPDATE_PRESET_START: {
      return {
        ...state,
        isPresetLoading: true,
      };
    }
    case StockTypes.UPDATE_PRESET_SUCCESS: {
      return {
        ...state,
        isPresetLoading: false,
        presets: state.presets.map((preset) =>
          preset.id === action.payload.id ? action.payload : preset
        ),
        loadedPreset: action.payload,
      };
    }
    case StockTypes.UPDATE_PRESET_FAILED: {
      return {
        ...state,
        isPresetLoading: false,
      };
    }

    case StockTypes.GET_PRESET_BY_ID_START: {
      return {
        ...state,
        isPresetLoading: true,
      };
    }
    case StockTypes.GET_PRESET_BY_ID_SUCCESS: {
      return {
        ...state,
        isPresetLoading: false,
        loadedPreset: {
          name: action.payload.name,
          id: action.payload.id,
        },
        filters: {
          ...state.filters,
          selected: {
            universe: action.payload.universe[0],
            country: action.payload.country,
            economy: action.payload.economy,
            sector: action.payload.sector,
          },
        },
        settings: {
          ...state.settings,
          scaleFinancials: action.payload.scale_financials,
          scaleBy: action.payload.scale_by,
        },
      };
    }
    case StockTypes.GET_PRESET_BY_ID_FAILED: {
      return {
        ...state,
        isPresetLoading: false,
      };
    }

    case StockTypes.SAVE_PRESET_START: {
      return {
        ...state,
        isPresetLoading: true,
      };
    }
    case StockTypes.SAVE_PRESET_SUCCESS: {
      return {
        ...state,
        isPresetLoading: false,
        presets: [...state.presets, action.payload],
      };
    }
    case StockTypes.SAVE_PRESET_FAILED: {
      return {
        ...state,
        isPresetLoading: false,
      };
    }

    case StockTypes.RESET_STOCK: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            universe: DEFAULT_STOCK_SCREENER_UNIVERSE_FILTER_OPTION,
            country: [DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION],
            economy: [],
            sector: [],
          },
        },
        selectedGroupIndex: 0,
        selectedFactors: {},
        selectedFactorsOrder: [],
        highlightedFactors: [],
        stocks: {},
        stocksNormalized: {},
        loadedPreset: null,
      };
    }

    // case RESET_DEFAULT:
    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
