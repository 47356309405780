export const DEFAULT_STOCK_SCREENER_UNIVERSE_FILTER_OPTION = "strict_universe";

export const DEFAULT_STOCK_SCREENER_COUNTRY_FILTER_OPTION = "united states";

export const StockScreenerUniverseFilter = {
  strict_universe: "Filtered",
  light_universe: "Unfiltered",
};

export const StockScreenerScaleFinancialsSetting = ["true", "false"];

export const StockScreenerScaleBySetting = {
  Assets: "Assets",
  Marketcap: "Marketcap",
};

export const StockScreenerConfigScreenBy = {
  Higher: "Higher",
  Lower: "Lower",
};

export const STOCK_SCREENER_DEBOUNCE_DELAY = 1500;

export const CELL_WIDTH = 200;

export const RATIO_STEPS = ["Operator", "Factors", "Name"];

export const RATIO_OPERATORS = {
  add: "+",
  subtract: "-",
  multiply: "*",
  divide: "/",
};

export const BACKTEST_STEPS = ["Name", "Options"];

export const STOCK_BACKTEST_WEIGHTED_OPTIONS = ["equal-weight", "value-weight"];

export const STOCK_BACKTEST_SIZE_OPTIONS = [20, 30, 50, 100];

export const STOCK_MODAL_MAX_HEIGHT = "65dvh";

export const STOCK_MODAL_MAX_WIDTH = "78dvw";

export const DEFAULT_KEYS_INDUSTRY_COMPARISON = {
  economy: "economy",
  factor: "factor",
  "lower quartile": "Lower Quartile",
  median: "Median",
  "upper quartile": "Upper Quartile",
};

export const MAX_HEIGHT = 250;

export const CONFIG_GROUPS_ORDER = [
  "info",
  "price",
  "financial",
  "factor",
  "ratio",
  "multiple",
  "growth",
];

export const CUSTOM_RATIO = "custom_ratio";
export const CUSTOM_MULTIFACTOR = "multifactor";
