const pageTitles = {
  portfolio: "Strategy Constructor",
  factors: "Factor Screening",
  "x-ray": "Strategy X-Ray",
  "factor-testing": "Factor Testing",
  "stock-screener": "Stock Screener",
  "model-strategies": "Model Strategies",
  admin: "Admin-panel",
  "": "Dashboard",
};

export { pageTitles };
