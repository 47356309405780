const DashboardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    clipRule="evenodd"
    fillRule="evenodd"
    height="20"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    viewBox="0 0 24 24"
    width="20"
  >
    <path d="M20 21a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1zm-9-9a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1zm4 5v2h4v-2zM5 13v6h4v-6zm15 0a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-5-8v6h4V5zm-4-1a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1zM5 5v2h4V5z" />
  </svg>
);

export default DashboardIcon;
