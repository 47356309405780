import { useEffect, useState } from "react";

export const useRouteAuth = (callbacksArr) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAllow, setIsAllow] = useState(false);

  useEffect(() => {
    async function check() {
      const resps = await Promise.all(
        callbacksArr.map(async (callback) => callback())
      );

      if (resps.length && resps.every(Boolean)) {
        setIsAllow(true);
      }
      setIsLoading(false);
    }

    check();

    return () => [true, false];
  }, []);

  return [isLoading, isAllow];
};
