import React from "react";
import { dashboardRoutes } from "../configs/routes";
import AuthService from "../services/Auth";
import Privacy from "./pages/Privacy/Privacy";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import UserGuide from "./pages/UserGuide/UserGuide";

const Home = React.lazy(() => import("./pages/Home/Home"));
const Portfolio = React.lazy(() => import("./pages/Portfolio/Portfolio"));
const Factors = React.lazy(() => import("./pages/Factors/Factors"));
const XRay = React.lazy(() => import("./pages/XRay/XRay"));
const FactorTesting = React.lazy(() =>
  import("./pages/FactorTesting/FactorTesting")
);
const StockScreener = React.lazy(() => import("./pages/StockScreener"));
// const SignUp = React.lazy(() => import("./pages/SignUp/SignUp"));
const SignIn = React.lazy(() => import("./pages/SignIn/SignIn"));
const Admin = React.lazy(() => import("./pages/Admin"));
const ErrorPage = React.lazy(() => import("./pages/Error/ErrorPage"));
const ModelsStrategies = React.lazy(() => import("./pages/ModelsStrategies/Models"));

const routes = [
  // {
  //   path: dashboardRoutes.signup,
  //   exact: true,
  //   name: "SignUp",
  //   component: SignUp,
  //   role: "user",
  //   privat: false,
  // },
  {
    path: dashboardRoutes.login,
    exact: true,
    name: "SignIn",
    component: SignIn,
    role: "user",
    privat: false,
  },
  {
    path: dashboardRoutes.privacy,
    exact: true,
    name: "Privacy",
    component: Privacy,
    role: "user",
    privat: false,
  },
  {
    path: dashboardRoutes.resetPassword,
    exact: true,
    name: "ResetPassword",
    component: ResetPassword,
    role: "user",
    privat: false,
  },
  {
    path: dashboardRoutes.portfolio,
    exact: true,
    name: "Portfolio",
    component: Portfolio,
    role: "user",
    privat: true,
    auth: [AuthService.authVerify],
    redirect: "/login",
  },
  {
    path: dashboardRoutes.modelStrategies,
    exact: true,
    name: "ModelsStrategies",
    component: ModelsStrategies,
    role: "user",
    privat: true,
    auth: [AuthService.authVerify],
    redirect: "/model-strategies",
  },
  {
    path: dashboardRoutes.factors,
    exact: true,
    name: "Factors",
    component: Factors,
    role: "user",
    privat: true,
    auth: [AuthService.authVerify],
    redirect: "/login",
  },
  {
    path: dashboardRoutes.xRay,
    exact: true,
    name: "X-Ray",
    component: XRay,
    role: "user",
    privat: true,
    auth: [AuthService.authVerify],
    redirect: "/login",
  },
  {
    path: dashboardRoutes.factorTesting,
    exact: true,
    name: "FactorTesting",
    component: FactorTesting,
    role: "user",
    privat: true,
    auth: [AuthService.authVerify],
    redirect: "/login",
  },
  {
    path: dashboardRoutes.stockScreener,
    exact: true,
    name: "StockScreener",
    component: StockScreener,
    role: "user",
    privat: true,
    auth: [AuthService.authVerify],
    redirect: "/login",
  },
  {
    path: dashboardRoutes.userGuide,
    exact: true,
    name: "UserGuide",
    component: UserGuide,
    role: "user",
    privat: false,
  },
  {
    path: dashboardRoutes.admin,
    exact: true,
    name: "Admin",
    component: Admin,
    role: "admin",
    privat: true,
    auth: [AuthService.authVerify, AuthService.adminVerify],
    redirect: "/",
  },
  {
    path: dashboardRoutes.dashboard,
    exact: true,
    name: "Home",
    component: Home,
    role: "user",
    privat: true,
    auth: [AuthService.authVerify],
    redirect: "/login",
  },
  {
    name: "ErrorPage",
    component: ErrorPage,
    role: "user",
    privat: false,
  },
];

export default routes;
