export const GET_TITLES_DATA_START = "XRAY.GET_TITLES_DATA_START";
export const GET_TITLES_DATA_SUCCESS = "XRAY.GET_TITLES_DATA_SUCCESS";
export const GET_TITLES_DATA_FAILED = "XRAY.GET_TITLES_DATA_FAILED";

export const SET_SELECTED_STRATEGY_TITLE = "XRAY.SET_SELECTED_STRATEGY_TITLE";

export const GET_X_RAY_STRATEGY_START = "XRAY.GET_X_RAY_STRATEGY_START";
export const GET_X_RAY_STRATEGY_SUCCESS = "XRAY.GET_X_RAY_STRATEGY_SUCCESS";
export const GET_X_RAY_STRATEGY_FAILED = "XRAY.GET_X_RAY_STRATEGY_FAILED";

export const GET_COMPONENTS_OVERVIEW_START =
  "XRAY.GET_COMPONENTS_OVERVIEW_START";
export const GET_COMPONENTS_OVERVIEW_SUCCESS =
  "XRAY.GET_COMPONENTS_OVERVIEW_SUCCESS";
export const GET_COMPONENTS_OVERVIEW_FAILED =
  "XRAY.GET_COMPONENTS_OVERVIEW_FAILED";

export const GET_STRATEGY_COLLECTION_START =
  "XRAY.GET_STRATEGY_COLLECTION_START";
export const GET_STRATEGY_COLLECTION_SUCCESS =
  "XRAY.GET_STRATEGY_COLLECTION_SUCCESS";
export const GET_STRATEGY_COLLECTION_FAILED =
  "XRAY.GET_STRATEGY_COLLECTION_FAILED";

export const GET_STRATEGY_STRAT_START = "XRAY.GET_STRATEGY_STRAT_START";
export const GET_STRATEGY_STRAT_SUCCESS = "XRAY.GET_STRATEGY_STRAT_SUCCESS";
export const GET_STRATEGY_STRAT_FAILED = "XRAY.GET_STRATEGY_STRAT_FAILED";

export const GET_CONTRIBUTION_ANALYSIS_START =
  "XRAY.GET_CONTRIBUTION_ANALYSIS_START";
export const GET_CONTRIBUTION_ANALYSIS_SUCCESS =
  "XRAY.GET_CONTRIBUTION_ANALYSIS_SUCCESS";
export const GET_CONTRIBUTION_ANALYSIS_FAILED =
  "XRAY.GET_CONTRIBUTION_ANALYSIS_FAILED";

export const GET_ECONOMIC_CYCLES_START = "XRAY.GET_ECONOMIC_CYCLES_START";
export const GET_ECONOMIC_CYCLES_SUCCESS = "XRAY.GET_ECONOMIC_CYCLES_SUCCESS";
export const GET_ECONOMIC_CYCLES_FAILED = "XRAY.GET_ECONOMIC_CYCLES_FAILED";

export const GET_MACRO_EXPOSURES_START = "XRAY.GET_MACRO_EXPOSURES_START";
export const GET_MACRO_EXPOSURES_SUCCESS = "XRAY.GET_MACRO_EXPOSURES_SUCCESS";
export const GET_MACRO_EXPOSURES_FAILED = "XRAY.GET_MACRO_EXPOSURES_FAILED";

export const GET_CONSISTENCY_ANALYSIS_START =
  "XRAY.GET_CONSISTENCY_ANALYSIS_START";
export const GET_CONSISTENCY_ANALYSIS_SUCCESS =
  "XRAY.GET_CONSISTENCY_ANALYSIS_SUCCESS";
export const GET_CONSISTENCY_ANALYSIS_FAILED =
  "XRAY.GET_CONSISTENCY_ANALYSIS_FAILED";

export const GET_ECONOMIC_EVENTS_START = "XRAY.GET_ECONOMIC_EVENTS_START";
export const GET_ECONOMIC_EVENTS_SUCCESS = "XRAY.GET_ECONOMIC_EVENTS_SUCCESS";
export const GET_ECONOMIC_EVENTS_FAILED = "XRAY.GET_ECONOMIC_EVENTS_FAILED";

export const GET_CONSISTENCY_UNDERWATER_START =
  "XRAY.GET_CONSISTENCY_UNDERWATER_START";
export const GET_CONSISTENCY_UNDERWATER_SUCCESS =
  "XRAY.GET_CONSISTENCY_UNDERWATER_SUCCESS";
export const GET_CONSISTENCY_UNDERWATER_FAILED =
  "XRAY.GET_CONSISTENCY_UNDERWATER_FAILED";

export const SET_XRAY_ACTIVE_TAB = "XRAY.SET_XRAY_ACTIVE_TAB";
