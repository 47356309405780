export const GET_SECURITY_START = "CATALOG.GET_SECURITY_START";
export const GET_SECURITY_SUCCESS = "CATALOG.GET_SECURITY_SUCCESS";
export const GET_SECURITY_FAILED = "CATALOG.GET_SECURITY_FAILED";

export const GET_METHODOLOGIES_START = "CATALOG.GET_METHODOLOGIES_START";
export const GET_METHODOLOGIES_SUCCESS = "CATALOG.GET_METHODOLOGIES_SUCCESS";
export const GET_METHODOLOGIES_FAILED = "CATALOG.GET_METHODOLOGIES_FAILED";

export const GET_BENCHMARKS_START = "CATALOG.GET_BENCHMARKS_START";
export const GET_BENCHMARKS_SUCCESS = "CATALOG.GET_BENCHMARKS_SUCCESS";
export const GET_BENCHMARKS_FAILED = "CATALOG.GET_BENCHMARKS_FAILED";
