import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSnackbar } from "notistack";
import BaseLayout from "./layout/baseLayout";
import SnackbarUtils from "./helpers/SnackBarUtils";
import "./styles/scss/fonts.scss";
import "./styles/scss/common.scss";

function App() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  SnackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar);

  return (
    <Router>
      <BaseLayout />
    </Router>
  );
}

export default App;
