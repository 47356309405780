const GraphIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 512 512"
  >
    <path d="M48 368a23.928 23.928 0 0 0 16.971-7.029L200 225.941l55.029 55.03a24 24 0 0 0 33.942 0l151.02-151.02-.009 54.049a24 24 0 0 0 24 24 24 24 0 0 0 24-24L488 72a24 24 0 0 0-24-24H352a24 24 0 0 0 0 48h54.059L272 230.059l-55.029-55.03a24 24 0 0 0-33.942 0l-152 152A24 24 0 0 0 48 368zM464 416H48a24 24 0 0 0 0 48h416a24 24 0 0 0 0-48z" />
  </svg>
);

export default GraphIcon;
