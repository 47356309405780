import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { NavLink } from "react-router-dom";
import useStyles from "./style";
import config from "./config";
import { useSelector } from "react-redux";

const SidebarNavigation = () => {
  const classes = useStyles();

  const isAdmin = useSelector((s) => s.user.isAdmin);
  const is_guest = useSelector((s) => s.user?.userData?.is_guest);

  return (
    <List className={classes.list}>
      { is_guest ?
        config.guestNavigation.map((item) => (
          <ListItem
            component={NavLink}
            exact
            className={classes.listItem}
            key={item.name}
            to={item.path}
          >
            <ListItemIcon className={classes.listItemIcon}>
              {" "}
              {item.icon}{" "}
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary={item.title} />
          </ListItem>
        ))
      :config.navigationList.map((item) => (
        <ListItem
          component={NavLink}
          exact
          className={classes.listItem}
          key={item.name}
          to={item.path}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {" "}
            {item.icon}{" "}
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary={item.title} />
        </ListItem>
      ))}
      {isAdmin &&
        config.adminNavigation.map((item) => (
          <ListItem
            component={NavLink}
            exact
            className={classes.listItem}
            key={item.name}
            to={item.path}
          >
            <ListItemIcon className={classes.listItemIcon}>
              {" "}
              {item.icon}{" "}
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={item.title}
            />
          </ListItem>
        ))}
    </List>
  );
};

export default SidebarNavigation;
