import axios from "axios";
import { mainUrl } from "../config";

const getMethodologies = async (token) =>
  axios
    .get(`${mainUrl.API_URL}/api/portfolio/catalog/searchmethodology`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getBenchmarks = async () =>
  axios
    .get(`${mainUrl.API_URL}/api/portfolio/catalog/benchmarks`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getSecurity = async () =>
  axios
    .get(`${mainUrl.API_URL}/api/portfolio/catalog/security`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getPublicAndPrivateStrategies = async (token) =>
  axios
    .get(`${mainUrl.API_URL}/api/portfolio/titles`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const getPublicStrategies = async () =>
  axios
    .get(`${mainUrl.API_URL}/api/portfolio/public_titles`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

const CatalogApi = {
  getMethodologies,
  getBenchmarks,
  getSecurity,
  getPublicStrategies,
  getPublicAndPrivateStrategies,
};

export default CatalogApi;
