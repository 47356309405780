export const GET_FACTOR_TESTING_FILTER_START =
  "GET_FACTOR_TESTING_FILTER_START";
export const GET_FACTOR_TESTING_FILTER_SUCCESS =
  "GET_FACTOR_TESTING_FILTER_SUCCESS";
export const GET_FACTOR_TESTING_FILTER_FAILED =
  "GET_FACTOR_TESTING_FILTER_FAILED";

export const GET_FACTOR_TESTING_REBALANCE_START =
  "GET_FACTOR_TESTING_REBALANCE_START";
export const GET_FACTOR_TESTING_REBALANCE_SUCCESS =
  "GET_FACTOR_TESTING_REBALANCE_SUCCESS";
export const GET_FACTOR_TESTING_REBALANCE_FAILED =
  "GET_FACTOR_TESTING_REBALANCE_FAILED";

export const GET_FACTOR_TESTING_CURRENCY_START =
  "GET_FACTOR_TESTING_CURRENCY_START";
export const GET_FACTOR_TESTING_CURRENCY_SUCCESS =
  "GET_FACTOR_TESTING_CURRENCY_SUCCESS";
export const GET_FACTOR_TESTING_CURRENCY_FAILED =
  "GET_FACTOR_TESTING_CURRENCY_FAILED";

export const GET_FACTOR_TESTING_SAVED_REPORTS_START =
  "GET_FACTOR_TESTING_SAVED_REPORTS_START";
export const GET_FACTOR_TESTING_SAVED_REPORTS_SUCCESS =
  "GET_FACTOR_TESTING_SAVED_REPORTS_SUCCESS";
export const GET_FACTOR_TESTING_SAVED_REPORTS_FAILED =
  "GET_FACTOR_TESTING_SAVED_REPORTS_FAILED";

export const LOAD_FACTOR_TESTING_SAVED_REPORT_START =
  "LOAD_FACTOR_TESTING_SAVED_REPORT_START";
export const LOAD_FACTOR_TESTING_SAVED_REPORT_SUCCESS =
  "LOAD_FACTOR_TESTING_SAVED_REPORT_SUCCESS";
export const LOAD_FACTOR_TESTING_SAVED_REPORT_FAILED =
  "LOAD_FACTOR_TESTING_SAVED_REPORT_FAILED";

export const CALCULATE_FACTOR_TESTING_CSV_REPORT_START =
  "CALCULATE_FACTOR_TESTING_CSV_REPORT_START";
export const CALCULATE_FACTOR_TESTING_CSV_REPORT_SUCCESS =
  "CALCULATE_FACTOR_TESTING_CSV_REPORT_SUCCESS";
export const CALCULATE_FACTOR_TESTING_CSV_REPORT_FAILED =
  "CALCULATE_FACTOR_TESTING_CSV_REPORT_FAILED";

export const SAVE_FACTOR_TESTING_REPORT_START =
  "SAVE_FACTOR_TESTING_REPORT_START";
export const SAVE_FACTOR_TESTING_REPORT_SUCCESS =
  "SAVE_FACTOR_TESTING_REPORT_SUCCESS";
export const SAVE_FACTOR_TESTING_REPORT_FAILED =
  "SAVE_FACTOR_TESTING_REPORT_FAILED";

export const UPDATE_FACTOR_TESTING_REPORT_START =
  "UPDATE_FACTOR_TESTING_REPORT_START";
export const UPDATE_FACTOR_TESTING_REPORT_SUCCESS =
  "UPDATE_FACTOR_TESTING_REPORT_SUCCESS";
export const UPDATE_FACTOR_TESTING_REPORT_FAILED =
  "UPDATE_FACTOR_TESTING_REPORT_FAILED";

export const GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_START =
  "GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_START";
export const GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_SUCCESS =
  "GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_SUCCESS";
export const GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_FAILED =
  "GET_FACTOR_TESTING_WIN_RATE_TABLE_DATA_FAILED";

export const GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_START =
  "GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_START";
export const GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_SUCCESS =
  "GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_SUCCESS";
export const GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_FAILED =
  "GET_FACTOR_TESTING_RETURNS_DISTRIBUTION_DATA_FAILED";

export const GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_START =
  "GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_START";
export const GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_SUCCESS =
  "GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_SUCCESS";
export const GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_FAILED =
  "GET_FACTOR_TESTING_BEST_WORST_PERIODS_DATA_FAILED";

export const GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_START =
  "GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_START";
export const GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_SUCCESS =
  "GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_SUCCESS";
export const GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_FAILED =
  "GET_FACTOR_TESTING_MONTHLY_TABLE_DATA_FAILED";

export const GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_START =
  "GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_START";
export const GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_SUCCESS =
  "GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_SUCCESS";
export const GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_FAILED =
  "GET_FACTOR_TESTING_ROLLING_RETURNS_VOLATILITY_SHARP_DATA_FAILED";

export const GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_START =
  "GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_START";
export const GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_SUCCESS =
  "GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_SUCCESS";
export const GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_FAILED =
  "GET_FACTOR_TESTING_UNDERWATER_CHART_DATA_FAILED";

export const GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_START =
  "GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_START";
export const GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_SUCCESS =
  "GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_SUCCESS";
export const GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_FAILED =
  "GET_FACTOR_TESTING_ROLLING_CORRELATION_CHART_DATA_FAILED";

export const DELETE_FACTOR_TESTING_REPORT_START =
  "DELETE_FACTOR_TESTING_REPORT_START";
export const DELETE_FACTOR_TESTING_REPORT_SUCCESS =
  "DELETE_FACTOR_TESTING_REPORT_SUCCESS";
export const DELETE_FACTOR_TESTING_REPORT_FAILED =
  "DELETE_FACTOR_TESTING_REPORT_FAILED";

export const SET_FACTOR_TESTING_SAVED_REPORT =
  "SET_FACTOR_TESTING_SAVED_REPORT";

export const SET_FACTOR_TESTING_ACTIVE_TAB = "SET_FACTOR_TESTING_ACTIVE_TAB";

export const SET_FACTOR_TESTING_FILTER = "SET_FACTOR_TESTING_FILTER";
