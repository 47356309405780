import UserApi from "../../services/User";
import { generateErrorMessage } from "../../helpers/Error";
import * as UserTypes from "../types/User";
import SnackBarUtils from "../../helpers/SnackBarUtils";
import { getDashboardComposition } from "./Dashboard";
import { getMethodologies } from "./Catalog";
import { batch } from "react-redux";
import { updateFactorsFiltersValues } from "./Factor";
import { logout } from "./Auth";
import { ADDITIONAL_ASSET_TYPES } from "../../helpers/Assets";

const refetchDataBasedOnRoute = {
  "/": getDashboardComposition,
  "/portfolio": [getMethodologies],
  "/factors": [updateFactorsFiltersValues],
};

const getParamsForRefetchData = (route, state) => {
  switch (route) {
    case "/":
      const { selectedFactor, selectedUniverse } = state.dashboard;

      if (selectedUniverse) {
        return {
          report_type: "universe",
          name: selectedUniverse.country_id,
        };
      }
      if (
        selectedFactor &&
        selectedFactor.report_type === ADDITIONAL_ASSET_TYPES.strategy
      ) {
        return {
          report_type: ADDITIONAL_ASSET_TYPES.strategy,
          name: selectedFactor.name,
        };
      }

    case "/portfolio":
      return {};

    case "/factors":
      return {};
  }
};

export const getUserFactorGroups = (controller) => async (dispatch) => {
  dispatch({ type: UserTypes.GET_FACTORS_GROUPING_START });
  return UserApi.getFactorGroups(controller)
    .then((data) => {
      dispatch({
        type: UserTypes.GET_FACTORS_GROUPING_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({ type: UserTypes.GET_FACTORS_GROUPING_FAILED });
      generateErrorMessage(error);
    });
};

export const updateUserFactorsGrouping =
  (params, pathname, controller) => async (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    dispatch({ type: UserTypes.UPDATE_USER_FACTORS_GROUPING_START });
    return UserApi.updateUserTokenWithFactorGroups(
      params,
      user.userData.access_token,
      controller
    )
      .then((data) => {
        const updates = {
          source_factors: params.user_group,
          robust: params.robust,
          access_token: data.access_token,
          token_type: data.token_type,
        };

        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user.userData,
            ...updates,
          })
        );

        dispatch({
          type: UserTypes.UPDATE_USER_FACTORS_GROUPING_SUCCESS,
          payload: updates,
        });

        if (Array.isArray(refetchDataBasedOnRoute[pathname])) {
          batch(() => {
            refetchDataBasedOnRoute[pathname].forEach((action) => {
              dispatch(action());
            });
          });
        } else {
          const paramsForFetch = getParamsForRefetchData(pathname, state);

          dispatch(
            refetchDataBasedOnRoute[pathname](paramsForFetch, controller)
          );
        }

        SnackBarUtils.success("Groups successfully updated");
      })
      .catch((error) => {
        dispatch({ type: UserTypes.UPDATE_USER_FACTORS_GROUPING_FAILED });
        generateErrorMessage(error);
      });
  };

export const initApp = () => async (dispatch, getState) => {
  dispatch({ type: UserTypes.INIT_APP_START });

  const { user } = getState();

  return UserApi.initApp(user.userData.access_token)
    .then((data) => {
      dispatch({
        type: UserTypes.INIT_APP_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch({ type: UserTypes.INIT_APP_FAILED });
        dispatch(logout());
      });
      generateErrorMessage(error);
    });
};
