import Fuse from "fuse.js";
import * as Yup from "yup";
import { ADDITIONAL_ASSET_TYPES, ASSET_TYPES } from "./Assets";

const MAX_PRESET_NAME_LENGTH = 48;

export const SavePresetValidation = Yup.object({
  name: Yup.string()
    .max(
      MAX_PRESET_NAME_LENGTH,
      `Name must be less than ${MAX_PRESET_NAME_LENGTH} characters`
    )
    .required("Name is a required field"),
  asset_list: Yup.array().min(
    1,
    "Asset list must contain at least one element"
  ),
  default_universe: Yup.string().required("Universe is a required field"),
  default_style_return: Yup.string().required(
    "Style return is a required field"
  ),
  default_factors_return: Yup.string().required(
    "Factors return is a required field"
  ),
  displayed_statistics: Yup.array().min(
    1,
    "Displayed Statistics list must contain at least one element"
  ),
});

export const renderLetter = (type) => {
  switch (type) {
    case ASSET_TYPES.benchmark:
      return "B";
    case ADDITIONAL_ASSET_TYPES.strategy:
      return "S";
    case ASSET_TYPES.portfolio:
      return "P";
    default:
      return "P";
  }
};

export const portfolioStyleIndex = (style) => {
  switch (style) {
    case "Momentum":
      return 0;
    case "Machine Learning":
      return 1;
    case "Multi Factor":
      return 2;
    case "Quality":
      return 3;
    case "Low Volatility":
      return 4;
    case "Issuance":
      return 5;
    case "Liquidity":
      return 6;
    case "Not Defined":
      return 7;
    case "Growth":
      return 8;
    case "Statistical Arbitrage":
      return 9;
    case "Trend Following":
      return 10;
    case "SmartBeta":
      return 11;
    case "ETF":
      return 12;
    case "Global Macro":
      return 13;
    case "Profit Growth":
      return 14;
    case "Profit Growth":
      return 15;
    case "Investment":
      return 16;
    case "Low Leverage":
      return 17;
    case "Skewness":
      return 18;
    case "Debt Issuance":
      return 20;
    case "Accruals":
      return 21;
    case "Seasonality":
      return 22;
    case "Profitability":
      return 23;
    case "Size":
      return 24;
    case "Value":
      return 25;
    default:
      return 0;
  }
};

export function searchOptions(options, searchValue) {
  if (!options.length) {
    return [];
  }

  const params = {
    useExtendedSearch: true,
    keys: ["name", "short_name", "long_name"],
    ignoreLocation: true,
  };

  const searchStr = searchValue
    .trim()
    .split(" ")
    .map((s) => "'" + s)
    .join(" ");

  const fuse = new Fuse(options, params);

  return fuse.search(searchStr).map((elem) => elem.item);
}

export class SelectedPreset {
  constructor(
    id,
    name,
    isDefault,
    date,
    default_factors_return,
    default_style_return,
    default_universe,
    stats
  ) {
    this.id = id;
    this.name = name;
    this.default = isDefault;
    this.date = date;
    this.default_factors_return = default_factors_return;
    this.default_style_return = default_style_return;
    this.default_universe = default_universe;
    this.stats = stats;
  }
}
