import {
  LOGOUT,
  ROLLING_RETURNS_FULFILLED_STATUS_SUCCEEDED,
  ROLLING_RETURNS_LOADING,
  ROLLING_RETURNS_REJECTED,
} from "../actions/Type";
import { requestStatuses } from "../../helpers/Constants";

const { idle } = requestStatuses;

const initialState = {
  status: idle,
  error: null,
};

export default function rollingReturns(state = initialState, action) {
  switch (action.type) {
    case ROLLING_RETURNS_REJECTED:
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    case ROLLING_RETURNS_LOADING:
      return { ...state, status: action.payload, error: null };
    case ROLLING_RETURNS_FULFILLED_STATUS_SUCCEEDED:
      return { ...state, status: action.payload, error: null };

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
