import {
  EarlyValues,
  MidValues,
  LateValues,
  RecessionValues,
  templateBusinessCycleTableFiltersSortArr,
  businessCycleTableGroups,
  MacroAndFactorInfluencesTableHeaders,
} from "../features/XRay/consts";
import { headerLength, isNeedHeader } from "./Factor";
import XLSX from "xlsx-js-style";
import moment from "moment";

const headersOrder = MacroAndFactorInfluencesTableHeaders.map(
  (item) => item.value
);

export const checkAllExposuresGroupMenu = (selectedEditTableOptions, key) => {
  switch (key) {
    case "early":
      return EarlyValues.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "middle":
      return MidValues.every((item) => selectedEditTableOptions.includes(item));

    case "late":
      return LateValues.every((item) =>
        selectedEditTableOptions.includes(item)
      );

    case "recession":
      return RecessionValues.every((item) =>
        selectedEditTableOptions.includes(item)
      );
  }
};

export const checkIndeterminateExposuresAllGroupMenu = (
  selectedEditTableOptions,
  key
) => {
  if (checkAllExposuresGroupMenu(selectedEditTableOptions, key)) {
    return false;
  }

  switch (key) {
    case "early":
      return selectedEditTableOptions.some((item) =>
        EarlyValues.includes(item)
      );

    case "middle":
      return selectedEditTableOptions.some((item) => MidValues.includes(item));

    case "late":
      return selectedEditTableOptions.some((item) => LateValues.includes(item));

    case "10years":
      return selectedEditTableOptions.some((item) =>
        years10Values.includes(item)
      );

    case "recession":
      return selectedEditTableOptions.some((item) =>
        RecessionValues.includes(item)
      );
  }
};

export const filterExposuresCellsWithEditTableOptions = (
  value,
  selectedEditTableOptions
) => {
  let result;

  switch (value) {
    case "all":
      result =
        selectedEditTableOptions.length ===
        templateBusinessCycleTableFiltersSortArr.length
          ? []
          : templateBusinessCycleTableFiltersSortArr;
      break;

    case "early":
      if (
        EarlyValues.every((item) => selectedEditTableOptions.includes(item))
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !EarlyValues.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...EarlyValues]);
        result = Array.from(res);
      }
      break;

    case "middle":
      if (MidValues.every((item) => selectedEditTableOptions.includes(item))) {
        const res = selectedEditTableOptions.filter(
          (item) => !MidValues.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...MidValues]);
        result = Array.from(res);
      }
      break;

    case "late":
      if (LateValues.every((item) => selectedEditTableOptions.includes(item))) {
        const res = selectedEditTableOptions.filter(
          (item) => !LateValues.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...LateValues]);
        result = Array.from(res);
      }
      break;

    case "recession":
      if (
        RecessionValues.every((item) => selectedEditTableOptions.includes(item))
      ) {
        const res = selectedEditTableOptions.filter(
          (item) => !RecessionValues.includes(item)
        );
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, ...RecessionValues]);
        result = Array.from(res);
      }
      break;

    default:
      if (selectedEditTableOptions.includes(value)) {
        const res = selectedEditTableOptions.filter((item) => item !== value);
        result = res;
      } else {
        const res = new Set([...selectedEditTableOptions, value]);
        result = Array.from(res);
      }
  }

  result.sort(
    (a, b) =>
      templateBusinessCycleTableFiltersSortArr.indexOf(a) -
      templateBusinessCycleTableFiltersSortArr.indexOf(b)
  );

  return result;
};

export function checkRangeData(value) {
  switch (true) {
    case value <= 0.1 && value > 0.05:
      return { label: "*", color: "#d4e9ec" };

    case value <= 0.05 && value > 0.01:
      return { label: "**", color: "#6eafb8" };

    case value <= 0.01 && value >= 0:
      return { label: "***", color: "#21686f" };

    case value < 0:
      return { label: "", color: "#ffacc4" };

    default:
      return { label: "", color: "#ffffff" };
  }
}

export const createExposuresPrintPage = (
  businessCycleRef,
  geographicalExposuresRef,
  styleExposureRef,
  macroAndFactorInfluencesRef
) => {
  const businessCycleElem = businessCycleRef.current.cloneNode(true);
  const geographicalExposureElem =
    geographicalExposuresRef.current.cloneNode(true);
  const styleExposureElem = styleExposureRef.current.cloneNode(true);
  const macroAndFactorInfluenceElem =
    macroAndFactorInfluencesRef.current.cloneNode(true);

  // businessCycle
  const businessCycleWrapper = document.createElement("div");
  const businessCycleTitle = document.createElement("p");
  businessCycleTitle.innerText = "Performance Through Business Cycle";
  businessCycleTitle.style.cssText =
    "font-size: 13px; font-weight: bold; font-family: 'Oswald', 'Arial', 'sans-serif' !important";
  businessCycleWrapper.style.cssText = "page-break-after: always !important;";

  businessCycleWrapper.appendChild(businessCycleTitle);
  businessCycleWrapper.appendChild(businessCycleElem);

  // geographicalExposure
  const geographicalExposureWrapper = document.createElement("div");
  const geographicalExposureTitle = document.createElement("p");
  geographicalExposureTitle.innerText = "Geographical Exposures";
  geographicalExposureTitle.style.cssText =
    "font-size: 13px; font-weight: bold; font-family: 'Oswald', 'Arial', 'sans-serif' !important";
  geographicalExposureWrapper.style.cssText =
    "page-break-after: always !important;";

  geographicalExposureWrapper.appendChild(geographicalExposureTitle);
  geographicalExposureWrapper.appendChild(geographicalExposureElem);

  // styleExposure
  const styleExposureWrapper = document.createElement("div");
  const styleExposureTitle = document.createElement("p");
  styleExposureTitle.innerText = "Style Exposure";
  styleExposureTitle.style.cssText =
    "font-size: 13px; font-weight: bold; font-family: 'Oswald', 'Arial', 'sans-serif' !important";
  styleExposureWrapper.style.cssText = "page-break-after: always !important;";

  styleExposureWrapper.appendChild(styleExposureTitle);
  styleExposureWrapper.appendChild(styleExposureElem);

  // charts
  const ChartsWrapperElem = document.createElement("div");
  ChartsWrapperElem.style.cssText =
    "page-break-after: always !important;page-break-before: always !important; display: flex; justify-content: space-evenly; flex-wrap: wrap; gap: 20px";

  ChartsWrapperElem.appendChild(geographicalExposureWrapper);
  ChartsWrapperElem.appendChild(styleExposureWrapper);

  // macroAndFactorInfluence
  const macroAndFactorInfluenceWrapper = document.createElement("div");
  const macroAndFactorInfluenceTitle = document.createElement("p");
  macroAndFactorInfluenceTitle.innerText = "Macro and Factor Influences";
  macroAndFactorInfluenceTitle.style.cssText =
    "font-size: 13px; font-weight: bold; font-family: 'Oswald', 'Arial', 'sans-serif' !important";
  macroAndFactorInfluenceWrapper.style.cssText =
    "page-break-after: always !important;";

  macroAndFactorInfluenceWrapper.appendChild(macroAndFactorInfluenceTitle);
  macroAndFactorInfluenceWrapper.appendChild(macroAndFactorInfluenceElem);

  const PrintElem = document.createElement("div");
  PrintElem.appendChild(businessCycleWrapper);
  PrintElem.appendChild(ChartsWrapperElem);
  PrintElem.appendChild(macroAndFactorInfluenceWrapper);

  return PrintElem;
};

export const createBusinessCyclePrintPage = (businessCycleRef) => {
  const businessCycleElem = businessCycleRef.current.cloneNode(true);
  const PrintElem = document.createElement("div");
  PrintElem.appendChild(businessCycleElem);
  return PrintElem;
};

export const createGeographicalExposuresPrintPage = (
  geographicalExposuresRef
) => {
  const geographicalExposuresElem =
    geographicalExposuresRef.current.cloneNode(true);
  const PrintElem = document.createElement("div");
  PrintElem.appendChild(geographicalExposuresElem);
  return PrintElem;
};

export const createStyleExposuresPrintPage = (styleExposureRef) => {
  const styleExposureElem = styleExposureRef.current.cloneNode(true);
  const PrintElem = document.createElement("div");
  PrintElem.appendChild(styleExposureElem);
  return PrintElem;
};

export const createMacroAndFactorInfluencesPrintPage = (
  macroAndFactorInfluencesRef
) => {
  const macroAndFactorInfluencesElem =
    macroAndFactorInfluencesRef.current.cloneNode(true);
  const PrintElem = document.createElement("div");
  PrintElem.appendChild(macroAndFactorInfluencesElem);
  return PrintElem;
};

export const getBusinessCycleExcelData = (
  economicCycles,
  selectedEditTableOptions
) => {
  const groupedHeaders = [];

  if (isNeedHeader(selectedEditTableOptions, EarlyValues)) {
    groupedHeaders.push({
      name: "Early (Rebounds)",
      count: headerLength(selectedEditTableOptions, EarlyValues),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, MidValues)) {
    groupedHeaders.push({
      name: "Mid (Peaks)",
      count: headerLength(selectedEditTableOptions, MidValues),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, LateValues)) {
    groupedHeaders.push({
      name: "Late (Moderates)",
      count: headerLength(selectedEditTableOptions, LateValues),
    });
  }

  if (isNeedHeader(selectedEditTableOptions, RecessionValues)) {
    groupedHeaders.push({
      name: "Recession (Contracts)",
      count: headerLength(selectedEditTableOptions, RecessionValues),
    });
  }

  let labelsArr = [
    {
      v: "",
    },
  ];

  groupedHeaders.forEach((item) => {
    labelsArr.push({
      v: item.name,
    });
    for (let index = 1; index < item.count; index++) {
      labelsArr.push({
        v: "",
      });
    }
  });

  const strategyRow = [
    economicCycles.strategy.strategy_name,
    ...selectedEditTableOptions.map((item) => {
      const [group, value] = item.split("&");
      return economicCycles.strategy.stats[group][value];
    }),
  ];

  const subHeaders = [
    {
      v: "Factor",
    },
    ...selectedEditTableOptions.map((item) => {
      const [group] = item.split("&");
      return {
        v: businessCycleTableGroups[group].children.find(
          (elem) => elem.id === item
        ).label,
      };
    }),
  ];

  const values = economicCycles.data.map((item) => {
    const data = item.stats;
    return [
      {
        v: `${item.title}, ${item.country}, ${item.top_btm}, ${item.style}`,

        s: {
          font: (item.title.toLowerCase().includes("mlt") ||
            item.style === "Multi Factor") && {
            color: { rgb: "BD8C1D" },
          },
        },
      },
      ...selectedEditTableOptions.map((item) => {
        const [group, value] = item.split("&");
        return {
          t: "n",
          v: data[group][value],
          s: { alignment: { horizontal: "right" } },
        };
      }),
    ];
  });

  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.aoa_to_sheet([
    [...labelsArr],
    subHeaders,
    strategyRow,
    ...values,
  ]);

  ws["!cols"] = [{ width: 60 }, ...labelsArr.map(() => ({ width: 15 }))];

  return [wb, ws, "Business Cycle"];
};

export const getMacroAndFactorInfluenceExcelData = (macroExposures) => {
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.aoa_to_sheet([
    [
      {
        v: "Factor",
      },
      ...MacroAndFactorInfluencesTableHeaders.map((item) => ({
        v: item.label,
      })),
    ],
    ...macroExposures.data.map((factor) => [
      {
        v: `${factor.title}, ${factor.top_btm}, ${factor.country}`,
        s: {
          font: (factor.title.toLowerCase().includes("mlt") ||
            factor.style === "Multi Factor") && {
            color: { rgb: "BD8C1D" },
          },
        },
      },
      ...headersOrder.map((key) => {
        const info = checkRangeData(factor.pvalues[key]);
        return {
          v: factor.betas[key].toFixed(2) + (info.label ? info.label : ""),
        };
      }),
    ]),
  ]);

  ws["!cols"] = [
    { width: 60 },
    ...macroExposures.data.map(() => ({ width: 15 })),
  ];

  return [wb, ws, "Macro Exposures"];
};

const EXCEL_FILE_NAME = `${moment().format("MMM YY")} report`;

export const checkColor = (value) => {
  if (!value && value !== 0) return "FFFFFF";

  if (value > 1) {
    return "11A60A";
  } else if (value >= 0) {
    return "A0DB9D";
  } else if (value < -1) {
    return "DD104B";
  } else {
    return "FFACC4";
  }
};

const getTitle = (item, groupBy) => {
  switch (groupBy.value) {
    case "style":
      return item.style && item.style;
    case "country":
      return item.country && item.country;
    case "factor":
      return (
        item.title &&
        `${item.title} ${item.top_btm ? ` - (${item.top_btm}) ` : ""}`
      );

    default:
      return "";
  }
};

export function exportExcelContributionAnalysis(
  groupBy,
  contributionAnalysis,
  selectedCellDataValue,
  tableData,
  data
) {
  const borderStyle = { style: "thin", color: { rgb: "000000" } };
  const alignmentStyle = { horizontal: "center" };

  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.aoa_to_sheet([
    [
      {
        v: "Factor",
      },
      {
        v: "%Return",
        s: {
          alignment: alignmentStyle,
        },
      },
      {
        v: "%Contribution",
        s: {
          alignment: alignmentStyle,
        },
      },
    ],
    [
      {
        v: contributionAnalysis.right_table.strategy["strategy name"],
        s: {
          fill: { fgColor: { rgb: "E6EDED" } },
        },
      },
      {
        v: selectedCellDataValue,
        s: {
          alignment: alignmentStyle,
          fill: { fgColor: { rgb: "E6EDED" } },
        },
      },
      {
        v: selectedCellDataValue,
        s: {
          alignment: alignmentStyle,
          fill: { fgColor: { rgb: "E6EDED" } },
        },
      },
    ],
    ...tableData.map((item, index) => {
      const bgStyles = {
        fgColor: { rgb: index % 2 > 0 ? "F3F6F7" : "FFFFFF" },
      };

      return [
        {
          v: getTitle(item, groupBy),
          s: {
            fill: bgStyles,
            font: (getTitle(item, groupBy).toLowerCase().includes("mlt") ||
              item.style === "Multi Factor") && {
              color: { rgb: "BD8C1D" },
            },
          },
        },
        {
          t: "n",
          v: item.date?.return ? item.date.return.toFixed(2) : "-",
          s: {
            alignment: alignmentStyle,
            fill: bgStyles,
          },
        },
        {
          t: "n",
          v: item.date?.contribution ? item.date.contribution.toFixed(2) : "-",
          s: {
            alignment: alignmentStyle,
            fill: bgStyles,
          },
        },
      ];
    }),
  ]);
  ws["!cols"] = [{ width: 50 }, { width: 15 }, { width: 15 }];

  const ws2 = XLSX.utils.aoa_to_sheet([
    [
      { v: "Month", s: { alignment: alignmentStyle } },
      ...data.columns
        .map((col) => ({
          v: col,
          s: { alignment: alignmentStyle },
        }))
        .reverse(),
    ],
    ...Object.keys(data.data).map((month) => {
      return [
        {
          v: moment(month).format("MMM"),
          s: {
            alignment: alignmentStyle,
            border: {
              top: borderStyle,
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            },
          },
        },
        ...Object.values(data.data[month])
          .map((val) =>
            val
              ? {
                  t: "n",
                  v: val.toFixed(1),
                  s: {
                    alignment: alignmentStyle,
                    fill: {
                      fgColor: { rgb: checkColor(val) },
                    },
                    border: {
                      top: borderStyle,
                      bottom: borderStyle,
                      left: borderStyle,
                      right: borderStyle,
                    },
                  },
                }
              : {
                  v: "NA",
                  s: {
                    alignment: alignmentStyle,
                    border: {
                      top: borderStyle,
                      bottom: borderStyle,
                      left: borderStyle,
                      right: borderStyle,
                    },
                  },
                }
          )
          .reverse(),
        ,
      ];
    }),
  ]);
  ws2["!cols"] = data.columns.map(() => {
    width: 5;
  });

  XLSX.utils.book_append_sheet(wb, ws2, "Monthly returns");
  XLSX.utils.book_append_sheet(wb, ws, cutExcelSheetName(EXCEL_FILE_NAME));

  XLSX.writeFile(wb, "Contribution_Analysis.xlsx");
}
