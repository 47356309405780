import * as CalculatorTypes from "../types/Calculator";
import { requestStatuses } from "../../helpers/Constants";

const { idle } = requestStatuses;

const initialState = {
  calculator: {
    assets: [],
    status: idle,
    error: null,
    isLoading: false,
  }
}

export default function calculator(state = initialState, action) {
  switch (action.type) {
    case CalculatorTypes.GET_RATIO_START:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          status: action.payload,
          isLoading: true,
          assets: []
        },
      };

    case CalculatorTypes.GET_RATIO_SUCCESS:
      return {
        ...state,
        calculator: {
          ...state.calculator,
          assets: action.payload,
          status: action.payload.status,
          isLoading: false
        },
      };

    case CalculatorTypes.GET_RATIO_FAILED:
      return {
        ...state,
        ratio: {
          ...state.calculators,
          status: action.payload.status,
          error: action.payload.error,
          isLoading: false,
        },
      };

    default:
      return state;
  }
}