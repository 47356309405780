import { createTheme } from "@mui/material/styles";

const oswald = '"Oswald", "Arial", "sans-serif" !important';
const arimo = '"Arimo", "Arial", "sans-serif" !important';

export const theme = createTheme({
  components: {
    MuiLink: {
      root: {
        "&.MuiLink-underlineHover": {
          textDecoration: "underline",
        },
        "&:hover": {
          color: "#184c51",
        },
      },
    },
    MuiCheckbox: {
      root: {
        "&.MuiCheckbox-colorSecondary.Mui-checked": {
          color: "#184c51",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1600,
    },
    unit: "px",
    step: 1,
  },
  palette: {
    contrastText: "#1c585e",
    background: {
      light: "#f3f6f7",
      tableHead:
        "linear-gradient(180deg, rgba(245,247,248,1) 0%, rgba(255,255,255,1) 100%)",
    },
    primary: {
      main: "#184c51",
      light: "#28565e",
      contrastText: "#fff",
    },
    secondary: {
      main: "#28565e",
    },
    border: {
      main: "#aeb0b1",
      secondary: "#e3e4e5",
    },
    danger: {
      main: "#ed5466",
      table: "#dd104b",
      tableSecondary: "#ffacc4",
    },
    success: {
      main: "#2e7d32",
      table: "#11a60a",
      tableSecondary: "#a0db9d",
    },
    grey: {
      900: "#25292e",
      100: "#a1a4a9",
      110: "#a1a4a9",
    },
  },
  typography: {
    h6: {
      fontFamily: oswald,
    },
    body1: {
      fontFamily: arimo,
    },
    body2: {
      fontFamily: arimo,
    },
    body3: {
      fontFamily: arimo,
      fontWeight: 700,
    },
    fontFamily: arimo,
    fontFamilyBold: oswald,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});
