export const SET_RETURN_PERIOD = "DASHBOARD.SET_RETURN_PERIOD";

export const SET_UNIVERSE = "DASHBOARD.SET_UNIVERSE";

export const SET_FACTOR = "DASHBOARD.SET_FACTOR";

export const GET_ALL_UNIVERSES_START = "DASHBOARD.GET_ALL_UNIVERSES_START";
export const GET_ALL_UNIVERSES_SUCCESS = "DASHBOARD.GET_ALL_UNIVERSES_SUCCESS";
export const GET_ALL_UNIVERSES_FAILED = "DASHBOARD.GET_ALL_UNIVERSES_FAILED";

export const GET_DASHBOARD_BENCHMARKS_START =
  "DASHBOARD.GET_DASHBOARD_BENCHMARKS_START";
export const GET_DASHBOARD_BENCHMARKS_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_BENCHMARKS_SUCCESS";
export const GET_DASHBOARD_BENCHMARKS_FAILED =
  "DASHBOARD.GET_DASHBOARD_BENCHMARKS_FAILED";

export const GET_DASHBOARD_ACTIVE_STRATEGIES_START =
  "DASHBOARD.GET_DASHBOARD_ACTIVE_STRATEGIES_START";
export const GET_DASHBOARD_ACTIVE_STRATEGIES_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_ACTIVE_STRATEGIES_SUCCESS";
export const GET_DASHBOARD_ACTIVE_STRATEGIES_FAILED =
  "DASHBOARD.GET_DASHBOARD_ACTIVE_STRATEGIES_FAILED";

export const GET_DASHBOARD_PORTFOLIOS_START =
  "DASHBOARD.GET_DASHBOARD_PORTFOLIOS_START";
export const GET_DASHBOARD_PORTFOLIOS_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_PORTFOLIOS_SUCCESS";
export const GET_DASHBOARD_PORTFOLIOS_FAILED =
  "DASHBOARD.GET_DASHBOARD_PORTFOLIOS_FAILED";

export const GET_DASHBOARD_ALL_STATS_START =
  "DASHBOARD.GET_DASHBOARD_ALL_STATS_START";
export const GET_DASHBOARD_ALL_STATS_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_ALL_STATS_SUCCESS";
export const GET_DASHBOARD_ALL_STATS_FAILED =
  "DASHBOARD.GET_DASHBOARD_ALL_STATS_FAILED";

export const GET_DASHBOARD_COMPOSITION_START =
  "DASHBOARD.GET_DASHBOARD_COMPOSITION_START";
export const GET_DASHBOARD_COMPOSITION_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_COMPOSITION_SUCCESS";
export const GET_DASHBOARD_COMPOSITION_FAILED =
  "DASHBOARD.GET_DASHBOARD_COMPOSITION_FAILED";

export const GET_DASHBOARD_STYLE_RETURN_START =
  "DASHBOARD.GET_DASHBOARD_STYLE_RETURN_START";
export const GET_DASHBOARD_STYLE_RETURN_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_STYLE_RETURN_SUCCESS";
export const GET_DASHBOARD_STYLE_RETURN_FAILED =
  "DASHBOARD.GET_DASHBOARD_STYLE_RETURN_FAILED";

export const SET_RETURN_TO_VOLATILITY_PERIOD =
  "DASHBOARD.SET_RETURN_TO_VOLATILITY_PERIOD";

export const GET_DASHBOARD_RETURN_TO_VOLATILITY_START =
  "DASHBOARD.GET_DASHBOARD_RETURN_TO_VOLATILITY_START";
export const GET_DASHBOARD_RETURN_TO_VOLATILITY_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_RETURN_TO_VOLATILITY_SUCCESS";
export const GET_DASHBOARD_RETURN_TO_VOLATILITY_FAILED =
  "DASHBOARD.GET_DASHBOARD_RETURN_TO_VOLATILITY_FAILED";

export const GET_DASHBOARD_METHODOLOGY_INFO_CLEAR =
  "DASHBOARD.GET_DASHBOARD_METHODOLOGY_INFO_CLEAR";
export const GET_DASHBOARD_METHODOLOGY_INFO_START =
  "DASHBOARD.GET_DASHBOARD_METHODOLOGY_INFO_START";
export const GET_DASHBOARD_METHODOLOGY_INFO_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_METHODOLOGY_INFO_SUCCESS";
export const GET_DASHBOARD_METHODOLOGY_INFO_FAILED =
  "DASHBOARD.GET_DASHBOARD_METHODOLOGY_INFO_FAILED";

export const SET_METHODOLOGY_INFORMATION =
  "DASHBOARD.SET_METHODOLOGY_INFORMATION";

export const SET_SELECTED_PRESET = "DASHBOARD.SET_SELECTED_PRESET";

export const METHODOLOGY_RETURN_TO_VOLATILITY_START =
  "DASHBOARD.METHODOLOGY_RETURN_TO_VOLATILITY_START";
export const METHODOLOGY_RETURN_TO_VOLATILITY_SUCCESS =
  "DASHBOARD.METHODOLOGY_RETURN_TO_VOLATILITY_SUCCESS";
export const METHODOLOGY_RETURN_TO_VOLATILITY_FAILED =
  "DASHBOARD.METHODOLOGY_RETURN_TO_VOLATILITY_FAILED";

export const GET_DASHBOARD_BENCHMARK_TITLES_START =
  "DASHBOARD.GET_DASHBOARD_BENCHMARK_TITLES_START";
export const GET_DASHBOARD_BENCHMARK_TITLES_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_BENCHMARK_TITLES_SUCCESS";
export const GET_DASHBOARD_BENCHMARK_TITLES_FAILED =
  "DASHBOARD.GET_DASHBOARD_BENCHMARK_TITLES_FAILED";

export const GET_DASHBOARD_STRATEGIES_TITLES_START =
  "DASHBOARD.GET_DASHBOARD_STRATEGIES_TITLES_START";
export const GET_DASHBOARD_STRATEGIES_TITLES_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_STRATEGIES_TITLES_SUCCESS";
export const GET_DASHBOARD_STRATEGIES_TITLES_FAILED =
  "DASHBOARD.GET_DASHBOARD_STRATEGIES_TITLES_FAILED";

export const GET_DASHBOARD_ALL_PORTFOLIOS_START =
  "DASHBOARD.GET_DASHBOARD_ALL_PORTFOLIOS_START";
export const GET_DASHBOARD_ALL_PORTFOLIOS_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_ALL_PORTFOLIOS_SUCCESS";
export const GET_DASHBOARD_ALL_PORTFOLIOS_FAILED =
  "DASHBOARD.GET_DASHBOARD_ALL_PORTFOLIOS_FAILED";

export const GET_DASHBOARD_PRESET_TITLES_START =
  "DASHBOARD.GET_DASHBOARD_PRESET_TITLES_START";
export const GET_DASHBOARD_PRESET_TITLES_SUCCESS =
  "DASHBOARD.GET_DASHBOARD_PRESET_TITLES_SUCCESS";
export const GET_DASHBOARD_PRESET_TITLES_FAILED =
  "DASHBOARD.GET_DASHBOARD_PRESET_TITLES_FAILED";

export const SAVE_DASHBOARD_PRESET_START =
  "DASHBOARD.SAVE_DASHBOARD_PRESET_START";
export const SAVE_DASHBOARD_PRESET_SUCCESS =
  "DASHBOARD.SAVE_DASHBOARD_PRESET_SUCCESS";
export const SAVE_DASHBOARD_PRESET_FAILED =
  "DASHBOARD.SAVE_DASHBOARD_PRESET_FAILED";

export const LOAD_DASHBOARD_PRESET_STATS_START =
  "DASHBOARD.LOAD_DASHBOARD_PRESET_STATS_START";
export const LOAD_DASHBOARD_PRESET_STATS_SUCCESS =
  "DASHBOARD.LOAD_DASHBOARD_PRESET_STATS_SUCCESS";
export const LOAD_DASHBOARD_PRESET_STATS_FAILED =
  "DASHBOARD.LOAD_DASHBOARD_PRESET_STATS_FAILED";

export const LOAD_EDITABLE_DASHBOARD_PRESET_START =
  "DASHBOARD.LOAD_EDITABLE_DASHBOARD_PRESET_START";
export const LOAD_EDITABLE_DASHBOARD_PRESET_SUCCESS =
  "DASHBOARD.LOAD_EDITABLE_DASHBOARD_PRESET_SUCCESS";
export const LOAD_EDITABLE_DASHBOARD_PRESET_FAILED =
  "DASHBOARD.LOAD_EDITABLE_DASHBOARD_PRESET_FAILED";

export const RESET_EDITABLE_DASHBOARD_PRESET =
  "DASHBOARD.RESET_EDITABLE_DASHBOARD_PRESET";

export const UPDATE_DASHBOARD_PRESET_START =
  "DASHBOARD.UPDATE_DASHBOARD_PRESET_START";
export const UPDATE_DASHBOARD_PRESET_SUCCESS =
  "DASHBOARD.UPDATE_DASHBOARD_PRESET_SUCCESS";
export const UPDATE_DASHBOARD_PRESET_FAILED =
  "DASHBOARD.UPDATE_DASHBOARD_PRESET_FAILED";

export const DELETE_DASHBOARD_PRESET_START =
  "DASHBOARD.DELETE_DASHBOARD_PRESET_START";
export const DELETE_DASHBOARD_PRESET_SUCCESS =
  "DASHBOARD.DELETE_DASHBOARD_PRESET_SUCCESS";
export const DELETE_DASHBOARD_PRESET_FAILED =
  "DASHBOARD.DELETE_DASHBOARD_PRESET_FAILED";

export const UPDATE_DEFAULT_DASHBOARD_PRESET =
  "DASHBOARD.UPDATE_DEFAULT_DASHBOARD_PRESET";
