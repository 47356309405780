import { requestStatuses } from "../../helpers/Constants";
import SnackBarUtils from "../../helpers/SnackBarUtils";
import { generateRandomColor } from "../../helpers/Strategy";
import * as FactorsTypes from "../types/Factor";
import {
  LOGOUT,
  RESET_DEFAULT,
  UPDATE_WIN_RATE_TABLE_FACTORS_SUCCESS,
} from "../actions/Type";

const { idle } = requestStatuses;

const defaultRebalanceValue = ["Beginning of Quarter"];
const defaultPositionValue = "Top-Index";
const defaultFiltersValue = ["$1M Turnover", "ILS 350K Turnover", "Largest 35"];
const defaultSizeValue = ["20"];
const defaultCounryValue = ["United States"];

const initialState = {
  summaryPerfomanceGroupBy: null,
  rebalanceFilter: defaultRebalanceValue,
  rebalanceList: [],
  methodologiesFilter: [],
  methodologyList: [],
  position: defaultPositionValue,
  positionList: [],
  filtersFilter: defaultFiltersValue,
  filterList: [],
  sizeFilter: defaultSizeValue,
  sizeList: [],
  countryFilter: defaultCounryValue,
  countriesList: [],
  currencyFilter: "LCL",
  benchmarksStatistic: {},
  mtdlgStyle: {},
  portfoliosStatistic: {},
  bookmarkedPortfolios: {},
  newStatistics: {},
  portfoliosStrategies: {
    byId: {},
  },
  orderOfStrategies: [],
  selectedStatisticsStrategies: [],
  loaders: {
    isRebalanceListLoading: false,
    isCountriesListLoading: false,
    isMethodologyListLoading: false,
    isFilterListLoading: false,
    isBenchmarksStatisticLoading: false,
    isPortfoliosStatisticLoading: false,
    isPortfoliosStrategiesLoading: false,
    isMtdlgStyleLoading: false,
    isHistoryDatesLoading: false,
  },
  activeTab: 0,
  selectedHistoryDate: null,
  historyDates: [],
};

function deleteItemFromObject(object, key) {
  delete object[key];
  return object;
}

function saveStatistics(state, action) {
  const { payload } = action;
  const {
    start_date,
    end_date,
    win_rate_table,
    beta_table,
    correlation_matrix,
    rolling_returns,
    rolling_correlation,
    alpha_table,
    ...strats
  } = payload;

  return {
    byId: {
      ...state.portfoliosStrategies.byId,
      ...Object.assign(
        {},
        ...Object.keys(strats).map((key) => {
          return {
            [key]: {
              ...state.portfoliosStrategies.byId[key],
              id: key,
              name: `${state.newStatistics[key].methodology_id} ${state.newStatistics[key].country_id}`,
              strategyCreator: "-",
              color: generateRandomColor(),
              longPos: 0,
              shortPos: 0,
              exposure: 0,
              past: [],
              present: {
                benchmarks: { byId: [], byAstId: new Set() },
                portfolios: { byId: [], byAstId: new Set() },
              },
              future: [],
              nestedStrategies: [],
              date: new Date().toISOString().slice(0, 10),
              initializationDates: [],
              stocks: {
                stocks: [],
                status: idle,
                error: null,
              },
              stats: {
                periodRets: payload[key]["performance_summary"],
                rollingRets: rolling_returns[key],
                cumulitiveRets: payload[key]["cumulative_returns"],
                yearly: payload[key]["year_returns"],
                monthly: payload[key]["month_returns"],
                weekly: payload[key]["week_returns"],
                strategy_returns: payload[key]["strategy_returns"],
                best_worst_periods: payload[key]["best_worst_periods"],
                returns_distribution: payload[key]["returns_distribution"],
                underwater: payload[key]["underwater"],
                worst_drawdowns: payload[key]["worst_drawdowns"],
              },
            },
          };
        })
      ),
    },
    winRate: win_rate_table != null ? win_rate_table : {},
    beta: beta_table != null ? beta_table : {},
    correllation_matrix:
      correlation_matrix != null && Object.keys(correlation_matrix).length > 0
        ? correlation_matrix
        : {},
    rollingCorellation: rolling_correlation,
    startDate: start_date,
    endDate: end_date,
  };
}

function updateFilterValue(currentState, payloadData) {
  if (!payloadData.length || !currentState.length) {
    return [];
  }

  const res = payloadData
    .filter((x) => currentState.includes(x.value))
    .map((item) => item.value);

  if (!res.length) {
    return [payloadData[0].value];
  }

  return res;
}

export default function factor(state = initialState, action) {
  switch (action.type) {
    case FactorsTypes.GET_REBALANCE_LIST_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isRebalanceListLoading: true,
        },
      };
    case FactorsTypes.GET_REBALANCE_LIST_SUCCESS:
      return {
        ...state,
        rebalanceList: action.payload,
        loaders: {
          ...state.loaders,
          isRebalanceListLoading: false,
        },
      };
    case FactorsTypes.GET_REBALANCE_LIST_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isRebalanceListLoading: false,
        },
      };

    case FactorsTypes.GET_COUNTRIES_LIST_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isCountriesListLoading: true,
        },
      };
    case FactorsTypes.GET_COUNTRIES_LIST_SUCCESS:
      return {
        ...state,
        countriesList: action.payload,
        loaders: {
          ...state.loaders,
          isCountriesListLoading: false,
        },
      };
    case FactorsTypes.GET_COUNTRIES_LIST_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isCountriesListLoading: false,
        },
      };

    case FactorsTypes.GET_METHODOLOGY_LIST_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isMethodologyListLoading: true,
        },
      };
    case FactorsTypes.GET_METHODOLOGY_LIST_SUCCESS:
      const mtdlg_list = action.payload.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );
      return {
        ...state,
        methodologyList: mtdlg_list,
        loaders: {
          ...state.loaders,
          isMethodologyListLoading: false,
        },
      };
    case FactorsTypes.GET_METHODOLOGY_LIST_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isMethodologyListLoading: false,
        },
      };

    case FactorsTypes.GET_POSITION_LIST_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isPositionListLoading: true,
        },
      };
    case FactorsTypes.GET_POSITION_LIST_SUCCESS:
      return {
        ...state,
        positionList: action.payload,
        loaders: {
          ...state.loaders,
          isPositionListLoading: false,
        },
      };
    case FactorsTypes.GET_POSITION_LIST_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isPositionListLoading: false,
        },
      };

    case FactorsTypes.GET_FILTER_LIST_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isFilterListLoading: true,
        },
      };
    case FactorsTypes.GET_FILTER_LIST_SUCCESS:
      return {
        ...state,
        filterList: action.payload,
        loaders: {
          ...state.loaders,
          isFilterListLoading: false,
        },
      };
    case FactorsTypes.GET_FILTER_LIST_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isFilterListLoading: false,
        },
      };

    case FactorsTypes.GET_SIZE_LIST_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isSizeListLoading: true,
        },
      };
    case FactorsTypes.GET_SIZE_LIST_SUCCESS:
      const sizes = action.payload.sort((a, b) =>
        +a.value > +b.value ? 1 : +b.value > +a.value ? -1 : 0
      );
      return {
        ...state,
        sizeList: sizes,
        loaders: {
          ...state.loaders,
          isSizeListLoading: false,
        },
      };
    case FactorsTypes.GET_SIZE_LIST_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isSizeListLoading: false,
        },
      };

    case FactorsTypes.GET_BENCHMARKS_STATISTIC_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isBenchmarksStatisticLoading: true,
        },
      };

    case FactorsTypes.GET_MTDLG_STYLE_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isMtdlgStyleLoading: true,
        },
      };

    case FactorsTypes.GET_BENCHMARKS_STATISTIC_SUCCESS:
      return {
        ...state,
        benchmarksStatistic: action.payload,
        loaders: {
          ...state.loaders,
          isBenchmarksStatisticLoading: false,
        },
      };

    case FactorsTypes.GET_MTDLG_STYLE_SUCCESS:
      return {
        ...state,
        mtdlgStyle: action.payload,
        loaders: {
          ...state.loaders,
          isMtdlgStyleLoading: false,
        },
      };

    case FactorsTypes.GET_MTDLG_STYLE_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isMtdlgStyleLoading: false,
        },
      };
    case FactorsTypes.GET_BENCHMARKS_STATISTIC_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isBenchmarksStatisticLoading: false,
        },
      };

    case FactorsTypes.GET_PORTFOLIOS_STATISTIC_START:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isPortfoliosStatisticLoading: true,
        },
      };
    case FactorsTypes.GET_PORTFOLIOS_STATISTIC_SUCCESS:
      const res = Object.values(state.newStatistics)
        .filter((item) => action.payload[item.portfolio_id] && item)
        .reduce((acc, val) => ({ ...acc, [val.asset_id]: val }), {});

      const val =
        Object.keys(state.newStatistics).length - Object.keys(res).length;

      if (val) {
        SnackBarUtils.warning(
          `${val} ${val === 1 ? "factor" : "factors"} was removed`
        );
      }

      return {
        ...state,
        portfoliosStatistic: action.payload,
        newStatistics: res,
        loaders: {
          ...state.loaders,
          isPortfoliosStatisticLoading: false,
        },
      };
    case FactorsTypes.GET_PORTFOLIOS_STATISTIC_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isPortfoliosStatisticLoading: false,
        },
      };

    case FactorsTypes.CLEAR_PORTFOLIOS_STATISTIC:
      return {
        ...state,
        portfoliosStatistic: {},
      };

    case FactorsTypes.ADD_BOOKMARKED_PORTFOLIO:
      return {
        ...state,
        bookmarkedPortfolios: {
          ...state.bookmarkedPortfolios,
          [action.payload.asset_id]: action.payload,
        },
      };
    case FactorsTypes.REMOVE_BOOKMARKED_PORTFOLIO:
      return {
        ...state,
        bookmarkedPortfolios: deleteItemFromObject(
          state.bookmarkedPortfolios,
          action.payload
        ),
      };

    case FactorsTypes.CLEAR_NEW_STATISTIC: {
      return {
        ...state,
        newStatistics: {},
      };
    }

    case FactorsTypes.ADD_PORTFOLIO_TO_STATISTIC:
      return {
        ...state,
        newStatistics: {
          ...state.newStatistics,
          [action.payload.asset_id]: action.payload,
        },
      };

    case FactorsTypes.REMOVE_ALL_PORTFOLIO_FROM_STATISTIC:
      return {
        ...state,
        newStatistics: {},
      };

    case FactorsTypes.REMOVE_PORTFOLIO_FROM_STATISTIC:
      return {
        ...state,
        newStatistics: deleteItemFromObject(
          state.newStatistics,
          action.payload
        ),
      };

    case FactorsTypes.GET_PORTFOLIOS_STRATEGIES_START: {
      return {
        ...state,
        portfoliosStrategies:
          action.payload === false
            ? {
                byId: {},
              }
            : { ...state.portfoliosStrategies },
        loaders: {
          ...state.loaders,
          isPortfoliosStrategiesLoading: true,
        },
      };
    }

    case FactorsTypes.GET_PORTFOLIOS_STRATEGIES_SUCCESS: {
      const { payload } = action;
      const {
        start_date,
        end_date,
        win_rate_table,
        beta_table,
        correlation_matrix,
        rolling_returns,
        rolling_correlation,
        alpha_table,
        ...strats
      } = payload;

      return {
        ...state,
        portfoliosStrategies: saveStatistics(state, action),
        orderOfStrategies: Object.keys(strats),
        selectedStatisticsStrategies: Object.keys(strats).filter(
          (strategy) =>
            Object.keys(strats[strategy].cumulative_returns).length > 0
        ),
        loaders: {
          ...state.loaders,
          isPortfoliosStrategiesLoading: false,
        },
      };
    }

    case FactorsTypes.GET_PORTFOLIOS_STRATEGIES_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isPortfoliosStrategiesLoading: false,
        },
      };
    }

    case FactorsTypes.SET_SELECTED_PORTFOLIO_STRATEGY: {
      const { payload } = action;
      return {
        ...state,
        selectedStatisticsStrategies:
          state.selectedStatisticsStrategies.indexOf(payload) >= 0
            ? state.selectedStatisticsStrategies.filter(
                (item) => item !== payload
              )
            : [...state.selectedStatisticsStrategies, payload],
      };
    }

    case FactorsTypes.SET_ALL_PORTFOLIOS_TO_STATISTIC: {
      const res = {};

      Object.values(state.portfoliosStatistic).forEach((element) => {
        res[element.asset_id] = element;
      });

      return {
        ...state,
        newStatistics: res,
      };
    }

    case FactorsTypes.SET_FACTOR_POSITION: {
      return {
        ...state,
        position: action.payload,
      };
    }

    case FactorsTypes.SET_FACTOR_FILTER_VALUE: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }

    case FactorsTypes.SET_FACTOR_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }

    case FactorsTypes.SET_FACTOR_SUMMARY_PERFOMANCE_GROUP_BY: {
      return {
        ...state,
        summaryPerfomanceGroupBy: action.payload,
      };
    }

    case FactorsTypes.GET_HISTORY_DATES_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isHistoryDatesLoading: true,
        },
      };
    }
    case FactorsTypes.GET_HISTORY_DATES_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isHistoryDatesLoading: false,
        },
        historyDates: action.payload.map((item) => item.execution_date),
      };
    }
    case FactorsTypes.GET_HISTORY_DATES_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isHistoryDatesLoading: false,
        },
      };
    }

    case FactorsTypes.SET_HISTORY_DATE: {
      return {
        ...state,
        selectedHistoryDate: action.payload,
      };
    }

    case FactorsTypes.UPDATE_FACTORS_FILTERS_VALUES_START: {
      return {
        ...state,
      };
    }
    case FactorsTypes.UPDATE_FACTORS_FILTERS_VALUES_SUCCESS: {
      return {
        ...state,
        rebalanceList: action.payload.rebalanceList,
        methodologyList: action.payload.methodologyList,
        filterList: action.payload.filterList,
        sizeList: action.payload.sizeList,
        countriesList: action.payload.countriesList,

        rebalanceFilter: updateFilterValue(
          state.rebalanceFilter,
          action.payload.rebalanceList
        ),
        filtersFilter: updateFilterValue(
          state.filtersFilter,
          action.payload.filterList
        ),
        sizeFilter: updateFilterValue(
          state.sizeFilter,
          action.payload.sizeList
        ),
        countryFilter: updateFilterValue(
          state.countryFilter,
          action.payload.countriesList
        ),
        methodologiesFilter: updateFilterValue(
          state.methodologiesFilter,
          action.payload.methodologyList
        ),
      };
    }
    case FactorsTypes.UPDATE_FACTORS_FILTERS_VALUES_FAILED: {
      return {
        ...state,
      };
    }

    case UPDATE_WIN_RATE_TABLE_FACTORS_SUCCESS: {
      return {
        ...state,
        portfoliosStrategies: {
          ...state.portfoliosStrategies,
          winRate: action.payload,
        },
      };
    }

    case RESET_DEFAULT: {
      return {
        ...state,
        activeTab: 0,
        position: defaultPositionValue,
        rebalanceFilter: updateFilterValue(
          defaultRebalanceValue,
          state.rebalanceList
        ),
        filtersFilter: updateFilterValue(defaultFiltersValue, state.filterList),
        sizeFilter: updateFilterValue(defaultSizeValue, state.sizeList),
        countryFilter: updateFilterValue(
          defaultCounryValue,
          state.countriesList
        ),
        currencyFilter: "LCL",
        methodologiesFilter: [],
        summaryPerfomanceGroupBy: null,
        newStatistics: {},
        selectedHistoryDate: null,
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
}
